import { valueMapping } from '@stylable/core';

import type { StylableDriver } from './stylable-driver';
import type { FullDeclarationMap } from './types';
import { getStaticComputedCSS } from './get-static-computed-css';
import { DEFAULT_FILTER, SELECTOR_STATE_REGEX } from './stylable-aggregation-constants';

export type FilterFunc = (prop: string) => boolean;

function matchRegex(selector: string, matcher: RegExp) {
    const match = selector.match(matcher);
    return { match, name: match ? match[1] : '' };
}

export class StylableAggregation {
    constructor(private stylableDriver: StylableDriver) {}
    /**
     * If multiple stylesheet provided we assume that they extending the same Base, we are fully aggregating the base and the variant (deep).
     * !All stylesheets following the first variant will be aggregated from it (shallow).
     * https://jira.wixpress.com/browse/STYL-693?filter=-1
     */
    public aggregateSelectorDeclarations(
        sheetPath: string,
        selector: string,
        filter: FilterFunc = DEFAULT_FILTER,
        useRawDecl = false,
        shallow?: boolean
    ): FullDeclarationMap {
        const sheet = this.stylableDriver.getStylesheet(sheetPath);
        if (!sheet) {
            return {};
        }

        const stateName = matchRegex(selector, SELECTOR_STATE_REGEX).name;

        // TODO: this is work around issue for when to filter -st- stuff related to states (should be removed)
        const filterDecl = stateName
            ? (prop: string) => prop !== valueMapping.extends && prop !== valueMapping.states && filter(prop)
            : filter;

        try {
            return getStaticComputedCSS(this.stylableDriver.stylable, sheet.getMeta(), selector, {
                filterDecl,
                scopes: sheet.getActiveScopes(),
                useRawDecl,
                shallow,
            }).decls;
        } catch (e) {
            throw new Error('getStaticComputedCSS error caused by:\n' + (e as Error)?.stack);
        }
    }
}
