import React, { useEffect, useState } from 'react';

import { SiteVarsDriver, StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';
import { Tabs, SectionDesc, OptimisticWrapper } from '@wixc3/stylable-panel-components';

import {
    ColorPicker,
    ColorPickerProps,
    CustomColorPicker,
    getColorPickerAPI,
    COLOR_VALUE_TYPE,
} from '../color-picker/color-picker';
import { GradientPicker, GradientPickerProps, GRADIENT_VALUE_TYPE } from '../gradient-picker/gradient-picker';
import { ImageFill, ImageFillProps, IMAGE_VALUE_TYPE } from '../../inputs/background/image-fill/image-fill';
import type { CustomSkinsPicker } from '../my-skins-picker/my-skins-picker';
import type { StylablePanelHost, DeclarationVisualizerDrivers } from '../../types';
import { PanelEventList } from '../../hosts/bi';
import { getTranslate } from '../../hosts/translate';
import { FillPickerContext } from './fill-picker-context';

import { style, classes } from './fill-picker.st.css';

export enum FillPickerTabs {
    Solid = 0,
    Gradient,
    Image,
}

interface FillPickerSingleSectionConfig {
    id: string;
    titleKey: string;
    defaultValue: string;
}

export const FillPickerSectionConfig: Record<FillPickerTabs, FillPickerSingleSectionConfig> = {
    [FillPickerTabs.Solid]: {
        id: 'solid',
        titleKey: StylablePanelTranslationKeys.picker.fill.section.solidTitle,
        defaultValue: 'value(site_1_1)',
    },
    [FillPickerTabs.Gradient]: {
        id: 'gradient',
        titleKey: StylablePanelTranslationKeys.picker.fill.section.gradientTitle,
        defaultValue: 'linear-gradient(value(site_2_2), value(site_3_3))',
    },
    [FillPickerTabs.Image]: {
        id: 'image',
        titleKey: StylablePanelTranslationKeys.picker.fill.section.imageTitle,
        defaultValue:
            'no-repeat url("https://static.wixstatic.com/media/d9657b20f1b9473f90815b9da211bb2a.jpg/v1/fit/w_924,h_520/d9657b20f1b9473f90815b9da211bb2a.jpg") 50%/cover',
    },
};

const getFillPickerSections = (translate: (key: string) => string) =>
    Object.keys(FillPickerSectionConfig).map(
        (_key: string, currTab: FillPickerTabs) =>
            ({
                id: FillPickerSectionConfig[currTab].id,
                customButton: () => translate(FillPickerSectionConfig[currTab].titleKey),
            } as SectionDesc)
    );

const DEFAULT_TAB = FillPickerTabs.Solid;
const GRADIENT_SNAP_THRESHOLD = 2;
const GRADIENT_DEFAULT_STARTING_SCALE = '150%';

export interface PickerPropOverrides {
    [COLOR_VALUE_TYPE]?: Partial<ColorPickerProps>;
    [GRADIENT_VALUE_TYPE]?: Partial<GradientPickerProps>;
    [IMAGE_VALUE_TYPE]?: Partial<ImageFillProps>;
}

export interface FillPickerProps {
    title?: string;
    siteVarsDriver?: SiteVarsDriver;
    value?: string;
    drivers?: DeclarationVisualizerDrivers;
    tab?: FillPickerTabs; // TODO: Determine preferredTab according to value?
    panelHost?: StylablePanelHost;
    onChange?: (value: string | null, tab: FillPickerTabs) => void;
    onClose?: () => void;
    border?: boolean;
    customColorPicker?: CustomColorPicker;
    customSkinsPicker?: CustomSkinsPicker;
    pickerPropOverrides?: PickerPropOverrides;
    className?: string;
}

type TabValues = Record<FillPickerTabs, string>;

const getTabValues = (defaultImage?: string) =>
    Object.keys(FillPickerSectionConfig).reduce((defaultValues, _value: string, tab: FillPickerTabs) => {
        defaultValues[tab] =
            tab === FillPickerTabs.Image && defaultImage
                ? `${FillPickerSectionConfig[tab].defaultValue}`.replace(/url\(".*"\)/, defaultImage)
                : `${FillPickerSectionConfig[tab].defaultValue}`;
        return defaultValues;
    }, {} as Record<FillPickerTabs, string>);

export const FillPickerInner = ({
    border,
    className,
    customColorPicker,
    customSkinsPicker,
    drivers,
    onChange,
    panelHost,
    pickerPropOverrides,
    siteVarsDriver,
    tab: propTab,
    value,
}: FillPickerProps) => {
    const [tabValues, setTabValues] = useState<TabValues>(getTabValues(panelHost?.defaultImage));
    const [currentTabValue, setCurrentTabValue] = useState<string | undefined>(value);
    const [fallbackValue, setFallbackValue] = useState<string | undefined>();
    const [tab, setTab] = useState<FillPickerTabs>(propTab || DEFAULT_TAB);

    useEffect(() => {
        setCurrentTabValue(value);
    }, [value]);

    const onChildFallback = (value: string | undefined) => {
        setFallbackValue(value);
    };

    const handleChange = (tab: FillPickerTabs, value: string | null) => {
        if (!onChange) {
            return;
        }

        setCurrentTabValue(value || undefined);
        onChange(value, tab);
    };

    const contentFunction = (id: string) => {
        const ColorPickerComp = customColorPicker ?? ColorPicker;
        const colorPickerOverrides = pickerPropOverrides ? pickerPropOverrides[COLOR_VALUE_TYPE] || {} : {};

        switch (id) {
            case FillPickerSectionConfig[FillPickerTabs.Solid].id:
                return (
                    <ColorPickerComp
                        className={classes.colorPicker}
                        id={FillPickerSectionConfig[FillPickerTabs.Solid].id}
                        {...getColorPickerAPI(
                            currentTabValue,
                            (value) => handleChange(FillPickerTabs.Solid, value),
                            drivers
                        )}
                        onHover={(value) => handleChange(FillPickerTabs.Solid, value)}
                        customSkinsPicker={customSkinsPicker}
                        {...colorPickerOverrides}
                    />
                );
            case FillPickerSectionConfig[FillPickerTabs.Gradient].id: {
                const gradientPickerOverrides = pickerPropOverrides
                    ? pickerPropOverrides[GRADIENT_VALUE_TYPE] || {}
                    : {};
                return (
                    <GradientPicker
                        className={classes.gradientPicker}
                        id={FillPickerSectionConfig[FillPickerTabs.Gradient].id}
                        siteVarsDriver={siteVarsDriver}
                        value={currentTabValue}
                        drivers={drivers}
                        panelHost={panelHost}
                        onChange={(value) => handleChange(FillPickerTabs.Gradient, value)}
                        customColorPicker={customColorPicker}
                        customSkinsPicker={customSkinsPicker}
                        colorPickerOverrides={colorPickerOverrides}
                        snapThreshold={GRADIENT_SNAP_THRESHOLD}
                        startingScale={GRADIENT_DEFAULT_STARTING_SCALE}
                        onFallback={(value) => onChildFallback(value)}
                        {...gradientPickerOverrides}
                    />
                );
            }
            case FillPickerSectionConfig[FillPickerTabs.Image].id: {
                const imagePickerOverrides = pickerPropOverrides ? pickerPropOverrides[IMAGE_VALUE_TYPE] || {} : {};

                return (
                    <ImageFill
                        className={classes.imageFill}
                        id={FillPickerSectionConfig[FillPickerTabs.Image].id}
                        panelHost={panelHost}
                        onChange={(value: string) => handleChange(FillPickerTabs.Image, value)}
                        reportBI={panelHost?.reportBI}
                        value={currentTabValue}
                        onFallback={(value) => onChildFallback(value)}
                        {...imagePickerOverrides}
                    />
                );
            }
        }
        return;
    };

    const handleSelectTab = (newTab: FillPickerTabs) => {
        panelHost?.unblockCommits && panelHost.unblockCommits(true, FillPickerSectionConfig[tab].id);

        const newTabValues = { ...tabValues };

        newTabValues[tab] = fallbackValue || currentTabValue || FillPickerSectionConfig[tab].defaultValue;
        setFallbackValue(undefined);

        const changeValue = newTabValues[newTab];

        setCurrentTabValue(changeValue);
        setTab(newTab);
        onChange && onChange(changeValue, newTab);

        setTabValues(newTabValues);

        const { FILL_PICKER_TAB_CLICK } = PanelEventList;
        panelHost?.reportBI && panelHost.reportBI(FILL_PICKER_TAB_CLICK, { tab: FillPickerSectionConfig[newTab].id });
    };

    const getTabSections = (translate: (key: string) => string) =>
        border
            ? getFillPickerSections(translate).filter(
                  (value) => value.id !== FillPickerSectionConfig[FillPickerTabs.Image].id
              )
            : getFillPickerSections(translate);

    return (
        <FillPickerContext.Provider value={{ siteVarsDriver, panelHost }}>
            <Tabs
                data-aid="st_fill_picker"
                className={style(classes.root, className)}
                sections={getTabSections(getTranslate(panelHost))}
                content={contentFunction}
                preferredTab={FillPickerSectionConfig[tab].id}
                onSelectTab={(index: number) => handleSelectTab(index as FillPickerTabs)}
                noScroll
            />
        </FillPickerContext.Provider>
    );
};

export const FillPicker = OptimisticWrapper(FillPickerInner as any, {});

FillPicker.panelName = 'fill';
