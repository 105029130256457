import type { UnitEditInfo } from '@wixc3/stylable-panel-components';

export const percentageEditInfo: Record<string, UnitEditInfo> = {
    percents: { unit: '%', min: 0, max: 100, step: 1, shiftStep: 10 },
};

export const percentageScaleEditInfo: Record<string, UnitEditInfo> = {
    percents: { unit: '%', min: 1, max: 200, maxRange: 10000, step: 1, shiftStep: 10 },
};

export const numberUnitEditInfo: Record<string, UnitEditInfo> = {
    // none:     {unit: '',   min: 0,    max: 10,  step: 1, shiftStep: 10},
    pixels: { unit: 'px', min: -100, max: 100, step: 1, shiftStep: 10 },
    ...percentageEditInfo,
    elements: { unit: 'em', min: 0, max: 5, step: 0.1 },
};

export const backgroundSizeEditInfo: Record<string, UnitEditInfo> = {
    ...percentageScaleEditInfo,
    pixels: { unit: 'px', min: 1, max: 1000, step: 10, optional: true, shiftStep: 10 },
};

export const patternBackgroundSizeEditInfo: Record<string, UnitEditInfo> = {
    percents: { unit: '%', min: 1, max: 100, step: 1, shiftStep: 10 },
    pixels: { unit: 'px', min: 1, max: 200, step: 5, shiftStep: 10 },
};

export const degreesEditInfo: Record<string, UnitEditInfo> = {
    percents: { unit: 'deg', min: 0, max: 360, step: 1, shiftStep: 10 },
};

export const cornerEditInfo: Record<string, UnitEditInfo> = Object.assign(numberUnitEditInfo, {
    pixels: { unit: 'px', min: 0, max: 30, step: 1, shiftStep: 10 },
});

export const shadowEditDistanceInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: -100, max: 100, step: 1, maxRange: 100, shiftStep: 10 },
};

export const shadowEditBlurInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 0, max: 15, step: 1, maxRange: 100, shiftStep: 10 },
};

export const paddingEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 0, max: 30, step: 1, maxRange: 100, shiftStep: 10 },
};

export const shadowSizeEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: -100, max: 100, step: 1, maxRange: 100, shiftStep: 10 },
};

export const opacityEditInfo: Record<string, UnitEditInfo> = {
    opacity: { unit: '', min: 0, max: 100, step: 1 },
};

export const genericEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 0, max: 40, step: 1, shiftStep: 10 },
    elements: { unit: 'em', min: 0, max: 5, step: 0.1 },
};

export const fractionEditInfo: Record<string, UnitEditInfo> = {
    none: { unit: '', min: 0, max: 1, step: 0.01, shiftStep: 10 },
};

export const weightEditInto: Record<string, UnitEditInfo> = {
    weight: { unit: '', min: 100, max: 900, step: 100, shiftStep: 10 },
};

export const timingEditInto: Record<string, UnitEditInfo> = {
    miliseconds: { unit: 'ms', min: 0, max: 2000, step: 20, shiftStep: 10 },
    seconds: { unit: 's', min: 0, max: 4, step: 0.2, shiftStep: 10 },
};

export const fontEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 6, max: 100, step: 1, maxRange: 10000, shiftStep: 10 },
    // elements: { unit: 'em', min: 0.1, max: 5, step: 0.1, maxRange: 10000, shiftStep: 10 },
    // rootElements: { unit: 'rem', min: 0.1, max: 5, step: 0.1, maxRange: 10000 }
};

export const iconSizeEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 6, max: 100, step: 1, maxRange: 1000, shiftStep: 10 },
    percents: { unit: '%', min: 1, max: 100, step: 1, maxRange: 5 },
};

export const childSpacingEditInfo: Record<string, UnitEditInfo> = {
    pixels: { unit: 'px', min: 0, max: 50, step: 1, shiftStep: 10 },
};

export const letterSpacingEditInfo: Record<string, UnitEditInfo> = {
    elements: { unit: 'em', min: -0.1, max: 0.7, step: 0.05, maxRange: 5, shiftStep: 10 },
    pixels: { unit: 'px', min: -4, max: 20, step: 1, optional: true, maxRange: 5, shiftStep: 10 },
    rootElements: { unit: 'rem', min: -0.1, max: 0.7, step: 0.05, optional: true, maxRange: 5 },
};

export const repeatsEditInto: Record<string, UnitEditInfo> = {
    repeats: { unit: '', min: 1, max: 10, step: 1, shiftStep: 10 },
};

export const gradientOffsetEditInto: Record<string, UnitEditInfo> = {
    offset: { unit: '', min: 0, max: 100, step: 1 },
};

export const gradientStripesOffsetEditInto: Record<string, UnitEditInfo> = {
    stripesOffset: { unit: 'px', min: 0, max: 50, step: 1 },
};

export const gradientSizeEditInto: Record<string, UnitEditInfo> = {
    size: { unit: '', min: 0, max: 100, step: 1 },
};

export const gradientStripesSizeEditInto: Record<string, UnitEditInfo> = {
    stripesSize: { unit: 'px', min: 0, max: 50, step: 1 },
};

export const gradientStepsEditInto: Record<string, UnitEditInfo> = {
    steps: { unit: '', min: 2, max: 12, step: 1 },
};
