import React from 'react';
import keycode from 'keycode';

import type { ValueInputProps } from '../../../types';
import type { ValueInputComponent } from '../../../drivers';
import { ContextInput } from '../context-input/context-input';

import { style, classes } from './number-input.st.css';

export function changeUnitValue(value: string, changeAmount: number, cursorPosition?: number) {
    let prefix = '';
    let suffix = '';
    let selectedValue = value;

    if (~value.indexOf(' ')) {
        const prefixEndIndex = value.slice(0, cursorPosition).lastIndexOf(' ') + 1;
        const suffixStartIndex = value.slice(cursorPosition || 0, value.length).indexOf(' ');
        const suffixIndex = suffixStartIndex !== -1 ? (cursorPosition || 0) + suffixStartIndex : value.length;

        prefix = value.slice(0, prefixEndIndex);
        suffix = value.slice(suffixIndex, value.length);
        selectedValue = value.slice(prefixEndIndex, suffixIndex);
    }

    if (selectedValue[0] === '.') {
        selectedValue = '0' + selectedValue;
    }
    const intValue = parseInt(selectedValue, 10);

    const units = selectedValue.slice(intValue.toString().length, selectedValue.length); // TODO opt in for default to px: ||'px'; + test

    const newValue = `${prefix}${intValue + changeAmount}${units}${suffix}`;
    return { value: newValue, intValue, units };
}

export class NumberInput extends React.Component<ValueInputProps> implements ValueInputComponent {
    public static type = 'NumberInput';

    private contextInput: ContextInput | null = null;
    private preserveSelection = -1;

    public focus() {
        this.contextInput && this.contextInput.focus();
    }
    public blur() {
        this.contextInput && this.contextInput.blur();
    }
    public select() {
        this.contextInput && this.contextInput.select();
    }
    public suggestionsShown() {
        return !!(this.contextInput && this.contextInput.suggestionsShown());
    }
    public suggestionsUsed() {
        return !!(this.contextInput && this.contextInput.suggestionsUsed());
    }

    public render() {
        const { value, contexts, siteVarsDriver, onChange, onFocus, onBlur, sheetDriver, className } = this.props;

        return (
            <ContextInput
                className={style(classes.root, className)}
                value={value}
                contexts={contexts}
                siteVarsDriver={siteVarsDriver}
                onChange={onChange}
                onKeyDown={this.handleKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                sheetDriver={sheetDriver}
                ref={(ref) => (this.contextInput = ref)}
            />
        );
    }

    public componentDidUpdate() {
        if (!this.contextInput) {
            return;
        }

        const input = this.contextInput.input();
        if (!input) {
            return;
        }

        if (this.preserveSelection !== -1) {
            input.setSelectionRange(this.preserveSelection, this.preserveSelection);
            this.preserveSelection = -1;
        }
    }

    private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { value, onChange, onKeyDown } = this.props;

        onKeyDown && onKeyDown(event);
        if (!this.contextInput || !onChange || !value || event.altKey) {
            return;
        }

        const input = this.contextInput.input();
        if (!input) {
            return;
        }

        let changeAmount = 0;
        switch (event.keyCode) {
            case keycode('up'):
                changeAmount = 1;
                break;
            case keycode('down'):
                changeAmount = -1;
                break;
            default:
                return;
        }

        const selectionEnd = input.selectionEnd || 0;

        const { value: newValue, intValue } = changeUnitValue(value, changeAmount, selectionEnd);

        if (isNaN(intValue)) {
            return;
        }

        let selectionOffset = 0;
        if (newValue.length > value.length) {
            selectionOffset = 1;
        } else if (newValue.length < value.length) {
            selectionOffset = -1;
        }
        this.preserveSelection = selectionEnd + selectionOffset;

        event.preventDefault();

        onChange(newValue);
    };
}
