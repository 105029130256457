import React from 'react';

import { DEFAULT_PLANE } from '@wixc3/stylable-panel-common';

import { Scrollbars } from '../scrollbars';
import { TabButton, TabIcon } from './tab-button';
import { Tooltip, TooltipAttachTo } from '../tooltip';

import { style, classes } from './tabs.st.css';

export interface SectionDesc {
    id: string;
    customButton?: TabIcon;
    highlight?: boolean;
}

export type Sections = SectionDesc[];
export type TabContent = JSX.Element | JSX.Element[] | undefined;

export enum TabsPlane {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
    VerticalCollapse = 'verticalCollapse',
}

export const DEFAULT_TABS_PLANE: TabsPlane = TabsPlane.Horizontal;

export interface TabsProps {
    sections: Sections;
    content: (id: string) => TabContent;
    onSelectTab?: (index: number) => void;
    sectionIdToTitlesFunction?: (id: string) => string;
    preferredTab?: string;
    initialTab?: number;
    plane?: TabsPlane;
    tooltips?: boolean;
    noSingleTab?: boolean;
    noScroll?: boolean;
    className?: string;
    'data-aid'?: string;
}

export interface TabsState {
    selectedTab: string | undefined;
    openSections: Record<string, boolean> | undefined;
}

export class Tabs extends React.Component<TabsProps, TabsState> {
    public static defaultProps: Partial<TabsProps> = {
        sectionIdToTitlesFunction: (id: string) => id,
        plane: DEFAULT_TABS_PLANE,
    };

    constructor(props: TabsProps) {
        super(props);

        const { sections, initialTab } = this.props;

        let selected;
        if (initialTab !== undefined && sections.length > initialTab) {
            selected = sections[initialTab].id;
        } else {
            selected = undefined;
        }

        this.state = {
            selectedTab: selected,
            openSections: undefined,
        };
    }

    public render() {
        const { sections, plane = DEFAULT_PLANE, className } = this.props;
        const { selectedTab } = this.state;

        if (sections.length < 1) {
            return <div>nothing to display</div>;
        }

        const singleTab = this.isSingleTab();
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;

        return (
            <div
                className={style(
                    classes.root,
                    {
                        selected: !!selectedTab,
                        plane,
                    },
                    className
                )}
                data-aid={this.props['data-aid']}
            >
                {isVerticalCollapse || !singleTab ? (
                    <nav className={classes.tabBar}>{this.renderTabSections()}</nav>
                ) : null}
                {!isVerticalCollapse ? this.renderTabContentContainer() : null}
            </div>
        );
    }

    public componentDidMount() {
        this.initTabs();
    }

    public componentDidUpdate() {
        this.initTabs();
    }

    private initTabs() {
        if (this.shouldOpenBestGuessTab()) {
            this.openBestGuessTab();
        }
    }

    private openBestGuessTab() {
        const { sections, preferredTab } = this.props;
        const { selectedTab } = this.state;

        if (sections.length > 0 && !sections.some((section) => section.id === selectedTab)) {
            // try preferred section first:
            const id = sections.some((section) => section.id === preferredTab) ? preferredTab : sections[0].id;

            this.setState({
                selectedTab: id,
                openSections: this.getOpenSections(this.props, id),
            });
        }
    }

    private renderTabSections() {
        const { sections, plane, tooltips } = this.props;
        const { selectedTab } = this.state;

        const hasSelection = selectedTab !== undefined;
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;
        const showTooltips = tooltips && hasSelection;

        let sectionRenderFunction = this.renderTabButton;
        if (isVerticalCollapse) {
            sectionRenderFunction = this.renderVerticalCollapseTabSections;
        } else if (showTooltips) {
            sectionRenderFunction = this.renderTabButtonTooltipWrapper;
        }

        return sections.map(sectionRenderFunction);
    }

    private renderTabButton = (section: SectionDesc, index: number) => {
        const { sectionIdToTitlesFunction = (id: string) => id, plane, tooltips } = this.props;
        const { selectedTab } = this.state;

        const hasSelection = selectedTab !== undefined;
        const isVertical = plane === TabsPlane.Vertical;
        const isVerticalCollapse = plane === TabsPlane.VerticalCollapse;
        const showTabTitle = isVerticalCollapse || (isVertical && !hasSelection);

        return (
            <TabButton
                key={`tab_button_${section.id}`}
                className={style(classes.tabButton, {
                    first: index === 0,
                    highlight: section.highlight || false,
                    noTooltips: !tooltips,
                })}
                id={section.id}
                title={showTabTitle ? sectionIdToTitlesFunction(section.id) : undefined}
                noIcon={isVerticalCollapse}
                selected={section.id === selectedTab}
                onClick={() => this.onTabButtonClick(index, section.id)}
                icon={section.customButton}
                openIndicator={isVerticalCollapse ? TabArrow : undefined}
            />
        );
    };

    private renderTabButtonTooltipWrapper = (section: SectionDesc, index: number) => {
        const { sectionIdToTitlesFunction = (id: string) => id, plane } = this.props;
        const { selectedTab } = this.state;

        const hasSelection = selectedTab !== undefined;
        const isHorizontal = plane === TabsPlane.Horizontal;

        return (
            <Tooltip
                key={section.id}
                className={classes.tabButtonWrapper}
                text={hasSelection ? sectionIdToTitlesFunction(section.id) : undefined}
                attachTo={isHorizontal ? TooltipAttachTo.Top : TooltipAttachTo.Right}
                verticalAdjust={isHorizontal ? 10 : undefined}
            >
                {this.renderTabButton(section, index)}
            </Tooltip>
        );
    };

    private renderVerticalCollapseTabSections = (section: SectionDesc, index: number) => {
        const { openSections } = this.state;
        const open = !!openSections && openSections[section.id];

        return (
            <div key={section.id} className={style(classes.sectionWrapper, { open })}>
                {this.renderTabButton(section, index)}
                {open ? this.renderContent(section.id) : null}
            </div>
        );
    };

    private renderTabContentContainer() {
        const { noScroll } = this.props;
        const { selectedTab } = this.state;

        const singleTab = this.isSingleTab();

        return (
            <div className={style(classes.tabContentContainer, { singleTab })}>
                {selectedTab ? (
                    <div className={classes.tabContent}>
                        {!noScroll ? <Scrollbars universal>{this.renderContent()}</Scrollbars> : this.renderContent()}
                    </div>
                ) : null}
            </div>
        );
    }

    private renderContent(id = this.state.selectedTab) {
        return id && this.props.content(id);
    }

    private getOpenSections(props: TabsProps, selectedTab?: string) {
        const { sections, plane } = props;

        if (plane !== TabsPlane.VerticalCollapse) {
            return undefined;
        }

        return sections.reduce((openSections, section) => {
            openSections[section.id] = !!selectedTab && selectedTab === section.id;
            return openSections;
        }, {} as Record<string, boolean>);
    }

    private onTabButtonClick(index: number, id: string) {
        const { openSections } = this.state;
        if (openSections) {
            openSections[id] = !openSections[id];
        }
        this.setState({ selectedTab: id, openSections });
        this.props.onSelectTab && this.props.onSelectTab(index);
    }

    private isSingleTab() {
        const { sections, noSingleTab } = this.props;
        return !!noSingleTab && sections.length === 1;
    }

    private shouldOpenBestGuessTab() {
        const { preferredTab, plane = DEFAULT_PLANE } = this.props;
        return (
            plane !== TabsPlane.Vertical ||
            preferredTab !== undefined ||
            this.isSingleTab() ||
            this.state.selectedTab !== undefined
        );
    }
}

const TabArrow: TabIcon = () => (
    <svg className={classes.tabArrow} width="12" height="12" viewBox="0 0 12 12">
        <path fillRule="evenodd" d="M6.052 8.98L11 3.992H1L5.95 8.98a.073.073 0 0 0 .102 0" />
    </svg>
);
