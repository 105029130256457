import {
    ShadowProps,
    ShadowsVisualizerFactory,
} from '../visualizer-factories/shadows-visualizer-factory/shadows-visualizer-factory';
import { createDeclarationVisualizer } from '../utils/visualizer-wrapper';

export const BoxShadowVisualizer = ShadowsVisualizerFactory('box-shadow');
export const TextShadowVisualizer = ShadowsVisualizerFactory('text-shadow');

export const ShadowsDeclarationVisualizer = createDeclarationVisualizer<ShadowProps>(
    'box-shadow',
    BoxShadowVisualizer,
    'Shadows'
);

export const TextShadowsDeclarationVisualizer = createDeclarationVisualizer<ShadowProps>(
    'text-shadow',
    TextShadowVisualizer,
    'Text Shadows'
);
