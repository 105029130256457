/* eslint-disable react/no-string-refs */
import React from 'react';

import type { CustomInputProps } from '../../../drivers';
import { NumberInput } from '../../value-inputs/number-input/number-input';
import { stringifyTopRightBottomLeft, DeclarationMap, expandShorthandCssProp } from '@wixc3/stylable-panel-drivers';

import { style, classes } from './margin-input.st.css';

const DEFAULT_VALUE = '0';

export interface MarginInputState {
    focused: string;
}

export class MarginInput extends React.Component<CustomInputProps, MarginInputState> {
    public state: MarginInputState = { focused: '' };
    private top: string | undefined = DEFAULT_VALUE;
    private right: string | undefined = DEFAULT_VALUE;
    private bottom: string | undefined = DEFAULT_VALUE;
    private left: string | undefined = DEFAULT_VALUE;

    constructor(props: CustomInputProps) {
        super(props);
        this.setValues(this.props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: CustomInputProps) {
        this.setValues(props);
    }

    public render() {
        const { siteVarsDriver, className } = this.props;

        return (
            <div
                className={style(
                    classes.root,
                    {
                        topFocused: this.state.focused === 'top',
                        rightFocused: this.state.focused === 'right',
                        bottomFocused: this.state.focused === 'bottom',
                        leftFocused: this.state.focused === 'left',
                    },
                    className
                )}
            >
                <div>
                    <NumberInput
                        className={style(classes.input, classes.inputLeft)}
                        value={this.left}
                        siteVarsDriver={siteVarsDriver}
                        onChange={this.changeLeft}
                        onFocus={() => this.setState({ focused: 'left' })}
                        onBlur={this.resetFocused}
                        ref="inputLeft"
                    />
                </div>
                <div className={classes.middle}>
                    <div>
                        <NumberInput
                            className={style(classes.input, classes.inputTop)}
                            value={this.top}
                            siteVarsDriver={siteVarsDriver}
                            onChange={this.changeTop}
                            onFocus={() => this.setState({ focused: 'top' })}
                            onBlur={this.resetFocused}
                            ref="inputTop"
                        />
                    </div>
                    <div className={classes.box}>
                        <div className={classes.boxInner}>
                            <div
                                className={classes.boxTop}
                                onClick={() => (this.refs.inputTop as HTMLInputElement).focus()}
                                onMouseDown={this.blockMouseDown}
                            />
                            <span
                                className={classes.boxLeft}
                                onClick={() => (this.refs.inputLeft as HTMLInputElement).focus()}
                                onMouseDown={this.blockMouseDown}
                            />
                            <span
                                className={classes.boxRight}
                                onClick={() => (this.refs.inputRight as HTMLInputElement).focus()}
                                onMouseDown={this.blockMouseDown}
                            />
                            <div
                                className={classes.boxBottom}
                                onClick={() => (this.refs.inputBottom as HTMLInputElement).focus()}
                                onMouseDown={this.blockMouseDown}
                            />
                        </div>
                    </div>
                    <div>
                        <NumberInput
                            className={style(classes.input, classes.inputBottom)}
                            value={this.bottom}
                            siteVarsDriver={siteVarsDriver}
                            onChange={this.changeBottom}
                            onFocus={() => this.setState({ focused: 'bottom' })}
                            onBlur={this.resetFocused}
                            ref="inputBottom"
                        />
                    </div>
                </div>
                <div>
                    <NumberInput
                        className={style(classes.input, classes.inputRight)}
                        value={this.right}
                        siteVarsDriver={siteVarsDriver}
                        onChange={this.changeRight}
                        onFocus={() => this.setState({ focused: 'right' })}
                        onBlur={this.resetFocused}
                        ref="inputRight"
                    />
                </div>
            </div>
        );
    }

    private setValues(props: CustomInputProps) {
        if (!props.value) {
            this.top = this.right = this.bottom = this.left = DEFAULT_VALUE;
            return;
        }

        let expanded: DeclarationMap = {};
        try {
            expanded = expandShorthandCssProp('margin', props.value);
            this.top = expanded['margin-top'];
            this.right = expanded['margin-right'];
            this.bottom = expanded['margin-bottom'];
            this.left = expanded['margin-left'];
        } catch {
            //
        }
    }

    private getValues(): string {
        const { top, right, bottom, left } = this;
        if (top && right && left && bottom) {
            return stringifyTopRightBottomLeft({ top, right, bottom, left }) as string;
        }
        return DEFAULT_VALUE;
    }

    private resetFocused = () => this.setState({ focused: '' });
    private blockMouseDown = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement>) => event.preventDefault();

    private changeTop = (top: string) => {
        this.top = top.trim();
        this.props.onChange(this.getValues());
    };

    private changeRight = (right: string) => {
        this.right = right.trim();
        this.props.onChange(this.getValues());
    };

    private changeBottom = (bottom: string) => {
        this.bottom = bottom.trim();
        this.props.onChange(this.getValues());
    };

    private changeLeft = (left: string) => {
        this.left = left.trim();
        this.props.onChange(this.getValues());
    };
}
