import React from 'react';

import { NumberUnitInput, NumberUnitInputProps } from './number-unit-input';
import { OptimisticWrapper } from '../../optimistic-wrapper';

import { style, classes } from './number-unit.st.css';

export interface NumberUnitProps extends NumberUnitInputProps {
    editing?: boolean;
    onSelect?: () => void;
    'data-aid'?: string;
}

export class NumberUnitInner extends React.Component<NumberUnitProps> {
    public render() {
        const {
            editing,
            value,
            units,
            column,
            inlineUnits,
            tooltipSlider,
            inlineSlider,
            onChange,
            onBlur,
            onSelect,
            tooltipPosition,
            knob,
            skipValidation,
            restrictiveRange,
            className,
        } = this.props;

        return (
            <span className={style(classes.root, className)} data-aid={this.props['data-aid']}>
                {!editing ? (
                    <span className={classes.displayMode} onClick={() => onSelect && onSelect()}>
                        {value}
                    </span>
                ) : (
                    <NumberUnitInput
                        className={classes.numberUnitInput}
                        value={value}
                        units={units}
                        column={column}
                        autoFocus={true}
                        inlineUnits={inlineUnits}
                        tooltipSlider={tooltipSlider}
                        inlineSlider={inlineSlider}
                        onChange={onChange}
                        onBlur={(origin?: string) => onBlur && onBlur(origin)}
                        tooltipPosition={tooltipPosition}
                        knob={knob}
                        skipValidation={skipValidation}
                        restrictiveRange={restrictiveRange}
                    />
                )}
            </span>
        );
    }
}

export const NumberUnit = OptimisticWrapper(NumberUnitInner, {
    onMouseDown: (context) => {
        context.setActive();
    },
    onMouseUp: (context) => {
        context.setIdle();
    },
    onChange: (context, ...args) => {
        context.onChange(...args);
    },
});
