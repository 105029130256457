import {
    createForceStateMatchers,
    OVERRIDE_STATE_PREFIX,
} from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';

import type { StylableDriver } from './stylable-driver';
import type { StylesheetDriver } from './stylable-stylesheet';

export const DEFAULT_OVERRIDE_STATE_PREFIX = 'data-override-';
export const STATE_SET_VALUE = 'true';
export const STYLABLE_OVERRIDE_STATE_PREFIX = OVERRIDE_STATE_PREFIX;

export const OverrideHintsErrors = {
    INVALID: 'Invalid selector specified: ',
    MULTIPLE_SELECTORS: 'Multiple selectors are unsupported: ',
    MULTIPLE_PARTS: 'Selectors including multiple parts with states are unsupported: ',
    MULTIPLE_STATES: 'Selectors with multiple states are unsupported: ',
};

export interface OverrideHints {
    state: string;
    stateAttribute: string; // TODO: Deprecate
    selectorToQuery: string;
}

export const getStateOverrideHints = (
    selector: string,
    overrideStatePrefix: string // TODO: Deprecate
): OverrideHints => {
    const reportError = (message: string) => {
        throw new Error(message + selector);
    };

    const forceStateMatchers = createForceStateMatchers(selector);
    if (forceStateMatchers.length === 0) {
        reportError(OverrideHintsErrors.INVALID);
    }
    if (forceStateMatchers.length > 1) {
        reportError(OverrideHintsErrors.MULTIPLE_SELECTORS);
    }

    const statesMatcherParts = forceStateMatchers[0];

    const statesMatcherPartsWithStates = statesMatcherParts.filter(
        (statesMatcherPart) => statesMatcherPart.states.length > 0
    );
    if (statesMatcherPartsWithStates.length === 0) {
        return { state: '', stateAttribute: '', selectorToQuery: '' };
    }
    if (statesMatcherPartsWithStates.length > 1) {
        reportError(OverrideHintsErrors.MULTIPLE_PARTS);
    }

    const { states, selector: selectorToQuery } = statesMatcherPartsWithStates[0];
    if (states.length > 1) {
        reportError(OverrideHintsErrors.MULTIPLE_STATES);
    }

    const state = states[0].name;

    return {
        state,
        stateAttribute: `${overrideStatePrefix}${state}`,
        selectorToQuery,
    };
};

export class StylableOverride {
    private selector = '';
    private prevSelector = '';
    private sheetDriver: StylesheetDriver | null = null;

    constructor(
        private queryDocument: Document | HTMLElement,
        private driver?: StylableDriver,
        private sheetPath?: string,
        private overrideStatePrefix = DEFAULT_OVERRIDE_STATE_PREFIX
    ) {
        if (this.driver && this.sheetPath) {
            this.sheetDriver = this.driver.getStylesheet(this.sheetPath);
        }
    }

    public setSelector(selector: string | null) {
        this.prevSelector = `${this.selector}`;
        this.selector = selector || '';
    }

    public override() {
        if (this.prevSelector === this.selector) {
            return;
        }
        this.removeStateOverride();
        this.setStateOverride();
    }

    public removeStateOverride(selector?: string) {
        const overrideSelector = `${selector || this.prevSelector}`;

        if (!overrideSelector) {
            return;
        }

        const { elements, stateAttribute } = this.getOverrideElements(overrideSelector);
        elements && Array.from(elements).forEach((element) => element.removeAttribute(stateAttribute));
    }

    public setStateOverride(selector?: string) {
        const overrideSelector = `${selector || this.selector}`;

        if (!overrideSelector) {
            return;
        }

        const { elements, stateAttribute } = this.getOverrideElements(overrideSelector);
        elements && Array.from(elements).forEach((element) => element.setAttribute(stateAttribute, STATE_SET_VALUE));
    }

    private getOverrideElements(selector: string) {
        const { selectorToQuery, stateAttribute } = getStateOverrideHints(selector, this.overrideStatePrefix);

        const targetSelector =
            selectorToQuery && this.sheetDriver ? this.sheetDriver.getTargetSelector(selectorToQuery) : '';

        return {
            elements: targetSelector ? this.queryDocument && this.queryDocument.querySelectorAll(targetSelector) : [],
            stateAttribute,
            selectorToQuery: targetSelector,
        };
    }
}
