import { domRect } from '@wixc3/stylable-panel-common-react';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import TooltipInner from './tooltip-inner';
import { classes, style } from './tooltip.st.css';

type WrapperRect = ClientRect | DOMRect | null;
export enum TooltipAttachTo {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
}
const ARROW_SIZE = '10px';

export interface TooltipProps {
    text?: string;
    verticalAdjust?: number;
    horizontalAdjust?: number;
    disabled?: boolean;
    dontOpen?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    attachTo?: TooltipAttachTo;
}

export interface TooltipRef {
    rootRef: React.RefObject<HTMLSpanElement>;
}

export const Tooltip = forwardRef<TooltipRef, TooltipProps>(function Tooltip(
    {
        attachTo = TooltipAttachTo.Top,
        horizontalAdjust = 0,
        verticalAdjust = 0,
        text,
        disabled,
        children,
        className,
        style: propStyle,
        dontOpen,
    },
    ref
) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const rootRef = useRef<HTMLSpanElement>(null);
    const getWrapperRect = (): WrapperRect => (isOpen ? domRect(rootRef.current!) : null);

    const toggleOpen = (open: boolean) => () => {
        setIsOpen(open);
    };

    const getTooltipPlacement = (attachTo?: TooltipAttachTo) => {
        const verticalOffset = verticalAdjust;
        const horizontalOffset = horizontalAdjust;

        let top = 0;
        let left = 0;
        let translateX = '0px';
        let translateY = '0px';
        const wrapperRect = getWrapperRect();

        if (wrapperRect) {
            top = wrapperRect.top;
            left = wrapperRect.left;
            switch (attachTo) {
                case TooltipAttachTo.Left:
                    translateX = `calc(-${ARROW_SIZE} - 100%)`;
                    translateY = `calc(${wrapperRect.height / 2}px - 50%)`;
                    break;
                case TooltipAttachTo.Right:
                    translateX = `calc(${wrapperRect.width}px + ${ARROW_SIZE})`;
                    translateY = `calc(${wrapperRect.height / 2}px - 50%)`;
                    break;
                case TooltipAttachTo.Bottom:
                    translateX = `calc(${wrapperRect.width / 2}px - 50%)`;
                    translateY = `calc(${wrapperRect.height}px + ${ARROW_SIZE})`;
                    break;
                case TooltipAttachTo.Top:
                default:
                    translateX = `calc(${wrapperRect.width / 2}px - 50%)`;
                    translateY = `calc(-${ARROW_SIZE} - 100%)`;
            }
        }
        return {
            top: `${top + verticalOffset}px`,
            left: `${left + horizontalOffset}px`,
            transform: `translateX(${translateX}) translateY(${translateY})`,
        };
    };
    // Expose tooltipRef to parent
    useImperativeHandle(ref, () => ({
        rootRef,
    }));

    return (
        <span
            className={style(classes.root, className)}
            style={propStyle}
            onMouseEnter={toggleOpen(true)}
            onMouseLeave={toggleOpen(false)}
            ref={rootRef}
        >
            {children}
            {text && !dontOpen && isOpen ? (
                <TooltipInner
                    attachTo={attachTo}
                    getTooltipPlacement={getTooltipPlacement}
                    disabled={disabled || false}
                    onMouseEnter={toggleOpen(false)}
                >
                    {text}
                </TooltipInner>
            ) : null}
        </span>
    );
});
