import type React from 'react';

import type { Paddings, Margins } from '@wixc3/shorthands-opener';

import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import {
    PaddingProps,
    MarginProps,
    PaddingVisualizerProps,
    MarginVisualizerProps,
    EdgeVisualizerFactory,
} from '../visualizer-factories/edge-visualizer-factory/edge-visualizer-factory';
import { createDeclarationVisualizer } from '../utils/visualizer-wrapper';

const PADDING_PROPS_LIST: Paddings[] = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
const MARGIN_PROPS_LIST: Margins[] = ['margin-top', 'margin-right', 'margin-bottom', 'margin-left'];

export const PaddingVisualizer = EdgeVisualizerFactory(
    'padding',
    PADDING_PROPS_LIST,
    StylablePanelTranslationKeys.controller.padding.title,
    StylablePanelTranslationKeys.controller.padding.sectionTooltip
) as React.ComponentClass<PaddingVisualizerProps>;

export const MarginVisualizer = EdgeVisualizerFactory(
    'margin',
    MARGIN_PROPS_LIST,
    StylablePanelTranslationKeys.controller.margin.title,
    StylablePanelTranslationKeys.controller.margin.sectionTooltip
) as React.ComponentClass<MarginVisualizerProps>;

export const PaddingDeclarationVisualizer = createDeclarationVisualizer<PaddingProps>('padding', PaddingVisualizer);

export const MarginDeclarationVisualizer = createDeclarationVisualizer<MarginProps>('margin', MarginVisualizer);
