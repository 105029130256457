import { IconToggleVisualizerFactory } from '../visualizer-factories/icon-toggle-visualizer-factory/icon-toggle-visualizer-factory';
import {
    BoldIcon,
    DirectionLeftIcon,
    DirectionRightIcon,
    ItalicIcon,
    StrikethroughIcon,
    UnderlineIcon,
} from '../controllers/text-visualizer/text-visualizer-icons';
import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

const textTooltips = StylablePanelTranslationKeys.controller.text.tooltips;

export const FONT_WEIGHT_BOLD = 'bold';
export const FONT_WEIGHT_BOLD_THRESHOLD = 600;

export const FONT_WEIGHT_CHECKED = '700';
export const FONT_WEIGHT_UNCHECKED = '400';

export const FONT_STYLE_CHECKED = 'italic';
export const FONT_STYLE_UNCHECKED = 'normal';

export const TEXT_DECORATION_NONE = 'none';
export const TEXT_DECORATION_UNDERLINE = 'underline';
export const TEXT_DECORATION_STRIKETHROUGH = 'line-through';

export const TEXT_DIRECTION_PROP = 'direction';
export const TEXT_DIRECTION_CHECKED = 'rtl';
export const TEXT_DIRECTION_UNCHECKED = 'ltr';

export const BoldVisualizer = IconToggleVisualizerFactory('font-weight', {
    tooltipKey: textTooltips.bold,
    MainIcon: BoldIcon,
    checkedValue: FONT_WEIGHT_CHECKED,
    uncheckedValue: FONT_WEIGHT_UNCHECKED,
    checkedPredicate: (value) =>
        !!value && (parseFloat(value) >= FONT_WEIGHT_BOLD_THRESHOLD || value === FONT_WEIGHT_BOLD),
});

export const ItalicVisualizer = IconToggleVisualizerFactory('font-style', {
    tooltipKey: textTooltips.italic,
    MainIcon: ItalicIcon,
    checkedValue: FONT_STYLE_CHECKED,
    uncheckedValue: FONT_STYLE_UNCHECKED,
    checkedPredicate: (value) => value === FONT_STYLE_CHECKED,
});

export const UnderlineVisualizer = IconToggleVisualizerFactory('text-decoration-line', {
    tooltipKey: textTooltips.underline,
    MainIcon: UnderlineIcon,
    checkedValue: TEXT_DECORATION_UNDERLINE,
    uncheckedValue: TEXT_DECORATION_NONE,
    checkedPredicate: (value) => !!value?.includes(TEXT_DECORATION_UNDERLINE),
});

export const StrikethroughVisualizer = IconToggleVisualizerFactory('text-decoration-line', {
    tooltipKey: textTooltips.strikethrough,
    MainIcon: StrikethroughIcon,
    checkedValue: TEXT_DECORATION_STRIKETHROUGH,
    uncheckedValue: TEXT_DECORATION_NONE,
    checkedPredicate: (value) => !!value?.includes(TEXT_DECORATION_STRIKETHROUGH),
});

export const DirectionVisualizer = IconToggleVisualizerFactory('direction', {
    tooltipKey: textTooltips.textDirection,
    MainIcon: DirectionLeftIcon,
    SecondaryIcon: DirectionRightIcon,
    checkedValue: TEXT_DIRECTION_CHECKED,
    uncheckedValue: TEXT_DIRECTION_UNCHECKED,
    checkedPredicate: (value) => value === TEXT_DIRECTION_CHECKED,
});
