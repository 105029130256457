import React, { createRef } from 'react';
import chroma from 'chroma-js';

import { domRect, isClickInElement } from '@wixc3/stylable-panel-common-react';
import {
    SiteVarsDriver,
    StylablePanelTranslationKeys,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wixc3/stylable-panel-drivers';
import {
    PalettePicker,
    PalettePickerStrings,
    ColorAdder,
    ColorAdderStrings,
    LabelWithAction,
    DimensionInput,
    TooltipAttachTo,
    TranslateDriver,
    isWhite,
    CompositeBlock,
} from '@wixc3/stylable-panel-components';

import {
    COLOR_ADDER_HSB,
    COLOR_ADDER_HSB_HUE,
    COLOR_ADDER_RGB,
    DIMENSION_ID,
    PERCENTS_RANGE,
} from '@wixc3/stylable-panel-common';
import { MySkinsPicker, CustomSkinsPicker } from '../my-skins-picker/my-skins-picker';
import type { DeclarationVisualizerDrivers, StylablePanelHost } from '../../types';
import type { OriginNode, OpenedDeclarationArray } from '../../declaration-types';
import {
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
} from '../../utils/visualizer-utils';
import { getTranslate } from '../../hosts/translate';
import { PanelEventList } from '../../hosts/bi';
import { getDimensionUnits } from '../../generated-visualizers';
import { FillPickerContext } from '../fill-picker/fill-picker-context';

import { style, classes } from './color-picker.st.css';

export const COLOR_VALUE_TYPE = 'color';

const PALETTE_PICKER_HORIZONTAL_ADJUSTMENT = 6;
const PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT = 48;

export enum ColorCategory {
    None = -1,
    Site = 0,
    User = 1,
}

export interface ColorPickerStrings {
    themeSelectionTitle: string;
    doneLinkLabel: string;
    changeLinkLabel: string;
    changeLinkTooltip: string;
    myColorsTitle: string;
    myColorsInformationTooltip: string;
    addColorLabel: string;
}

const ColorPickerStringsFallbacks: ColorPickerStrings = {
    themeSelectionTitle: 'Theme Colors',
    doneLinkLabel: 'Done',
    changeLinkLabel: 'Change colors',
    changeLinkTooltip: "Change your site's color theme",
    myColorsTitle: 'Custom Colors',
    myColorsInformationTooltip: 'Add colors so you can easily find and reuse them later.',
    addColorLabel: '+ Add Color',
};

export interface ColorPickerTranslations {
    colorPicker: ColorPickerStrings;
    colorAdder: ColorAdderStrings;
    palettePicker: PalettePickerStrings;
}

export interface ASTPickerAPI<PROPS extends string = string> {
    astValue?: OpenedDeclarationArray<PROPS>;
    onAstChange?: (
        declarations: OpenedDeclarationArray<PROPS>,
        stringifyExpression?: (v: OriginNode) => string
    ) => void;
}

export interface ColorPickerAPI extends ASTPickerAPI {
    currentColor?: string;
    onChange?: (value: string) => void;
}

export const getColorPickerAPI = (
    currentColor?: string,
    onChange?: (value: string) => void,
    drivers?: DeclarationVisualizerDrivers
): ColorPickerAPI => ({
    currentColor,
    onChange,
    astValue: currentColor ? createVisualizerValueFromDeclarationMap({ [COLOR_VALUE_TYPE]: currentColor }) : undefined,
    onAstChange: (value, stringifyExpression) => {
        if (onChange) {
            const changeValue = createDeclarationMapFromVisualizerValue(
                value,
                drivers ? { value: [], drivers } : undefined,
                stringifyExpression
            )[COLOR_VALUE_TYPE];
            if (changeValue) {
                onChange(changeValue);
            }
        }
    },
});

export interface ColorPickerProps extends ColorPickerAPI {
    id?: string;
    title?: string;
    siteVarsDriver?: SiteVarsDriver;
    panelHost?: StylablePanelHost;
    onReset?: () => void;
    onHover?: (value: string | null) => void;
    onBlur?: () => void;
    customSkinsPicker?: CustomSkinsPicker;
    minimalMode?: boolean;
    noOpacity?: boolean;
    noAddColor?: boolean;
    className?: string;
}

export type CustomColorPicker = React.ComponentClass<ColorPickerProps>;

export interface ColorPickerState {
    selectedCategory: ColorCategory;
    hoveredCategory: ColorCategory;
    selectedColor: number;
    hoveredColor: number;
    alpha: number;
    adderShown: boolean;
    palettePickerShown: boolean;
    paletteIndex: number;
}

// TODO: When moving from no color to color - opacity should reset to 100
export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    public static panelName = 'color';
    public static contextType = FillPickerContext;
    context: React.ContextType<typeof FillPickerContext>;

    private componentId = this.props.id || 'colorPicker';
    private fallbackColor: string | null = null;
    private colorPickerRect!: ClientRect | DOMRect;
    private translations!: ColorPickerTranslations;
    private translateDriver: TranslateDriver<ColorPickerStrings>;

    private colorPicker = createRef<HTMLDivElement>();
    private colorAdder = createRef<ColorAdder>();
    private palettePicker = createRef<PalettePicker>();

    constructor(props: ColorPickerProps, context: FillPickerContext) {
        super(props);
        this.context = context;
        this.state = {
            selectedCategory: ColorCategory.None,
            hoveredCategory: ColorCategory.None,
            selectedColor: -1,
            hoveredColor: -1,
            alpha: 1,
            adderShown: false,
            palettePickerShown: false,
            paletteIndex: -1,
        };
        this.setSelectedColor(this.props);
        this.setSelectedPalette(this.props);

        const { panelHost } = this.getContextProps(this.props);
        this.setTranslations(panelHost);
        this.translateDriver = new TranslateDriver<ColorPickerStrings>(
            ColorPickerStringsFallbacks,
            this.translations.colorPicker
        );

        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public adderOpen() {
        return this.state.adderShown;
    }
    public palettePickerOpen() {
        return this.state.palettePickerShown;
    }

    public componentDidMount() {
        this.setColorPickerRect();
    }

    public componentDidUpdate() {
        this.setColorPickerRect();
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public render() {
        const { minimalMode, noOpacity, noAddColor, className } = this.props;
        const { panelHost } = this.getContextProps(this.props);
        const { alpha, adderShown, palettePickerShown, paletteIndex } = this.state;
        const { translate } = this.translateDriver;
        const { dimensionUnits } = panelHost || {};

        const displayedColor = this.getDisplayedColor();
        const displayedColorHex = displayedColor ? displayedColor.hex('rgb').toUpperCase() : '';

        const palettes = panelHost && panelHost.getPalettes ? panelHost.getPalettes() : [];
        const onSiteColorChange = panelHost?.onSiteColorChange;

        const changeColorActionTooltipText = palettePickerShown
            ? undefined
            : onSiteColorChange
            ? translate('changeLinkTooltip')
            : undefined;

        const percentsRangeUnits = getDimensionUnits({
            id: DIMENSION_ID.COLOR_PICKER_PERCENTS_RANGE,
            dimensionUnits,
            customUnits: PERCENTS_RANGE,
        });
        const colorAdderUnits = {
            [DIMENSION_ID.COLOR_ADDER_RGB]: getDimensionUnits({
                id: DIMENSION_ID.COLOR_ADDER_RGB,
                dimensionUnits,
                customUnits: COLOR_ADDER_RGB,
            }),
            [DIMENSION_ID.COLOR_ADDER_HSB]: getDimensionUnits({
                id: DIMENSION_ID.COLOR_ADDER_HSB,
                dimensionUnits,
                customUnits: COLOR_ADDER_HSB,
            }),
            [DIMENSION_ID.COLOR_ADDER_HSB_HUE]: getDimensionUnits({
                id: DIMENSION_ID.COLOR_ADDER_HSB,
                dimensionUnits,
                customUnits: COLOR_ADDER_HSB_HUE,
            }),
        };

        return [
            <div
                key="color_picker"
                className={style(classes.root, { adder: adderShown, minimalMode: !!minimalMode }, className)}
                ref={this.colorPicker}
                data-aid="st_colorpicker"
            >
                {!minimalMode ? (
                    <header>
                        <LabelWithAction
                            className={classes.siteColorsTitle}
                            text={translate('themeSelectionTitle')}
                            actionText={palettePickerShown ? translate('doneLinkLabel') : translate('changeLinkLabel')}
                            actionTooltipAttachSide={TooltipAttachTo.Right}
                            actionTooltipText={changeColorActionTooltipText}
                            onActionClick={this.handleChangeSiteColorsClick}
                            actionDataAid="st_colorpicker_changesitecolorsbutton"
                        />
                    </header>
                ) : null}
                {this.renderSiteColorDisplay()}
                {!noOpacity ? (
                    <CompositeBlock className={classes.editColor}>
                        <DimensionInput
                            className={classes.inputElementOpacity}
                            value={`${Math.round(alpha * 100).toString()}%`}
                            config={{ units: percentsRangeUnits }}
                            isSlider={true}
                            keepRange={true}
                            opacitySliderColor={displayedColorHex}
                            onChange={
                                displayedColor ? (value) => this.setColorAlpha(displayedColor, value) : () => null
                            }
                        />
                    </CompositeBlock>
                ) : null}
                {this.renderSkinsPicker(this.getColorCSSValue(displayedColorHex))}
                {!noAddColor ? (
                    <footer className={classes.footer}>
                        <LabelWithAction
                            className={classes.footerLabelWithAction}
                            text={displayedColorHex}
                            actionText={translate('addColorLabel')}
                            onActionClick={() => this.setState({ adderShown: true, palettePickerShown: false })}
                            actionDataAid="st_colorpicker_addcolorbutton"
                        />
                    </footer>
                ) : null}
                {adderShown ? (
                    <ColorAdder
                        className={classes.colorAdder}
                        initialColor={displayedColorHex}
                        onAdd={this.handleUserColorAdd}
                        onCancel={() => this.setState({ adderShown: false })}
                        translations={this.translations.colorAdder}
                        units={colorAdderUnits}
                        ref={this.colorAdder}
                        data-aid="st_colorpicker_coloradder"
                    />
                ) : null}
            </div>,
            palettePickerShown ? (
                <PalettePicker
                    key="palette_picker"
                    className={classes.palettePicker}
                    style={this.getPalettePickerStyle()}
                    palettes={palettes}
                    selectedPalette={paletteIndex}
                    // onHover={index => this.handlePaletteChange(index !== -1 ? index : paletteIndex, false)}
                    onSelect={(index) => this.handlePaletteChange(index, true)}
                    translations={this.translations.palettePicker}
                    ref={this.palettePicker}
                    data-aid="st_colorpicker_palettepicker"
                />
            ) : null,
        ];
    }

    private getContextProps = (props: ColorPickerProps) => {
        const { siteVarsDriver: contextSiteVarsDriver, panelHost: contextPanelHost } = this.context;
        const { siteVarsDriver: propsSiteVarsDriver, panelHost: propsPanelHost } = props;
        return {
            siteVarsDriver: contextSiteVarsDriver ?? propsSiteVarsDriver,
            panelHost: contextPanelHost ?? propsPanelHost,
        };
    };

    private setColorPickerRect() {
        if (!this.colorPicker.current) {
            return;
        }

        this.colorPickerRect = domRect(this.colorPicker.current);
    }

    private setSelectedColor(props: ColorPickerProps) {
        const { currentColor } = props;
        const { siteVarsDriver } = this.getContextProps(props);
        const evalDeclarationValue =
            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

        let alpha = 1;
        let selectedCategory = ColorCategory.None;
        let selectedColor = -1;

        const evalCurrentColor = evalDeclarationValue(currentColor);

        if (evalCurrentColor) {
            this.fallbackColor = evalCurrentColor;

            try {
                const currColorObj = chroma(evalCurrentColor);
                alpha = currColorObj.alpha();

                const userIndex = siteVarsDriver?.findUserColor(currColorObj) ?? -1;
                if (userIndex !== -1) {
                    selectedCategory = ColorCategory.User;
                    selectedColor = userIndex;
                } else {
                    const siteIndex = siteVarsDriver?.findSiteColor(currColorObj) ?? -1;
                    if (siteIndex !== -1) {
                        selectedCategory = ColorCategory.Site;
                        selectedColor = siteIndex;
                    }
                }
            } catch {
                //
            }
        }

        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...this.state, selectedCategory, selectedColor, alpha };
    }

    private setSelectedPalette(props: ColorPickerProps) {
        const { siteVarsDriver, panelHost } = this.getContextProps(props);

        const palettes = panelHost && panelHost.getPalettes ? panelHost.getPalettes() : [];
        if (!siteVarsDriver || palettes.length === 0) {
            return;
        }

        const siteValues = [...siteVarsDriver.siteColors]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ value }) => value)
            .join(',');

        let paletteIndex = -1;
        for (let i = 0; i < palettes.length; i++) {
            const currentPalette = palettes[i].columns
                .reduce((flatColumns, column) => flatColumns.concat(column), [] as string[])
                .join(',');
            if (currentPalette === siteValues) {
                paletteIndex = i;
                break;
            }
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = { ...this.state, paletteIndex };
    }

    private renderSiteColorDisplay() {
        const { minimalMode } = this.props;
        const { siteVarsDriver } = this.getContextProps(this.props);
        const { selectedCategory, selectedColor } = this.state;

        return (
            <div className={classes.paletteDisplayer}>
                {siteVarsDriver?.siteColors.map(({ name, value }, index) => [
                    <input
                        key={`site_color_${index}_radio`}
                        className={classes.hiddenRadio}
                        type="radio"
                        name={COLOR_VALUE_TYPE}
                        value={`value(${name})`}
                        checked={selectedCategory === ColorCategory.Site && selectedColor === index}
                        onChange={() => null}
                    />,
                    <div
                        className={style(classes.siteColorItem, {
                            minimal: !!minimalMode,
                            white: isWhite(chroma(value)),
                        })}
                        key={`site_color_${index}_item`}
                        style={{ backgroundColor: value }}
                        onClick={() => this.setSiteColor(index)}
                        onMouseEnter={() => this.hoverSiteColor(index)}
                        onMouseLeave={this.getFallbackColor}
                        data-aid="st_colorpicker_sitecoloritem"
                    />,
                ])}
            </div>
        );
    }

    private getNewIndexAfterRemove = (index: number, oldIndex: number) => {
        let newIndex = -1;
        if (oldIndex !== index) {
            newIndex = oldIndex - (oldIndex > index ? 1 : 0);
        }
        return newIndex;
    };

    private handleRemoveCustomColor = (index: number) => {
        const { selectedCategory, hoveredCategory, selectedColor, hoveredColor } = this.state;
        const { siteVarsDriver, panelHost } = this.getContextProps(this.props);

        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);

        const removed = siteVarsDriver?.removeUserValue('user', index) ?? false;
        if (panelHost && panelHost.onUserColorRemove) {
            panelHost.onUserColorRemove(index);
        }

        if (removed) {
            if (hoveredCategory === ColorCategory.User) {
                this.setState({ hoveredColor: this.getNewIndexAfterRemove(index, hoveredColor) });
            }

            if (selectedCategory === ColorCategory.User) {
                this.setState({ selectedColor: this.getNewIndexAfterRemove(index, selectedColor) });
            }
        }
    };

    private renderSkinsPicker = (currentColor: string) => {
        const { onChange, onHover, onReset, customSkinsPicker: CustomSkinsPickerComp } = this.props;
        const { siteVarsDriver } = this.getContextProps(this.props);
        const { selectedCategory, selectedColor } = this.state;
        const { translate } = this.translateDriver;

        if (CustomSkinsPickerComp) {
            return (
                <CustomSkinsPickerComp
                    value={currentColor}
                    valueType={COLOR_VALUE_TYPE}
                    onItemClick={(_index, value) => onChange && onChange(this.getColorCSSValue(value))}
                    onItemEnter={(_index, value) => onHover && onHover(this.getColorCSSValue(value))}
                    onItemLeave={() => onHover && onHover(this.fallbackColor)}
                />
            );
        }

        return (
            <MySkinsPicker
                className={classes.myColors}
                title={translate('myColorsTitle')}
                information={translate('myColorsInformationTooltip')}
                values={siteVarsDriver?.getCategoryValues('user').map(({ value }) => value)}
                radioGroupName={COLOR_VALUE_TYPE}
                itemDataAid="st_colorpicker_usercoloritem"
                isChecked={(index) => selectedCategory === ColorCategory.User && selectedColor === index}
                isWhite={(color) => isWhite(chroma(color))}
                onItemClick={(index) => this.setUserColor(index)}
                onItemEnter={(index) => this.hoverUserColor(index)}
                onItemLeave={() => this.getFallbackColor()}
                onAdd={() => this.setState({ adderShown: true, palettePickerShown: false })}
                onItemRemove={(index) => this.handleRemoveCustomColor(index)}
                onReset={
                    onReset
                        ? () => {
                              onReset();
                              this.fallbackColor = null;
                              this.setState({
                                  selectedCategory: ColorCategory.None,
                                  selectedColor: -1,
                              });
                          }
                        : undefined
                }
            />
        );
    };

    private setSiteColor(selectedColor: number) {
        const { selectedCategory, selectedColor: stateSelectedColor } = this.state;
        const { panelHost } = this.getContextProps(this.props);

        if (selectedCategory === ColorCategory.Site && stateSelectedColor === selectedColor) {
            return;
        }
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);
        this.setState({ selectedCategory: ColorCategory.Site, selectedColor });

        this.dispatchSiteColor(selectedColor, this.props.onChange);

        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
    }

    private hoverSiteColor(hoveredColor: number) {
        const { hoveredCategory, hoveredColor: stateHoveredColor } = this.state;
        const { panelHost } = this.getContextProps(this.props);

        if (hoveredCategory === ColorCategory.Site && stateHoveredColor === hoveredColor) {
            return;
        }
        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
        this.setState({ hoveredCategory: ColorCategory.Site, hoveredColor });

        this.dispatchSiteColor(hoveredColor, this.props.onHover);
    }

    private setUserColor(selectedColor: number, closeAdder = false) {
        const { onChange } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps(this.props);
        const { selectedCategory, selectedColor: stateSelectedColor, adderShown } = this.state;

        if (selectedCategory === ColorCategory.User && stateSelectedColor === selectedColor) {
            return;
        }
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(true, this.componentId);
        this.setState({
            selectedCategory: ColorCategory.User,
            selectedColor,
            adderShown: closeAdder ? false : adderShown,
        });

        if (!onChange || !siteVarsDriver) {
            return;
        }

        const colorVar = siteVarsDriver.getCategoryValues('user')[selectedColor];
        onChange(this.getColorCSSValue(colorVar.value));

        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
    }

    private hoverUserColor(hoveredColor: number) {
        const { onHover } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps(this.props);
        const { hoveredCategory, hoveredColor: stateHoveredColor } = this.state;

        if (hoveredCategory === ColorCategory.User && stateHoveredColor === hoveredColor) {
            return;
        }
        panelHost && panelHost.blockCommits && panelHost.blockCommits(this.componentId);
        this.setState({ hoveredCategory: ColorCategory.User, hoveredColor });

        if (!onHover || !siteVarsDriver) {
            return;
        }

        const colorVar = siteVarsDriver.getCategoryValues('user')[hoveredColor];
        onHover(this.getColorCSSValue(colorVar.value));
    }

    private setColorAlpha(color: chroma.Color, alpha: string) {
        const { onChange } = this.props;
        const { selectedCategory, selectedColor } = this.state;

        if (!onChange) return;

        const newAlpha = parseFloat(alpha) / 100;
        const newColor = color.alpha(newAlpha);
        if (newAlpha === 1) {
            if (selectedCategory !== ColorCategory.None) {
                onChange(this.getValue(selectedCategory, selectedColor)!);
            } else {
                onChange(newColor.hex('rgb').toUpperCase());
            }
        } else {
            onChange(newColor.css());
        }

        this.setState({ alpha: newAlpha });
    }

    private handleUserColorAdd = (value: string) => {
        const { siteVarsDriver, panelHost } = this.getContextProps(this.props);

        siteVarsDriver?.addUserColor(value);
        panelHost?.onUserColorAdd?.(value);
        if (siteVarsDriver) {
            this.setUserColor(siteVarsDriver.getCategoryValues('user').length - 1, true);
        }
    };

    private handlePaletteChange(paletteIndex: number, select: boolean) {
        const { onChange /*, onHover*/ } = this.props;
        const { panelHost } = this.getContextProps(this.props);
        const { selectedCategory, selectedColor } = this.state;
        const { PALETTE_SELECT } = PanelEventList;
        const isSiteColorSelected = selectedCategory === ColorCategory.Site && selectedColor !== -1;

        // if (isSiteColorSelected) {
        //     this.dispatchSiteColor(selectedColor, select ? onChange : onHover);
        // }
        if (isSiteColorSelected && select) {
            this.dispatchSiteColor(selectedColor, onChange);
        }

        if (panelHost && panelHost.onPaletteChange) {
            panelHost.onPaletteChange(paletteIndex, !select);
            panelHost.reportBI?.(PALETTE_SELECT, { value: paletteIndex });

            if (select) {
                this.setState({ paletteIndex });
            }
        }
    }

    private dispatchSiteColor(index: number, callback?: (value: string) => void) {
        const { siteVarsDriver } = this.getContextProps(this.props);

        if (!callback || !siteVarsDriver) {
            return;
        }

        const colorVar = siteVarsDriver.siteColors[index];
        if (this.state.alpha === 1) {
            callback(`value(${colorVar.name})`);
        } else {
            callback(this.getColorCSSValue(colorVar.value));
        }
    }

    private getValue(category: ColorCategory, color: number) {
        const { siteVarsDriver } = this.getContextProps(this.props);

        if (!siteVarsDriver) {
            return null;
        }

        switch (category) {
            case ColorCategory.Site:
                return `value(${siteVarsDriver.siteColors[color].name})`;
            case ColorCategory.User:
                return (siteVarsDriver.getCategoryValues('user')[color] || {}).value;
        }
        return null;
    }

    private getDisplayedColor() {
        const { alpha, selectedCategory, hoveredCategory, selectedColor, hoveredColor } = this.state;

        if (hoveredCategory !== ColorCategory.None && hoveredColor !== -1) {
            return this.getColor(hoveredCategory, hoveredColor);
        } else if (selectedCategory !== ColorCategory.None && selectedColor !== -1) {
            return this.getColor(selectedCategory, selectedColor);
        } else if (this.fallbackColor) {
            try {
                return chroma(this.fallbackColor).alpha(alpha);
            } catch {
                //
            }
        }
        return null;
    }

    private getColor(category: ColorCategory, color: number) {
        const { siteVarsDriver } = this.getContextProps(this.props);
        const { alpha } = this.state;
        if (!siteVarsDriver) {
            return null;
        }
        if (category === ColorCategory.Site) {
            return chroma(siteVarsDriver.siteColors[color].value).alpha(alpha);
        } else {
            const userColor = siteVarsDriver.getCategoryValues('user')[color];
            return userColor ? chroma(userColor.value).alpha(alpha) : null;
        }
    }

    private getFallbackColor = () => {
        const { onHover } = this.props;
        const { siteVarsDriver, panelHost } = this.getContextProps(this.props);
        const { alpha, selectedCategory, selectedColor } = this.state;

        this.setState({ hoveredCategory: ColorCategory.None, hoveredColor: -1 });

        if (!onHover) return;

        let fallbackColor: string | null = this.fallbackColor;
        if (selectedCategory !== ColorCategory.None && selectedColor !== -1) {
            if (alpha === 1) {
                fallbackColor = this.getValue(selectedCategory, selectedColor);
            } else {
                fallbackColor = siteVarsDriver
                    ? this.getColorCSSValue(
                          (selectedCategory === ColorCategory.Site
                              ? siteVarsDriver.siteColors
                              : siteVarsDriver.getCategoryValues('user'))[selectedColor].value
                      )
                    : null;
            }
        }

        onHover(fallbackColor);
        panelHost && panelHost.unblockCommits && panelHost.unblockCommits(false, this.componentId);
        panelHost && panelHost.onRevertQuickChange && panelHost.onRevertQuickChange();
    };

    private getColorCSSValue = (value: string) => {
        const { alpha } = this.state;

        return alpha === 1 ? value : chroma(value).alpha(alpha).css();
    };

    private getPalettePickerStyle(): React.CSSProperties {
        return {
            position: 'fixed',
            height: `${this.colorPickerRect.height + PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT}px`,
            top: `${this.colorPickerRect.top - PALETTE_PICKER_HEADER_HEIGHT_VERTICAL_ADJUSTMENT}px`,
            left: `${this.colorPickerRect.right + PALETTE_PICKER_HORIZONTAL_ADJUSTMENT}px`,
            zIndex: 1,
        };
    }

    private handleDocumentMouseDown = (event: MouseEvent) => {
        if (this.colorAdder.current && this.adderOpen()) {
            if (!isClickInElement(event, this.colorAdder.current)) {
                this.setState({ adderShown: false });
            }
            return;
        }

        const { onBlur } = this.props;

        if (
            !onBlur ||
            isClickInElement(event, this) ||
            (this.palettePickerOpen() &&
                this.palettePicker.current &&
                isClickInElement(event, this.palettePicker.current))
        ) {
            return;
        }

        onBlur();
    };

    private setTranslations(panelHost?: StylablePanelHost) {
        const translate = getTranslate(panelHost);

        const {
            themeSelection: { title: themeSelectionTitle, doneLinkLabel, changeLinkLabel, changeLinkTooltip },
            myColorsTitle,
            myColorsInformationTooltip,
            addColorLabel,
            colorAdder: { hexTabLabel, rgbTabLabel, hsbTabLabel, cancelButtonLabel, addButtonLabel },
            palettePicker: { title },
        } = StylablePanelTranslationKeys.picker.color;

        this.translations = {
            colorPicker: {
                themeSelectionTitle: translate(themeSelectionTitle),
                doneLinkLabel: translate(doneLinkLabel),
                changeLinkLabel: translate(changeLinkLabel),
                changeLinkTooltip: translate(changeLinkTooltip),
                myColorsTitle: translate(myColorsTitle),
                myColorsInformationTooltip: translate(myColorsInformationTooltip),
                addColorLabel: translate(addColorLabel),
            },
            colorAdder: {
                hexTabLabel: translate(hexTabLabel),
                rgbTabLabel: translate(rgbTabLabel),
                hsbTabLabel: translate(hsbTabLabel),
                cancelButtonLabel: translate(cancelButtonLabel),
                addButtonLabel: translate(addButtonLabel),
            },
            palettePicker: {
                title: translate(title),
            },
        };
    }

    private handleChangeSiteColorsClick = () => {
        const { palettePickerShown: prevPalettePickerShown } = this.state;
        const { panelHost } = this.getContextProps(this.props);
        const { COLOR_PICKER_CHANGE_COLORS_CLICK } = PanelEventList;

        if (panelHost?.onSiteColorChange) {
            panelHost.onSiteColorChange();
            panelHost?.reportBI?.(COLOR_PICKER_CHANGE_COLORS_CLICK);
        } else {
            this.setState({ palettePickerShown: !prevPalettePickerShown, adderShown: false });
            if (!prevPalettePickerShown) {
                panelHost?.reportBI?.(COLOR_PICKER_CHANGE_COLORS_CLICK);
            }
        }
    };
}
