import {
    CSSCodeAst,
    TextNode,
    MethodCall,
    ShorthandsTypeMap,
    OpenedBorderRadiusShorthand,
    createCssValueAST,
    valueTextNode,
    getFullText,
    getShorthandOpener as getShorthandOpenerGeneric,
    getShorthandCloser as getShorthandCloserGeneric,
} from '@wixc3/shorthands-opener';

import {
    DeclarationMap,
    GenericDeclarationMap,
    EvalDeclarationValue,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wixc3/stylable-panel-drivers';

import {
    OriginNode,
    ParseShorthandAPI,
    EvaluatedAst,
    OpenedShorthandValue,
    SimpleOpenedShorthand,
    DeclarationValue,
    BaseDeclarationNode,
    DeclarationNode,
    OpenedShortHandDeclarationNode,
    OpenedDeclaration,
    OpenedDeclarationArray,
    isDeclarationExpressionItem,
    isDeclaration,
    isOpenedShortHand,
    getVariableExpression,
    createDeclarationNode,
    createOpenedShorthandDeclarationNode,
    createNonCommittedDeclarationNode,
} from '../declaration-types';
import type {
    OpenedDeclarationList,
    ControllerVariablesDriver,
    DeclarationVisualizerDrivers,
    DeclarationVisualizerProps,
} from '../types';

export const getShorthandOpener = <MAIN extends keyof ShorthandsTypeMap>(main: MAIN) =>
    getShorthandOpenerGeneric<OriginNode, MAIN>(main);
export const getShorthandCloser = <MAIN extends keyof ShorthandsTypeMap>(main: MAIN) =>
    getShorthandCloserGeneric<OriginNode, MAIN>(main);

const flatMap = <T, U>(array: T[], callback: (value: T) => U[]): U[] => ([] as U[]).concat(...array.map(callback));

const flattenDeclarationShorthand = (value: OpenedShorthandValue): DeclarationValue =>
    Array.isArray(value) ? flatMap(value, flattenDeclarationShorthand) : [value.origin || value.value];

const ensureSpace = (val: DeclarationValue) => {
    if (!isDeclarationExpressionItem(val[0]) && val[0].before.length === 0) {
        val[0].before.push({
            type: 'space',
            value: ' ',
            end: -1,
            start: -1,
        });
    }
    return val;
};

export const flattenAndEnsureSpace = (value: OpenedShorthandValue) => ensureSpace(flattenDeclarationShorthand(value));

export function wrapDeclarationChangeValue<PROPS extends string>(
    prop: PROPS,
    newValue?: string | DeclarationValue,
    existingValueNode?: OpenedDeclaration<PROPS>
): OpenedDeclaration<PROPS> {
    const newValueNode = newValue ? (typeof newValue === 'string' ? [valueTextNode(newValue)] : newValue) : [];
    return existingValueNode
        ? {
              ...existingValueNode,
              value: newValueNode,
          }
        : createNonCommittedDeclarationNode({
              name: prop,
              value: newValueNode,
          });
}

export const getDeclarationValue = <PROPS extends string>(
    declarationList: OpenedDeclarationList<PROPS>,
    prop: PROPS,
    defaultValue?: string
): OpenedDeclarationArray<PROPS> => {
    const value = declarationList[prop] as OpenedDeclarationArray<PROPS>;
    return value.length === 0 && defaultValue !== undefined ? [wrapDeclarationChangeValue(prop, defaultValue)] : value;
};

export function visualizerOptimisticValueResolver<PROPS extends string>(
    targetValue: OpenedDeclarationArray<PROPS> | undefined,
    sourceValue: OpenedDeclarationArray<PROPS>
) {
    if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
        const sourceValueCopy = [...sourceValue];
        return targetValue
            .reduce((value, targetDecl) => {
                const foundSourceDeclIndex = sourceValueCopy.findIndex(
                    (sourceDecl) => sourceDecl.name === targetDecl.name
                );
                if (foundSourceDeclIndex !== -1) {
                    const foundSourceDecl = sourceValueCopy.splice(foundSourceDeclIndex, 1)[0];
                    if (foundSourceDecl.kind === targetDecl.kind) {
                        value.push(foundSourceDecl);
                    } else {
                        value.push(targetDecl);
                    }
                } else {
                    value.push(targetDecl);
                }
                return value;
            }, [] as OpenedDeclarationArray<PROPS>)
            .concat(sourceValueCopy);
    }
    return sourceValue;
}

export const getDeclarationText = <PROPS extends string>(
    declaration?: OpenedDeclaration<PROPS>,
    stringifyExpression?: (v: OriginNode) => string
): string | undefined => {
    if (!declaration) {
        return undefined;
    }

    const { value } = declaration;
    if (value.length === 0) {
        return undefined;
    }

    try {
        let hasText = false;
        return value
            .map((item) => {
                if (!item) {
                    throw new Error('declaration value item is undefined');
                }

                let text = '';

                if (isDeclarationExpressionItem(item)) {
                    // TODO: Should we throw or just return an empty string?
                    if (!stringifyExpression) {
                        throw new Error('stringifyExpression is undefined');
                    }
                    text = (hasText ? ' ' : '') + stringifyExpression(item);
                } else {
                    text = getFullText(item);
                }

                if (!hasText && text !== '') {
                    hasText = true;
                }
                return text;
            })
            .join('');
    } catch (e) {
        console.warn(e);
        return undefined;
    }
};

export const evalOpenedDeclarationValue = (
    value: CSSCodeAst[],
    evalDeclarationValue: EvalDeclarationValue = DEFAULT_EVAL_DECLARATION_VALUE
) =>
    value.reduce((evaluatedValue, node) => {
        switch (node.type) {
            case 'text':
                evaluatedValue.push({
                    ...node,
                    text: evalDeclarationValue(node.text),
                } as TextNode);
                break;
            case 'call':
                evaluatedValue.push(
                    node.name !== 'value'
                        ? ({
                              ...node,
                              text: evalDeclarationValue(node.text),
                              args: evalOpenedDeclarationValue(node.args),
                          } as MethodCall)
                        : // Convert Stylable variable usages to text nodes
                          ({
                              type: 'text',
                              start: node.start,
                              end: node.end,
                              before: node.before,
                              after: node.after,
                              text: evalDeclarationValue(node.text),
                          } as TextNode)
                );
                break;
            default:
                evaluatedValue.push(node);
        }
        return evaluatedValue;
    }, [] as DeclarationValue);

export const getOpenedDeclarationList = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    shorthandProps: PROPS[],
    props: DeclarationVisualizerProps<PROPS>
): OpenedDeclarationList<PROPS> => {
    const {
        value,
        drivers: { variables },
    } = props;

    return openDeclarationList(main, shorthandProps, createShorthandOpenerApi(variables), value);
};

export const getPropValueDeclaration = <PROPS extends string>(value: OpenedDeclarationArray<PROPS>, prop: PROPS) =>
    [...value].reverse().find((node) => node.name === prop);

export const createDeclarationMapFromVisualizerValue = <PROPS extends string>(
    value: OpenedDeclarationArray<PROPS>,
    props?: DeclarationVisualizerProps<PROPS>,
    stringifyExpression?: (v: OriginNode) => string
): GenericDeclarationMap<PROPS> =>
    value.reduce((declarationMap, node) => {
        declarationMap[node.name] = getDeclarationText(
            node,
            stringifyExpression ?? props?.drivers.variables.getVariableValue
        )?.trim();
        return declarationMap;
    }, {} as GenericDeclarationMap<PROPS>);

export const getTextFromSinglePropVisualizer = <PROP extends string>(
    prop: PROP,
    props: DeclarationVisualizerProps<PROP>
): string | undefined => {
    const {
        value,
        drivers: { variables },
    } = props;
    const lastValue = getPropValueDeclaration(value, prop);
    let textValue = getDeclarationText(lastValue, variables.getVariableValue);
    if (!textValue) {
        return undefined;
    }

    if (textValue.includes(' ') && isOpenedShortHand(lastValue) && lastValue.value.some(isDeclarationExpressionItem)) {
        const original = lastValue.originalDecl as DeclarationNode<keyof ShorthandsTypeMap>;
        const originalText = getDeclarationText(original, variables.getVariableValue);
        const openedValue = openDeclarationList(original.name, [prop], createShorthandOpenerApi(variables), [
            createDeclarationNode({
                ...original,
                value: originalText ? createCssValueAST(originalText) : [],
            }),
        ])[prop];
        textValue = getDeclarationText(openedValue[openedValue.length - 1]);
    }

    return textValue?.trim();
};

export const getShorthandControllerValue = <PROPS extends string>(
    declarationList: OpenedDeclarationList<PROPS>,
    props: DeclarationVisualizerProps<PROPS>
): GenericDeclarationMap<PROPS> =>
    (Object.keys(declarationList) as PROPS[]).reduce((value, prop) => {
        return { ...value, ...createDeclarationMapFromVisualizerValue(declarationList[prop], props) };
    }, {} as GenericDeclarationMap<PROPS>);

export const controllerToVisualizerChange = <PROPS extends string>(
    controllerValue: GenericDeclarationMap<PROPS>,
    props: DeclarationVisualizerProps<PROPS>,
    main?: string | string[],
    declarationList?: OpenedDeclarationList<PROPS>
): OpenedDeclarationArray<PROPS> => {
    const mainList = Array.isArray(main) ? main : [main];
    return (Object.keys(controllerValue) as PROPS[]).reduce((value, prop) => {
        value.push(
            wrapDeclarationChangeValue(
                prop,
                controllerValue[prop],
                getPropValueDeclaration(
                    !declarationList || mainList.includes(prop) ? props.value : declarationList[prop],
                    prop
                )
            )
        );
        return value;
    }, [] as OpenedDeclarationArray<PROPS>);
};

export const getShorthandChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    changeValue: GenericDeclarationMap<MAIN | PROPS>,
    declarationList: OpenedDeclarationList<PROPS>,
    props: DeclarationVisualizerProps<PROPS>
): OpenedDeclarationArray<MAIN | PROPS> =>
    getChildChanges<MAIN, PROPS>(
        controllerToVisualizerChange(changeValue, props, main, declarationList),
        createShorthandOpenerApi(props.drivers.variables)
    );

export const createVisualizerValueFromDeclarationMap = <PROPS extends string = string>(
    value: DeclarationMap,
    astValue = false
): OpenedDeclarationArray<PROPS> =>
    Object.keys(value).reduce((visualizerValue, prop) => {
        const newValue = value[prop];
        visualizerValue.push({
            name: prop,
            value: newValue ? (astValue ? createCssValueAST(newValue) : [valueTextNode(newValue)]) : [],
        } as DeclarationNode<PROPS>);
        return visualizerValue;
    }, [] as OpenedDeclarationArray<PROPS>);

export const createDeclarationVisualizerDrivers = (): DeclarationVisualizerDrivers => ({
    variables: {
        getVariableAstValue: () => [valueTextNode('')],
        getVariableValue: () => '',
    },
});

export const createShorthandOpenerApi = ({ getVariableAstValue }: ControllerVariablesDriver) =>
    ({
        isExpression: isDeclarationExpressionItem,
        getValue: getVariableAstValue,
        toString: getVariableExpression,
    } as ParseShorthandAPI);

export const getSimpleOpenedShorthand = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN,
    shorthandValue: DeclarationValue,
    shorthandApi: ParseShorthandAPI
): SimpleOpenedShorthand<PROPS> => {
    if (main === 'background') {
        throw new Error('background prop unsupported');
    }

    const opened = getShorthandOpener(main)(shorthandValue, shorthandApi);

    if (main === 'border-radius') {
        const openedBorderRadius = opened as OpenedBorderRadiusShorthand<OriginNode>;
        if (openedBorderRadius.length > 1) {
            throw new Error('border-radius prop with multiple corners is unsupported');
        }
        return openedBorderRadius[0] as unknown as SimpleOpenedShorthand<PROPS>;
    }

    return opened as unknown as SimpleOpenedShorthand<PROPS>;
};

export const closeSimpleShorthand = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN,
    opened: SimpleOpenedShorthand<PROPS>,
    shorthandApi: ParseShorthandAPI
) => {
    if (main === 'background') {
        throw new Error('background prop unsupported');
    }

    return getShorthandCloser(main)(
        (main === 'border-radius' ? [opened] : opened) as unknown as SimpleOpenedShorthand<ShorthandsTypeMap[MAIN]>,
        shorthandApi
    );
};

export const sanitizeOpenedDeclaration = <PROPS extends string>(declaration: OpenedDeclaration<PROPS>) => {
    const { name, value } = declaration;

    if (value.length > 0 && value.every((item) => !isDeclarationExpressionItem(item))) {
        return {
            ...declaration,
            value: createCssValueAST(
                getDeclarationText({
                    name,
                    value,
                } as OpenedDeclaration<PROPS>) || ''
            ),
        } as OpenedDeclaration<PROPS>;
    }

    return declaration;
};

const getOpenedShorthandNodes = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    originalDecl: DeclarationNode<MAIN | PROPS>,
    shorthandValue: DeclarationValue,
    shorthandApi: ParseShorthandAPI
) => {
    const main = originalDecl.name as MAIN;
    const opened = getSimpleOpenedShorthand<MAIN, PROPS>(main, shorthandValue, shorthandApi);

    return (Object.keys(opened) as PROPS[]).reduce((openedNodes, prop) => {
        openedNodes[prop] = createOpenedShorthandDeclarationNode({
            name: prop,
            value: flattenDeclarationShorthand(opened[prop]),
            originalDecl,
            important: originalDecl.important,
        });
        return openedNodes;
    }, {} as Record<PROPS, OpenedShortHandDeclarationNode<PROPS>>);
};

export const openDeclarationList = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    main: MAIN | MAIN[],
    props: PROPS[],
    shorthandApi: ParseShorthandAPI,
    declarations: OpenedDeclarationArray<MAIN | PROPS>
): OpenedDeclarationList<PROPS> => {
    const mainList = Array.isArray(main) ? main : [main];
    return declarations.reduce(
        (acc, current) => {
            const prop = current.name;
            const sanitizedCurrent = sanitizeOpenedDeclaration(current);
            if (!mainList.includes(prop as MAIN)) {
                acc[prop as PROPS] = acc[prop as PROPS] || [];
                acc[prop as PROPS].push(sanitizedCurrent as OpenedDeclaration<PROPS>);
            } else if (current.value.length > 0 && isDeclaration(current)) {
                try {
                    const openedNodes = getOpenedShorthandNodes<MAIN, PROPS>(
                        current,
                        sanitizedCurrent.value,
                        shorthandApi
                    );
                    for (const prop of Object.keys(openedNodes) as PROPS[]) {
                        acc[prop] = acc[prop] || [];
                        acc[prop].push(openedNodes[prop]);
                    }
                } catch (e) {
                    console.warn(e);
                }
            }
            return acc;
        },
        props.reduce((acc, prop) => {
            acc[prop] = [];
            return acc;
        }, {} as OpenedDeclarationList<PROPS>)
    );
};

const applyOpenedShorthandChange = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    originalDecl: OpenedDeclaration<MAIN | PROPS>,
    changeDecl: OpenedShortHandDeclarationNode<MAIN | PROPS>,
    shorthandApi: ParseShorthandAPI
): OpenedDeclaration<MAIN | PROPS> => {
    const main = originalDecl.name as MAIN;
    const opened = getSimpleOpenedShorthand<MAIN, PROPS>(main, originalDecl.value, shorthandApi);

    const newValue: EvaluatedAst = { value: changeDecl.value[0] as CSSCodeAst };
    opened[changeDecl.name as PROPS] = newValue;
    const closedValue = closeSimpleShorthand(main, opened, shorthandApi);
    return {
        ...originalDecl,
        value: closedValue,
    };
};

export const getChildChanges = <MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    declarations: OpenedDeclarationArray<PROPS | MAIN>,
    shorthandApi: ParseShorthandAPI
): OpenedDeclarationArray<PROPS | MAIN> => {
    return declarations.reduce((acc, decl) => {
        if (decl.value.length > 0 && isOpenedShortHand(decl)) {
            try {
                acc.push(
                    applyOpenedShorthandChange<MAIN, PROPS>(
                        decl.originalDecl as OpenedDeclaration<MAIN | PROPS>,
                        decl,
                        shorthandApi
                    )
                );
            } catch (e) {
                console.warn(e);
            }
        } else {
            acc.push(decl);
        }
        return acc;
    }, [] as OpenedDeclarationArray<PROPS | MAIN>);
};

export const compareVisualizerValues = <PROPS extends string>(
    value1: OpenedDeclarationArray<PROPS>,
    value2: OpenedDeclarationArray<PROPS>,
    {
        drivers: {
            variables: { getVariableValue },
        },
    }: DeclarationVisualizerProps<PROPS>
): boolean => {
    if (value1.length !== value2.length) {
        return true;
    }

    for (let i = 0; i < value1.length; i++) {
        if ((value1[i] as BaseDeclarationNode).name !== (value2[i] as BaseDeclarationNode).name) {
            return true;
        }

        if (getDeclarationText(value1[i], getVariableValue) !== getDeclarationText(value2[i], getVariableValue)) {
            return true;
        }
    }

    return false;
};
