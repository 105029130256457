import ReactDOM from 'react-dom';

export const isInRect = (clientX: number, clientY: number, rect: ClientRect | DOMRect) =>
    clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom;

export const domRect = (instance: React.ReactInstance) =>
    // eslint-disable-next-line react/no-find-dom-node
    (ReactDOM.findDOMNode(instance) as HTMLDivElement).getBoundingClientRect();

export const isClickInElement = (event: MouseEvent, instance: React.ReactInstance) =>
    isInRect(event.clientX, event.clientY, domRect(instance));
