import React from 'react';

import { BorderRadiuses, INITIAL_UNIVERSAL, INITIAL_IMAGE_SOURCE } from '@wixc3/shorthands-opener';

import {
    DeclarationMap,
    GenericDeclarationMap,
    StylablePanelTranslationKeys,
    parseGradient,
    DEFAULT_WRAP_SITE_COLOR,
} from '@wixc3/stylable-panel-drivers';
import { Title, Tooltip, CompositeBlock, OptimisticWrapper } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';

import type {
    OpenedDeclarationList,
    DeclarationIO,
    DeclarationVisualizerProps,
    VisualizerComponent,
} from '../../types';
import type { OpenedDeclarationArray } from '../../declaration-types';
import type { DimensionVisualizerProps } from '../../visualizer-factories';
import {
    getDeclarationValue,
    getShorthandControllerValue,
    getShorthandChange,
    getOpenedDeclarationList,
    controllerToVisualizerChange,
    createDeclarationMapFromVisualizerValue,
    visualizerOptimisticValueResolver,
    compareVisualizerValues,
    getVisualizerWrapper,
    createDeclarationVisualizer,
} from '../../utils';
import {
    BorderInitialConfig,
    EMPTY_IMAGE_BORDER_CHANGE_VALUE,
    getSideWidth,
} from '../border-visualizer/border-visualizer';
import { getTranslate } from '../../hosts/translate';
import {
    BorderTopLeftRadiusInputVisualizer,
    BorderTopRightRadiusInputVisualizer,
    BorderBottomRightRadiusInputVisualizer,
    BorderBottomLeftRadiusInputVisualizer,
    getDimensionUnits,
} from '../../generated-visualizers';

import { classes, style } from './corner-visualizer.st.css';

export type CornerName = 'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft';

const DEFAULT_VALUE = '0px';

export const CornerVisualizerConfig = {
    resetBorders: true,
};

function getCornerProp(corner: CornerName): BorderRadiuses {
    switch (corner) {
        case 'topLeft':
            return 'border-top-left-radius';
        case 'topRight':
            return 'border-top-right-radius';
        case 'bottomRight':
            return 'border-bottom-right-radius';
        case 'bottomLeft':
            return 'border-bottom-left-radius';
    }
}

function getCornerTitle(corner: CornerName) {
    switch (corner) {
        case 'topLeft':
            return 'Top Left';
        case 'topRight':
            return 'Top Right';
        case 'bottomRight':
            return 'Bottom Right';
        case 'bottomLeft':
            return 'Bottom Left';
    }
}

export interface CornerInputState {
    focused: string;
    linked: boolean;
    inputHover: string;
}

export type CornerProps = 'border-radius' | BorderRadiuses | 'border-image-source' | 'border-image-width';
export type CornerDeclarationMap = GenericDeclarationMap<CornerProps>;
type CornerVisualizerValue = OpenedDeclarationArray<CornerProps>;
export type CornerVisualizerProps = DeclarationVisualizerProps<CornerProps>;
type CornerInputVisualizerClass = React.ComponentClass<DimensionVisualizerProps<CornerProps>>;

const CORNER_PROPS_LIST: CornerProps[] = [
    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
];
const OUTSIDE_PROPS_LIST: CornerProps[] = ['border-image-source', 'border-image-width'];
const ALL_PROPS_LIST = CORNER_PROPS_LIST.concat(OUTSIDE_PROPS_LIST);

const cornerInputVisualizerMap: Record<CornerName, CornerInputVisualizerClass> = {
    topLeft: BorderTopLeftRadiusInputVisualizer as CornerInputVisualizerClass,
    topRight: BorderTopRightRadiusInputVisualizer as CornerInputVisualizerClass,
    bottomRight: BorderBottomRightRadiusInputVisualizer as CornerInputVisualizerClass,
    bottomLeft: BorderBottomLeftRadiusInputVisualizer as CornerInputVisualizerClass,
};

export class CornerVisualizerInner
    extends React.Component<CornerVisualizerProps, CornerInputState>
    implements VisualizerComponent<CornerProps>
{
    public static INPUT_PROPS: CornerProps[] = CORNER_PROPS_LIST;
    public static OUTSIDE_PROPS: CornerProps[] = OUTSIDE_PROPS_LIST;

    private declarationMapValue: CornerDeclarationMap;
    private openedDeclarationList: OpenedDeclarationList<CornerProps>;
    private changeFromWithin = false;
    private outsideValues: DeclarationMap;

    constructor(props: CornerVisualizerProps) {
        super(props);

        this.openedDeclarationList = getOpenedDeclarationList('border-radius', ALL_PROPS_LIST, props);
        this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, props);
        this.outsideValues = this.getOutsideValues();
        this.state = { focused: '', linked: this.shouldLink(), inputHover: '' };
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(newProps: CornerVisualizerProps) {
        this.openedDeclarationList = getOpenedDeclarationList('border-radius', ALL_PROPS_LIST, newProps);
        this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, newProps);
        this.outsideValues = this.getOutsideValues();
    }

    public componentDidUpdate(prevProps: CornerVisualizerProps) {
        const { linked } = this.state;
        const isSameValue = this.shouldLink();

        if (
            linked !== isSameValue &&
            !this.changeFromWithin &&
            compareVisualizerValues(this.props.value, prevProps.value, this.props)
        ) {
            this.toggleLink();
        }
        this.changeFromWithin = false;
    }

    public render() {
        const { plane = DEFAULT_PLANE, panelHost, className } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <div className={style(classes.root, { plane }, className)} data-common-controller-highlight-parts>
                <Title
                    className={classes.title}
                    text={translate(StylablePanelTranslationKeys.controller.corners.title)}
                />
                {this.renderController()}
            </div>
        );
    }

    private renderController() {
        const { panelHost, plane = DEFAULT_PLANE } = this.props;
        const { focused, linked, inputHover } = this.state;

        const translate = getTranslate(panelHost);

        return (
            <div
                className={style(classes.input, {
                    focused,
                    linked,
                    inputHover,
                    plane,
                })}
            >
                <CompositeBlock
                    className={style(classes.controllerBlock)}
                    title={translate(StylablePanelTranslationKeys.controller.corners.sectionLabel)}
                    information={translate(StylablePanelTranslationKeys.controller.corners.sectionTooltip)}
                />
                <div className={style(classes.controllerContainer, 'controllerContainer')}>
                    <div className={classes.topControls}>
                        {this.renderCornerInput('topLeft')}
                        {this.renderCornerInput('topRight')}
                    </div>

                    <div className={style(classes.controllerPreviewStage)}>
                        <div className={style(classes.controllerPreviewContent)}>
                            {this.renderCornerPreview('topLeft')}
                            {this.renderCornerPreview('topRight')}
                        </div>

                        <div className={classes.midControls}>
                            <Tooltip
                                text={translate(
                                    linked
                                        ? StylablePanelTranslationKeys.controller.corners.unlockTooltip
                                        : StylablePanelTranslationKeys.controller.corners.lockTooltip
                                )}
                                verticalAdjust={-10}
                                horizontalAdjust={1}
                                className={style(classes.tooltip)}
                            >
                                <div className={classes.linkButton} onClick={this.toggleLinkClick} />
                            </Tooltip>
                        </div>

                        <div className={classes.controllerPreviewContent}>
                            {this.renderCornerPreview('bottomLeft')}
                            {this.renderCornerPreview('bottomRight')}
                        </div>
                    </div>

                    <div className={classes.bottomControls}>
                        {this.renderCornerInput('bottomLeft')}
                        {this.renderCornerInput('bottomRight')}
                    </div>
                </div>
            </div>
        );
    }

    private renderCornerPreview(corner: CornerName) {
        const verticalEdge = corner == 'topLeft' || corner === 'topRight' ? 'Top' : 'Bottom';
        const horizontalEdge = corner === 'topLeft' || corner === 'bottomLeft' ? 'Left' : 'Right';
        const inlineStyle = { [`border${verticalEdge}${horizontalEdge}Radius`]: this.getCornerValue(corner) };
        return (
            <div
                className={style(
                    classes.borderCorner,
                    { corner },
                    classes[`border${verticalEdge}`],
                    classes[`border${horizontalEdge}`],
                    classes.controllerPreviewContent
                )}
                style={inlineStyle}
            />
        );
    }

    private renderCornerInput(corner: CornerName) {
        const { drivers, propertyWrappers, DefaultPropertyWrapper, onDelete, panelHost } = this.props;
        const { focused } = this.state;

        const CornerInputVisualizer = cornerInputVisualizerMap[corner];

        const cornerProp = getCornerProp(corner);
        const CornerInputWrapper = getVisualizerWrapper(propertyWrappers, cornerProp, DefaultPropertyWrapper);

        const wrapperProps: DeclarationIO<CornerProps> = {
            value: getDeclarationValue(this.openedDeclarationList, cornerProp, DEFAULT_VALUE),
            onChange: (declarations) => {
                const changeValue = createDeclarationMapFromVisualizerValue(declarations, { value: [], drivers })[
                    cornerProp
                ];
                if (changeValue) {
                    this.changeCorner(corner, changeValue);
                }
            },
            onDelete,
        };

        const units = getDimensionUnits({ id: DIMENSION_ID.CORNERS, dimensionUnits: panelHost?.dimensionUnits });

        return (
            <div
                className={style(
                    classes.inputContainer,
                    { cornerName: corner, editing: corner === focused },
                    `${corner}Corner`
                )}
                onMouseEnter={() => this.mouseEnter(corner)}
                onMouseLeave={this.mouseLeave}
            >
                <CornerInputWrapper
                    title={getCornerTitle(corner)}
                    mainProp={cornerProp}
                    claims={new Set([cornerProp])}
                    {...wrapperProps}
                >
                    <CornerInputVisualizer
                        className={classes.inputElement}
                        {...wrapperProps}
                        config={{ units }}
                        drivers={drivers}
                        onFocus={() => this.setState({ focused: corner })}
                        onBlur={() => this.setState({ focused: '' })}
                    />
                </CornerInputWrapper>
            </div>
        );
    }

    private handleInputCompChange = (corner: CornerName, value: string) => {
        const { onChange } = this.props;
        const { linked } = this.state;

        if (!onChange) {
            return;
        }

        const prop = linked ? 'border-radius' : getCornerProp(corner);

        onChange(
            getShorthandChange<'border-radius', CornerProps>(
                'border-radius',
                { [prop]: value } as CornerDeclarationMap,
                this.openedDeclarationList,
                this.props
            )
        );
    };

    private handleBorderOutsideChange = (borderImageSource: string) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(
                controllerToVisualizerChange(
                    this.getResetBorderImageValue(borderImageSource) as CornerDeclarationMap,
                    this.props
                )
            );
        }
    };

    private toggleLinkClick = () => {
        this.changeFromWithin = true;
        this.toggleLink();
    };

    private toggleLink = () => {
        this.setState({ linked: !this.state.linked, focused: '' });
    };

    private mouseEnter(inputName: string) {
        this.setState({ inputHover: inputName });
    }

    private mouseLeave = () => {
        this.setState({ inputHover: '' });
    };

    private changeCorner(corner: CornerName, newValue: string) {
        const { panelHost, onChange } = this.props;

        this.changeFromWithin = true;

        if (!onChange) {
            return;
        }

        this.handleInputCompChange(corner, newValue.trim());

        if (CornerVisualizerConfig.resetBorders) {
            const borderImageSource = this.getBorderImageSource();
            if (borderImageSource) {
                this.handleBorderOutsideChange(borderImageSource);

                const translate = getTranslate(panelHost);
                const message = StylablePanelTranslationKeys.notifications.borderFillResetText;

                panelHost?.onEditorNotify &&
                    panelHost.onEditorNotify({
                        type: 'warning',
                        title: translate(message),
                        message,
                    });
            }
        }
    }

    private getOutsideValues() {
        const outsideValues: DeclarationMap = {};
        OUTSIDE_PROPS_LIST.forEach((prop) => {
            outsideValues[prop] = this.declarationMapValue[prop];
        });
        return outsideValues;
    }

    private getCornerValue = (corner: CornerName) => {
        return this.declarationMapValue[getCornerProp(corner)] || DEFAULT_VALUE;
    };

    private getBorderImageSource(): string | undefined {
        const { 'border-image-source': source } = this.outsideValues;
        return source && source !== INITIAL_IMAGE_SOURCE && source !== INITIAL_UNIVERSAL ? source : undefined;
    }

    private getResetBorderImageValue(borderImageSource: string): DeclarationMap {
        const { siteVarsDriver } = this.props;
        const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

        const widths = getSideWidth(this.outsideValues);

        let resetColor = BorderInitialConfig.fill;
        const parsedGradient = parseGradient(borderImageSource);
        if (parsedGradient && parsedGradient.colorStops.length > 0) {
            resetColor = wrapSiteColor(parsedGradient.colorStops[0].color);
        }

        return {
            'border-top': `${widths.top} ${BorderInitialConfig.style} ${resetColor}`,
            'border-right': `${widths.right} ${BorderInitialConfig.style} ${resetColor}`,
            'border-bottom': `${widths.bottom} ${BorderInitialConfig.style} ${resetColor}`,
            'border-left': `${widths.left} ${BorderInitialConfig.style} ${resetColor}`,
            ...EMPTY_IMAGE_BORDER_CHANGE_VALUE,
        };
    }

    private shouldLink(): boolean {
        const {
            [`border-top-left-radius`]: topLeft,
            [`border-top-right-radius`]: topRight,
            [`border-bottom-right-radius`]: bottomRight,
            [`border-bottom-left-radius`]: bottomLeft,
        } = this.declarationMapValue;

        return topLeft === topRight && topRight === bottomRight && bottomRight === bottomLeft;
    }
}

export const CornerVisualizer = OptimisticWrapper<React.ComponentClass<CornerVisualizerProps>, CornerVisualizerValue>(
    CornerVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);

export const CornersDeclarationVisualizer = createDeclarationVisualizer<CornerProps>(
    'border-radius',
    CornerVisualizer,
    'Corners'
);
