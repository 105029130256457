import { createMinimalFS, MinimalFSWithWrite, FileWithVersion } from './memory-minimal-fs';

export type UsageMapping = Record<string, boolean> | ((namespace: string) => boolean);

export interface BuildConfig {
    entries?: string[];
    emitBuild?: boolean;
    usageMapping?: UsageMapping;
}

export type BuildHook = (css: string, stylesheets: string[]) => void;

export class PostCSSDriver {
    protected fsSource: Record<string, FileWithVersion> = {};
    protected fs: MinimalFSWithWrite;
    protected requireModule: (path: string) => any;
    protected entries: string[] = [];
    protected buildHooks: BuildHook[];
    protected fileHooks: BuildHook[];

    constructor(protected initialBuildHook?: BuildHook) {
        const { fs, requireModule } = createMinimalFS({ files: this.fsSource });
        this.fs = fs;
        this.requireModule = requireModule;

        this.buildHooks = [];
        this.fileHooks = [];
        initialBuildHook && this.registerBuildHook(initialBuildHook);
    }

    public setEntries(entries: string[]) {
        this.entries = entries;
    }

    public writeFile(path: string, content: string) {
        this.fs.writeFileSync(path, content);
        if (this.fileHooks.length) {
            this.fileHooks.forEach((hook) => hook(content, [path]));
        }
    }

    public readFile(path: string) {
        return (this.fsSource[path] && this.fsSource[path].content) || null;
    }

    public removeFile(path: string) {
        this.fs.removeFileSync(path);
    }

    public buildCSS({ entries, emitBuild = true }: BuildConfig = {}) {
        const entriesArray = entries || this.entries;
        const css = entriesArray.map((path) => this.readFile(path)).join('\n');
        if (this.buildHooks.length && emitBuild !== false) {
            this.buildHooks.forEach((hook) => hook(css, entriesArray));
        }
        return css;
    }

    /*
    sets a single event listener which will be fire when
    - output (built) css changes - if fileChangeHook is false (default)
    OR
    - a source file changes - if fileChangeHook is true
     */
    //TODO change function names to be more generic and deprecate
    public setBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        this.initialBuildHook = buildHook;
        if (fileChangeHook) {
            this.fileHooks = [buildHook];
        } else {
            this.buildHooks = [buildHook];
        }
    }

    /*
    Registers an event listener which will be fire when
    - output (built) css changes - if fileChangeHook is false (default)
    OR
    - a source file changes - if fileChangeHook is true
     */
    public registerBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        let hookArray;
        if (fileChangeHook) {
            hookArray = this.fileHooks;
        } else {
            hookArray = this.buildHooks;
        }
        hookArray.push(buildHook);
    }

    public unregisterBuildHook(buildHook: BuildHook, fileChangeHook = false) {
        let hookArray;
        if (fileChangeHook) {
            hookArray = this.fileHooks;
        } else {
            hookArray = this.buildHooks;
        }
        const hookIndex = hookArray.indexOf(buildHook);
        !!~hookIndex && hookArray.splice(hookIndex, 1);
    }
}
