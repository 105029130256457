import React, { SyntheticEvent } from 'react';
import {
    ITreeView,
    ToggledTreeViewItemState,
    TreeView,
    TreeViewRenderItemProps,
    TreeViewSlots,
    TreeViewState,
} from '@wixc3/sui-tree-view';

import { ArrowTree } from '@wixc3/stylable-panel-common-react';
import { classes, style } from './tree-display.st.css';
import { OptionListItem } from './option-list-item';
import { ListViewSelectionType } from '@wixc3/sui-list-view';
import { OptionSelectSource } from './option-list';
import { TooltipAttachTo } from './tooltip';

const INDENTATION_OFFSET = 18;
const FLAT_TREE_INDENTATION_OFFSET = 24;
const FLAT_TREE_TOOLTIP_ATTACH_TO = TooltipAttachTo.Top;

type TreeViewDataItem = {
    name: string;
};

export interface TreeDisplayItems {
    id: string;
    dataItem: TreeViewDataItem;
    children?: TreeDisplayItems[];
}

export interface TreeDisplayProps {
    value?: string;
    items: TreeDisplayItems[];
    flatTree?: boolean;
    className?: string;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string) => void;
}

export interface TreeDisplayState {
    treeViewState: TreeViewState;
}

type TreeItemProps = TreeViewRenderItemProps<TreeViewDataItem> & {
    flatTree?: boolean;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string, source: OptionSelectSource, e: SyntheticEvent, treeView: ITreeView) => void;
};

const Item = (props: TreeItemProps) => {
    const {
        itemIndex,
        dataItemId,
        dataItem,
        level,
        flatTree,
        onHover,
        onSelect,
        isSelected,
        hasChildren,
        isExpanded,
        treeView,
    } = props;

    function onSelectFunc(id: string, source: OptionSelectSource, e: SyntheticEvent) {
        onSelect && onSelect(`${id}`, source, e, treeView);
    }

    const indentation = { paddingLeft: `${level * (!flatTree ? INDENTATION_OFFSET : FLAT_TREE_INDENTATION_OFFSET)}px` };
    const Icon = (props: any) => {
        return hasChildren ? (
            <div {...props} style={indentation}>
                {!flatTree ? <ArrowTree className={style(classes.arrow)} /> : null}
            </div>
        ) : (
            <div className={style(classes.arrowPlaceholder, { flatTree: !!flatTree })} style={indentation} />
        );
    };
    return (
        <OptionListItem
            index={itemIndex}
            key={`${itemIndex}`}
            id={`${dataItemId}`}
            className={style(classes.item, { expanded: isExpanded })}
            item={{
                id: `${dataItemId}`,
                displayName: dataItem.name,
                icon: Icon,
            }}
            onSelect={onSelectFunc}
            onHover={onHover}
            isSelected={isSelected}
            hoverOnlyFromLabel={!flatTree}
            preventOverflowWithEllipsis={true}
            attachTooltipTo={flatTree ? FLAT_TREE_TOOLTIP_ATTACH_TO : undefined}
        />
    );
};

function getExpandedUpToTarget(root: TreeDisplayItems[], targetId: string, agg: string[] = []): string[] {
    // Base case:
    const match = root.find((item) => item.id === targetId);
    if (match) {
        const newAgg = agg.slice();
        newAgg.push(match.id);
        return newAgg;
    }

    let matchInChildren: string[] = [];
    root.forEach((item) => {
        const newAgg = agg.slice();
        newAgg.push(item.id);
        if (item.children) {
            const subResult = getExpandedUpToTarget(item.children, targetId, newAgg);
            if (subResult.length > 0) {
                matchInChildren = subResult;
            }
        }
    });
    return matchInChildren;
}

//TODO should remove this
/*
This function returns the siblings of a tree node
 */
// function getSiblings(root: TreeDisplayItems[], targetId: string): string[] | undefined{
//     let foundId = false;
//     let matchInChildren: string[] | undefined;
//     root.forEach((item) => {
//         // Base case
//         if (item.id === targetId) {
//             foundId = true
//         }
//         // recursive
//         if (item.children && !matchInChildren) {
//             const subResult = getSiblings(item.children, targetId);
//             if (subResult) {
//                 matchInChildren = subResult
//             }
//         }
//     });
//
//     if (foundId) {
//         // Base case
//         const result = root.slice();
//         return result.filter((val) => val.id !== targetId).map((val) => val.id);
//     }
//     if (matchInChildren) {
//         return matchInChildren
//     }
//     return undefined;
// }

export function getChildFromTree(root: TreeDisplayItems[], id: string): TreeDisplayItems | undefined {
    let match: TreeDisplayItems | undefined;
    root.forEach((item) => {
        if (item.id === id) {
            match = item;
        }

        if (item.children && !match) {
            const subResult = getChildFromTree(item.children, id);
            if (subResult) {
                match = subResult;
            }
        }
    });
    if (match) {
        return match;
    }
    return undefined;
}

function isParentOf(root: TreeDisplayItems[], parentId: string, childId: string): boolean {
    const subTree = getChildFromTree(root, parentId);
    if (subTree && subTree.children) {
        const child = getChildFromTree(subTree.children, childId);
        if (child) {
            return true;
        }
    }
    return false;
}

function getAllTreeIds(root: TreeDisplayItems[]): string[] {
    return root.reduce((allTreeIds, item) => {
        return allTreeIds.concat([item.id]).concat(item.children ? getAllTreeIds(item.children) : []);
    }, [] as string[]);
}

export class TreeDisplay extends React.Component<TreeDisplayProps, TreeDisplayState> {
    public state: TreeDisplayState = {
        treeViewState: {
            selectedIds: [],
            toggledItemsIds: [],
            selectionStartId: null,
            currentNavigatableItemId: null,
            disabledIds: [],
            typeAheadValue: null,
        },
    };

    constructor(props: TreeDisplayProps) {
        super(props);

        if (props.value) {
            this.state.treeViewState.toggledItemsIds = this.getExpandedInitialNodes(props);
        }
    }

    public render() {
        const { className, items, flatTree, onHover, value } = this.props;

        const treeViewItemView = TreeViewSlots.renderItem<TreeViewDataItem>().provide(Item, {
            props: (props) => ({
                ...props,
                flatTree,
                onHover,
                onSelect: this.localOnSelectLabel,
                isSelected: value === props.dataItemId,
            }),
        });

        return (
            <div className={style(classes.root, className)} onMouseLeave={this.handleLeave}>
                <TreeView
                    renderItem={treeViewItemView}
                    selectionType={ListViewSelectionType.Single}
                    toggledTreeViewItemState={ToggledTreeViewItemState.Expanded}
                    treeViewState={this.state.treeViewState}
                    onChange={this.updateTreeState}
                >
                    {items}
                </TreeView>
            </div>
        );
    }

    private updateTreeState = (state: TreeViewState) => {
        this.setState({ treeViewState: state });
    };

    private handleLeave = () => this.props.onHover && this.props.onHover(null);

    private localOnSelectLabel = (
        dataItemId: string,
        source: OptionSelectSource,
        _e: SyntheticEvent,
        treeView: ITreeView
    ) => {
        const { onSelect, items, value, flatTree } = this.props;

        function toggleExpandSelf(isExpanded?: boolean) {
            treeView.updateState((stateController) => {
                stateController.toggleExpanded(dataItemId, isExpanded);
            });
        }

        // TODO remove this.
        // This collapses the siblings of the selected node. feature removed after development
        // function collapseSiblings() {
        //     const siblingIds = getSiblings(items, dataItemId);
        //     if (siblingIds) {
        //         treeView.updateState((stateController) => {
        //             siblingIds.forEach((id) => {
        //                 stateController.toggleExpanded(id, false); // force collapse
        //             })
        //         });
        //     }
        // }

        // transform state:
        if (flatTree) {
            onSelect && onSelect(dataItemId);
        } else if (source === OptionSelectSource.ICON) {
            // Collapse or expand self:
            toggleExpandSelf();
            if (value && isParentOf(items, dataItemId, value)) {
                onSelect && onSelect(dataItemId);
            }
        } else if (source === OptionSelectSource.LABEL) {
            if (this.isItemSelected(dataItemId)) {
                toggleExpandSelf();
            } else {
                toggleExpandSelf(true); // expand if possible
            }

            // fire onSelect
            onSelect && onSelect(dataItemId);
        }
    };

    private isItemSelected(dataItemId: string) {
        return this.props.value === dataItemId;
    }

    private getExpandedInitialNodes = (props: TreeDisplayProps): string[] => {
        const { value, items, flatTree } = props;
        if (flatTree) {
            return getAllTreeIds(items);
        }
        const targetId = value;
        if (targetId) {
            return getExpandedUpToTarget(this.props.items, targetId);
        }
        return [];
    };
}
