import React from 'react';
import { ContextInput } from '../context-input/context-input';
import type { ValueInputProps } from '../../../types';
import type { ValueInputComponent } from '../../../drivers';
import { StylableSiteVars } from '@wixc3/stylable-panel-drivers';
import { ColorPicker } from '../../../pickers/color-picker/color-picker';
import { style, classes } from './color-input.st.css';

export interface ColorInputState {
    colorPickerShown: boolean;
}

export class ColorInput extends React.Component<ValueInputProps, ColorInputState> implements ValueInputComponent {
    public static type = 'ColorInput';

    public state: ColorInputState = { colorPickerShown: false };

    private contextInput: ContextInput | null = null;
    private siteVarsDriver!: StylableSiteVars;

    constructor(props: ValueInputProps) {
        super(props);
        const { sheetDriver } = this.props;
        if (sheetDriver) {
            this.siteVarsDriver = new StylableSiteVars(sheetDriver);
        }
    }

    public focus() {
        this.contextInput && this.contextInput.focus();
    }
    public blur() {
        this.contextInput && this.contextInput.blur();
    }
    public select() {
        this.contextInput && this.contextInput.select();
    }
    public suggestionsShown() {
        return !!(this.contextInput && this.contextInput.suggestionsShown());
    }
    public suggestionsUsed() {
        return !!(this.contextInput && this.contextInput.suggestionsUsed());
    }

    public render() {
        const { value, contexts, siteVarsDriver, onChange, onKeyDown, onFocus, onBlur, sheetDriver, className } =
            this.props;
        const { colorPickerShown } = this.state;

        const colorValue = this.getValueColor();

        return (
            <span className={style(classes.root, className)} onClick={this.handleClick}>
                {value && [
                    <span
                        key="color_box"
                        className={classes.colorBox}
                        style={{ backgroundColor: colorValue }}
                        onClick={() => this.setState({ colorPickerShown: !colorPickerShown })}
                    />,
                    this.siteVarsDriver && colorPickerShown ? (
                        <ColorPicker
                            key="color_picker"
                            className={classes.colorPicker}
                            siteVarsDriver={this.siteVarsDriver}
                            currentColor={colorValue}
                            onChange={onChange}
                            onHover={this.handleColorPickerHover}
                            // onClose={() => this.setState({colorPickerShown: false})}
                            onBlur={() => this.setState({ colorPickerShown: false })}
                        />
                    ) : null,
                ]}
                <ContextInput
                    className={classes.contextInput}
                    value={value}
                    siteVarsDriver={siteVarsDriver}
                    contexts={contexts}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    sheetDriver={sheetDriver}
                    ref={(ref) => (this.contextInput = ref)}
                />
            </span>
        );
    }

    private handleClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

    private getValueColor() {
        const { value, sheetDriver } = this.props;
        return sheetDriver ? sheetDriver.evalDeclarationValue(value) : value;
    }

    private handleColorPickerHover = (value: string | null) => {
        value && this.props.onChange && this.props.onChange(value);
    };
}
