import React from 'react';

import { style, classes } from './toggle-switch.st.css';

export interface ToggleSwitchProps {
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
    className?: string;
}

export class ToggleSwitch extends React.Component<ToggleSwitchProps> {
    public render() {
        const { value, disabled, onChange, className } = this.props;

        return (
            <span
                className={style(
                    classes.root,
                    {
                        toggled: !!value,
                        disabled: !!disabled,
                    },
                    className
                )}
                onClick={() => !disabled && onChange(!value)}
            >
                <div className={classes.knob} />
            </span>
        );
    }
}
