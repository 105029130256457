import React from 'react';

import { style, classes } from './title.st.css';

export interface TitleProps {
    text?: string;
    className?: string;
    style?: React.CSSProperties;
}

export const Title: React.FunctionComponent<TitleProps> = ({ text, className, style: propStyle }) => (
    <h2 className={style(classes.root, className)} style={propStyle}>
        {text}
    </h2>
);
