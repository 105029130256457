import { CustomInputWrapperFactory } from '../inputs/custom-input-wrapper/custom-input-wrapper';
import type { CustomInput, ValueInputComponentClass, CustomInputPreview } from './types';

export class ValueInputDriver {
    private inputs: Record<string, ValueInputComponentClass>;

    constructor(private defaultInput: ValueInputComponentClass) {
        this.inputs = {};
    }

    // TODO: Register suggestions?

    public registerInput(propNames: string | string[], Input: ValueInputComponentClass) {
        const propList = typeof propNames === 'string' ? [propNames] : propNames;
        propList.forEach((propName) => (this.inputs[propName] = Input));
    }

    public registerCustomInput(
        propNames: string | string[],
        Preview: CustomInputPreview,
        Input: CustomInput,
        inline = false
    ) {
        const propList = typeof propNames === 'string' ? [propNames] : propNames;
        propList.forEach(
            (propName) =>
                ((this.inputs[propName] as any) = CustomInputWrapperFactory(
                    propName,
                    Preview,
                    Input,
                    this.getInput(propName),
                    inline
                ))
        );
    }

    public getInput(propName: string) {
        return this.inputs[propName] || this.defaultInput;
    }
}
