import React from 'react';
import type { DimensionProps, UnitRef } from '@wixc3/stylable-panel-common';
import {
    CssUnit,
    DimensionInputProps,
    DimensionInputState,
    InputContext,
    InputError,
    InternalDimensionProps,
    MODIFIER_KEYS,
    ParseConfig,
    ParsedDimension,
    RangeContext,
} from './types';
import {
    DEFAULT_INPUT_VALUE,
    DEFAULT_PLACEHOLDER,
    DEFAULT_SHIFT_STEP,
    DEFAULT_STEP,
    MAX_INPUT_LENGTH,
    MAX_VALUE,
    MAX_VALUE_LENGTH,
    MIN_VALUE,
    TOOLTIP_OFFSET,
    UNITLESS_CONFIG,
    UNITLESS_SYMBOL,
    ZERO_STR,
} from './consts';
import {
    breakError,
    compileValue,
    debug,
    getDropdownOptions,
    getRefSymbol,
    getSliderConfig,
    getSymbolRef,
    isFloat,
    isNumber,
    keyInput,
    sanitizeNegativeFloat,
    stringExtract,
    trimFloatValue,
    unitFilter,
    validateNodes,
    validateNum,
    valueFilter,
} from './utils';
import { exponentValue, splitNumDigit } from './patterns';
import { DropDown } from '../../drop-down';
import { Slider } from '../../slider';
import { Tooltip } from '../../tooltip';
import { classes, style } from './dimension-input.st.css';

export class DimensionInput extends React.Component<DimensionInputProps, DimensionInputState> {
    public static defaultProps: Partial<DimensionInputProps> = {};
    private _value: number = DEFAULT_INPUT_VALUE;
    private _unit: CssUnit = '';
    private _config: InternalDimensionProps = UNITLESS_CONFIG;
    private _editContext: InputContext = InputContext.ABORT;
    private _interaction = false;

    constructor(props: DimensionInputProps) {
        super(props);

        const { value: propValue, config: { keywords } = {} } = this.props;

        this.state = {
            editValue: keywords?.includes(propValue) ? propValue : DEFAULT_INPUT_VALUE.toString(),
            inputContext: InputContext.ABORT,
            inputError: InputError.NONE,
        };

        if (propValue && !keywords?.includes(propValue)) {
            const { value, unit, error } = this.parse(propValue, {
                applyUnit: true,
                unitConfig: this._config,
            });

            this.setConfig(unit);

            this.state = {
                ...this.state,
                editValue: (error ?? (this._value === value && this._unit === unit)
                    ? this.range(value)
                    : value
                ).toString(),
            };

            this._value = value;
        }
    }

    public componentDidUpdate(prevProps: DimensionInputProps) {
        const { config: { keywords } = {}, value: propValue } = this.props;
        if (this.props !== prevProps && propValue) {
            if (keywords?.includes(propValue)) {
                this.setConfig('');
                return;
            }
            const { value, unit /*, error */ } = this.parse(propValue, {
                applyUnit: true,
                unitConfig: this._config,
            });

            this.setConfig(unit);

            this.setState({ editValue: value.toString() });
            this._value = value;
        }
    }

    public render() {
        const { isDisabled, isRequired, debug, className } = this.props;
        const { inputContext } = this.state;
        return (
            <span
                className={style(
                    classes.root,
                    {
                        context: inputContext,
                        disabled: !!isDisabled,
                        required: !!isRequired,
                    },
                    className
                )}
            >
                {this.renderSlider()}
                {this.renderInput()}
                {this.renderUnitSelector()}
                {debug && this.showDebugInfo()}
            </span>
        );
    }

    private renderInput() {
        const {
            isDisabled,
            isRequired,
            inputDataAid,
            isInputCyclic,
            keepRange,
            isBoundRange,
            hidePlaceholder,
            tooltipContent,
            trimUnit,
            forceFocus,
            config: { keywords } = {},
        } = this.props;

        const { inputContext } = this.state;

        const { name } = getSymbolRef(this._config.symbol!);
        const { inputError, editValue } = this.state;
        const { max, min, symbol, displaySymbol } = this._config;

        const rangeProps = !isBoundRange &&
            keepRange &&
            !isInputCyclic && {
                min,
                max,
            };

        const styleState = {
            symbol: name,
            focused: inputContext !== InputContext.ABORT,
            inputError: inputError !== InputError.NONE,
        };

        const autoFocusProp = forceFocus ? { autoFocus: true } : {};

        const localConfig = this._config;
        const dimensionSymbol = displaySymbol || symbol || '';
        const isFocused = inputContext === InputContext.FOCUS;

        return (
            <span
                className={classes.inputElementWrapper}
                data-highlight-input-display={!isFocused && !isDisabled}
                data-aid={inputDataAid}
            >
                <span className={classes.tooltipWrapper} data-tooltip-hidden={isFocused}>
                    <Tooltip
                        className={classes.tooltip}
                        text={isFocused || !tooltipContent ? '' : tooltipContent || ''}
                        verticalAdjust={TOOLTIP_OFFSET}
                    >
                        <input
                            className={style(classes.inputElement, styleState)}
                            value={editValue}
                            onFocus={this.onFocus}
                            onChange={this.onInputChange}
                            onKeyDown={this.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            onBlur={(event) => {
                                !this._interaction ? this.onBlur(event, localConfig) : event.currentTarget.select();
                            }}
                            onWheel={() => {
                                this.setState({ inputContext: InputContext.ABORT });
                            }}
                            type={keywords?.length ? 'text' : 'number'}
                            key={`editInput_${forceFocus ? 'forced' : ''}`}
                            placeholder={
                                hidePlaceholder
                                    ? DEFAULT_PLACEHOLDER
                                    : keywords?.includes(this.props.value)
                                    ? this.props.value
                                    : this._value.toString()
                            }
                            disabled={!!isDisabled}
                            required={!!isRequired}
                            {...rangeProps}
                            {...autoFocusProp}
                        />
                        <span
                            className={style(classes.symbol, {
                                unit: dimensionSymbol,
                                ref: name,
                                hideUnit: isFocused,
                            })}
                            data-symbol={trimUnit ? '' : dimensionSymbol}
                        >
                            <span className={classes.dimensionSymbol}>{!trimUnit && dimensionSymbol}</span>
                        </span>
                    </Tooltip>
                </span>
            </span>
        );
    }

    private renderUnitSelector() {
        const { isDisabled, config: { units } = {}, focusOnlyUnitSelector = true } = this.props;
        const { inputContext } = this.state;
        const hideUnitSelector = focusOnlyUnitSelector && inputContext === InputContext.ABORT;
        const unitRefs = Object.keys(units ?? {});

        if (unitRefs.length < 2 || hideUnitSelector) {
            return;
        }

        const { name } = getSymbolRef(this._unit);

        return (
            <div
                className={classes.unitSelectorWrapper}
                onMouseEnter={() => (this._interaction = true)}
                onMouseLeave={() => (this._interaction = false)}
            >
                <DropDown
                    className={style(classes.unitSelector, { isDisabled: !!isDisabled })}
                    value={name}
                    options={getDropdownOptions(units ?? {})}
                    onSelect={(newUnit) => this.onDropDownSelect(newUnit as UnitRef)}
                    disabled={isDisabled}
                    smallArrow
                />
            </div>
        );
    }

    private renderSlider() {
        const { value, isSlider, opacitySliderColor, isSliderCyclicKnob, isDisabled } = this.props;
        const { sliderMin, sliderMax, sliderStep } = this._config;
        const { editValue } = this.state;
        const validRange = isSlider && isNumber(sliderMin) && isNumber(sliderMax);

        return (
            validRange && (
                <Slider
                    className={classes.slider}
                    value={isNumber(value) ? +value : this._value}
                    min={sliderMin}
                    max={sliderMax}
                    step={sliderStep}
                    knob={isSliderCyclicKnob}
                    disabled={isDisabled || !isNumber(editValue) || editValue === ''}
                    color={opacitySliderColor}
                    onChange={(value) => this.setValue(value)}
                />
            )
        );
    }

    private readonly onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        event.preventDefault();

        const { config: { keywords } = {}, onChange, isBoundRange } = this.props;
        const { value } = event.currentTarget;

        if (value === this.state.editValue) {
            return;
        }

        if (value === '') {
            this.setState({
                editValue: value,
            });
            return;
        }

        if (!isNumber(value)) {
            this.setState({
                editValue: value,
                inputError: keywords?.some((word) => word.startsWith(value)) ? InputError.NONE : InputError.INVALID,
            });

            if (keywords?.includes(value)) {
                this.setConfig('');
                onChange?.(value);
            }
            return;
        }

        const sanitized = this.sanitize(value);
        if (sanitized.editValue !== value && sanitized.editValue && sanitized.inputError) {
            this.setState(sanitized);
            return;
        }

        const parsed = this.parse(value, {
            applyUnit: false,
            unitConfig: this._config,
        });

        if (parsed.error === InputError.INVALID) {
            this.setState({
                editValue: value,
                inputError: parsed.error,
            });
            return;
        }

        const { hasRange } = this.getRangeProps(parsed.value);

        const { min, max } = this._config;

        const state = this.rangeState(parsed.value, {
            FREE_RANGE_CYCLIC_MAX: {
                editValue: min?.toString() ?? '',
                inputError: InputError.NONE,
            },
            FREE_RANGE_CYCLIC_MIN: {
                editValue: max?.toString() ?? '',
                inputError: InputError.NONE,
            },
            NO_CYCLIC_RANGE_DIFFER: isBoundRange
                ? {
                      editValue: value.toString(),
                      inputError: hasRange ? InputError.INVALID : InputError.NONE,
                  }
                : {
                      inputError: InputError.NONE,
                  },
            NO_RANGE_NO_CYCLIC: {
                editValue: parsed.value.toString(),
                inputError: hasRange ? InputError.INVALID : InputError.NONE,
            },
            DEFAULT: Object.assign({}, this.state, {
                editValue: value.toString(),
                inputError: hasRange ? InputError.RANGE : InputError.NONE,
            }),
        });

        this.setState(state);

        if (state.inputError === InputError.NONE) {
            this._value = parsed.value;
            onChange?.(`${parsed.value}${parsed.unit}`);
        }
    };

    private readonly onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { onFocus } = this.props;
        event.currentTarget.select();
        this.setState({ inputContext: InputContext.FOCUS });

        if (onFocus) {
            onFocus();
        }
    };

    private onBlur(event: React.FocusEvent<HTMLInputElement>, config: InternalDimensionProps) {
        const { noValueLengthLimit, isInputCyclic, config: { keywords } = {}, onBlur, onChange } = this.props;
        const { value: targetValue } = event.currentTarget;
        const { ABORT, INTERACTIVE_UI } = InputContext;
        if (keywords?.includes(targetValue)) {
            this.setState({
                inputContext: this._interaction ? INTERACTIVE_UI : ABORT,
                inputError: InputError.NONE,
            });

            onBlur?.();
            return;
        }

        if (targetValue === '' || !isNumber(targetValue)) {
            this.setState({
                editValue: keywords?.includes(this.props.value) ? this.props.value : this._value.toString(),
                inputContext: this._interaction ? INTERACTIVE_UI : ABORT,
                inputError: InputError.NONE,
            });

            onBlur?.();
            return;
        }

        const parsed = this.parse(targetValue, {
            applyUnit: false,
            unitConfig: config,
        });

        const { value } = parsed;
        const shouldEnforceValue = !noValueLengthLimit && value.toString().length > MAX_VALUE_LENGTH;

        const enforced = shouldEnforceValue
            ? isFloat(value)
                ? trimFloatValue(value)
                : value < 0
                ? this.range(MIN_VALUE)
                : this.range(MAX_VALUE)
            : value;

        const ranged = this.range(enforced, isInputCyclic);

        if (value !== ranged || parsed.error === InputError.INVALID) {
            if (keywords?.includes(this.props.value)) {
                this.setState({
                    editValue: this.props.value,
                    inputContext: this._interaction ? INTERACTIVE_UI : ABORT,
                    inputError: InputError.NONE,
                });
                return;
            }
            this.setState({
                editValue: ranged.toString(),
                inputContext: this._interaction ? INTERACTIVE_UI : ABORT,
                inputError: InputError.NONE,
            });
        } else {
            this.setState({
                editValue: this._value.toString(),
                inputContext: this._interaction ? INTERACTIVE_UI : ABORT,
                inputError: InputError.NONE,
            });
        }

        if (ranged !== this._value) {
            onChange?.(`${ranged}${this._unit}`);
        }
        onBlur?.();
    }

    private readonly onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { keyCode, shiftKey } = event;
        const { UP, DOWN, ESC } = MODIFIER_KEYS;
        const { KEY_CHANGE, ABORT } = InputContext;

        const isKey = keyInput(keyCode);
        const keyUp = isKey(UP);
        const keyDown = isKey(DOWN);

        if (keyUp || keyDown) {
            event.preventDefault();
        }

        if (isKey(ESC)) {
            this.setState({
                editValue: this._value.toString(),
                inputContext: ABORT,
            });
            return;
        }

        if ((keyUp || keyDown) && isNumber(this.state.editValue)) {
            this._editContext = KEY_CHANGE;
            this.onStep(keyUp, shiftKey);
            return;
        }
    };

    private readonly onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { keyCode } = event;
        const { UP, DOWN } = MODIFIER_KEYS;
        const isKey = keyInput(keyCode);

        if (isKey(DOWN) || isKey(UP)) {
            this._editContext = InputContext.EDIT_TIME;

            if (isNumber(this.state.editValue)) {
                event.currentTarget.select();
            }
        }
    };

    private onStep(keyUp: boolean, shiftKey: boolean) {
        const { editValue } = this.state;
        const { isInputCyclic, onChange } = this.props;
        const { step, shiftStep } = this._config;
        let { min, max } = this._config;

        min = min ?? MIN_VALUE;
        max = max ?? MAX_VALUE;

        const stepValue = shiftKey ? shiftStep ?? DEFAULT_SHIFT_STEP : step ?? DEFAULT_STEP;

        const value = Number(editValue === '' ? this._value : editValue);
        let newValue = Number((value + stepValue * (keyUp ? 1 : -1)).toFixed(2));

        if (isInputCyclic && isNumber(min) && isNumber(max)) {
            if (keyUp && value + stepValue > max) {
                this.setState({ inputError: InputError.NONE });
                newValue = min + stepValue - (max - value);
            } else if (!keyUp && value - stepValue < min) {
                this.setState({ inputError: InputError.NONE });
                newValue = max + (value - stepValue);
            }
        }

        if (newValue !== value) {
            const { hasRange } = this.getRangeProps(newValue);
            if (hasRange) {
                newValue = Math.max(min, Math.min(max, newValue));
            }
            this._value = newValue;
            this.setState({ editValue: newValue.toString(), inputError: InputError.NONE });
            onChange?.(`${newValue}${this._unit}`);
        }
    }

    private onDropDownSelect(ref: UnitRef) {
        const oldUnit = this._unit;
        const unit = getRefSymbol(ref);
        this.setConfig(unit);

        const ranged = this.range(this._value);

        if (ranged !== this._value) {
            this.setValue(ranged);
        } else {
            const { onChange } = this.props;
            if (oldUnit !== unit) {
                onChange?.(`${ranged}${unit}`);
            }
        }

        this._interaction = false;

        this.forceUpdate();
    }

    private setValue(value: number) {
        const { onChange } = this.props;

        this.setState({
            editValue: value.toString(),
            inputError: InputError.NONE,
        });

        if (this._value !== value) {
            this._value = value;
            onChange?.(`${value}${this._unit}`);
        }
    }

    private setConfig(unit: CssUnit) {
        const { name, symbol } = getSymbolRef(unit);
        const config = this.getConfig(name);
        const { isSlider, config: { units } = {} } = this.props;
        const { displaySymbol, min, max, step, shiftStep, noFloat } = config;
        const sliderConfig = isSlider ? getSliderConfig(config) : {};

        const targetConfig = {
            symbol,
            displaySymbol,
            min: validateNum(min),
            max: validateNum(max) || MAX_VALUE,
            step: step ?? DEFAULT_STEP,
            shiftStep: shiftStep ?? DEFAULT_SHIFT_STEP,
            noFloat,
        };

        let enforcedConfig = {};
        if (units && this.props.value === ZERO_STR) {
            const firstRef = Object.keys(units)[0];
            this._unit = getRefSymbol(firstRef as UnitRef);
            enforcedConfig = Object.assign(Object.values(units)[0]!, {
                symbol: getRefSymbol(firstRef as UnitRef),
            });
        } else if (targetConfig.symbol) {
            this._unit = targetConfig.symbol;
        } else if (units?.unitless) {
            this._unit = UNITLESS_SYMBOL;
        }

        this._config = {
            ...targetConfig,
            ...sliderConfig,
            ...enforcedConfig,
        };
    }

    private parse(sample: string, options: ParseConfig): ParsedDimension {
        const error = breakError(this._value, this._unit);
        const { INVALID, EMPTY } = InputError;

        if (sample.length && !!sample.match(exponentValue)) {
            return error(INVALID);
        }

        const nodes = sample.match(splitNumDigit) || [];
        if (!nodes.length) {
            return error(EMPTY);
        }

        const fixedNodes = sanitizeNegativeFloat(nodes);
        if (!validateNodes(fixedNodes)) {
            return error(INVALID);
        }

        let matched = stringExtract(nodes, valueFilter);
        if (!matched.length) {
            return error(INVALID);
        }

        const compiledMatched = Number(matched).toString();
        if (compiledMatched !== matched || compiledMatched.length > MAX_INPUT_LENGTH) {
            matched = matched.slice(0, MAX_INPUT_LENGTH);
        }

        const { applyUnit, unitConfig } = options;
        const { symbol } = getSymbolRef(stringExtract(nodes, unitFilter));

        let compiled = Number(compileValue(matched));
        compiled = isNumber(compiled) ? compiled : DEFAULT_INPUT_VALUE;

        const truncFloat = isFloat(compiled) && this.noFloatValue(symbol, applyUnit, unitConfig);

        return {
            value: truncFloat ? Math.trunc(compiled) : compiled,
            unit: applyUnit ? symbol : this._unit,
        };
    }

    private noFloatValue(symbol: string, applyUnit: boolean, unitConfig?: DimensionProps) {
        const { config: { units } = {}, noFloat } = this.props;
        const { name } = getSymbolRef(symbol);
        const config = units?.[name];
        const floatDisable = {
            prop: noFloat,
            unit: !applyUnit && unitConfig && unitConfig.noFloat,
            dimension: applyUnit && config && symbol != this._unit && config.noFloat,
        };
        return Object.values(floatDisable).indexOf(true) !== -1;
    }

    private sanitize(value: string): DimensionInputState {
        const { noValueLengthLimit } = this.props;
        const { editValue } = this.state;
        const { MAX_LENGTH, EMPTY, RANGE } = InputError;

        const state = (newState: Partial<DimensionInputState>) => Object.assign({}, this.state, newState);

        const ranged = this.range(Number(editValue)).toString();
        const valueLength = ranged.length;
        if (noValueLengthLimit && valueLength > MAX_VALUE_LENGTH) {
            return state({
                editValue: editValue.slice(0, MAX_VALUE_LENGTH - 1),
                inputError: MAX_LENGTH,
            });
        } else if (editValue.length > MAX_INPUT_LENGTH) {
            return state({
                editValue: editValue.slice(0, MAX_INPUT_LENGTH),
                inputError: MAX_LENGTH,
            });
        } else if (Number(value) > MAX_VALUE) {
            return state({
                editValue: value,
                inputError: RANGE,
            });
        } else if (!value.length || (value.length && !value.trim().length)) {
            return state({
                editValue: '',
                inputError: EMPTY,
            });
        }

        return state({ editValue: value });
    }

    private range(value: number, reverse?: boolean): number {
        const { isInputCyclic, keepRange } = this.props;

        if (!isInputCyclic && !keepRange) {
            return value;
        }

        let { min, max } = this._config;
        min = min ?? MIN_VALUE;
        max = max ?? MAX_VALUE;

        const validMin = isNumber(min);
        const validMax = isNumber(max);

        if (!validMin && !validMax) {
            return value;
        }

        const exceedMin = validMin && value < min;
        const exceedMax = validMax && value > max;

        const maxOrMin = reverse ? min : max;
        const minOrMax = reverse ? max : min;

        if (exceedMax && validMin) {
            if (isInputCyclic) {
                return minOrMax;
            }
            if (keepRange) {
                return maxOrMin;
            }
        }

        if (exceedMin && validMax) {
            if (isInputCyclic) {
                return maxOrMin;
            }
            if (keepRange) {
                return minOrMax;
            }
        }

        if (exceedMax || exceedMin) {
            return this._value;
        }

        return value;
    }

    private getRangeProps(parsedValue: number) {
        const { keepRange, isInputCyclic } = this.props;

        let { min, max } = this._config;
        min = min ?? MIN_VALUE;
        max = max ?? MAX_VALUE;

        const maxRange = isNumber(max) && max < parsedValue;
        const minRange = isNumber(min) && min > parsedValue;

        const freeRangeCyclic = !keepRange && isInputCyclic;
        const ranged = this.range(parsedValue).toString();
        const hasRange = maxRange || minRange;

        return {
            hasRange,
            maxRange,
            minRange,
            freeRangeCyclic,
            ranged,
        };
    }

    private rangeState(
        value: number,
        reducers: Record<RangeContext, Partial<DimensionInputState>>
    ): DimensionInputState {
        const state = (name: RangeContext) => Object.assign({}, this.state, reducers[name]);

        const { FREE_RANGE_CYCLIC_MAX, FREE_RANGE_CYCLIC_MIN, NO_CYCLIC_RANGE_DIFFER, NO_RANGE_NO_CYCLIC, DEFAULT } =
            RangeContext;

        const { editValue } = this.state;
        const { keepRange, isInputCyclic } = this.props;
        const { hasRange, maxRange, minRange, freeRangeCyclic, ranged } = this.getRangeProps(value);

        const isRangeKeyChange = hasRange && this._editContext === InputContext.KEY_CHANGE;
        if (!isRangeKeyChange) {
            return state(DEFAULT);
        }

        if (freeRangeCyclic && maxRange) {
            return state(FREE_RANGE_CYCLIC_MAX);
        }

        if (freeRangeCyclic && minRange) {
            return state(FREE_RANGE_CYCLIC_MIN);
        }

        if (!freeRangeCyclic && ranged !== editValue) {
            return state(NO_CYCLIC_RANGE_DIFFER);
        }

        if (!keepRange && !isInputCyclic) {
            return state(NO_RANGE_NO_CYCLIC);
        }

        return state(DEFAULT);
    }

    private getConfig(unitRef: UnitRef): DimensionProps {
        const { config: { units } = {} } = this.props;

        if (!units) {
            return UNITLESS_CONFIG;
        }

        const unitRefs = Object.keys(units);
        const ref = units[unitRef];

        return ref ? ref : unitRefs.length ? (units[unitRefs[0] as UnitRef] as DimensionProps) : UNITLESS_CONFIG;
    }

    private showDebugInfo() {
        return (
            <div className={classes.debug}>
                <pre>{debug(Object.assign({}, { storedValue: this._value.toString() }, this.state, this._config))}</pre>
            </div>
        );
    }
}
