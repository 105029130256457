import React from 'react';

import { Title } from '@wixc3/stylable-panel-components';
import {
    StylesheetDriver,
    DeclarationMap,
    BlockVariantSet,
    createReactStyleFromCssStyle,
} from '@wixc3/stylable-panel-drivers';

import { HOVER_OFF_TIMEOUT_MS } from '../../../../setup/consts';

import { style, classes } from './simple-block-variant-controller.st.css';

export interface SimpleBlockVariantControllerProps {
    onChange: (value: DeclarationMap) => void;
    onHover?: (value: DeclarationMap) => void;
    onHoverOff?: () => void;
    onSaveNew?: () => void;
    onDeleteUserBlockVariant?: (index: number) => void;
    variantSets: BlockVariantSet;
    userVariantSets?: BlockVariantSet;
    customComponent?: (variant: Record<string, string>) => JSX.Element;
    sheetDriver?: StylesheetDriver;
    className?: string;
}

export interface SimpleBlockVariantControllerState {
    showAll: boolean;
}

export const SHOW_MORE_THRESHOLD = 5;

export default class SimpleBlockVariantController extends React.Component<
    SimpleBlockVariantControllerProps,
    SimpleBlockVariantControllerState
> {
    public state: SimpleBlockVariantControllerState = {
        showAll: false,
    };
    private shouldTriggerLeave = false;

    public render() {
        const { variantSets, userVariantSets, onSaveNew, className } = this.props;

        const buttons =
            variantSets &&
            variantSets.map((variant, index) => {
                return this.renderSelectionButton(variant, index);
            });

        const userButtons =
            (userVariantSets &&
                userVariantSets.map((variant, index) => {
                    return this.renderSelectionButton(variant, index, true);
                })) ||
            [];

        const length = buttons ? buttons.length : 0;
        const seeAllString = this.state.showAll ? 'Show less' : `See all (${length})`;
        const showMoreButton =
            length > SHOW_MORE_THRESHOLD || userButtons.length > 0 ? (
                <button className={classes.showMore} onClick={() => this.setState({ showAll: !this.state.showAll })}>
                    {seeAllString}
                </button>
            ) : undefined;
        const saveNewButton =
            onSaveNew !== undefined ? (
                <button className={classes.saveNew} onClick={onSaveNew}>
                    Save
                </button>
            ) : undefined;

        let previewModeButtons;
        if (buttons) {
            previewModeButtons = buttons.slice(0, SHOW_MORE_THRESHOLD);
        }
        return (
            <div className={style(classes.root, { open: this.state.showAll }, className)}>
                <Title text="Presets " />
                <div className={classes.utils}>
                    {saveNewButton}
                    {showMoreButton}
                </div>
                <div className={classes.buttons} onMouseLeave={this.hoverOff}>
                    <div className={classes.section}>{this.state.showAll ? buttons : previewModeButtons}</div>
                    {this.state.showAll && userButtons.length > 0 ? (
                        <div className={classes.userSection}>
                            <Title text="My Presets " />
                            <div className={classes.section}>{this.state.showAll ? userButtons : undefined}</div>
                        </div>
                    ) : undefined}
                </div>
            </div>
        );
    }

    private renderSelectionButton(variant: DeclarationMap, index: number, allowDelete = false) {
        const camelCasedVariantStyle = createReactStyleFromCssStyle(variant, this.props.sheetDriver);

        let buttonStyle = {};
        let children: React.ReactNode = null;
        if (this.props.customComponent) {
            children = <div className={classes.buttonInner}>{this.props.customComponent(camelCasedVariantStyle)}</div>;
        } else {
            buttonStyle = { style: camelCasedVariantStyle };
        }

        const button = (
            <div className={classes.blockVariant} key={index}>
                {allowDelete ? (
                    <div className={classes.delete} onClick={() => this.deleteBlockVariant(index)} />
                ) : undefined}
                <div
                    className={classes.button}
                    onClick={() => this.props.onChange(variant)}
                    onMouseEnter={() => {
                        this.props.onHover && this.props.onHover(variant);
                        this.shouldTriggerLeave = false;
                    }}
                    onMouseLeave={this.leaveTimeout}
                    data-aid={`st_preseton_controller_button_${index}`}
                    {...buttonStyle}
                >
                    {children}
                </div>
            </div>
        );
        return button;
    }

    private deleteBlockVariant(index: number) {
        this.props.onDeleteUserBlockVariant && this.props.onDeleteUserBlockVariant(index);
    }

    private leaveTimeout = () => {
        this.shouldTriggerLeave = true;
        setTimeout(() => {
            if (this.shouldTriggerLeave) {
                this.hoverOff();
                this.shouldTriggerLeave = false;
            }
        }, HOVER_OFF_TIMEOUT_MS);
    };

    private hoverOff = () => {
        this.props.onHoverOff && this.props.onHoverOff();
    };
}
