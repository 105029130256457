import type { CustomInputProps } from '../../drivers';
import { CommonInput } from './common-input';
import { expandShorthandCssProp, DeclarationMap } from '@wixc3/stylable-panel-drivers';

const DEFAULT_VALUE = '0';

export class CommonCornerInput<S> extends CommonInput<S> {
    protected cornerValues: Array<string | undefined> = [DEFAULT_VALUE, DEFAULT_VALUE, DEFAULT_VALUE, DEFAULT_VALUE];

    protected setValues(props: CustomInputProps): string | undefined {
        if (!this.props.value) {
            this.cornerValues[0] = this.cornerValues[1] = this.cornerValues[2] = this.cornerValues[3] = DEFAULT_VALUE;
            return;
        }

        let expanded: DeclarationMap = {};
        try {
            expanded = expandShorthandCssProp('border-radius', props.value || '');
            this.cornerValues[0] = expanded['border-top-left-radius'];
            this.cornerValues[1] = expanded['border-top-right-radius'];
            this.cornerValues[2] = expanded['border-bottom-right-radius'];
            this.cornerValues[3] = expanded['border-bottom-left-radius'];
        } catch {
            //
        }
        return;
    }

    protected getCssStringValue(): string {
        if (this.cornerValues[0] !== undefined) {
            if (
                this.cornerValues[0] === this.cornerValues[1] &&
                this.cornerValues[1] === this.cornerValues[2] &&
                this.cornerValues[2] === this.cornerValues[3]
            ) {
                return this.cornerValues[0]!;
            }
            if (this.cornerValues[0] === this.cornerValues[2] && this.cornerValues[1] === this.cornerValues[3]) {
                return `${this.cornerValues[0]} ${this.cornerValues[1]}`;
            }
            if (this.cornerValues[1] === this.cornerValues[3]) {
                return `${this.cornerValues[0]} ${this.cornerValues[1]} ${this.cornerValues[2]}`;
            }
            return `${this.cornerValues[0]} ${this.cornerValues[1]} ${this.cornerValues[2]} ${this.cornerValues[3]}`;
        } else {
            return '';
        }
    }

    protected isSameValue(): boolean {
        return (
            this.cornerValues[0] === this.cornerValues[1] &&
            this.cornerValues[1] === this.cornerValues[2] &&
            this.cornerValues[2] === this.cornerValues[3]
        );
    }
}
