import type * as postcss from 'postcss';

import { CollectionDriver, Collection, ChangeContext } from './stylable-collection';
import type { StylesheetDriver } from '../stylable-stylesheet';
import { splitLayers } from '../stylable-shorthands';

export class ImageCollectionDriver extends CollectionDriver<Collection> {
    protected router(decl: postcss.Declaration, sheet: StylesheetDriver, declarations: Collection) {
        const addImage = (value: string, change: (value: string) => void) => {
            if (declarations[value] === undefined) {
                declarations[value] = [];
            }
            declarations[value].push({ declaration: decl, change });
        };

        if (decl.prop === 'background' || decl.prop === 'background-image') {
            this.handleImageBackgroundProperty(sheet, decl.value, addImage);
        } else if (decl.prop === 'border-image' || decl.prop === 'border-image-source') {
            this.handleImageURLProperty(sheet, decl.value, addImage);
        }
    }

    private handleImageBackgroundProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const backgrounds = splitLayers(sheet.evalDeclarationValue(declValue));
        backgrounds.forEach((layer) => {
            this.handleImageURLProperty(sheet, layer, adder);
        });
    }

    private handleImageURLProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);
        const urlRegex = /url\(.*\)/;

        function changeURL(this: ChangeContext, value: string) {
            const currEval = sheet.evalDeclarationValue(this.declaration.value);
            const currURLMatch = currEval.match(urlRegex);
            if (currURLMatch) {
                this.declaration.value = currEval.replace(currURLMatch[0], value);
                updateFromAST(this.declaration);
            }
        }

        const urlMatch = sheet.evalDeclarationValue(declValue).match(urlRegex);
        urlMatch && adder(urlMatch[0], changeURL);
    }
}
