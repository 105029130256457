import React from 'react';

import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';
import { Tooltip } from '@wixc3/stylable-panel-components';
import type { IconProps } from '@wixc3/stylable-panel-common-react';

import type { TranslateFunc } from '../../types';

import { style, classes } from './text-visualizer.st.css';

export enum IconKey {
    Bold = 'bold',
    Italic = 'italic',
    Underline = 'underline',
    Strikethrough = 'strikethrough',
    TextColor = 'textColor',
    BackgroundColor = 'backgroundColor',
    StrokeColor = 'strokeColor',
    Transform = 'transform',
    Direction = 'direction',
    Alignment = 'alignment',
}

interface IconOptions {
    noHighlight?: boolean;
    noStateResizing?: boolean;
}

export interface IconConfiguration {
    tooltipKey?: string;
    MainIcon?: React.FunctionComponent;
    SecondaryIcon?: React.FunctionComponent;
    hide?: boolean;
}

export type IconConfigurationMap = Record<IconKey, IconConfiguration>;

export const getIconConfiguration = ({ noHighlight, noStateResizing }: IconOptions): IconConfigurationMap => {
    const textTooltips = StylablePanelTranslationKeys.controller.text.tooltips;
    return {
        [IconKey.Bold]: {
            hide: noStateResizing,
        },
        [IconKey.Italic]: {
            tooltipKey: textTooltips.italic,
            MainIcon: ItalicIcon,
            hide: noStateResizing,
        },
        [IconKey.Underline]: {
            tooltipKey: textTooltips.underline,
            MainIcon: UnderlineIcon,
        },
        [IconKey.Strikethrough]: {
            tooltipKey: textTooltips.strikethrough,
            MainIcon: StrikethroughIcon,
        },
        [IconKey.TextColor]: {
            tooltipKey: textTooltips.textColor,
            MainIcon: TextColorIcon,
        },
        [IconKey.BackgroundColor]: {
            tooltipKey: textTooltips.highlightColor,
            MainIcon: BackgroundColorIcon,
            hide: noHighlight,
        },
        [IconKey.StrokeColor]: {
            tooltipKey: textTooltips.outlineColor,
            MainIcon: StrokeColorIcon,
        },
        [IconKey.Transform]: {
            hide: noStateResizing,
        },
        [IconKey.Direction]: {
            tooltipKey: textTooltips.textDirection,
            MainIcon: DirectionLeftIcon,
            SecondaryIcon: DirectionRightIcon,
            hide: noStateResizing,
        },
        [IconKey.Alignment]: {
            hide: noStateResizing,
        },
    };
};

export const MAX_ROW_ICONS = 5;

export const getIconRows = (iconConfiguration: IconConfigurationMap, iconsPerRow = MAX_ROW_ICONS) => {
    const filteredIconKeys = (Object.keys(iconConfiguration) as IconKey[]).filter(
        (iconKey) => !iconConfiguration[iconKey].hide
    );

    const iconRows: IconKey[][] = [];
    while (filteredIconKeys.length > 0) {
        iconRows.push(filteredIconKeys.splice(0, iconsPerRow));
    }

    return iconRows;
};

export interface IconInRowProps {
    iconKey: IconKey;
    iconConfiguration: IconConfigurationMap;
    translate: TranslateFunc;
}

export enum ColorPickerTextType {
    None = 0,
    Text,
    Background,
    Stroke,
}

type OpenColorPicker = (colorPickerType: ColorPickerTextType) => void;

interface ColorIconProps extends IconInRowProps {
    type: ColorPickerTextType;
    color: string | undefined;
    openColorPicker: OpenColorPicker;
    iconClassName: string;
}

export const ColorIcon: React.FunctionComponent<ColorIconProps> = ({
    iconKey,
    iconConfiguration,
    type,
    color,
    openColorPicker,
    iconClassName,
    translate,
}) => {
    const { tooltipKey, MainIcon } = iconConfiguration[iconKey];
    const Icon = MainIcon as React.FunctionComponent<ColorIconSVGProps>;

    // TODO: Checked when ColorPicker is open
    return (
        <Tooltip className={classes.iconTooltip} text={tooltipKey && translate(tooltipKey)}>
            <span
                className={style(classes.icon, {
                    /*checked: colorPickerType === type*/
                })}
                onClick={() => openColorPicker(type)}
            >
                <Icon className={iconClassName} color={color} />
            </span>
        </Tooltip>
    );
};

export interface ColorIconSVGProps extends IconProps {
    color?: string;
}

export const BoldIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="11" height="14" viewBox="0 0 11 14">
        <path
            fillRule="evenodd"
            d="M6.74 14H1.98c-1.09 0-1.99-.9-1.99-2V2c0-1.1.9-2 1.99-2h3.9C7.34 0 8.5.64 9.25 1.79c.81 1.24.87 2.83.16 4.15-.14.27-.15.51-.35.73 1 .65 1.71 1.7 1.83 2.9.11 1.15-.24 2.24-.98 3.07C9.13 13.5 7.97 14 6.74 14zM1.98 8v4h4.98c.67 0 2 .06 2-2 0-1.94-.9-2-2-2H1.98zm0-2h3.99c.62 0 1.99 0 1.99-2 0-1.94-1.21-2-1.99-2H1.98v4z"
        />
    </svg>
);

export const ItalicIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="8" height="14" viewBox="0 0 8 14">
        <path fillRule="evenodd" d="M8 2V0H1v2h2.71L2.12 12H0v2h7v-2H4.15L5.74 2H8z" />
    </svg>
);

export const UnderlineIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="12" height="16" viewBox="0 0 12 16">
        <path
            fillRule="evenodd"
            d="M0 16v-2h12v2H0zm6-3.75c-2.8 0-5-2.24-5-5.21V1.09C1 .49 1.4 0 2 0s1 .49 1 1.09v5.95c0 1.5 1.21 3.03 3 3.03s3-1.53 3-3.03V1.09C9 .49 9.4 0 10 0s1 .49 1 1.09v5.95c0 2.97-2.2 5.21-5 5.21z"
        />
    </svg>
);

export const StrikethroughIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fillRule="evenodd"
            d="M17.0883089,14 L8,14 L8,13 L14.9201,13 L13.9871,13 C11.7891,13 10.0001,11.314 10.0001,9.242 C10.0001,8.175 10.4641,7.134 11.2731,6.387 C12.2671,5.471 13.4921,5 14.7911,5 C16.0911,5 17.3171,5.471 18.2441,6.326 L19.2621,7.266 L17.9041,8.734 L16.8871,7.795 C15.7931,6.785 13.7931,6.784 12.6951,7.796 C12.2241,8.231 12.0001,8.724 12.0001,9.242 C12.0001,10.212 12.8921,11 13.9871,11 L14.9201,11 C16.5572021,11 18.0000891,11.7971332 18.8323182,13 L23,13 L23,14 L19.3454863,14 C19.4996086,14.4346492 19.5831,14.8993449 19.5831,15.382 C19.5831,17.929 17.3761,20 14.6641,20 L14.5061,20 C12.6441,20 10.9641,19.033 10.1211,17.477 L11.8791,16.523 C12.3651,17.421 13.3961,18 14.5061,18 L14.6641,18 C16.2731,18 17.5831,16.825 17.5831,15.382 C17.5831,14.8670642 17.3997336,14.3899074 17.0883089,14 Z"
        />
    </svg>
);

export const CapitalizeIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M8.59340659,14 L13.4065934,14 L13,13 L11,8 L9,13 L8.59340659,14 Z M7.78021978,16 L6.15384615,20 L4,20 L9.38461538,7.0769543 C9.65278063,6.42190494 10.2921971,5.99562832 11,5.99993388 C11.7078029,5.99562832 12.3472194,6.42190494 12.6153846,7.0769543 L18,20 L15.8461538,20 L14.2197802,16 L7.78021978,16 Z M24,14 L24,13.83 C24,12.8193189 23.1806811,12 22.17,12 L21.41,12 C20.7707718,11.9977216 20.1577283,12.2537574 19.71,12.71 C19.3178778,13.1021222 18.6821222,13.1021222 18.29,12.71 C17.8978778,12.3178778 17.8978778,11.6821222 18.29,11.29 C19.1183339,10.4640963 20.2402761,10.0002163 21.41,10 L22.17,10 C24.2829701,10.0054954 25.9945046,11.7170299 26,13.83 L26,17 L26,20 L21,20 C19.3431458,20 18,18.6568542 18,17 C18,15.3431458 19.3431458,14 21,14 L24,14 Z M24,16 L21,16 C20.4477153,16 20,16.4477153 20,17 C20,17.5522847 20.4477153,18 21,18 L24,18 L24,17 L24,16 Z"
        />
    </svg>
);

export const UppercaseIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M16.5 11.6000203L18.3846154 7.0769543C18.6527806 6.42190494 19.2921971 5.99562832 20 5.99993388 20.7078029 5.99562832 21.3472194 6.42190494 21.6153846 7.0769543L27 20 24.8461538 20 23.2197802 16 18.3333293 16 16.5 11.6000203zM17.5934066 14L22.4065934 14 22 13 20 8 18 13 17.5934066 14zM8.59340659 14L13.4065934 14 13 13 11 8 9 13 8.59340659 14zM7.78021978 16L6.15384615 20 4 20 9.38461538 7.0769543C9.65278063 6.42190494 10.2921971 5.99562832 11 5.99993388 11.7078029 5.99562832 12.3472194 6.42190494 12.6153846 7.0769543L18 20 15.8461538 20 14.2197802 16 7.78021978 16z"
        />
    </svg>
);

export const LowercaseIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M22,14 L22,13.83 C22,12.8193189 21.1806811,12 20.17,12 L19.41,12 C18.7707718,11.9977216 18.1577283,12.2537574 17.71,12.71 C17.3178778,13.1021221 16.6821222,13.1021221 16.29,12.71 C15.8978779,12.3178778 15.8978779,11.6821222 16.29,11.29 C17.1183339,10.4640963 18.2402761,10.0002163 19.41,10 L20.17,10 C22.2829701,10.0054954 23.9945046,11.7170299 24,13.83 L24,17 L24,20 L19,20 C17.3431458,20 16,18.6568542 16,17 C16,15.3431458 17.3431458,14 19,14 L22,14 Z M22,16 L19,16 C18.4477153,16 18,16.4477153 18,17 C18,17.5522847 18.4477153,18 19,18 L22,18 L22,17 L22,16 Z M12,14 L12,13.83 C12,12.8193189 11.1806811,12 10.17,12 L9.41,12 C8.77077175,11.9977216 8.15772827,12.2537574 7.71,12.71 C7.31787782,13.1021222 6.68212219,13.1021221 6.29000002,12.71 C5.89787786,12.3178778 5.89787785,11.6821222 6.29,11.29 C7.11833386,10.4640963 8.24027614,10.0002163 9.41,10 L10.17,10 C12.2829701,10.0054954 13.9945046,11.7170299 14,13.83 L14,17 L14,20 L9,20 C7.34314575,20 6,18.6568542 6,17 C6,15.3431458 7.34314575,14 9,14 L12,14 Z M12,16 L9,16 C8.44771525,16 8,16.4477153 8,17 C8,17.5522847 8.44771525,18 9,18 L12,18 L12,17 L12,16 Z"
        />
    </svg>
);

export const AlignmentLeftIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M22,18 L22,20 L8,20 L8,18 L22,18 Z M17,15 L17,17 L8,17 L8,15 L17,15 Z M21,12 L21,14 L8,14 L8,12 L21,12 Z M17,9 L17,11 L8,11 L8,9 L17,9 Z M22,6 L22,8 L8,8 L8,6 L22,6 Z"
        />
    </svg>
);

export const AlignmentRightIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M22,18 L22,20 L8,20 L8,18 L22,18 Z M22,15 L22,17 L12,17 L12,15 L22,15 Z M22,12 L22,14 L9,14 L9,12 L22,12 Z M22,9 L22,11 L12,11 L12,9 L22,9 Z M22,6 L22,8 L8,8 L8,6 L22,6 Z"
        />
    </svg>
);

export const AlignmentCenterIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M22,18 L22,20 L8,20 L8,18 L22,18 Z M19,15 L19,17 L11,17 L11,15 L19,15 Z M21,12 L21,14 L9,14 L9,12 L21,12 Z M19,9 L19,11 L11,11 L11,9 L19,9 Z M22,6 L22,8 L8,8 L8,6 L22,6 Z"
        />
    </svg>
);

export const AlignmentJustifyIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <path
            fill="currentColor"
            d="M18,18 L18,20 L9,20 L9,18 L18,18 Z M22,15 L22,17 L9,17 L9,15 L22,15 Z M22,12 L22,14 L9,14 L9,12 L22,12 Z M22,9 L22,11 L9,11 L9,9 L22,9 Z M22,6 L22,8 L9,8 L9,6 L22,6 Z"
        />
    </svg>
);

export const DirectionLeftIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="12" height="14" viewBox="0 0 12 14">
        <path
            fillRule="evenodd"
            d="M10 13.5v-14h2v14h-2zm-3-8H5.7c-1.82 0-3.3-1.18-3.3-3s1.48-3 3.3-3H9v14H7v-8zm-5.39 7.79c-.18.14-.4.21-.61.21a1 1 0 0 1-.79-.39.99.99 0 0 1 .18-1.4l1.58-1.21L.39 9.29a.99.99 0 0 1-.18-1.4 1 1 0 0 1 1.4-.18l3.66 2.79-3.66 2.79z"
        />
    </svg>
);

export const DirectionRightIcon: React.FunctionComponent<IconProps> = ({ className }) => (
    <svg className={className} width="18" height="14" viewBox="0 0 18 14">
        <path
            fillRule="evenodd"
            d="M15.79 11l1.6 1.21c.44.33.53.96.19 1.4-.2.25-.5.39-.81.39-.21 0-.43-.07-.61-.21L12.44 11l3.72-2.79c.44-.34 1.08-.25 1.42.18.34.44.25 1.07-.19 1.4L15.79 11zM8.65 0h2.03v14H8.65V0zM5.61 6H4.29C2.44 6 .95 4.82.95 3s1.49-3 3.34-3H7.64v14H5.61V6z"
        />
    </svg>
);

export const TextColorIcon: React.FunctionComponent<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="22px" height="18px">
        <path fill={color || 'black'} fillRule="evenodd" d="M 17 3C 17 3 27.03 17 17 17 6.9 17 17 3 17 3Z" />
        <path
            fillRule="evenodd"
            d="M 16.96 18C 13.35 18 12 14.95 12 13.03 12 9.97 15.69 4.09 16.12 3.43 16.12 3.43 16.95 2.12 16.95 2.12 16.95 2.12 17.8 3.42 17.8 3.42 18.23 4.08 22 9.97 22 13.03 22 15.09 20.44 18 16.96 18ZM 16.96 3.97C 16.96 3.97 13 10.18 13 13.03 13 14.52 14.02 16.99 16.96 16.99 19.89 16.99 21 14.52 21 13.03 21 10.18 16.96 3.97 16.96 3.97ZM 3.6 9.02C 3.6 9.02 2 13.03 2 13.03 2 13.03-0 13.03-0 13.03-0 13.03 5 1 5 1 5 1 6 0 6 0 6 0 7 0 7 0 7 0 8 1 8 1 8 1 11 7.01 11 7.01 11 7.01 11 11.02 11 11.02 11 11.02 9.4 9.02 9.4 9.02 9.4 9.02 3.6 9.02 3.6 9.02ZM 7 3.01C 7 3.01 6 3.01 6 3.01 6 3.01 4.4 7.01 4.4 7.01 4.4 7.01 8.6 7.01 8.6 7.01 8.6 7.01 7 3.01 7 3.01Z"
        />
    </svg>
);

export const BackgroundColorIcon: React.FunctionComponent<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="28px" height="21px">
        <path fill={color || 'transparent'} fillRule="evenodd" d="M 23 6C 23 6 33.03 20 23 20 12.9 20 23 6 23 6Z" />
        <path
            fillRule="evenodd"
            d="M 22.96 21C 19.35 21 18 17.95 18 16.03 18 12.98 21.69 7.09 22.12 6.43 22.12 6.43 22.95 5.12 22.95 5.12 22.95 5.12 23.8 6.42 23.8 6.42 24.23 7.09 28 12.97 28 16.03 28 18.09 26.44 21 22.96 21ZM 22.96 6.97C 22.96 6.97 19 13.18 19 16.03 19 17.52 20.02 20 22.96 20 25.89 20 27 17.52 27 16.03 27 13.18 22.96 6.97 22.96 6.97ZM 14 6.01C 14 6.01 15 10.02 15 10.02 15 10.02 12 10.02 12 10.02 12 10.02 13 6.01 13 6.01 13 6.01 14 6.01 14 6.01ZM 18 10.02C 18 10.02 15 4.01 15 4.01 15 4.01 14 3.01 14 3.01 14 3.01 13 3.01 13 3.01 13 3.01 12 4.01 12 4.01 12 4.01 6.63 16.03 6.63 16.03 6.63 16.03 9 16.03 9 16.03 9 16.03 11 12.02 11 12.02 11 12.02 16 12.02 16 12.02 16 12.02 16.79 13 16.79 13 16.32 14.18 16 15.26 16 16.03 16 16.93 16.3 18.07 16.98 19.04 16.98 19.04 6 19.04 6 19.04 4.9 19.04 4 18.14 4 17.03 4 17.03 4 15.03 4 15.03 4 15.03 3 15.03 3 15.03 2.45 15.03 2 14.58 2 14.03 2 13.47 2.45 13.02 3 13.02 3 13.02 4 13.02 4 13.02 4 13.02 4 11.02 4 11.02 4 11.02 3 11.02 3 11.02 2.45 11.02 2 10.57 2 10.02 2 9.47 2.45 9.02 3 9.02 3 9.02 4 9.02 4 9.02 4 9.02 4 7.01 4 7.01 4 7.01 2 7.01 2 7.01 1.45 7.01 1 6.57 1 6.01 1 5.46 1.45 5.01 2 5.01 2 5.01 4 5.01 4 5.01 4 5.01 4 2 4 2 4 2 1 2 1 2 0.45 2 0 1.56 0 1 0 0.45 0.45 0 1 0 1 0 4 0 4 0 4 0 20 0 20 0 21.1 0 22 0.9 22 2 22 2 22 4.01 22 4.01 22 4.01 21 5.01 21 5.01 21 5.01 19.43 7.1 18 10.02Z"
        />
    </svg>
);

export const StrokeColorIcon: React.FunctionComponent<ColorIconSVGProps> = ({ className, color }) => (
    <svg className={className} width="30" height="25" viewBox="0 0 30 25">
        <g fill="none" fillRule="evenodd">
            <mask id="stroke-b" fill="#fff">
                <path d="M21.9578,22 C19.0238,22 17.9998,19.489 17.9998,18 C17.9998,15.156 21.9578,8.958 21.9578,8.958 C21.9578,8.958 25.9998,15.156 25.9998,18 C25.9998,19.489 24.8928,22 21.9578,22" />
            </mask>
            <g fill={color || 'transparent'} mask="url(#stroke-b)">
                <rect width="10" height="16" transform="translate(17 7)" />
            </g>
            <path
                stroke="currentColor"
                d="M9.06100557,12.9941841 L7.74377329,17.2304297 L5,17.2304297 L9.64266185,5.27647142 C10.0425525,4.24683011 11.2014172,3.73631635 12.2310585,4.13620702 C12.7526764,4.33879228 13.1655685,4.75063707 13.3694779,5.27173884 L16,11.9941841 L14.5,15.9941841 L14.4403028,16.005538 L13.7293705,12.9941841 L9.06100557,12.9941841 Z M9.68289222,10.9941841 L13.2572026,10.9941841 L12.5557696,8.02306722 C12.4744778,7.67873281 12.2172891,7.40298314 11.8794446,7.29793264 C11.3520667,7.13394799 10.7916066,7.42853645 10.6276219,7.95591438 L9.68289222,10.9941841 Z"
            />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M22.7965,8.3765 C23.2275,9.0365 27.0005,14.9475 27.0005,18.0005 C27.0005,20.0595 25.4365,23.0005 21.9585,23.0005 C18.3465,23.0005 17.0005,19.9175 17.0005,18.0005 C17.0005,14.9525 20.6945,9.0435 21.1155,8.3845 L21.9495,7.0785 L22.7965,8.3765 Z M21.9585,22.0005 C24.8935,22.0005 26.0005,19.4895 26.0005,18.0005 C26.0005,15.1565 21.9585,8.9585 21.9585,8.9585 C21.9585,8.9585 18.0005,15.1565 18.0005,18.0005 C18.0005,19.4895 19.0245,22.0005 21.9585,22.0005 Z"
            />
        </g>
    </svg>
);
