import LayoutController from './layout-controller-setup';
import {
    GroupControllerFactory,
    ControllerComponentClass,
    VisualizerComponentClass,
    BackgroundVisualizer,
    CornerVisualizer,
    IconVisualizer,
    PaddingVisualizer,
    MarginVisualizer,
    ShadowsVisualizerFactory,
    BorderVisualizer,
    TextVisualizer,
} from '@wixc3/stylable-panel-controllers';

import { animationProps } from './archetypes';

export function setup(): Record<string, VisualizerComponentClass | ControllerComponentClass> {
    return {
        /******************************** Fill ********************************/
        background: BackgroundVisualizer as VisualizerComponentClass,
        /******************************** Text ********************************/
        text: TextVisualizer as VisualizerComponentClass,
        // 'color': ColorControllerFactory('Color', 'color') as ControllerComponentClass,
        // 'font-size': CommonControllerFactory('font-size', ['font-size'], NumberUnitFactory(fontEditInfo), value => value['font-size'], 'Font Size') as ControllerComponentClass,
        // 'line-height': CommonControllerFactory('line-height', ['line-height'], NumberUnitFactory(fontEditInfo), value => value['line-height'], 'Line Height') as ControllerComponentClass,
        // 'letter-spacing': CommonControllerFactory('letter-spacing', ['letter-spacing'], NumberUnitFactory(letterSpacingEditInfo), value => value['letter-spacing'], 'Letter Spacing') as ControllerComponentClass,
        // 'font-weight': CommonControllerFactory('font-weight', ['font-weight'], NumberUnitFactory(weightEditInto, true), value => value['font-weight'], 'Font Weight') as ControllerComponentClass,
        /******************************* Borders ******************************/
        border: BorderVisualizer as VisualizerComponentClass,
        /******************************* Corners ******************************/
        'border-radius': CornerVisualizer as VisualizerComponentClass,
        /******************************* Layout *******************************/
        layout: LayoutController,
        padding: PaddingVisualizer as VisualizerComponentClass,
        /******************************* Spacing ******************************/
        margin: MarginVisualizer as VisualizerComponentClass,
        /******************************* Shadow *******************************/
        'box-shadow': ShadowsVisualizerFactory('box-shadow') as VisualizerComponentClass,
        /******************************** FX **********************************/
        animation: GroupControllerFactory('Animation', animationProps) as ControllerComponentClass,
        // 'transition': GroupControllerFactory(
        //     'Transition',
        //     transitionProps
        // ) as ControllerComponentClass,
        // opacity: CommonControllerFactory(
        //     'opacity',
        //     ['opacity'],
        //     NumberUnitFactory(fractionEditInfo, '%'),
        //     (value) => value.opacity,
        //     'Opacity'
        // ) as ControllerComponentClass,
        // 'transition-duration': CommonControllerFactory(
        //     'transition-duration',
        //     ['transition-duration'],
        //     NumberUnitFactory(timingEditInto),
        //     (value) => value['transition-duration'],
        //     'Transition Duration'
        // ) as ControllerComponentClass,
        // 'transition-delay': CommonControllerFactory(
        //     'transition-delay',
        //     ['transition-delay'],
        //     NumberUnitFactory(timingEditInto),
        //     (value) => value['transition-delay'],
        //     'Transition Delay'
        // ) as ControllerComponentClass,
        /********************************* Icon *******************************/
        icon: IconVisualizer as VisualizerComponentClass,
        /**************************** Block Variants **************************/
        // 'spacing-bv': BlockVariantControllerFactory(
        //     'margin',
        //     spacingProps,
        //     SpacingPreview
        // ) as ControllerComponentClass,
        // 'border-bv': BlockVariantControllerFactory(
        //     'border',
        //     borderWithImageProps,
        //     BorderPreview
        // ) as ControllerComponentClass,
        // 'border-radius-bv': BlockVariantControllerFactory(
        //     'border-radius',
        //     cornerProps,
        //     DefaultPreview,
        //     ['border-image-source'],
        //     value => (!value['border-image-source'] || value['border-image-source'] === 'none')
        // ) as ControllerComponentClass,
        // 'box-shadow-bv': BlockVariantControllerFactory(
        //     'box-shadow',
        //     ['box-shadow'],
        //     ShadowPreview
        // ) as ControllerComponentClass,
        // 'filter-bv': BlockVariantControllerFactory(
        //     'filter',
        //     ['filter', 'transform', 'opacity'],
        //     ImagePreview
        // ) as ControllerComponentClass,
        // 'fill-bv': BlockVariantControllerFactory(
        //     'background',
        //     backgroundProps,
        //     FillPreview
        // ) as ControllerComponentClass,
        // 'font-bv': BlockVariantControllerFactory(
        //     'font',
        //     textProps.filter(prop => prop !== 'font-size' && prop !== 'line-height'),
        //     TextPreview
        // ) as ControllerComponentClass,
    };
}
