import React from 'react';

import { NumberUnitInput } from '@wixc3/stylable-panel-components';

import {
    CommonCornerInput,
    CommonControllerFactory,
    ControllerComponentClass,
    propsToShorthandCorners,
} from '@wixc3/stylable-panel-controllers';
import { cornerProps } from '../../../setup/archetypes';
import { cornerEditInfo } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './corner-tweak.st.css';

export class CornerTweakInput extends CommonCornerInput<{}> {
    public render() {
        const { value, className } = this.props;

        let component;
        if (this.isSameValue()) {
            component = (
                <NumberUnitInput
                    className={classes.input}
                    value={value || '0px'}
                    units={cornerEditInfo}
                    inlineSlider
                    onChange={this.handleInputChange}
                />
            );
        }
        return <div className={style(classes.root, className)}>{component}</div>;
    }

    private handleInputChange = (value: string) => {
        this.props.onChange && this.props.onChange(value);
    };
}

const CornerTweak = CommonControllerFactory(
    'border-radius',
    cornerProps,
    CornerTweakInput,
    propsToShorthandCorners
) as ControllerComponentClass;
export default CornerTweak;
