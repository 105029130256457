import React from 'react';
import { CompositeBlock, Tooltip, Scrollbars } from '@wixc3/stylable-panel-components';
import { AddSkin, DeleteIndicator } from '@wixc3/stylable-panel-common-react';
import { style, classes } from './my-skins-picker.st.css';

export interface CustomSkinsPickerProps {
    value?: string;
    valueType?: string;
    onItemClick?: (index: number, value: string) => void;
    onItemEnter?: (index: number, value: string) => void;
    onItemLeave?: (index: number, value: string) => void;
    onItemRemove?: (index: number) => void;
    className?: string;
}

export type CustomSkinsPicker = React.ComponentClass<CustomSkinsPickerProps>;

export interface MySkinsPickerProps extends CustomSkinsPickerProps {
    title?: string;
    information?: string;
    addTooltipLabel?: string;
    values?: string[];
    radioGroupName?: string;
    itemDataAid?: string;
    isChecked?: (index: number) => boolean;
    isWhite?: (value: string) => boolean;
    onReset?: () => void;
    onAdd?: () => void;
}

export interface MySkinsPickerState {
    hoveredItemIndex: number;
}

export class MySkinsPicker extends React.Component<MySkinsPickerProps, MySkinsPickerState> {
    public state: MySkinsPickerState = { hoveredItemIndex: -1 };

    public render() {
        const { title, information, values = [], className } = this.props;

        return (
            <div className={style(classes.root, className)}>
                <Scrollbars>
                    <CompositeBlock className={classes.mySkins} title={title} information={information}>
                        <div className={classes.customSkins}>
                            {this.renderAddButton()}
                            {this.renderResetButton()}
                            {values.map(this.renderUserSkinOption)}
                        </div>
                    </CompositeBlock>
                </Scrollbars>
            </div>
        );
    }

    private renderResetButton() {
        const { onReset } = this.props;

        if (!onReset) {
            return null;
        }

        return (
            <span className={style(classes.userSkinOption, classes.resetButton)} onClick={onReset}>
                <span className={style(classes.userSkinItem, { white: true })}>
                    <div className={classes.diagonal} />
                </span>
            </span>
        );
    }

    private renderAddButton() {
        const { addTooltipLabel, onAdd } = this.props;

        if (!onAdd) {
            return null;
        }

        return (
            <Tooltip text={addTooltipLabel} verticalAdjust={-7}>
                <span className={style(classes.userSkinOption, classes.addButton)} onClick={onAdd}>
                    <AddSkin className={classes.addIcon} />
                </span>
            </Tooltip>
        );
    }

    private renderDeleteIndicator(index: number, value: string) {
        const { onItemRemove, onItemLeave, onItemEnter, values } = this.props;

        if (!onItemRemove) {
            return;
        }

        return (
            <div
                key={`my_skin_${index}_deleteindicator`}
                data-aid="st_skinspicker_deleteindicator"
                className={classes.deleteIndicator}
                onClick={(event) => {
                    event.stopPropagation();
                    onItemLeave && onItemLeave(index, value);
                    values && values[index + 1] && onItemEnter && onItemEnter(index + 1, values[index + 1]);
                    onItemRemove(index);
                }}
            >
                <DeleteIndicator />
            </div>
        );
    }

    private renderUserSkinOption = (value: string, index: number) => {
        const { radioGroupName, itemDataAid, isChecked, isWhite, onItemClick } = this.props;

        const { hoveredItemIndex } = this.state;

        return (
            <span key={`my_skin_${index}`} className={classes.userSkinOption}>
                <input
                    className={classes.hiddenRadio}
                    type="radio"
                    name={radioGroupName}
                    value={value}
                    checked={isChecked ? isChecked(index) : false}
                    onChange={() => null}
                />
                <div
                    className={style(classes.userSkinItem, { white: isWhite ? isWhite(value) : false })}
                    style={{ background: value }}
                    data-aid={itemDataAid}
                    onClick={() => onItemClick && onItemClick(index, value)}
                    onMouseEnter={() => this.handleUserSkinItemMouseEnter(index, value)}
                    onMouseLeave={() => this.handleUserSkinItemMouseLeave(index, value)}
                >
                    {hoveredItemIndex === index ? this.renderDeleteIndicator(index, value) : null}
                </div>
            </span>
        );
    };

    private handleUserSkinItemMouseEnter = (index: number, value: string) => {
        const { onItemEnter } = this.props;

        this.setState({ hoveredItemIndex: index });
        onItemEnter && onItemEnter(index, value);
    };

    private handleUserSkinItemMouseLeave = (index: number, value: string) => {
        const { onItemLeave } = this.props;

        this.setState({ hoveredItemIndex: -1 });
        onItemLeave && onItemLeave(index, value);
    };
}
