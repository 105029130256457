import React from 'react';

import { Close, isClickInElement } from '@wixc3/stylable-panel-common-react';

import { style, classes } from './popup-panel.st.css';

export interface PopupPanelProps {
    title?: string;
    children?: React.ReactNode;
    onClose?: () => void;
    onBlur?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

// TODO: Test
// TODO: Move onBlur behavior here
export class PopupPanel extends React.Component<PopupPanelProps> {
    constructor(props: PopupPanelProps) {
        super(props);
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public render() {
        const { title, children, onClose, className, style: propStyle } = this.props;

        return (
            <div className={style(classes.root, className)} style={propStyle}>
                <div className={classes.header}>
                    {title}
                    <button className={classes.closeButton} onClick={() => onClose && onClose()}>
                        <Close />
                    </button>
                </div>
                {children}
            </div>
        );
    }

    private handleDocumentMouseDown = (event: MouseEvent) => {
        const { onBlur } = this.props;

        if (!onBlur) {
            return;
        }

        if (isClickInElement(event, this)) {
            return;
        }

        // const isClickInColorPickerPopups = (colorPicker: ColorPicker) => {
        //     if (colorPicker.adderOpen() &&
        //         isClickInElement(event, colorPicker.refs.colorAdder)
        //     ) {
        //         return true;
        //     }

        //     if (colorPicker.palettePickerOpen() &&
        //         isClickInElement(event, colorPicker.refs.palettePicker)
        //     ) {
        //         return true;
        //     }

        //     return false;
        // };

        // if (this.colorPicker && isClickInColorPickerPopups(this.colorPicker)) {
        //     return;
        // }

        // if (this.gradientPicker && this.gradientPicker.colorPickerOpen()) {
        //     if (isClickInElement(event, this.gradientPicker.refs.colorPicker)) {
        //         return;
        //     }

        //     const gradientPickerColorPicker = this.gradientPicker.refs.colorPicker as ColorPicker;
        //     if (isClickInColorPickerPopups(gradientPickerColorPicker)) {
        //         return;
        //     }
        // }

        onBlur();
    };
}
