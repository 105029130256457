import React from 'react';

import { OVERRIDE_STATE_PREFIX } from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';

import { StylableOverride, StylableEditor, ComponentEditSession } from '@wixc3/stylable-panel-drivers';
import type { ExtendedGlobalHost } from '@wixc3/stylable-panel-controllers';
import { DEFAULT_PLANE } from '@wixc3/stylable-panel-common';

import { EditorPanel, StylePanelExternalProps } from './editor-panel';
import type { StylePanelPage } from '../panels';
import { setHelpLinkId } from '../ui/stylable-panel-help';

import { style, classes } from './wix-panel.st.css';

export interface WixStyleEditorProps extends StylePanelExternalProps {
    stylableEditor: StylableEditor;
    onChange: (css: string) => void;
    className?: string;
}

export class WixStyleEditor extends React.Component<WixStyleEditorProps> {
    private editor: StylableEditor;
    private overrideDriver: StylableOverride | undefined;
    private noHoverOverride = false;

    constructor(props: WixStyleEditorProps, context: any) {
        super(props, context);

        const { stylableEditor, panelHost } = props;

        this.editor = stylableEditor;
        this.editor.stylableDriver.registerBuildHook(this.onChange, true);

        this.initOverrideDriver();

        this.withSelection((selection) => {
            const { componentName, selector, variant } = selection;
            this.setHelpLink(componentName, selector, variant, panelHost!);
        });
    }

    public componentDidUpdate() {
        this.overrideDriver && this.overrideDriver.override();
        this.noHoverOverride = false;
    }

    public componentWillUnmount() {
        this.editor.stylableDriver.unregisterBuildHook(this.onChange, true);
        this.removeOverrides(false);
        this.overrideDriver && this.overrideDriver.override();
        this.withSelection((selection) => {
            selection.selector = `.${selection.variant}`;
        });
    }

    public render() {
        const { categoryConfig, panelHost, plane = DEFAULT_PLANE, initialView, className } = this.props;
        const selection = this.editor.getSelection(0);

        return selection ? (
            <div className={style(classes.root, className)}>
                <EditorPanel
                    className={classes.editor}
                    editSession={selection}
                    categoryConfig={categoryConfig}
                    panelHost={panelHost}
                    onPresetSelect={this.presetSelect}
                    onElementSelect={this.handleElementSelection}
                    onForceState={this.setPartStateOverride}
                    onStateSelect={this.handlePartStateSelect}
                    onRemoveOverrides={this.removeOverrides}
                    onPageChange={this.pageChange}
                    initialView={initialView}
                    plane={plane}
                />
            </div>
        ) : null;
    }

    public pageChange = (page: StylePanelPage) => {
        this.withSelection((selection) => {
            selection.page = page;
            this.forceUpdate();
        });
    };

    private setHelpLink = (componentName: string, selector: string, _variant: string, panelHost: ExtendedGlobalHost) =>
        setHelpLinkId(`${componentName}${selector}`, panelHost);

    private initOverrideDriver() {
        const { panelHost } = this.props;
        if (panelHost) {
            const { stageRoot } = panelHost;

            if (stageRoot) {
                this.overrideDriver = new StylableOverride(
                    stageRoot,
                    this.editor.stylableDriver,
                    this.editor.stylesheetPath,
                    OVERRIDE_STATE_PREFIX
                );
            }
        }
    }

    private presetSelect = () => {
        this.forceUpdate();
    };

    private onChange = (_css: string, stylesheets: string[]) => {
        const { onChange } = this.props;

        if (onChange) {
            this.withSelection((selection) => {
                if (stylesheets.includes(selection.stylesheetPath)) {
                    onChange(selection.getSourceCSS());
                }
            });
        }
    };

    private handleElementSelection = (selector: string) => {
        const { panelHost } = this.props;
        this.withSelection((selection) => {
            const { componentName, variant } = selection;
            this.setHelpLink(componentName, selector, variant, panelHost!);
            const stateUpdated = selection.setSelector(selector);
            if (stateUpdated) {
                this.removeOverrides();
            }
            this.props.panelHost?.onElementSelect?.(selector);
            this.forceUpdate();
        });
    };

    private handlePartStateSelect = (stateSelector: string) => {
        const filterAfterStateMatch = stateSelector.match(/.*[^:]:[^:]+/);
        const overrideSelector = filterAfterStateMatch ? filterAfterStateMatch[0] : stateSelector;
        this.overrideDriver && this.overrideDriver.setSelector(overrideSelector);
        this.props.panelHost &&
            this.props.panelHost.onForceState &&
            this.props.panelHost.onForceState(overrideSelector);

        this.noHoverOverride = true;
        this.withSelection((selection) => {
            // TODO: Figure out if there's a better way to set this (through setSelector, without updateComputed)
            selection.selector = stateSelector;
            this.forceUpdate();
        });
    };

    private removeOverrides = (callParentRevert = true) => {
        callParentRevert &&
            this.props.panelHost &&
            this.props.panelHost.revertForceState &&
            this.props.panelHost.revertForceState();
        if (this.overrideDriver) {
            this.overrideDriver.setSelector(null);
            this.forceUpdate();
        }
    };

    private setPartStateOverride = (stateOverrideSelector: string | null) => {
        if (this.noHoverOverride) {
            this.noHoverOverride = false;
            return;
        }

        const selection = this.editor.getSelection(0);

        const stateSelector = stateOverrideSelector || (selection && selection.selector) || '';
        const filterAfterStateMatch = stateSelector.match(/.*[^:]:[^:]+/);
        const overrideSelector = filterAfterStateMatch ? filterAfterStateMatch[0] : stateSelector;

        if (this.overrideDriver) {
            this.overrideDriver.setSelector(overrideSelector);
        }
        this.props.panelHost &&
            this.props.panelHost.onForceState &&
            this.props.panelHost.onForceState(overrideSelector);

        this.forceUpdate();
    };

    private withSelection = (callback: (selection: ComponentEditSession) => void) => {
        const selection = this.editor.getSelection(0);
        if (selection) {
            callback(selection);
        }
    };
}
