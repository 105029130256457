import React from 'react';

import type { SelectorSet, DynamicPartInputSelectorValues } from '@wixc3/stylable-panel-drivers';
import { CompositeBlock, DropDown } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE } from '@wixc3/stylable-panel-common';

import type { DynamicPartInputProps } from '../types';
import { getTranslate } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './child-display-dropdown.st.css';

export const DISPLAY_HIDDEN = 'none';
export const DISPLAY_SHOWN = 'initial';

export const OPTION_ID_ALL = 'all';
export const OPTION_ID_ONLY_PREFIX = 'only_';

const getElementDisplays = (values: DynamicPartInputSelectorValues) =>
    Object.keys(values).map((selector) => values[selector].decls.display !== DISPLAY_HIDDEN);

const allAreDisplayed = (displays: boolean[]) => displays.every((display) => !!display);

export const AllElementsDisplayed = (values: DynamicPartInputSelectorValues) =>
    Object.keys(values).length > 1 && allAreDisplayed(getElementDisplays(values));

export const displayTitleKey = 'display_title_key';
export const displayOptionKey = (option: string) => `display_option_${option}_key`;

export default class ChildDisplayDropdown extends React.Component<DynamicPartInputProps> {
    public render() {
        const { values, controllerData, panelHost, plane = DEFAULT_PLANE, className } = this.props;

        if (Object.keys(values).length === 1) {
            return null;
        }

        const translate = getTranslate(panelHost);

        const translationKeys = controllerData ? controllerData.translationKeys || {} : {};
        const title = translationKeys[displayTitleKey] ? translate(translationKeys[displayTitleKey]) : 'Show';

        return (
            <div className={style(classes.root, { plane }, className)}>
                <CompositeBlock className={classes.controllerBlock} title={title}>
                    <DropDown
                        className={classes.dropDown}
                        value={this.getDropDownValue()}
                        options={this.getDropDownOptions()}
                        onSelect={this.handleDropDownSelect}
                    />
                </CompositeBlock>
            </div>
        );
    }

    private getDropDownValue() {
        const elementDisplays = getElementDisplays(this.props.values);

        if (allAreDisplayed(elementDisplays)) {
            return OPTION_ID_ALL;
        }

        const firstDisplayedElementIndex = elementDisplays.findIndex((display) => !!display);
        if (!~firstDisplayedElementIndex) {
            return '';
        }

        return `${OPTION_ID_ONLY_PREFIX}${firstDisplayedElementIndex}`;
    }

    private getDropDownOptions() {
        const { controllerData, panelHost } = this.props;

        const translate = getTranslate(panelHost);

        const elementNames = this.elementNames();
        const translationKeys = controllerData ? controllerData.translationKeys || {} : {};

        return [
            {
                id: OPTION_ID_ALL,
                displayName: translationKeys[displayOptionKey('all')]
                    ? translate(translationKeys[displayOptionKey('all')])
                    : elementNames.join(' & '),
            },
            ...elementNames.map((name, index) => ({
                id: `${OPTION_ID_ONLY_PREFIX}${index}`,
                displayName: translationKeys[displayOptionKey(name)]
                    ? translate(translationKeys[displayOptionKey(name)])
                    : `${name} Only`,
            })),
        ];
    }

    private handleDropDownSelect = (id: string) => {
        const { values, onChangeSelector } = this.props;
        if (!onChangeSelector) {
            return;
        }

        let shouldDisplay: (index: number) => boolean = () => false;

        if (id === OPTION_ID_ALL) {
            shouldDisplay = () => true;
        } else if (id.startsWith(OPTION_ID_ONLY_PREFIX)) {
            const displayedIndex = parseInt(id.replace(OPTION_ID_ONLY_PREFIX, ''), 10);
            shouldDisplay = (index) => index === displayedIndex;
        }

        const changeSet = Object.keys(values).reduce((currChangeSet, selector, index) => {
            currChangeSet[selector] = {
                display: shouldDisplay(index) ? DISPLAY_SHOWN : DISPLAY_HIDDEN,
            };
            return currChangeSet;
        }, {} as SelectorSet);

        onChangeSelector(changeSet);
    };

    private elementNames = () => Object.keys(this.props.values).map((selector) => selector.split('::').pop() || '');
}
