import { UnitRef, UnitRefPartialRecord, DimensionProps } from '@wixc3/stylable-panel-common';

export enum InputError {
    NONE = 'none',
    EMPTY = 'empty',
    RANGE = 'range',
    INVALID = 'invalid',
    MAX_LENGTH = 'max_length',
}

export enum InputContext {
    ABORT = 'abort',
    FOCUS = 'focus',
    KEY_CHANGE = 'key_change',
    INTERACTIVE_UI = 'interactive',
    EDIT_TIME = 'edit',
}

export enum RangeContext {
    FREE_RANGE_CYCLIC_MAX = 'FREE_RANGE_CYCLIC_MAX',
    FREE_RANGE_CYCLIC_MIN = 'FREE_RANGE_CYCLIC_MIN',
    NO_CYCLIC_RANGE_DIFFER = 'NO_CYCLIC_RANGE_DIFFER',
    NO_RANGE_NO_CYCLIC = 'NO_RANGE_NO_CYCLIC',
    DEFAULT = 'DEFAULT',
}

export enum MODIFIER_KEYS {
    COMMAND = 'command',
    CTRL = 'ctrl',
    UP = 'up',
    DOWN = 'down',
    PLUS = '+',
    EQ = '=',
    ESC = 'esc',
}

export type CssUnit = /* unitless */ '' | 'px' | 'em' | 'rem' | '%' | 'deg' | 's' | 'ms' | 'vw' | 'vh';

export const INPUT_UNITS_MAP: Record<UnitRef, CssUnit> = {
    [UnitRef.CUSTOM]: '',
    [UnitRef.PIXELS]: 'px',
    [UnitRef.ELEMENTS]: 'em',
    [UnitRef.ROOT_ELEMENTS]: 'rem',
    [UnitRef.PERCENTS]: '%',
    [UnitRef.DEGREES]: 'deg',
    [UnitRef.SECONDS]: 's',
    [UnitRef.MILLISECONDS]: 'ms',
    [UnitRef.VIEW_WIDTH]: 'vw',
    [UnitRef.VIEW_HEIGHT]: 'vh',
};

export interface ParseConfig {
    applyUnit: boolean;
    unitConfig?: DimensionProps;
}

export interface ParsedDimension {
    value: number;
    unit: CssUnit;
    error?: InputError;
}

export interface PreviewProps extends ParsedDimension {
    dimension: string;
}

export interface DimensionInputConfig {
    units?: UnitRefPartialRecord;
    keywords?: string[];
}

export interface DimensionVisualizerFactoryProps {
    useDisplaySymbol?: boolean;
    trimUnit?: boolean;
    keepRange?: boolean;
    isInputCyclic?: boolean;
    isSliderCyclicKnob?: boolean;
    isRequired?: boolean;
    isSlider?: boolean;
    focusOnlyUnitSelector?: boolean;
    noFloat?: boolean;
    noValueLengthLimit?: boolean;
    hidePlaceholder?: boolean;
    debug?: boolean;
    inputDataAid?: string;
    isBoundRange?: boolean;
    // responsiveTypography?: boolean; // experimental
    // localeOutput?: boolean; // experimental
}

export interface DimensionVisualizerComponentProps {
    config?: DimensionInputConfig;
    isDisabled?: boolean;
    opacitySliderColor?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    forceFocus?: boolean;
    tooltipContent?: string;
}

export interface DimensionInputProps extends DimensionVisualizerFactoryProps, DimensionVisualizerComponentProps {
    value: string;
    onChange?: (value: string) => void;
    className?: string;
}

export interface DimensionInputState {
    editValue: string;
    inputError: InputError;
    inputContext: InputContext;
}

export interface InternalDimensionProps extends DimensionProps {
    symbol?: CssUnit;
}

export interface DimensionRef {
    name: UnitRef;
    symbol: CssUnit;
}
