import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import type { SingleCategoryConfig } from '../../types';

export const iconBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.icon.title,
    props: ['width', 'height', 'fill'],
};

export default iconBlockVariants;
