import React from 'react';
import chroma from 'chroma-js';

import { TranslateDriver } from '../../drivers/translate-driver';
import { isWhite } from './color-picker-utils';

import { style, classes } from './palette-picker.st.css';
import { Scrollbars } from '../../scrollbars';

export type PaletteColumn = string[];

export interface Palette {
    columns: PaletteColumn[];
    previewIndices: number[];
}

export interface PalettePickerStrings {
    title: string;
}

const PalettePickerStringsFallbacks: PalettePickerStrings = {
    title: 'Color Palettes',
};

export interface PalettePickerProps {
    palettes?: Palette[];
    selectedPalette?: number;

    onSelect?: (index: number) => void;
    onHover?: (index: number) => void;

    translations?: Partial<PalettePickerStrings>;

    className?: string;
    style?: React.CSSProperties;
    ['data-aid']?: string;
}

export default class PalettePicker extends React.Component<PalettePickerProps> {
    private translateDriver: TranslateDriver<PalettePickerStrings>;

    constructor(props: PalettePickerProps, context: any) {
        super(props, context);
        this.translateDriver = new TranslateDriver<PalettePickerStrings>(
            PalettePickerStringsFallbacks,
            props.translations
        );
    }

    public render() {
        const { palettes: palettes = [], onHover, className, style: propStyle } = this.props;
        const { translate } = this.translateDriver;

        return (
            <div className={style(classes.root, className)} style={propStyle} data-aid={this.props['data-aid']}>
                <div className={classes.title}>{translate('title')}</div>
                <Scrollbars style={{ height: 'calc(100% - 53px)', width: '153px' }}>
                    <div className={classes.paletteList} onMouseLeave={() => onHover && onHover(-1)}>
                        {palettes.map((palette, index) => this.renderPalette(palette, index))}
                    </div>
                </Scrollbars>
            </div>
        );
    }

    private renderPalette(palette: Palette, index: number) {
        const { selectedPalette, onSelect, onHover } = this.props;
        const selected = selectedPalette === index;

        return (
            <div
                key={`palette_${index}`}
                className={classes.paletteWrapper}
                onMouseEnter={() => onHover && onHover(index)}
                onClick={() => onSelect && onSelect(index)}
                data-aid="st_palettepicker_palettewrapper"
            >
                <div
                    className={style(classes.paletteOption, { selected })}
                    data-aid={selected ? 'st_palettepicker_selectedpalette' : 'st_palettepicker_paletteoption'}
                >
                    {this.getPalettePreviews(palette).map((backgroundColor, colorIndex) => (
                        <span
                            key={`palette_${index}_option_${colorIndex}`}
                            className={style(classes.paletteOptionColor, { white: isWhite(chroma(backgroundColor)) })}
                            style={{ backgroundColor }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    private getPalettePreviews(palette: Palette) {
        return palette.previewIndices.map((previewIndex, columnIndex) => palette.columns[columnIndex][previewIndex]);
    }
}
