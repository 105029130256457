import React, { SyntheticEvent } from 'react';

import { classes, style } from './option-list-item.st.css';
import { Option, OptionSelectSource } from './option-list';
import { Checkmark } from '@wixc3/stylable-panel-common-react';
import { Tooltip, TooltipAttachTo, TooltipRef } from './tooltip';

type OnSelect = (itemId: string, source: OptionSelectSource, e: SyntheticEvent) => void;
type OnHover = (itemId: string | null, source: OptionSelectSource, e: SyntheticEvent) => void;

export type OptionListCustomRender = (item: Option, index: number) => JSX.Element | undefined;

export interface OptionListItemProps {
    index: number;
    id: string;
    onSelect?: OnSelect;
    onHover?: OnHover;
    isSelected?: boolean;
    hovered?: boolean;
    hoverOnlyFromLabel?: boolean;
    showSelectedIcon?: boolean;
    item: Option;
    attachTooltipTo?: TooltipAttachTo;
    customOptionRender?: OptionListCustomRender;
    className?: string;
    preventOverflowWithEllipsis?: boolean;
}

export interface OptionListItemState {
    tooltipRef: TooltipRef | null;
}

const INDENTATION_MULTIPLIER = 7;
const DEFAULT_TOOLTIP_ATTACH = TooltipAttachTo.Right;

export class OptionListItem extends React.Component<OptionListItemProps, OptionListItemState> {
    public constructor(props: OptionListItemProps) {
        super(props);

        this.state = { tooltipRef: null };
    }
    private renderIcon = (item: Option, onSelect?: OnSelect) => {
        if (item.icon) {
            return (
                <div
                    className={style(classes.iconContainer)}
                    onClick={
                        !item.disabled
                            ? (e: SyntheticEvent) => onSelect && onSelect(item.id, OptionSelectSource.ICON, e)
                            : () => null
                    }
                >
                    <item.icon className={classes.icon} />
                </div>
            );
        } else {
            return null;
        }
    };

    public render() {
        const {
            index,
            id,
            isSelected,
            hovered,
            hoverOnlyFromLabel,
            showSelectedIcon,
            onSelect,
            onHover,
            item,
            attachTooltipTo = DEFAULT_TOOLTIP_ATTACH,
            customOptionRender,
            className,
            preventOverflowWithEllipsis,
        } = this.props;

        const renderOption = customOptionRender
            ? customOptionRender
            : (item: Option) => {
                  return (
                      <>
                          {this.renderIcon(item, onSelect)}
                          <Tooltip
                              className={classes.tooltip}
                              dontOpen={!this.shouldAllowTooltip()}
                              text={item.displayName}
                              ref={(c) => !this.state.tooltipRef && this.setState({ tooltipRef: c })}
                              attachTo={attachTooltipTo}
                          >
                              <span
                                  className={classes.label}
                                  onMouseEnter={(e) =>
                                      hoverOnlyFromLabel && onHover && onHover(!item.disabled ? id : null, index, e)
                                  }
                                  onClick={
                                      !item.disabled
                                          ? (e: SyntheticEvent) => onSelect && onSelect(id, OptionSelectSource.LABEL, e)
                                          : () => null
                                  }
                                  data-aid="st_option_list_item_label"
                              >
                                  {item.displayName}
                              </span>
                          </Tooltip>
                          {item.subLabel ? <span>{item.subLabel}</span> : null}
                          {showSelectedIcon && isSelected ? <Checkmark className={classes.selectedIcon} /> : null}
                      </>
                  );
              };

        const itemStyle: Partial<React.CSSProperties> = {};
        if (item.indentation) {
            itemStyle.marginLeft = `${item.indentation * INDENTATION_MULTIPLIER}px`;
        }
        if (item.font) {
            itemStyle.fontFamily = item.font;
        }

        return (
            <div
                id={`option_${index}`}
                key={`option_${index}`}
                className={style(
                    classes.root,
                    {
                        id,
                        selected: !!isSelected,
                        hovered: !!hovered,
                        disabled: !!item.disabled,
                        ellipsisAllowed: !!preventOverflowWithEllipsis,
                        hasDivider: !!item.hasDivider,
                        isAction: !!item.isAction,
                    },
                    className
                )}
                style={itemStyle}
                onMouseEnter={(e) => !hoverOnlyFromLabel && onHover && onHover(!item.disabled ? id : null, index, e)}
                onClick={
                    !item.disabled
                        ? (e: SyntheticEvent) => onSelect && onSelect(id, OptionSelectSource.OPTION, e)
                        : () => null
                }
                data-name={id}
            >
                {renderOption(item, index)}
            </div>
        );
    }

    private shouldAllowTooltip = () => {
        const { preventOverflowWithEllipsis } = this.props;
        const { tooltipRef } = this.state;

        const textIsShortenedOrStillUnknown =
            !tooltipRef ||
            !tooltipRef.rootRef.current ||
            tooltipRef.rootRef.current.offsetWidth < tooltipRef.rootRef.current.scrollWidth;
        return preventOverflowWithEllipsis && textIsShortenedOrStillUnknown;
    };
}
