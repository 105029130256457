import type { ControllerStateData } from '../types';

export const controllerNoStateResizing = (props: ControllerStateData) => {
    const { selectorState, controllerData } = props;
    let noStateResizing = false;

    if (
        selectorState !== undefined &&
        controllerData &&
        controllerData.noStateResizing &&
        controllerData.noStateResizing.length > 0
    ) {
        const noStateResizingList = controllerData.noStateResizing;
        noStateResizing = !!noStateResizingList.find((state) => state === selectorState);
    }

    return noStateResizing;
};
