import type { FontTheme, SelectableFonts, FontItems } from '@wixc3/stylable-panel-controllers';

export interface FontFace {
    // TODO: Move to font-types
    display?: string;
    family?: string;
    featureSettings?: string;
    loaded?: Promise<any>;
    status?: string;
    stretch?: string;
    style?: string;
    unicodeRange?: string;
    variant?: string;
    weight?: string;
    load: () => void;
}

const hebrewFontFamilies = [
    // Hebrew from Wix
    'Adler-W26-Regular',
    'Frank-Ruhl-W26-Regular',
];
const allFontFamilies = [
    'Arial',
    'Cambria',
    'Courier New',
    'Times New Roman',
    'Lucida Console',
    'Helvetica',
    'cursive',
    'fantasy',
    'serif',
    'sans-serif',
    'system-ui',

    // Google fonts:
    'Amatic SC',
    'Anton',
    'Barlow',
    'Basic',
    'Caudex',
    'Chelsea Market',
    'Cinzel',
    'Cookie',
    'Corben',
    'Cormorant Garamond',
    'Damion',
    'Dancing Script',
    'EB Garamond',
    'Enriqueta',
    'Forum',
    'Fredericka the Great',
    'Jockey One',
    'Josefin Slab',
    'Jura',
    'Kelly Slab',
    'Lato',
    'Libre Baskerville',
    'Lobster',
    'Marck Script',
    'Monoton',
    'Mr De Haviland',
    'Niconne',
    'Noticia Text',
    'Open Sans Condensed',
    'Oswald',
    'Overlock',
    'Patrick Hand',
    'Play',
    'Playfair Display',
    'Poppins',
    'Questrial',
    'Raleway',
    'Roboto',
    'Rozha One',
    'Sacramento',
    'Sail',
    'Sarina',
    'Signika',
    'Spinnaker',
    'Suez One',
    'Work Sans',
];
export const fontFamilies = allFontFamilies.concat(hebrewFontFamilies);

export const googleFontsURL =
    'https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Anton|Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Basic|Caudex:400,400i,700,700i|Chelsea+Market|Cinzel:400,700,900|Cookie|Corben:400,700|Cormorant+Garamond:300,300i,400,400i,500,500i,600,600i,700,700i|Damion|Dancing+Script:400,700|EB+Garamond:400,400i,500,500i,600,600i,700,700i,800,800i|Enriqueta:400,700|Forum|Fredericka+the+Great|Jockey+One|Josefin+Slab:100,100i,300,300i,400,400i,600,600i,700,700i|Jura:300,400,500,600,700|Kelly+Slab|Lato:100,300,400,700,900|Libre+Baskerville:400,400i,700|Lobster|Marck+Script|Monoton|Mr+De+Haviland|Niconne|Noticia+Text:400,400i,700,700i|Open+Sans+Condensed:300,300i,700|Oswald:200,300,400,500,600,700|Overlock:400,400i,700,700i,900,900i|Patrick+Hand|Play:400,700|Playfair+Display:400,400i,700,700i,900,900i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Questrial|Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Rozha+One|Sacramento|Sail|Sarina|Signika:300,400,600,700|Spinnaker|Suez+One|Work+Sans:100,200,300,400,500,600,700,800,900';

export function loadFontFamily(fontFamily: string, doc: Document = document) {
    // Load all weights
    // TODO test properly.
    const doc1 = doc as any as { fonts: Set<FontFace> };
    if (doc1.fonts) {
        Array.from(doc1.fonts.values())
            .filter((font: FontFace) => {
                return font.family === fontFamily;
            })
            .forEach((font) => font.load());
    }
}

export function getFontThemes(): FontTheme[] {
    return [
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '40px',
            lineHeight: '1.4em',
            family: 'Playfair Display',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Playfair Display, system-ui',
            cssColor: '#000000',
            seoTag: 'h1',
            cssClass: 'font_0',
            // displayName: 'text_editor_theme_new_1'
            displayName: 'Heading 1',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '28px',
            lineHeight: '1.4em',
            family: 'Poppins',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Poppins, system-ui',
            cssColor: '#000000',
            seoTag: 'h2',
            cssClass: 'font_2',
            // displayName: 'text_editor_theme_new_2'
            displayName: 'Heading 2',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '60px',
            lineHeight: '1.4em',
            family: 'Questrial',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Questrial, system-ui',
            cssColor: '#000000',
            seoTag: 'h3',
            cssClass: 'font_3',
            // displayName: 'text_editor_theme_new_3'
            displayName: 'Heading 3',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '40px',
            lineHeight: '1.4em',
            family: 'Raleway',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Raleway, system-ui',
            cssColor: '#000000',
            seoTag: 'h4',
            cssClass: 'font_4',
            // displayName: 'text_editor_theme_new_4'
            displayName: 'Heading 4',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '25px',
            lineHeight: '1.4em',
            family: 'Roboto',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Roboto, system-ui',
            cssColor: '#000000',
            seoTag: 'h5',
            cssClass: 'font_5',
            // displayName: 'text_editor_theme_new_5'
            displayName: 'Heading 5',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '22px',
            lineHeight: '1.4em',
            family: 'Rozha One',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Rozha One, system-ui',
            cssColor: '#000000',
            seoTag: 'h6',
            cssClass: 'font_6',
            // displayName: 'text_editor_theme_new_6'
            displayName: 'Heading 6',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '17px',
            lineHeight: '1.4em',
            family: 'Spinnaker',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Spinnaker, system-ui',
            cssColor: '#000000',
            seoTag: 'p',
            cssClass: 'font_7',
            // displayName: 'text_editor_theme_7'
            displayName: 'Paragraph 1',
        },
        {
            style: 'italic',
            variant: 'normal',
            weight: 'bold',
            size: '15px',
            lineHeight: '1.4em',
            family: 'Lobster, system-ui',
            color: '{color_18}',
            bold: true,
            italic: true,
            fontWithFallbacks: 'Lobster, system-ui',
            cssColor: '#384AD3',
            seoTag: 'p',
            cssClass: 'font_8',
            // displayName: 'text_editor_theme_8'
            displayName: 'Paragraph 2',
        },
        {
            style: 'normal',
            variant: 'normal',
            weight: 'normal',
            size: '14px',
            lineHeight: '1.4em',
            family: 'Work Sans',
            color: '{color_15}',
            bold: false,
            italic: false,
            fontWithFallbacks: 'Work Sans, system-ui',
            cssColor: '#000000',
            seoTag: 'p',
            cssClass: 'font_9',
            // displayName: 'text_editor_theme_9'
            displayName: 'Paragraph 3',
        },
    ];
}

const LANG_TO_GROUP_NAME = {
    hebrew: 'Hebrew',
    latin: 'All Fonts',
};

const LANG_TO_EXAMPLE = {
    hebrew: 'דוגמא',
    latin: '',
};

export function getSelectableFonts(): SelectableFonts[] {
    return [
        {
            lang: 'hebrew',
            fonts: hebrewFontFamilies.map((family) => ({
                cdnName: '',
                characterSets: ['hebrew'],
                cssFontFamily: `${family}, cursive`,
                displayName: family.replace(/-/g, ' '),
                fallbacks: '',
                fontFamily: family,
                genericFamily: 'cursive',
                permissions: 'all',
                provider: 'monotype',
                spriteIndex: 0,
            })),
        },
        {
            lang: 'latin',
            fonts: allFontFamilies.map((family) => ({
                cdnName: '',
                characterSets: ['latin'],
                cssFontFamily: `${family}, serif`,
                displayName: family.replace(/-/g, ' '),
                fallbacks: '',
                fontFamily: family,
                genericFamily: 'serif',
                permissions: 'all',
                provider: 'monotype',
                spriteIndex: 0,
            })),
        },
    ];
}

export function getFontsItems(selectableFonts: SelectableFonts[]): FontItems[] {
    return selectableFonts.map((selectableFont) => ({
        groupName: (LANG_TO_GROUP_NAME as any)[selectableFont.lang],
        items: selectableFont.fonts
            ? selectableFont.fonts.map((font) => ({
                  label: font.displayName,
                  value: font.fontFamily,
                  example: (LANG_TO_EXAMPLE as any)[selectableFont.lang],
                  cssFontFamily: `${font.cssFontFamily}, system-ui`,
              }))
            : [],
    }));
}
