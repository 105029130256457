import React from 'react';
import { Scrollbars } from '@wixc3/stylable-panel-components';
import { isClickInElement } from '@wixc3/stylable-panel-common-react';
import { style, classes } from './border-style-picker.st.css';

export const BORDER_STYLES = [
    'solid',
    'dashed',
    'dotted',
    'double' /*,
    'groove',
    'ridge',
    'inset',
    'outset',
    'none',
    'hidden'*/,
];

export interface BorderStylePickerProps {
    value?: string;
    onClose?: () => void;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    className?: string;
}

export class BorderStylePicker extends React.Component<BorderStylePickerProps> {
    constructor(props: BorderStylePickerProps) {
        super(props);
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    public render() {
        return (
            <div className={style(classes.root, this.props.className)}>
                <Scrollbars style={{ height: '273px' }}>
                    {BORDER_STYLES.map((borderStyle) => this.renderBorderStyleItem(borderStyle))}
                </Scrollbars>
            </div>
        );
    }

    private renderBorderStyleItem(borderStyle: string) {
        const { value, onChange } = this.props;

        return (
            <div
                key={`border_style_${borderStyle}`}
                className={style(classes.borderStyleItem, { selected: value === borderStyle })}
                onClick={() => onChange && onChange(borderStyle)}
            >
                <div
                    className={style(classes.borderStyleItemBorder, {
                        thick: borderStyle === 'double',
                    })}
                    style={{ borderTopStyle: borderStyle as any }}
                />
            </div>
        );
    }

    private handleDocumentMouseDown = (event: MouseEvent) => {
        const { onBlur } = this.props;

        if (!onBlur) {
            return;
        }

        if (isClickInElement(event, this)) {
            return;
        }

        onBlur();
    };
}
