import React from 'react';

import { Title } from '@wixc3/stylable-panel-components';

import { style, classes } from './optional-controller.st.css';

export interface OptionalControllerProps {
    title?: string;
    onClick?: () => void;
    className?: string;
}

export default class OptionalController extends React.Component<OptionalControllerProps> {
    public static defaultProps: Partial<OptionalControllerProps> = {};
    constructor(props: OptionalControllerProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.state = {
            value: undefined,
        };
    }

    public render() {
        const { title, className } = this.props;

        return (
            <div className={style(classes.root, className)}>
                <Title className={classes.title} text={title} />
                <button className={classes.button} onClick={this.onClick}>
                    +
                </button>
            </div>
        );
    }

    public onClick = () => {
        this.props.onClick && this.props.onClick();
    };
}
