/* eslint-disable react/no-string-refs */
import React from 'react';
import keycode from 'keycode';

import { isInRect, domRect, isClickInElement } from '@wixc3/stylable-panel-common-react';

import { DropDown } from '../../drop-down';
import { Slider } from '../../slider';
import { OptimisticWrapper } from '../../optimistic-wrapper';
import { optionsFromStrings } from '../../option-list';
import { UnitEditInfo, parseNumberUnit } from '../../utils/number-utils';

import { style, classes } from './number-unit-input.st.css';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;
const DEFAULT_STEP = 1;
const DEFAULT_SHIFT_STEP = 0;

export interface NumberUnitInputProps {
    value: string;
    units: Record<string, UnitEditInfo>;
    column?: boolean;
    autoFocus?: boolean;
    inlineUnits?: string;
    tooltipSlider?: boolean;
    inlineSlider?: boolean;
    tooltipPosition?: 'top' | 'bottom';
    knob?: boolean;
    skipValidation?: boolean;
    restrictiveRange?: boolean;
    disabled?: boolean;
    opacitySliderColor?: string;
    inputDataAid?: string;
    onChange: (value: string) => void;
    onBlur?: (origin?: string) => void;
    className?: string;
}

export interface NumberUnitInputState {
    inlineValue: string;
    tooltipShown: boolean;
    invalid: boolean;
}

export class NumberUnitInputInner extends React.Component<NumberUnitInputProps, NumberUnitInputState> {
    public static defaultProps: Partial<NumberUnitInputProps> = {
        tooltipPosition: 'top',
    };

    private value: number | undefined;
    private unit!: UnitEditInfo;
    private unitOptions: string[] = [];

    constructor(props: NumberUnitInputProps) {
        super(props);
        this.setValue(this.props);
        this.state = {
            inlineValue: this.props.value,
            tooltipShown: false,
            invalid: false,
        };
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: NumberUnitInputProps) {
        if (props.value !== this.props.value || props.units !== this.props.units) {
            this.setValue(props);
            this.setState({ inlineValue: props.value });
        }
    }

    public render() {
        const {
            column,
            autoFocus,
            inlineUnits,
            tooltipSlider,
            inlineSlider,
            tooltipPosition,
            knob,
            disabled,
            inputDataAid,
            className,
        } = this.props;
        const { tooltipShown, invalid } = this.state;

        const { unit, min, max, step } = this.unit;

        const inputMin = knob ? min - step : min; // to allow cyclic computation. otherwise input doesn't even send an event

        const unitOptions = this.getUnitsOptions();
        const computedInlineUnits =
            inlineUnits !== undefined ? inlineUnits : unitOptions.length === 1 ? unitOptions[0].id : undefined;

        return (
            <span
                className={style(
                    classes.root,
                    {
                        column: !!column,
                        tooltipPosition: tooltipPosition!,
                        knobMode: !!knob,
                        disabled: !!disabled,
                    },
                    className
                )}
            >
                {tooltipSlider && tooltipShown && !inlineSlider ? (
                    <div className={classes.tooltip} ref="tooltip">
                        {this.renderSlider()}
                        <div className={classes.arrow} ref="arrow" />
                    </div>
                ) : null}
                {inlineSlider ? this.renderSlider() : null}
                <input
                    className={style(classes.numberInput, { tooltip: !!tooltipShown, invalid })}
                    type="number"
                    value={this.value}
                    min={inputMin}
                    max={max}
                    step={step}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onChange={(event) => this.handleNumberChange(event.target.value)}
                    onFocus={this.handleNumberInputFocus}
                    onBlur={(ev) => this.handleInputBlur(ev, 'input')}
                    onKeyDown={this.handleNumberInputKeyDown}
                    data-aid={inputDataAid}
                    ref="numberInput"
                />
                {computedInlineUnits ? (
                    <span
                        className={style(classes.inlineUnit)}
                        onClick={() => (this.refs.numberInput as HTMLInputElement).focus()}
                    >
                        {computedInlineUnits}
                    </span>
                ) : null}
                {computedInlineUnits === undefined ? (
                    <DropDown
                        className={classes.unitInput}
                        value={unit}
                        options={unitOptions}
                        onSelect={(newUnit) => this.handleUnitChange(newUnit)}
                        disabled={disabled}
                        noOpenOnSingleOption
                        smallArrow
                        ref="dropDown"
                    />
                ) : null}
            </span>
        );
    }

    public componentWillUnmount() {
        this.cleanupMouseDownListener();
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }

    private setValue(props: NumberUnitInputProps) {
        const { value: propValue, units, inlineUnits } = props;

        const parsedNumber = parseNumberUnit(propValue);
        let { unit } = parsedNumber;
        const unitNames = Object.keys(units);
        this.unitOptions = unitNames.map((key) => units[key].unit);
        if (!unit && !inlineUnits) {
            unit = this.unitOptions[0];
        }
        this.value = parsedNumber.value;
        const unitIndex = this.unitOptions.findIndex((unitStr) => unitStr === unit);
        this.unit =
            unitIndex !== -1
                ? units[unitNames[unitIndex]]
                : {
                      unit: unit || '',
                      min: DEFAULT_MIN,
                      max: DEFAULT_MAX,
                      step: DEFAULT_STEP,
                  };
    }

    private renderSlider() {
        const { inlineSlider, disabled, opacitySliderColor } = this.props;
        const { min, max, step } = this.unit;

        return (
            <Slider
                className={style(classes.slider, { inline: !!inlineSlider })}
                value={this.value}
                min={min}
                max={max}
                step={step}
                knob={this.props.knob}
                disabled={disabled}
                color={opacitySliderColor}
                onChange={(value) => this.handleNumberChange(value)}
            />
        );
    }

    private getUnitsOptions() {
        const { units } = this.props;

        const unitNames = Object.keys(units);
        const unitOptions = unitNames.filter((key) => !units[key].optional).map((key) => units[key].unit);

        return optionsFromStrings(this.unit.optional ? [this.unit.unit].concat(unitOptions) : unitOptions);
    }

    private handleNumberChange(value: number | string) {
        const { value: propValue, onChange } = this.props;
        const { unit } = this.unit;
        let newValue = `${value}`;
        newValue = `${newValue}${newValue ? unit : ''}`;
        newValue !== propValue && onChange(newValue);
    }

    private handleUnitChange(unit: string) {
        const { units, onChange } = this.props;

        if (this.value) {
            onChange(`${this.value}${unit}`);
        } else {
            this.unit = units[Object.keys(units)[this.unitOptions.findIndex((unitStr) => unitStr === unit)]];
            this.forceUpdate();
        }
    }

    private handleNumberInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const { tooltipSlider, inlineSlider } = this.props;

        event.currentTarget.select();
        if (!tooltipSlider || inlineSlider) {
            return;
        }
        document.addEventListener('mousedown', this.handleMouseDown);
        this.setState({ tooltipShown: true });
    };

    private handleInputBlur = (event: React.FocusEvent<HTMLInputElement>, origin?: string) => {
        event.preventDefault();
        event.stopPropagation();

        const { onBlur } = this.props;
        this.verifyChange();

        onBlur && onBlur(origin);
    };

    private cleanupMouseDownListener() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    }

    private handleNumberInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { knob, onChange, skipValidation, restrictiveRange } = this.props;
        const { unit, step, min, max } = this.unit;

        if (!onChange || this.value === undefined) {
            return;
        }

        const change = (value: number) => {
            event.stopPropagation();
            event.preventDefault();
            let inlineValue = `${value}${unit}`;

            if (knob || skipValidation) {
                if (value < min) {
                    inlineValue = `${restrictiveRange ? min : max}${unit}`;
                } else if (value > max) {
                    inlineValue = `${restrictiveRange ? max : min}${unit}`;
                }
            }

            onChange(inlineValue);
            this.setState({ inlineValue });
        };

        let keyStep = step;
        if (event.shiftKey) {
            const { shiftStep } = this.unit;
            keyStep = shiftStep ? shiftStep : DEFAULT_SHIFT_STEP;
        }

        switch (event.keyCode) {
            case keycode('backspace'):
                change(0);
                break;
            case keycode('up'):
                change(this.value + keyStep);
                break;
            case keycode('down'):
                change(this.value - keyStep);
                break;
        }
    };

    private handleMouseDown = (event: MouseEvent) => {
        if (!this.refs.tooltip) {
            return;
        }

        const tooltipRect = domRect(this.refs.tooltip);
        if (
            event.target !== this.refs.tooltip &&
            event.target !== this.refs.slider &&
            event.target !== this.refs.arrow &&
            event.target !== this.refs.numberInput &&
            !isInRect(event.clientX, event.clientY, tooltipRect)
        ) {
            this.cleanupMouseDownListener();
            this.setState({ tooltipShown: false });
        }
    };

    private verifyChange = () => {
        const { unit, min, max, maxRange } = this.unit;
        const { value, onChange, skipValidation, restrictiveRange } = this.props;

        if (!skipValidation || !restrictiveRange) {
            return;
        }

        let numVal = parseFloat(value);
        const belowMin = numVal < min;
        const currentMax = skipValidation || !maxRange ? max : maxRange;
        const outOfRange = belowMin || numVal > currentMax;

        if (outOfRange) {
            numVal = belowMin ? min : currentMax;
            onChange(`${numVal}${unit}`);
        }
    };

    private handleDocumentMouseDown = (event: MouseEvent) => {
        const { onBlur } = this.props;

        if (!onBlur) {
            return;
        }

        if (isClickInElement(event, this)) {
            return;
        }

        const { tooltipShown } = this.state;

        if (tooltipShown && isClickInElement(event, this.refs.tooltip)) {
            return;
        }

        const dropDown = this.refs.dropDown as DropDown;
        if (dropDown && dropDown.optionListOpen() && isClickInElement(event, dropDown.refs.optionList)) {
            return;
        }

        this.verifyChange();

        onBlur();
    };
}

export const NumberUnitInput = OptimisticWrapper(NumberUnitInputInner, {
    onMouseDown: (context) => {
        context.setActive();
    },
    onMouseUp: (context) => {
        context.setIdle();
    },
    onChange: (context, ...args) => {
        context.onChange(...args);
    },
});
