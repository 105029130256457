import React from 'react';
import type { SelectorSet } from '@wixc3/stylable-panel-drivers';
import { CompositeBlock, OptimisticDimensionInput } from '@wixc3/stylable-panel-components';
import type { DynamicPartInputProps } from '../types';
import { FLEX_CHILD_SPACING } from '@wixc3/stylable-panel-common';
import { getTranslate } from '@wixc3/stylable-panel-controllers';
import { style, classes } from './flex-child-spacing.st.css';
export const PARAM_FIRST = 'first';
export const PARAM_LAST = 'last';
export interface FlexChildSpacingProps extends DynamicPartInputProps {
    maintainExisting?: boolean;
}
export const spacingTitleKey = 'spacing_title_key';
// TODO: React to direction and justify changes
export default class FlexChildSpacing extends React.Component<FlexChildSpacingProps> {
    public static defaultProps: Partial<FlexChildSpacingProps> = {
        maintainExisting: false,
    };
    public render() {
        const { controllerData, panelHost, className } = this.props;
        const translate = getTranslate(panelHost);
        const translationKeys = controllerData ? controllerData.translationKeys || {} : {};
        const title = translationKeys[spacingTitleKey] ? translate(translationKeys[spacingTitleKey]) : 'Spacing';
        return (
            <div className={style(classes.root, className)}>
                <CompositeBlock className={classes.controllerBlock} title={title}>
                    <OptimisticDimensionInput
                        className={classes.inputElement}
                        value={`${this.getSliderValue()}px`}
                        config={{ units: FLEX_CHILD_SPACING }}
                        isSlider={true}
                        onChange={this.handleSliderChange}
                    />
                </CompositeBlock>
            </div>
        );
    }
    private getSliderValue() {
        const { values } = this.props;
        const selectors = Object.keys(values);
        const isVertical = this.isVertical();
        const isReverse = this.isReverse();
        const elementMargins = this.elementMargins();
        const aggregatedMargin = elementMargins.reduce((currAggregatedMargin, { top, bottom, left, right }, index) => {
            const { params } = values[selectors[index]];
            return (
                currAggregatedMargin +
                (params === (isReverse ? PARAM_LAST : PARAM_FIRST) ? 0 : parseFloat(isVertical ? top : left)) +
                (params === (isReverse ? PARAM_FIRST : PARAM_LAST) ? 0 : parseFloat(isVertical ? bottom : right))
            );
        }, 0);
        return `${(2 * aggregatedMargin) / elementMargins.length}`;
    }
    private handleSliderChange = (value: string) => {
        const { values, maintainExisting, onChangeSelector } = this.props;
        if (!onChangeSelector) {
            return;
        }
        const isVertical = this.isVertical();
        const isReverse = this.isReverse();
        const elementMargins = this.elementMargins();
        const changeSet = Object.keys(values).reduce((currChangeSet, selector, index) => {
            const { params } = values[selector];
            const margins = elementMargins[index];
            const spacing = parseInt(value, 10) / 2;
            const top = isVertical
                ? params === (isReverse ? PARAM_LAST : PARAM_FIRST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.top, 10)
                : '0';
            const bottom = isVertical
                ? params === (isReverse ? PARAM_FIRST : PARAM_LAST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.bottom, 10)
                : '0';
            const left = !isVertical
                ? params === (isReverse ? PARAM_LAST : PARAM_FIRST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.left, 10)
                : '0';
            const right = !isVertical
                ? params === (isReverse ? PARAM_FIRST : PARAM_LAST)
                    ? '0'
                    : spacing
                : maintainExisting
                ? parseInt(margins.right, 10)
                : '0';
            currChangeSet[selector] = {
                margin: `${top}px ${right}px ${bottom}px ${left}px`,
            };
            return currChangeSet;
        }, {} as SelectorSet);
        onChangeSelector(changeSet);
    };
    private elementMargins = () => {
        const { values } = this.props;
        return Object.keys(values).map((selector) => {
            const {
                decls: {
                    'margin-top': top = '0',
                    'margin-bottom': bottom = '0',
                    'margin-left': left = '0',
                    'margin-right': right = '0',
                },
            } = values[selector];
            return { top, bottom, left, right };
        });
    };
    private isVertical = () => {
        const {
            value: { 'flex-direction': direction = 'row' },
        } = this.props;
        return direction === 'column' || direction === 'column-reverse';
    };
    private isReverse = () => {
        const {
            value: { 'flex-direction': direction = 'row' },
        } = this.props;
        return direction === 'row-reverse' || direction === 'column-reverse';
    };
}
