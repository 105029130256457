export const areRecordsEqual = (
    recordA: Record<string, string | undefined>,
    recordB: Record<string, string | undefined>
) => {
    if ((!recordA && !!recordB) || (!!recordA && !recordB)) {
        return false;
    }
    if (recordA === recordB) {
        return true;
    }

    const keysA = Object.keys(recordA);
    const keysB = Object.keys(recordB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const keyName of keysA) {
        if (recordA[keyName] !== recordB[keyName]) {
            return false;
        }
    }

    return true;
};
