import React from 'react';
import chroma from 'chroma-js';

import { Title } from '@wixc3/stylable-panel-components';

import type { ControllerComponent, ExtendedControllerProps } from '../types';
import { ColorPicker } from '../pickers/color-picker/color-picker';
import type { CustomSkinsPicker } from '../pickers/my-skins-picker/my-skins-picker';

import { style, classes } from './color-controller.st.css';

export interface ColorControllerProps extends ExtendedControllerProps {
    customSkinsPicker?: CustomSkinsPicker;
}

export interface ColorControllerState {
    colorPickerShown: boolean;
}

export function ColorControllerFactory(title: string, propName: string): React.ComponentClass<ColorControllerProps> {
    class ColorController
        extends React.Component<ColorControllerProps, ColorControllerState>
        implements ControllerComponent
    {
        public static INPUT_PROPS: string[] = [propName];

        public state: ColorControllerState = { colorPickerShown: false };

        public render() {
            const { siteVarsDriver, value, panelHost, customSkinsPicker, className } = this.props;
            const { colorPickerShown } = this.state;

            const colorValue = value[propName];
            let noOpacityColor = 'black';
            let inputValue = 100;
            try {
                const color = chroma(colorValue || '');
                noOpacityColor = color.alpha(1).toString();
                inputValue = Math.floor(color.alpha() * 100);
            } catch {
                //
            }

            return (
                <div className={style(classes.root, className)}>
                    <Title className={classes.title} text={title} />
                    <div className={classes.controls}>
                        <input
                            className={classes.opacitySlider}
                            style={{ background: `linear-gradient(to right, white, ${noOpacityColor})` }}
                            type="range"
                            value={inputValue}
                            min="0"
                            max="100"
                            step="1"
                            onChange={this.handleInputChange}
                        />
                        <input
                            className={classes.opacityInput}
                            type="number"
                            value={inputValue}
                            onChange={this.handleInputChange}
                        />
                        <span
                            className={classes.preview}
                            style={{ backgroundColor: colorValue }}
                            onClick={() => this.setState({ colorPickerShown: !colorPickerShown })}
                        >
                            {colorValue !== undefined ? ' ' : 'N/A'}
                        </span>
                        {colorPickerShown ? (
                            <ColorPicker
                                className={classes.colorPicker}
                                siteVarsDriver={siteVarsDriver}
                                currentColor={colorValue}
                                panelHost={panelHost}
                                onChange={this.handleColorPickerChange}
                                onHover={this.handleColorPickerChange}
                                // onClose={() => this.setState({colorPickerShown: false})}
                                onBlur={() => this.setState({ colorPickerShown: false })}
                                customSkinsPicker={customSkinsPicker}
                            />
                        ) : null}
                    </div>
                </div>
            );
        }

        private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value, onChange } = this.props;

            try {
                const color = chroma(value[propName] || '');
                const numberValue = parseFloat(event.target.value);
                onChange && onChange({ [propName]: color.alpha(numberValue / 100).css() });
            } catch {
                //
            }
        };

        private handleColorPickerChange = (changeValue: string | null) => {
            if (!changeValue) {
                return;
            }

            const { value, sheetDriver, onChange } = this.props;

            let newValue = changeValue;
            let currAlpha = 1;
            try {
                currAlpha = chroma(value[propName] || '').alpha();
            } catch {
                //
            }
            if (currAlpha !== 1) {
                const evalNewValue = sheetDriver ? sheetDriver.evalDeclarationValue(changeValue) : changeValue;
                newValue = chroma(evalNewValue).alpha(currAlpha).css();
            }

            onChange && onChange({ [propName]: newValue });
        };
    }

    return ColorController;
}
