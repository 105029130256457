import React from 'react';
import chroma from 'chroma-js';
import { BoxShadow, parse as parseBoxShadow, stringify as stringifyBoxShadow } from 'css-box-shadow';

import {
    StylableComments,
    StylablePanelTranslationKeys,
    GenericDeclarationMap,
    removeComments,
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wixc3/stylable-panel-drivers';
import { Tooltip, OptimisticWrapper } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';

import type { VisualizerComponent, DeclarationVisualizerProps } from '../../types';
import type { OpenedDeclarationArray } from '../../declaration-types';
import {
    createDeclarationMapFromVisualizerValue,
    controllerToVisualizerChange,
    visualizerOptimisticValueResolver,
    createVisualizerValueFromDeclarationMap,
} from '../../utils';
import { LAYER_HIDDEN_PROP, LayersController } from '../../controllers';
import { SingleShadowInput, TextShadowLayerInput } from '../../inputs';
import type { CustomInputProps } from '../../drivers';
import { getTranslate } from '../../hosts/translate';
import { PanelEventList } from '../../hosts/bi';

import { classes, style } from './shadows-visualizer.st.css';
import { getDimensionUnits, OpacityVisualizer } from '../../generated-visualizers';

const DEFAULT_COLOR = 'black';
export const DEFAULT_SHADOW = `3px 3px 2px ${DEFAULT_COLOR}`;
const EMPTY_COLOR = 'currentcolor';
const EMPTY_SHADOW = `1px 1px 0 ${EMPTY_COLOR}`;
const MAX_PREVIEW_SHADOW_SIZE = 3;

export type ShadowProps = 'box-shadow' | 'text-shadow';
export type ShadowVisualizerProps = DeclarationVisualizerProps<ShadowProps>;

type ShadowDeclarationMap = GenericDeclarationMap<ShadowProps>;
type ShadowVisualizerValue = OpenedDeclarationArray<ShadowProps>;

export function ShadowsVisualizerFactory(propName: ShadowProps): React.ComponentClass<ShadowVisualizerProps> {
    class ShadowsVisualizer extends React.Component<ShadowVisualizerProps> implements VisualizerComponent<ShadowProps> {
        public static BLOCK_VARIANT_CONTROLLER = false;
        public static INPUT_PROPS: ShadowProps[] = [propName];

        private commentDriver!: StylableComments;
        private shouldOpenShadowPicker = false;

        private declarationMapValue: ShadowDeclarationMap;

        constructor(props: ShadowVisualizerProps) {
            super(props);
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillUpdate(newProps: ShadowVisualizerProps) {
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(newProps.value, newProps);
            if (this.shouldOpenShadowPicker) {
                this.openShadowPicker(DEFAULT_SHADOW, 0);
                this.shouldOpenShadowPicker = false;
            }
        }

        public render() {
            const { plane = DEFAULT_PLANE, panelHost, className } = this.props;
            const value = this.declarationMapValue[propName];

            const translate = getTranslate(panelHost);

            let shadows: BoxShadow[] = [];
            shadows = this.getShadows();
            if (value !== undefined && value !== 'none') {
                this.commentDriver = new StylableComments(value);
                if (!this.commentDriver.indicesValid) {
                    shadows = [];
                }
            } else {
                this.commentDriver = new StylableComments('');
            }

            return (
                <LayersController
                    className={style(classes.root, className)}
                    title={translate(StylablePanelTranslationKeys.controller.shadows.title)}
                    addLayerLabel={translate(StylablePanelTranslationKeys.controller.shadows.addLayerButtonLabel)}
                    plane={plane}
                    panelHost={panelHost}
                    onAdd={() => this.addNewShadowLayer(shadows)}
                    onReplace={(srcIndex, dstIndex) => this.replaceShadowLayers(shadows, srcIndex, dstIndex)}
                    onDuplicate={(index) => this.duplicateShadowLayer(shadows, index)}
                    onRemove={(index) => this.removeShadowLayer(shadows, index)}
                    onHide={(_newHidden, index) => this.showHideShadowLayer(shadows, index)}
                >
                    {shadows.map((shadow, index) => this.renderShadowLayer(shadow, index))}
                </LayersController>
            );
        }

        private getShadows() {
            const shadowValue = this.declarationMapValue[propName];
            const { siteVarsDriver } = this.props;
            const evalDeclarationValue =
                siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

            if (shadowValue !== undefined && shadowValue !== 'none') {
                return parseBoxShadow(evalDeclarationValue(removeComments(shadowValue)));
            }

            return parseBoxShadow(EMPTY_SHADOW);
        }

        private openShadowPicker(value: string, index: number) {
            const { drivers, siteVarsDriver, panelHost } = this.props;

            if (!panelHost?.onOpenPanel) {
                return;
            }

            const translate = getTranslate(panelHost);

            const ShadowInput = propName === 'box-shadow' ? SingleShadowInput : TextShadowLayerInput;

            const shadowPickerProps: CustomInputProps = {
                className: classes.shadowInput,
                title: translate(StylablePanelTranslationKeys.controller.shadows.shadowPickerTitle),
                value,
                drivers,
                siteVarsDriver,
                panelHost,
                onChange: (changeValue: string) => this.changeShadowLayer(changeValue, index),
            };

            panelHost.onOpenPanel(ShadowInput.panelName, shadowPickerProps);
        }

        private renderShadowLayer(shadow: BoxShadow, index: number) {
            const { panelHost, drivers } = this.props;
            const translate = getTranslate(panelHost);

            const hidden = this.commentDriver.isCommented(index);
            const layerValue = stringifyBoxShadow([shadow]);
            const colorValue = shadow.color;
            let opacityValue = 100;
            try {
                const color = chroma(colorValue);
                opacityValue = Math.floor(color.alpha() * 100);
            } catch {
                //
            }

            const noColor = shadow.color === EMPTY_COLOR;
            const isBoxShadow = propName === 'box-shadow';

            const units = getDimensionUnits({ id: DIMENSION_ID.OPACITY, dimensionUnits: panelHost?.dimensionUnits });

            return (
                <span
                    key={`shadow_layer_${index}`}
                    className={style(classes.shadowLayer, { hidden })}
                    {...{ [LAYER_HIDDEN_PROP]: hidden }}
                >
                    <Tooltip
                        // text={draggingIndex === -1 && shadowPickerIndex !== index ? 'Edit Shadow' : undefined}
                        text={
                            !hidden
                                ? translate(StylablePanelTranslationKeys.controller.shadows.layerThumbnailTooltip)
                                : undefined
                        }
                        verticalAdjust={-1}
                        horizontalAdjust={-5}
                    >
                        <span
                            className={classes.shadowBox}
                            onClick={!hidden ? () => this.openShadowPicker(layerValue, index) : () => null}
                        >
                            <div
                                className={classes.shadowPreview}
                                style={{
                                    [isBoxShadow ? 'boxShadow' : 'textShadow']: this.getShadowLayerPreview(shadow),
                                }}
                            >
                                {isBoxShadow ? ' ' : 'Aa'}
                            </div>
                        </span>
                    </Tooltip>

                    <OpacityVisualizer
                        drivers={drivers}
                        className={classes.inputElementOpacity}
                        value={createVisualizerValueFromDeclarationMap({
                            opacity: `${opacityValue}%`,
                        })}
                        config={{ units }}
                        onChange={(value) => {
                            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (opacity) {
                                this.changeShadowLayerColorAlpha(opacity, index);
                            }
                        }}
                        isDisabled={hidden || noColor}
                    />
                </span>
            );
        }

        private getShadowLayerPreview(shadow: BoxShadow) {
            const previewShadow: BoxShadow = { ...shadow };

            if (previewShadow.offsetX > 0) {
                previewShadow.offsetX = Math.min(previewShadow.offsetX, MAX_PREVIEW_SHADOW_SIZE);
            } else {
                previewShadow.offsetX = Math.max(previewShadow.offsetX, -1 * MAX_PREVIEW_SHADOW_SIZE);
            }

            if (previewShadow.offsetY > 0) {
                previewShadow.offsetY = Math.min(previewShadow.offsetY, MAX_PREVIEW_SHADOW_SIZE);
            } else {
                previewShadow.offsetY = Math.max(previewShadow.offsetY, -1 * MAX_PREVIEW_SHADOW_SIZE);
            }

            if (previewShadow.spreadRadius) {
                previewShadow.spreadRadius = Math.min(previewShadow.spreadRadius, MAX_PREVIEW_SHADOW_SIZE);
            }

            return stringifyBoxShadow([previewShadow]);
        }

        private changeShadowLayer(layerValue: string, index: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            const shadows = this.getShadows();

            shadows[index] = parseBoxShadow(layerValue)[0];
            if (shadows[index].color === EMPTY_COLOR) {
                shadows[index].color = DEFAULT_COLOR;
            }

            onChange(
                controllerToVisualizerChange(
                    { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                    this.props
                )
            );
        }

        private changeShadowLayerColorAlpha(value: string, index: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            const shadows = this.getShadows();

            try {
                const color = chroma(shadows[index].color);
                const numberValue = parseFloat(value);
                shadows[index].color = color.alpha(numberValue / 100).css();

                onChange(
                    controllerToVisualizerChange(
                        { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                        this.props
                    )
                );
            } catch {
                //
            }
        }

        private addNewShadowLayer(shadows: BoxShadow[]) {
            const { onChange } = this.props;

            if (!onChange) return;

            let appendToExisting = false;
            if (shadows.length) {
                if (shadows.length > 1) {
                    appendToExisting = true;
                } else if (shadows[0].color !== EMPTY_COLOR) {
                    appendToExisting = true;
                } else {
                    this.shouldOpenShadowPicker = true;
                }
            }

            onChange(
                controllerToVisualizerChange(
                    {
                        [propName]: appendToExisting
                            ? `${DEFAULT_SHADOW}, ${this.stringifyShadowsForChange(shadows)}`
                            : DEFAULT_SHADOW,
                    } as ShadowDeclarationMap,
                    this.props
                )
            );

            const { panelHost } = this.props;
            if (!panelHost || !panelHost.reportBI) {
                return;
            }
            const { reportBI } = panelHost;
            const { ADD_LAYER_CLICK } = PanelEventList;
            reportBI && reportBI(ADD_LAYER_CLICK, { origin: 'shadow' });
        }

        private replaceShadowLayers(shadows: BoxShadow[], srcIndex: number, dstIndex: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            const srcLayer = { ...shadows[srcIndex] };
            shadows[srcIndex] = shadows[dstIndex];
            shadows[dstIndex] = srcLayer;

            this.commentDriver.replaceLayers(srcIndex, dstIndex);

            onChange(
                controllerToVisualizerChange(
                    { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                    this.props
                )
            );
        }

        private duplicateShadowLayer(shadows: BoxShadow[], index: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            shadows.splice(index, 0, shadows[index]);

            this.commentDriver.duplicateLayer(index);

            onChange(
                controllerToVisualizerChange(
                    { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                    this.props
                )
            );
        }

        // TODO: 'box-shadow: none' when we delete to no shadow
        private removeShadowLayer(shadows: BoxShadow[], index: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            shadows.splice(index, 1);

            this.commentDriver.removeLayer(index);
            onChange(
                controllerToVisualizerChange(
                    { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                    this.props
                )
            );
        }

        private showHideShadowLayer(shadows: BoxShadow[], index: number) {
            const { onChange } = this.props;

            if (!onChange) return;

            this.commentDriver.toggleCommentLayer(index);

            onChange(
                controllerToVisualizerChange(
                    { [propName]: this.stringifyShadowsForChange(shadows) } as ShadowDeclarationMap,
                    this.props
                )
            );
        }

        private stringifyShadowsForChange(shadows: BoxShadow[]) {
            const { siteVarsDriver } = this.props;
            const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
            const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

            if (shadows.length === 0) {
                return '';
            }

            loadSiteColors();
            for (const shadow of shadows) {
                shadow.color = wrapSiteColor(shadow.color);
            }

            return this.commentDriver.setLayerComments(stringifyBoxShadow(shadows));
        }
    }

    return OptimisticWrapper<React.ComponentClass<ShadowVisualizerProps>, ShadowVisualizerValue>(
        ShadowsVisualizer,
        {},
        true,
        visualizerOptimisticValueResolver
    );
}
