import type * as postcss from 'postcss';

import { valueMapping } from '@stylable/core';

import type { StylableDriver } from './stylable-driver';
import type { StylesheetDriver } from './stylable-stylesheet';
import { StylableVariant } from './stylable-variant';

export interface Preset {
    path: string;
    named?: string;
    style?: string;
}

export interface PresetDriverParams {
    stylableDriver: StylableDriver;
    componentName?: string;
    presets: Preset[];
    sourceClassName: string;
    sheetPath?: string;
    presetSheetPaths?: string[];
    snapshots?: string[];
    extendsSymbol?: string /* when provided, PresetDriver will not attempt to find the extends symbol using the working stylesheet*/;
}

interface PresetDriverPresets {
    staticPresets: Preset[];
    myPresets: Preset[];
    themePresets: Preset[];
}

interface StylesheetPresets {
    themePresets: string[];
    myPresets: string[];
}

export type PresetListType = 'static' | 'user' | 'theme';

export const THEME_PRESET_PROPNAME = '-editor-theme';
export const STATIC_PRESET_CLASS_PREFIX = 'preset';
export const USER_PRESET_CLASS_PREFIX = 'my-preset';
export const THEME_PRESET_CLASS_PREFIX = 'theme-preset';

const DEFAULT_COMPONENT_NAME = 'unknown-comp';

export const getClassnameExtends = (sheet: StylesheetDriver, sourceClassName: string) => {
    let classSymbol;
    sheet.AST.walkRules(`.${sourceClassName}`, (rule: postcss.Rule) => {
        rule.walkDecls(valueMapping.extends, (decl: postcss.Declaration) => {
            classSymbol = decl.value;
        });
    });
    return classSymbol;

    // const classSymbol = meta.classes[sourceClassName];
    // if (!classSymbol) { return undefined; }
    // const extendingSymbol = classSymbol[valueMapping.extends];
    // if (!extendingSymbol || extendingSymbol._kind !== 'import') {
    //     return undefined;
    // } else {
    //     const _import = extendingSymbol.import;
    //     const extendsOriginName = extendingSymbol.name;
    //     if (extendingSymbol.type === 'default') {
    //         return _import.defaultExport;
    //     } else if (extendingSymbol.type === 'named') {
    //         return Object.keys(extendingSymbol.import.named).find(name => {
    //             return _import.named[name] === extendsOriginName;
    //         });
    //     }
    // }
};

export class PresetDriver {
    public componentName: string;
    public generatedSheet!: StylesheetDriver;

    private stylableDriver: StylableDriver;
    private workingStylesheet: StylesheetDriver | undefined;
    private entryPath!: string;
    private variantDriver: StylableVariant | undefined;
    private presets: PresetDriverPresets;
    private shouldRestorePreset = false;
    private savedRules: postcss.Rule[] | undefined;
    private presetSheetPaths: string[] = [];
    private sourceClassName: string;
    private extendsSymbol: string | undefined;
    private snapshots: string[];

    constructor(params: PresetDriverParams) {
        this.presets = {
            staticPresets: params.presets || [],
            myPresets: [],
            themePresets: [],
        };
        this.stylableDriver = params.stylableDriver;
        this.componentName = params.componentName || DEFAULT_COMPONENT_NAME;
        this.sourceClassName = params.sourceClassName;
        this.extendsSymbol = params.extendsSymbol;
        this.snapshots = params.snapshots || [];

        this.initStylesheets(params);
    }

    public get staticPresets() {
        return this.presets.staticPresets;
    }
    public get firstSnapshot() {
        return this.snapshots[0];
    }

    public getBuiltCSS() {
        this.stylableDriver.writeFile(this.entryPath, this.generatedSheet.source);
        return this.stylableDriver.buildCSS({
            emitBuild: false,
            entries: [this.entryPath],
        });
    }

    public getPresetIdList(presetList: PresetListType = 'static') {
        switch (presetList) {
            case 'static':
                return this.presets.staticPresets.map((_value: Preset, index: number) =>
                    this.presetClassName(STATIC_PRESET_CLASS_PREFIX + index)
                );
            case 'user':
                return this.presets.myPresets.map((_value: Preset, index: number) =>
                    this.presetClassName(USER_PRESET_CLASS_PREFIX + index)
                );
            case 'theme':
                return this.presets.themePresets.map((_value: Preset, index: number) =>
                    this.presetClassName(THEME_PRESET_CLASS_PREFIX + index)
                );
        }
    }

    public get rootClassName() {
        return this.presetClassName('root');
    }

    public removeAllSelectorRules(selector: string) {
        if (!this.variantDriver || !this.workingStylesheet) {
            return;
        }

        this.stylableDriver.batch(this.entryPath, () => {
            const rules = this.variantDriver!.getVariantStyleRules(selector);
            rules.forEach((rule) => this.workingStylesheet!.removeRule(rule));
        });
    }

    public applyPresetToWorkingStylesheet(presetIndex: number, fromPresetList: PresetListType = 'static') {
        const selector = '.' + this.sourceClassName;

        // Save previous st-mixin data:
        if (!this.shouldRestorePreset) {
            this.shouldRestorePreset = true;
            this.savedRules = this.variantDriver && this.variantDriver.getVariantStyleRules(selector); // save current rules
        }
        const currentExtends = this.extendsSymbol || getClassnameExtends(this.workingStylesheet!, this.sourceClassName);
        if (!currentExtends) {
            return;
        }

        const targetStylesheet = this.workingStylesheet!;

        if (fromPresetList === 'static') {
            this.removeAllSelectorRules(selector);
            const preset = this.presets.staticPresets[presetIndex];

            const { default: stylesheetId } = targetStylesheet.upsertImport({
                path: preset.path,
                requestedDefaultImportName: this.componentName,
            });
            targetStylesheet.upsertStyleRule(selector);

            targetStylesheet.upsertDeclarationToRule(selector, {
                prop: valueMapping.mixin,
                value: `${stylesheetId}`,
            });
            targetStylesheet.upsertDeclarationToRule(selector, {
                prop: valueMapping.extends,
                value: currentExtends,
            });
        } else {
            // Save preset data
            const presetList = fromPresetList === 'user' ? this.presets.myPresets : this.presets.themePresets;
            const sourceSelector = '.' + presetList[presetIndex].named;

            const presetVariantDriver = this.getPresetVariantDriver(presetList[presetIndex].path);
            const rulesFromPreset = presetVariantDriver ? presetVariantDriver.getVariantStyleRules(sourceSelector) : [];

            this.removeAllSelectorRules(selector);

            rulesFromPreset.forEach((rule) => {
                const newSelector = rule.selector.replace(sourceSelector, selector);
                const newRule: postcss.Rule = rule.clone({ selector: newSelector });
                targetStylesheet.addRule(newRule);
            });
        }
    }

    public restorePreset() {
        if (!this.shouldRestorePreset) {
            return;
        }

        const selector = '.' + this.sourceClassName;
        this.removeAllSelectorRules(selector); // remove current rules
        this.workingStylesheet && this.savedRules!.forEach((rule) => this.workingStylesheet!.addRule(rule)); // add saved ones

        this.clearRestore();
    }

    public clearRestore() {
        this.savedRules = [];
        this.shouldRestorePreset = false;
    }

    private presetClassName(className: string) {
        return this.stylableDriver.getTargetClass(this.entryPath, className);
    }

    private initStylesheets(params: PresetDriverParams) {
        if (params.sheetPath) {
            this.workingStylesheet = this.stylableDriver.getStylesheet(params.sheetPath)!;
            this.variantDriver = new StylableVariant(this.workingStylesheet);

            this.presetSheetPaths = [params.sheetPath];
        }

        if (params.presetSheetPaths) {
            this.presetSheetPaths = params.presetSheetPaths;
        }

        this.generateStylesheet();
    }

    private generateStylesheet() {
        this.entryPath = `/${this.componentName}-presets.st.css`;
        this.stylableDriver.writeFile(this.entryPath, '');
        this.generatedSheet = this.stylableDriver.getStylesheet(this.entryPath)!;

        this.presets.staticPresets.map((preset: Preset, index: number) => {
            let localMixinName: string;
            if (preset.named) {
                this.generatedSheet.upsertImport({
                    path: preset.path,
                    named: [preset.named],
                });
                localMixinName = preset.named;
            } else {
                const { default: d } = this.generatedSheet.upsertImport({
                    path: preset.path,
                });
                localMixinName = d;
            }

            this.generatedSheet.upsertStyleRule(`.preset${index}`);
            this.generatedSheet.upsertDeclarationToRule(`.preset${index}`, {
                prop: valueMapping.mixin,
                value: `${localMixinName}`,
            });
        });

        this.presetSheetPaths.forEach((presetPath) => {
            const presetStylesheet = this.stylableDriver.getStylesheet(presetPath);
            if (!presetStylesheet) {
                return;
            }

            const { themePresets, myPresets } = this.getPresetsFromStyleSheet(presetStylesheet);
            const presetsFromWorkingStyleSheet = themePresets.concat(myPresets);
            const { named } = this.generatedSheet.upsertImport({
                path: presetPath,
                named: presetsFromWorkingStyleSheet,
            });
            const sitePresets = Object.keys(named!) || [];

            sitePresets.forEach((className: string) => {
                if (themePresets.indexOf(className) !== -1) {
                    this.addPresetToList(
                        className,
                        `.${THEME_PRESET_CLASS_PREFIX}`,
                        presetPath,
                        this.presets.themePresets
                    );
                } else {
                    this.addPresetToList(className, `.${USER_PRESET_CLASS_PREFIX}`, presetPath, this.presets.myPresets);
                }
            });
        });
    }

    private addPresetToList(className: string, selectorPrefix: string, path: string, presetList: Preset[]) {
        const index = presetList.length;
        presetList.push({ named: className, path });

        this.generatedSheet.upsertStyleRule(`${selectorPrefix}${index}`);
        this.generatedSheet.upsertDeclarationToRule(`${selectorPrefix}${index}`, {
            prop: valueMapping.mixin,
            value: className,
        });
    }

    private getPresetsFromStyleSheet(stylesheet: StylesheetDriver): StylesheetPresets {
        const themePresets: string[] = [];
        const myPresets: string[] = [];

        if (!stylesheet) {
            return { themePresets, myPresets };
        }

        const currentExtends = this.extendsSymbol || getClassnameExtends(this.workingStylesheet!, this.sourceClassName);
        const rules =
            stylesheet &&
            stylesheet.AST.nodes &&
            stylesheet.AST.nodes.filter(
                (rule) =>
                    rule.type === 'rule' &&
                    rule.nodes &&
                    rule.nodes.find(
                        (decl) =>
                            decl.type === 'decl' && decl.prop === valueMapping.extends && decl.value === currentExtends
                    )
            );

        rules &&
            rules.forEach((rule) => {
                let targetList;
                if (rule.type === 'rule') {
                    if (
                        rule.nodes &&
                        rule.nodes.find(
                            (decl) =>
                                decl.type === 'decl' && decl.prop === THEME_PRESET_PROPNAME && decl.value === 'true'
                        )
                    ) {
                        // rule has editor theme true:
                        targetList = themePresets;
                    } else {
                        targetList = myPresets;
                    }

                    const selector =
                        rule.selector[0] === '.' ? rule.selector.slice(1, rule.selector.length) : rule.selector;
                    if (selector !== this.sourceClassName && selector !== 'root') {
                        targetList.push(selector);
                    }
                }
            });

        return { themePresets, myPresets };
    }

    private getPresetVariantDriver(path: string) {
        const stylesheet = this.stylableDriver.getStylesheet(path);
        return stylesheet ? new StylableVariant(stylesheet) : undefined;
    }
}
