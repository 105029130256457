import React from 'react';
import { Option, OptionList } from '@wixc3/stylable-panel-components';
import { isInRect } from '@wixc3/stylable-panel-common-react';
import type { GenericDeclarationMap } from '@wixc3/stylable-panel-drivers';
import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';
import { controllerToVisualizerChange, createDeclarationMapFromVisualizerValue } from '../../utils';
import { getTranslate } from '../../hosts/translate';
import type { OpenedDeclarationArray } from '../../declaration-types';
import { PopupIcon } from './popup-icon';
import { style, classes } from './icon-keyword-visualizer.st.css';

type IconKeywordFactoryProps = {
    options: Option[];
    tooltipKey: string;
    MainIcon: React.FunctionComponent;
};

type IconKeywordState = { openPopup: boolean };

export interface IconKeywordVisualizerProps<MAIN extends string> extends DeclarationVisualizerProps<MAIN> {}

export function IconKeywordVisualizerFactory<MAIN extends string>(
    main: MAIN,
    iconKeywordProps: IconKeywordFactoryProps
): React.ComponentClass<IconKeywordVisualizerProps<MAIN>, IconKeywordState> {
    const { options, tooltipKey, MainIcon } = iconKeywordProps;

    return class IconKeywordVisualizer
        extends React.Component<IconKeywordVisualizerProps<MAIN>, IconKeywordState>
        implements VisualizerComponent<MAIN>
    {
        private declarationMapValue: GenericDeclarationMap<MAIN>;

        constructor(props: IconKeywordVisualizerProps<MAIN>) {
            super(props);
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
            this.state = {
                openPopup: false,
            };
        }

        public componentDidUpdate() {
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(this.props.value, this.props);
        }

        private getPopupIconIcon(value: string) {
            const valueOption = options.find((option) => option.id === value);
            return valueOption && valueOption.icon;
        }

        private onPopupIconChange = (value: OpenedDeclarationArray<MAIN>) => {
            const { onChange } = this.props;
            if (onChange) {
                onChange(value);
                this.closePopup();
            }
        };

        private openPopup = () => {
            document.addEventListener('mousedown', this.handleDocumentMouseDown);
            this.setState({ openPopup: true });
        };

        private closePopup = () => {
            document.removeEventListener('mousedown', this.handleDocumentMouseDown);
            this.setState({ openPopup: false });
        };

        private handleDocumentMouseDown = (event: MouseEvent) => {
            // eslint-disable-next-line react/no-string-refs
            const popupComp = this.refs[`popup_${main}`] as PopupIcon;
            if (!popupComp) {
                return;
            }
            const popupRef = popupComp.popupRef;

            if (popupRef && !isInRect(event.clientX, event.clientY, popupRef.getBoundingClientRect())) {
                this.closePopup();
            }
        };

        render() {
            const { [main]: value } = this.declarationMapValue;
            const { panelHost, className } = this.props;
            const translate = getTranslate(panelHost);

            if (!value) {
                return null;
            }

            return (
                <PopupIcon
                    className={style(classes.root, className)}
                    iconKey={main}
                    translate={translate}
                    stateOpenPopup={this.state.openPopup}
                    openPopup={this.openPopup}
                    SelectedIcon={this.getPopupIconIcon(value)}
                    tooltipKey={tooltipKey}
                    MainIcon={MainIcon}
                    ref={`popup_${main}`}
                >
                    <OptionList
                        className={classes.optionList}
                        value={value}
                        options={options}
                        onSelect={(value) => {
                            this.onPopupIconChange(
                                controllerToVisualizerChange(
                                    {
                                        [main]: value,
                                    } as GenericDeclarationMap<MAIN>,
                                    this.props
                                )
                            );
                        }}
                        onClose={this.closePopup}
                    />
                </PopupIcon>
            );
        }
    };
}
