import React from 'react';
import chroma from 'chroma-js';
import type { GradientAST } from 'gradient-parser';

import {
    CSSCodeAst,
    Backgrounds,
    BackgroundLayer as BackgroundLayerGeneric,
    createCssValueAST,
    evaluateAst,
    getShorthandLayers,
    INITIAL_IMAGE_SOURCE,
} from '@wixc3/shorthands-opener';

import { Solid, Gradient as GradientIcon, Image } from '@wixc3/stylable-panel-common-react';
import {
    StylablePanelTranslationKeys,
    CalculatedBackgroundLayers,
    verifiedExpandGradient,
    gradientFromAST,
    parseGradient,
    solidGradient,
    parseSolidGradient,
    stringifyGradient,
    normalizeGradientColorStop,
    FullDeclarationMap,
    GenericDeclarationMap,
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wixc3/stylable-panel-drivers';
import { Option, Tooltip, BackgroundBox, OptimisticWrapper } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';

import type { DeclarationVisualizerProps, VisualizerComponent, VisualizerComponentClass } from '../../types';
import type { OriginNode, ParseShorthandAPI, EvaluatedAst, OpenedDeclarationArray } from '../../declaration-types';
import {
    stringifyBackgroundImage,
    getShorthandOpener,
    visualizerOptimisticValueResolver,
    getDeclarationText,
    evalOpenedDeclarationValue,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    controllerToVisualizerChange,
    createShorthandOpenerApi,
    sanitizeOpenedDeclaration,
    compareVisualizerValues,
    createDeclarationVisualizer,
} from '../../utils';
import { LayersController /*, { LAYER_HIDDEN_PROP }*/ } from '../layers-controller/layers-controller';

import {
    FillPicker,
    FillPickerProps,
    FillPickerTabs,
    FillPickerSectionConfig,
} from '../../pickers/fill-picker/fill-picker';

import { PanelEventList } from '../../hosts/bi';
import { getTranslate } from '../../hosts/translate';

import { getDimensionUnits, OpacityVisualizer } from '../../generated-visualizers';

import { style, classes } from './background-visualizer.st.css';

const DEFAULT_BACKGROUND_COLOR = 'transparent';

enum BackgroundLayer {
    Solid = 0,
    Gradient,
    Image,
}

const addLayerMenuOptions: Option[] = [
    { id: 'solid', displayName: 'Solid', icon: Solid },
    { id: 'gradient', displayName: 'Gradient', icon: GradientIcon },
    { id: 'image', displayName: 'Image', icon: Image },
];

interface BackgroundVisualizerState {
    fillPickerIndex: number;
}

function constrainBackgroundImageForPreview(value: FullDeclarationMap): FullDeclarationMap {
    const retVal = Object.assign({}, value);
    if (retVal['background-repeat'] && retVal['background-repeat'] === 'no-repeat') {
        retVal['background-size'] = 'cover';
        retVal['background-position'] = 'center';
    } else {
        retVal['background-size'] = '80%';
        retVal['background-position'] = '0% 0%';
        retVal['background-repeat'] = 'repeat';
    }
    return retVal;
}

type BackgroundShorthandLayer = BackgroundLayerGeneric<OriginNode>;

const getCodeAstText = (ast: CSSCodeAst) => ast.text;
const getSimpleAstText = (simpleValue: EvaluatedAst) => getCodeAstText(simpleValue.value);
const getMultipleCodeAstText = (multipleAst: CSSCodeAst[]) =>
    multipleAst.map((value) => getCodeAstText(value)).join(' ');
const getMultipleAstText = (multipleValue: EvaluatedAst[]) =>
    multipleValue.map((value) => getSimpleAstText(value)).join(' ');
const getSimpleLayerPropText = (prop: keyof BackgroundShorthandLayer, layer: BackgroundShorthandLayer) =>
    getSimpleAstText(layer[prop] as EvaluatedAst);
const getMultipleLayerPropText = (prop: keyof BackgroundShorthandLayer, layer: BackgroundShorthandLayer) =>
    getMultipleAstText(layer[prop] as EvaluatedAst[]);
const getBackgroundImagesLayer = (layer: BackgroundShorthandLayer) =>
    ({
        'background-attachment': getSimpleLayerPropText('background-attachment', layer),
        'background-clip': getSimpleLayerPropText('background-clip', layer),
        'background-image': getSimpleLayerPropText('background-image', layer),
        'background-origin': getSimpleLayerPropText('background-origin', layer),
        'background-position': getMultipleLayerPropText('background-position', layer),
        'background-repeat': getMultipleLayerPropText('background-repeat', layer),
        'background-size': getMultipleLayerPropText('background-size', layer),
    } as Record<Backgrounds, string>);

export type BackgroundLonghandProps = Backgrounds | 'background-position-x' | 'background-position-y';
export type BackgroundProps = BackgroundLonghandProps | 'background';

export interface BackgroundVisualizerProps extends DeclarationVisualizerProps<BackgroundProps> {
    inlineLayers?: boolean;
}

type BackgroundDeclarationMap = GenericDeclarationMap<BackgroundProps>;
export type BackgroundVisualizerValue = OpenedDeclarationArray<BackgroundProps>;
const BACKGROUND_LONGHAND_PROPS: BackgroundLonghandProps[] = [
    'background-attachment',
    'background-clip',
    'background-color',
    'background-image',
    'background-origin',
    'background-position',
    'background-position-x',
    'background-position-y',
    'background-repeat',
    'background-size',
];

export const EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE = BACKGROUND_LONGHAND_PROPS.reduce(
    (emptyChangeValue, prop) => ({ ...emptyChangeValue, [prop]: '' }),
    {} as Record<BackgroundLonghandProps, string>
);

export class BackgroundVisualizerInner
    extends React.Component<BackgroundVisualizerProps, BackgroundVisualizerState>
    implements VisualizerComponent<BackgroundProps>
{
    public static defaultProps: Partial<BackgroundVisualizerProps> = {
        inlineLayers: true,
    };
    public static BLOCK_VARIANT_CONTROLLER = false;
    public static INPUT_PROPS: BackgroundProps[] = ['background', ...BACKGROUND_LONGHAND_PROPS];
    public static AST_VALUE = true;

    private shorthandApi: ParseShorthandAPI;
    private backgroundLayers!: CalculatedBackgroundLayers;
    private layerTypes!: BackgroundLayer[];
    private shouldOpenFillPicker = false;

    constructor(props: BackgroundVisualizerProps) {
        super(props);
        this.state = { fillPickerIndex: -1 };

        const {
            drivers: { variables },
        } = props;
        this.shorthandApi = createShorthandOpenerApi(variables);
        this.setBackgroundLayers(this.props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(newProps: BackgroundVisualizerProps, newState: BackgroundVisualizerState) {
        const {
            value,
            drivers: { variables },
        } = newProps;
        this.shorthandApi = createShorthandOpenerApi(variables);
        this.setBackgroundLayers(newProps);
        if (this.shouldOpenFillPicker) {
            this.openFillPicker(0);
            this.shouldOpenFillPicker = false;
            return;
        }

        const { fillPickerIndex } = newState;

        if (
            compareVisualizerValues(value, this.props.value, newProps) &&
            fillPickerIndex !== -1 &&
            this.layerTypes[fillPickerIndex] === BackgroundLayer.Image
        ) {
            this.openFillPicker(fillPickerIndex);
        }
    }

    public render() {
        const { inlineLayers, plane = DEFAULT_PLANE, panelHost, className } = this.props;

        const translate = getTranslate(panelHost);

        const showSolidLayer = !!this.backgroundLayers.solid || this.backgroundLayers.images.length === 0;

        return (
            <div className={style(classes.root, { plane }, className)}>
                <LayersController
                    className={classes.layersController}
                    title={translate(StylablePanelTranslationKeys.controller.fills.title)}
                    addLayerLabel={translate(StylablePanelTranslationKeys.controller.fills.addLayerButtonLabel)}
                    addOptions={!inlineLayers ? addLayerMenuOptions : []}
                    plane={plane}
                    panelHost={panelHost}
                    onAdd={(id) => this.addNewBackgroundLayer(id)}
                    onReplace={(srcIndex, dstIndex) => this.replaceBackgroundLayers(srcIndex, dstIndex)}
                    onDuplicate={(index) => this.duplicateBackgroundLayer(index)}
                    onRemove={(index) => this.removeBackgroundLayer(index)}
                    // onHide={(hidden, index) => this.showHideBackgroundLayer(hidden, index)}
                >
                    {this.backgroundLayers.images.map((val, index) => {
                        const type = this.layerTypes[index];
                        return this.renderBackgroundLayer(type, val, index);
                    })}
                    {showSolidLayer
                        ? this.renderBackgroundLayer(
                              BackgroundLayer.Solid,
                              { background: this.backgroundLayers.solid! },
                              this.backgroundLayers.images.length
                          )
                        : null}
                </LayersController>
            </div>
        );
    }

    // TODO: This should partially be extracted to openBackgroundDeclarationList and used to set this.openedDeclarationList
    private calcBackgroundLayers(props: BackgroundVisualizerProps): CalculatedBackgroundLayers {
        const { value, siteVarsDriver } = props;

        let backgroundLayers: CalculatedBackgroundLayers = { images: [] };
        let numImageLayers = 0;

        for (const decl of value) {
            if (decl.value.length === 0) {
                continue;
            }

            const shorthandLayers = getShorthandLayers(
                evaluateAst(sanitizeOpenedDeclaration(decl).value, this.shorthandApi)
            );

            switch (decl.name) {
                case 'background': {
                    backgroundLayers = { images: [] };

                    shorthandLayers.forEach((layer, index) => {
                        const evalDeclarationValue =
                            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ??
                            DEFAULT_EVAL_DECLARATION_VALUE;
                        const shorthand = evalOpenedDeclarationValue(
                            layer.map((ast) => ast.value),
                            evalDeclarationValue
                        );
                        const opener = getShorthandOpener('background');
                        let imagesLayer: Record<string, string> = {};

                        try {
                            const opened = opener(shorthand, this.shorthandApi);

                            if (index === shorthandLayers.length - 1) {
                                backgroundLayers.solid = getSimpleAstText(opened.color);
                            }

                            imagesLayer = getBackgroundImagesLayer(opened.layers[0]);
                        } catch (e) {
                            console.warn(e);
                            imagesLayer = {
                                background: getMultipleCodeAstText(shorthand as CSSCodeAst[]),
                            };
                        }

                        backgroundLayers.images.push(imagesLayer);
                    });

                    numImageLayers = backgroundLayers.images.length;

                    break;
                }
                case 'background-color': {
                    backgroundLayers.solid = getSimpleAstText(shorthandLayers[0][0]);

                    break;
                }
                case 'background-image': {
                    shorthandLayers.forEach((value, i) => {
                        if (!backgroundLayers.images[i]) {
                            backgroundLayers.images[i] = {};
                        }

                        backgroundLayers.images[i]['background-image'] = getSimpleAstText(value[0]);
                    });

                    numImageLayers = shorthandLayers.length;

                    break;
                }
                case 'background-repeat':
                case 'background-attachment':
                case 'background-position':
                case 'background-clip':
                case 'background-origin':
                case 'background-size': {
                    for (let i = 0; i < Math.max(shorthandLayers.length, backgroundLayers.images.length); i++) {
                        if (!backgroundLayers.images[i]) {
                            backgroundLayers.images[i] = {};
                        }

                        const layers = shorthandLayers[i % shorthandLayers.length];
                        backgroundLayers.images[i][decl.name] = getSimpleAstText(layers[0]);
                        if (decl.name === 'background-position' && layers[1]) {
                            backgroundLayers.images[i][decl.name] += ` ${getSimpleAstText(layers[1])}`;
                        }
                    }

                    break;
                }
            }
        }

        backgroundLayers.images = backgroundLayers.images.slice(0, numImageLayers);

        if (
            backgroundLayers.images.length > 0 &&
            backgroundLayers.images[backgroundLayers.images.length - 1]['background-image'] === INITIAL_IMAGE_SOURCE
        ) {
            // Delete last empty layer (if it only had background-color)
            backgroundLayers.images.pop();
        }

        return backgroundLayers;
    }

    public setBackgroundLayers(props: BackgroundVisualizerProps) {
        this.backgroundLayers = this.calcBackgroundLayers(props);
        if (this.backgroundLayers.solid === DEFAULT_BACKGROUND_COLOR) {
            delete this.backgroundLayers.solid;
        }
        const showSolidLayer = !!this.backgroundLayers.solid || this.backgroundLayers.images.length === 0;
        this.layerTypes = this.backgroundLayers.images.map((layerValue) => this.classifyImageLayer(layerValue));
        if (showSolidLayer) {
            this.layerTypes.push(BackgroundLayer.Solid);
        }
    }

    private openFillPicker(fillPickerIndex: number) {
        const { drivers, siteVarsDriver, panelHost } = this.props;

        if (!panelHost?.onOpenPanel) {
            return;
        }

        const translate = getTranslate(panelHost);

        const solid = this.layerTypes[fillPickerIndex] === BackgroundLayer.Solid;
        let layerValue: string | undefined;
        let index = fillPickerIndex;
        if (solid) {
            layerValue = this.backgroundLayers.solid;
            index = this.backgroundLayers.images.length;
        } else {
            const value = this.backgroundLayers.images[fillPickerIndex];
            layerValue =
                value && typeof value !== 'string' ? stringifyBackgroundImage(value.background || value) : value;
        }
        let fillPickerTab = this.layerTypes[fillPickerIndex] as any as FillPickerTabs;
        let fillPickerValue = layerValue;
        if (layerValue && this.layerTypes[fillPickerIndex] === BackgroundLayer.Gradient) {
            const expanded = verifiedExpandGradient(layerValue);
            if (expanded) {
                const gradientAST = expanded['background-image'] as GradientAST;
                const colorStops = gradientAST.colorStops.map((stop) => normalizeGradientColorStop(stop));
                if (
                    gradientAST.type === 'linear-gradient' &&
                    colorStops.length === 2 &&
                    colorStops[0] === colorStops[1]
                ) {
                    fillPickerValue = colorStops[0];
                    fillPickerTab = FillPickerTabs.Solid;
                }
            }
        }

        const fillPickerProps: FillPickerProps = {
            // key: `fill_picker_${index}`,
            className: classes.fillPicker,
            title: translate(StylablePanelTranslationKeys.controller.fills.fillPickerTitle),
            siteVarsDriver,
            value: fillPickerValue,
            tab: fillPickerTab,
            drivers,
            panelHost,
            onChange: (value: string | null, tab: FillPickerTabs) => {
                if (this.layerTypes[index] === BackgroundLayer.Solid) {
                    this.changeBackgroundColor(value);
                } else {
                    this.changeBackgroundLayer(tab, value, index);
                }
            },
            onClose: () => this.setState({ fillPickerIndex: -1 }),
        };

        panelHost.onOpenPanel(FillPicker.panelName, fillPickerProps);
        if (fillPickerIndex !== this.state.fillPickerIndex) {
            this.setState({ fillPickerIndex });
        }
    }

    private renderBackgroundLayer(type: BackgroundLayer, value: FullDeclarationMap, index: number) {
        const { drivers, panelHost } = this.props;
        const { fillPickerIndex } = this.state;
        const translate = getTranslate(panelHost);

        const solid = type === BackgroundLayer.Solid;

        const units = getDimensionUnits({ id: DIMENSION_ID.OPACITY, dimensionUnits: panelHost?.dimensionUnits });

        const layerValueBase = value.background || value;
        const layerValue = stringifyBackgroundImage(layerValueBase);
        let layerPreviewStyle: string | undefined = `${layerValue}`;

        if (type === BackgroundLayer.Image) {
            layerPreviewStyle = stringifyBackgroundImage(constrainBackgroundImageForPreview(value));
        }

        let opacityValue = 100;
        let opacityDisabled = true;
        let opacityChange: (value: string) => void = () => null;
        let noColor = false;

        // TODO: Try to make this happen once, and use in renderFillPicker
        if (layerValue && type === BackgroundLayer.Gradient) {
            const expanded = verifiedExpandGradient(layerValue);
            if (expanded) {
                const gradientAST = expanded['background-image'] as GradientAST;
                const colorStops = gradientAST.colorStops.map((stop) => normalizeGradientColorStop(stop));
                try {
                    opacityValue = Math.max(
                        ...colorStops.map((stop) => Math.floor((chroma(stop).alpha() as any as number) * 100))
                    );
                    opacityChange = (val) => this.changeBackgroundGradientAlpha(expanded, val, index);
                    opacityDisabled = false;
                } catch {
                    //
                }
            }
        } else if (solid) {
            try {
                const color = chroma(layerValue || '');
                opacityValue = Math.floor((color.alpha() as any as number) * 100);
            } catch {
                //
            }
            opacityChange = (val) => this.changeBackgroundColorAlpha(val);
            if (!layerValue) {
                noColor = true;
            } else {
                opacityDisabled = false;
            }
        }

        // TODO: Wrap solid layers with propertyWrappers
        return (
            <span
                key={`background_layer_${index}`}
                className={classes.backgroundLayer} /*{...{[LAYER_HIDDEN_PROP]: hidden}}*/
            >
                <Tooltip
                    className={classes.backgroundTooltip}
                    text={translate(StylablePanelTranslationKeys.controller.fills.layerThumbnailTooltip)}
                    verticalAdjust={-1}
                    horizontalAdjust={-5}
                >
                    <BackgroundBox
                        className={classes.backgroundBox}
                        value={layerPreviewStyle}
                        selected={index === fillPickerIndex}
                        noColor={noColor}
                        onClick={() => this.openFillPicker(index)}
                        data-aid="st_backgroundcontroller_backgroundbox"
                    />
                </Tooltip>
                {noColor || !opacityDisabled ? (
                    <OpacityVisualizer
                        drivers={drivers}
                        className={classes.opacityInput}
                        value={createVisualizerValueFromDeclarationMap({
                            opacity: `${opacityValue}%`,
                        })}
                        config={{ units }}
                        isDisabled={opacityDisabled}
                        onChange={(value) => {
                            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (opacity) {
                                opacityChange(opacity);
                            }
                        }}
                    />
                ) : (
                    <div className={classes.imagePlaceholder} />
                )}
            </span>
        );
    }

    private addNewBackgroundLayer = (id?: string) => {
        const { onChange, inlineLayers } = this.props;

        if (!onChange || (!id && !inlineLayers)) {
            return;
        }

        let defaultValue = '';
        switch (id) {
            case 'solid':
                defaultValue = this.getSolidNewValue();
                break;
            case 'gradient':
                defaultValue = FillPickerSectionConfig[FillPickerTabs.Gradient].defaultValue;
                break;
            case 'image':
                defaultValue = FillPickerSectionConfig[FillPickerTabs.Image].defaultValue;
                break;
            case undefined:
                defaultValue = this.getSolidNewValue();
                this.shouldOpenFillPicker = true;
                break;
        }

        this.addLayerChange(defaultValue);
    };

    private addLayerChange(value: string) {
        const { panelHost, onChange } = this.props;

        if (!onChange) {
            return;
        }

        const backgroundLayersString = this.stringifyBackgroundLayers();

        const { ADD_LAYER_CLICK } = PanelEventList;
        panelHost?.reportBI && panelHost?.reportBI(ADD_LAYER_CLICK, { origin: 'background' });

        onChange(
            controllerToVisualizerChange(
                {
                    background: backgroundLayersString ? `${value}, ${backgroundLayersString}` : value,
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    }

    private getSolidNewValue() {
        return !this.backgroundLayers.solid && this.backgroundLayers.images.length === 0
            ? FillPickerSectionConfig[FillPickerTabs.Solid].defaultValue
            : solidGradient(FillPickerSectionConfig[FillPickerTabs.Solid].defaultValue);
    }

    private stringifyBackgroundLayers() {
        const { siteVarsDriver } = this.props;
        const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
        const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

        loadSiteColors();

        const imageLayers = this.stringifyImageLayers(this.backgroundLayers.images);
        if (this.backgroundLayers.solid) {
            const solid = wrapSiteColor(this.backgroundLayers.solid);
            return `${imageLayers ? imageLayers + ', ' : ''}${solid}`;
        }

        return imageLayers;
    }

    private stringifyImageLayers(images: FullDeclarationMap[]) {
        const { siteVarsDriver } = this.props;
        const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;

        return images
            .map((layer) => {
                const type = this.classifyImageLayer(layer);
                if (type === BackgroundLayer.Gradient) {
                    const gradientProp = layer.background ? 'background' : 'background-image';
                    const gradient = parseGradient(layer[gradientProp]);
                    if (gradient) {
                        for (const colorStop of gradient.colorStops) {
                            colorStop.color = wrapSiteColor(colorStop.color);
                        }

                        const newLayer = { ...layer };
                        newLayer[gradientProp] = stringifyGradient(gradient, false);
                        return stringifyBackgroundImage(newLayer.background || newLayer || layer);
                    }
                }

                return stringifyBackgroundImage(layer.background || layer);
            })
            .join(', ');
    }

    private classifyImageLayer(value: FullDeclarationMap): BackgroundLayer {
        let type: BackgroundLayer = BackgroundLayer.Gradient;

        if (
            value.background === undefined &&
            value['background-image'] !== undefined &&
            !~value['background-image'].indexOf('gradient')
        ) {
            type = BackgroundLayer.Image;
        }

        return type;
    }

    private changeBackgroundLayer = (tab: FillPickerTabs, value: string | null, index: number) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        let setValue = value || '';
        if (value && !~value.indexOf('linear-gradient') && tab === FillPickerTabs.Solid) {
            setValue =
                index !== this.backgroundLayers.images.length - 1 || !!this.backgroundLayers.solid
                    ? solidGradient(value)
                    : value;
        }
        this.backgroundLayers.images[index].background = setValue;

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    };

    private changeBackgroundColor = (value: string | null) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (value) {
            this.backgroundLayers.solid = value;
        } else {
            delete this.backgroundLayers.solid;
        }

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    };

    private changeBackgroundColorAlpha(value: string) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        try {
            const color = chroma(this.backgroundLayers.solid || '');
            const numberValue = parseFloat(value);
            this.backgroundLayers.solid = color.alpha(numberValue / 100).css();

            onChange(
                controllerToVisualizerChange(
                    {
                        background: this.stringifyBackgroundLayers(),
                        ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                    } as BackgroundDeclarationMap,
                    this.props
                )
            );
        } catch {
            //
        }
    }

    private changeBackgroundGradientAlpha(
        expandedGradient: Record<string, string | GradientAST>,
        value: string,
        index: number
    ) {
        const { onChange } = this.props;

        if (!onChange || parseFloat(value) === 0) {
            return;
        }

        const gradient = gradientFromAST(expandedGradient['background-image'] as GradientAST);
        const maxOpacity = Math.max(
            ...gradient.colorStops.map((stop) => Math.floor((chroma(stop.color).alpha() as any as number) * 100))
        );
        const multiplier = parseFloat(value) / maxOpacity;
        for (const colorStop of gradient.colorStops) {
            const currentColor = chroma(colorStop.color);
            const newAlpha = parseFloat(((currentColor.alpha() as any as number) * multiplier).toFixed(2));
            colorStop.color = currentColor.alpha(newAlpha).css();
        }

        this.backgroundLayers.images[index] = {
            ...expandedGradient,
            'background-image': stringifyGradient(gradient, false),
        };

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    }

    private replaceBackgroundLayers(srcIndex: number, dstIndex: number) {
        const { onChange } = this.props;

        if (!onChange || srcIndex === dstIndex) {
            return;
        }

        const allLayers = [...this.backgroundLayers.images];
        if (this.backgroundLayers.solid) {
            allLayers.push({ background: solidGradient(this.backgroundLayers.solid) });
        }

        const srcLayer = { ...allLayers[srcIndex] };
        allLayers[srcIndex] = allLayers[dstIndex];
        allLayers[dstIndex] = srcLayer;

        delete this.backgroundLayers.solid;
        this.backgroundLayers.images = allLayers;

        const lastSolidColor = this.getLastSolidColor();
        if (lastSolidColor) {
            this.backgroundLayers.images.splice(this.backgroundLayers.images.length - 1, 1);
            this.backgroundLayers.solid = lastSolidColor;
        }

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    }

    private duplicateBackgroundLayer(index: number) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (this.backgroundLayers.solid && index === this.backgroundLayers.images.length) {
            this.backgroundLayers.images.splice(this.backgroundLayers.images.length, 0, {
                background: solidGradient(this.backgroundLayers.solid),
            });
        } else if (this.backgroundLayers.images.length > 0) {
            this.backgroundLayers.images.splice(index, 0, this.backgroundLayers.images[index]);
        }

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    }

    private removeBackgroundLayer(index: number) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (index === this.backgroundLayers.images.length) {
            delete this.backgroundLayers.solid;
        } else {
            this.backgroundLayers.images.splice(index, 1);
        }

        if (!this.backgroundLayers.solid && this.backgroundLayers.images.length > 0) {
            const solidColor = this.getLastSolidColor();
            if (solidColor) {
                this.backgroundLayers.solid = solidColor;
                this.backgroundLayers.images.splice(this.backgroundLayers.images.length - 1, 1);
            }
        }

        onChange(
            controllerToVisualizerChange(
                {
                    background: this.stringifyBackgroundLayers(),
                    ...EMPTY_BACKGROUND_LONGHANDS_CHANGE_VALUE,
                } as BackgroundDeclarationMap,
                this.props
            )
        );
    }

    private getLastSolidColor() {
        return parseSolidGradient(
            this.backgroundLayers.images[this.backgroundLayers.images.length - 1].background ||
                this.backgroundLayers.images[this.backgroundLayers.images.length - 1]['background-image']
        );
    }

    // private showHideBackgroundLayer(hidden: boolean, index: number) {

    // }
}

const backgroundVisualizerOptimisticValueResolver = (
    targetValue: BackgroundVisualizerValue | undefined,
    sourceValue: BackgroundVisualizerValue
) => {
    const resolvedValue = visualizerOptimisticValueResolver(targetValue, sourceValue);
    return resolvedValue.reduce((wrappedValue, value) => {
        let valueAST = value.value;
        if (value.name === 'background' && value.value.length === 1) {
            const declarationText = getDeclarationText(value);
            if (declarationText) {
                valueAST = createCssValueAST(declarationText);
            }
        }
        wrappedValue.push({
            ...value,
            value: valueAST,
        });
        return wrappedValue;
    }, [] as OpenedDeclarationArray<BackgroundProps>);
};

export const BackgroundVisualizer = OptimisticWrapper<
    React.ComponentClass<BackgroundVisualizerProps>,
    BackgroundVisualizerValue
>(BackgroundVisualizerInner, {}, true, backgroundVisualizerOptimisticValueResolver);
(BackgroundVisualizer as VisualizerComponentClass).AST_VALUE = true;

export const BackgroundDeclarationVisualizer = createDeclarationVisualizer<BackgroundProps>(
    'background',
    BackgroundVisualizer
);
