/* eslint-disable react/no-string-refs */
import React from 'react';
import keycode from 'keycode';
import type { CustomInput, CustomInputPreview, ValueInputComponent, ValueInputComponentClass } from '../../drivers';
import type { ValueInputProps } from '../../types';
import type { ContextInput } from '../value-inputs/context-input/context-input';
import { style, classes } from './custom-input-wrapper.st.css';

export interface CustomInputWrapperState {
    showPopup: boolean;
}

// TODO: BaseInput Suite
export function CustomInputWrapperFactory(
    name: string,
    Preview: CustomInputPreview,
    Input: CustomInput,
    InputComp: ValueInputComponentClass,
    inline = false
): React.ComponentClass<ValueInputProps> {
    class CustomInputWrapper
        extends React.Component<ValueInputProps, CustomInputWrapperState>
        implements ValueInputComponent
    {
        public static type = 'CustomInputWrapper';
        public static inputCompType = InputComp.type;

        public state: CustomInputWrapperState = { showPopup: false };

        public suggestionsShown() {
            return (this.refs.valueInput as ContextInput).suggestionsShown();
        }
        public suggestionsUsed() {
            return (this.refs.valueInput as ContextInput).suggestionsUsed();
        }
        public focus() {
            (this.refs.valueInput as ContextInput).focus();
        }
        public select() {
            (this.refs.valueInput as ContextInput).select();
        }
        public blur() {
            (this.refs.valueInput as ContextInput).blur();
        }

        public render() {
            const { value, siteVarsDriver, onChange, className } = this.props;

            return (
                <span
                    className={style(classes.root, { inline }, 'customInputWrapper', className)}
                    onClick={(event: React.MouseEvent<HTMLSpanElement>) => event.stopPropagation()}
                >
                    <button className={classes.previewButton} onClick={this.togglePopup}>
                        <Preview className={classes.preview} value={value} />
                    </button>
                    {inline && this.renderValueInput()}
                    {this.state.showPopup && (
                        <div className={classes.popup} ref="popup">
                            {!inline && <div className={classes.popupTitle}>{name}:</div>}
                            <Input
                                className={classes.customInput}
                                value={value || ''}
                                siteVarsDriver={siteVarsDriver}
                                onChange={onChange || (() => null)}
                            />
                        </div>
                    )}
                    {!inline && this.renderValueInput()}
                </span>
            );
        }

        public componentWillUnmount() {
            this.cleanupEventListeners();
        }

        private renderValueInput() {
            const { value, sheetDriver, siteVarsDriver, onChange, onKeyDown, onBlur, contexts } = this.props;

            return (
                <InputComp
                    className={classes.inputComp}
                    value={value}
                    sheetDriver={sheetDriver}
                    siteVarsDriver={siteVarsDriver}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    contexts={contexts}
                    ref="valueInput"
                />
            );
        }

        private togglePopup = (_event: React.MouseEvent<HTMLSpanElement>) => {
            if (!this.state.showPopup) {
                this.openPopup();
            } else {
                this.closePopup();
            }
        };

        private openPopup() {
            if (!inline) {
                document.addEventListener('click', this.handleClick);
                document.addEventListener('keydown', this.handleKeyDown);
            }
            this.setState({ showPopup: true });
        }

        private closePopup() {
            if (!inline) {
                this.cleanupEventListeners();
            }
            this.setState({ showPopup: false });
        }

        private cleanupEventListeners() {
            document.removeEventListener('click', this.handleClick);
            document.removeEventListener('keydown', this.handleKeyDown);
        }

        private handleClick = (event: MouseEvent) => {
            const popupRect = (this.refs.popup as HTMLDivElement).getBoundingClientRect();
            if (
                event.clientX >= popupRect.left &&
                event.clientX <= popupRect.left + popupRect.width &&
                event.clientY >= popupRect.top &&
                event.clientY <= popupRect.top + popupRect.height
            ) {
                return;
            }
            this.closePopup();
        };

        private handleKeyDown = (event: KeyboardEvent) => {
            if (event.keyCode === keycode('esc')) {
                this.closePopup();
            }
        };
    }

    return CustomInputWrapper;
}
