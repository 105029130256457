import { ValueInputDriver } from '../drivers';
import { ArrowPreview, ContextInput, MarginInput } from '../inputs';

import { ColorInput, MixinInput, NumberInput, PaddingInput } from '../inputs/value-inputs';

export function valueInputSetupProps() {
    const valueInputDriver = new ValueInputDriver(ContextInput);
    valueInputDriver.registerInput(
        [
            'color',
            'background-color',
            'border-color',
            'border-bottom-color',
            'border-left-color',
            'border-right-color',
            'border-top-color',
            'outline-color',
        ],
        ColorInput
    );
    valueInputDriver.registerInput(
        [
            'font-size',
            'margin',
            'margin-top',
            'margin-right',
            'margin-bottom',
            'margin-left',
            'padding',
            'padding-top',
            'padding-right',
            'padding-bottom',
            'padding-left',
            'line-height',
            'border-width',
            'width',
            'min-width',
            'max-width',
            'height',
            'min-height',
            'max-height',
            'top',
            'right',
            'bottom',
            'left',
            'border-radius',
        ],
        NumberInput
    );
    valueInputDriver.registerInput('-st-mixin', MixinInput);

    valueInputDriver.registerCustomInput('margin', ArrowPreview, MarginInput, true);
    valueInputDriver.registerCustomInput('padding', ArrowPreview, PaddingInput, true);

    return valueInputDriver;
}
