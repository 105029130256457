import React from 'react';
import { parse as parseBoxShadow, BoxShadow } from 'css-box-shadow';

import {
    CompositeBlock,
    DimensionInput,
    RadioGroup,
    RadioButtonProps,
    BackgroundBox,
    OptimisticWrapper,
    parseNumberUnit,
} from '@wixc3/stylable-panel-components';
import { StylablePanelTranslationKeys, DEFAULT_EVAL_DECLARATION_VALUE } from '@wixc3/stylable-panel-drivers';
import {
    DEGREES_RANGE,
    DIMENSION_ID,
    SINGLE_SHADOW_BLUR,
    SINGLE_SHADOW_DISTANCE,
    SINGLE_SHADOW_SPREAD,
} from '@wixc3/stylable-panel-common';

import type { CustomInputProps } from '../../../drivers';
import type { TranslateFunc } from '../../../types';
import { CommonInput } from '../common-input';
import { ColorPicker, ColorPickerProps, getColorPickerAPI } from '../../../pickers/color-picker/color-picker';
import { getDimensionUnits } from '../../../generated-visualizers';
import { getTranslate } from '../../../hosts/translate';

import { style, classes } from './single-shadow-input.st.css';

// Arbitrary default values:
const DEFAULT_ANGLE = (1 / 2) * Math.PI;
const DEFAULT_DISTANCE = '1px';
const DEFAULT_SPREAD = '0px';
const DEFAULT_BLUR = '0px';
export const DEFAULT_SHADOW_INPUT_COLOR = 'rgba(0,0,0,0.2)';

const EMPTY_COLOR = 'currentcolor';

export enum ShadowTypes {
    OUTER_SHADOW = 'outerShadow',
    INNER_SHADOW = 'innerShadow',
}

export interface Shadow {
    angle: number;
    distance: string;
    spread: string;
    blur: string;
    color: string;
    inset: boolean;
}

const defaultShadowValues: Shadow = {
    angle: DEFAULT_ANGLE.valueOf(),
    distance: DEFAULT_DISTANCE,
    spread: DEFAULT_SPREAD,
    blur: DEFAULT_BLUR,
    color: DEFAULT_SHADOW_INPUT_COLOR,
    inset: false,
};

// Math functions:
function toDegrees(angle: number): number {
    return angle * (180 / Math.PI);
}

function toRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}

function round(x: number, i: number) {
    i = Math.pow(10, i);
    // default
    return Math.round(x * i) / i;
}

const { innerShadowToggleSectionLabel, outerShadowToggleLabel, innerShadowToggleLabel } =
    StylablePanelTranslationKeys.picker.shadow;
const { OUTER_SHADOW, INNER_SHADOW } = ShadowTypes;

const getToggleInsetControls = (inset: boolean, onChange: () => void, translate: TranslateFunc): RadioButtonProps[] => [
    {
        id: OUTER_SHADOW,
        label: translate(outerShadowToggleLabel),
        checked: !inset,
        onChange,
    },
    {
        id: INNER_SHADOW,
        label: translate(innerShadowToggleLabel),
        checked: inset,
        onChange,
    },
];

function ShadowInput(textShadow?: boolean): React.ComponentClass<CustomInputProps> & { panelName: string } {
    class ShadowInputInner extends CommonInput<{}> {
        public static panelName: string = textShadow ? 'textShadow' : 'boxShadow';

        protected shadowValues: Shadow = Object.assign({}, defaultShadowValues);
        private value = this.props.value;

        public render() {
            return (
                <div className={style(classes.root, this.props.className)}>
                    {this.renderColorController()}
                    <div className={classes.longDivider} />
                    {!textShadow ? this.renderInsetController() : null}
                    {!textShadow ? <div className={classes.longDivider} /> : null}
                    {this.renderDirectionController()}
                    <div className={classes.longDivider} />
                    {this.renderDistanceController()}
                    <div className={classes.longDivider} />
                    {this.renderBlurController()}
                    {!textShadow ? <div className={classes.longDivider} /> : null}
                    {!textShadow ? this.renderSizeController() : null}
                </div>
            );
        }

        protected setValues(): string | undefined {
            if (!this.value || this.value === '') {
                this.shadowValues = Object.assign({}, defaultShadowValues);
                return;
            }

            const parts: BoxShadow[] = parseBoxShadow(this.value);
            const shadowLayer = parts[0];
            const x = shadowLayer.offsetX;
            const y = shadowLayer.offsetY;

            const distance = Math.round(Math.sqrt(x * x + y * y));
            this.shadowValues.distance = distance + 'px';
            if (distance !== 0) {
                this.shadowValues.angle = Math.atan2(y, x);
            }

            this.shadowValues.blur = shadowLayer.blurRadius ? shadowLayer.blurRadius + 'px' : DEFAULT_BLUR;
            this.shadowValues.spread = shadowLayer.spreadRadius ? shadowLayer.spreadRadius + 'px' : '';
            this.shadowValues.color = shadowLayer.color;
            this.shadowValues.inset = shadowLayer.inset;
            return;
        }

        protected getCssStringValue() {
            const dimension = parseNumberUnit(this.shadowValues.distance);
            const newX = round(dimension.value! * round(Math.cos(this.shadowValues.angle), 5), 2);
            const newY = round(dimension.value! * round(Math.sin(this.shadowValues.angle), 5), 2);

            const x = newX + 'px';
            const y = newY + 'px';

            const hasBlur = !isNaN(parseFloat(this.shadowValues.blur));
            const hasSpread = !isNaN(parseFloat(this.shadowValues.spread));

            let blurString = '';
            if (hasSpread) {
                blurString = hasBlur ? this.shadowValues.blur + ' ' : '0 ';
            } else if (hasBlur) {
                blurString = this.shadowValues.blur + ' ';
            }

            const spreadString = hasSpread ? this.shadowValues.spread + ' ' : '';
            const colorString =
                this.shadowValues.color !== '' && this.shadowValues.color !== undefined ? this.shadowValues.color : '';
            return `${
                this.shadowValues.inset ? 'inset ' : ''
            }${x} ${y} ${blurString}${spreadString}${colorString}`.trim();
        }

        private renderColorController() {
            const { siteVarsDriver, panelHost } = this.props;
            const evalDeclarationValue =
                siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

            const translate = getTranslate(panelHost);
            const color = evalDeclarationValue(this.shadowValues.color);
            const noColor = !color || color === EMPTY_COLOR;

            return (
                <CompositeBlock
                    className={classes.colorGroup}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.colorLabel)}
                    singleLine
                >
                    <BackgroundBox
                        className={classes.colorBox}
                        value={color}
                        noColor={noColor}
                        showNoColorDiagonal
                        onClick={() => this.openColorPicker(!noColor ? color : EMPTY_COLOR)}
                    />
                </CompositeBlock>
            );
        }

        private renderInsetController() {
            const translate = getTranslate(this.props.panelHost);

            return (
                <CompositeBlock className={classes.group} title={translate(innerShadowToggleSectionLabel)}>
                    <RadioGroup
                        className={classes.insetGroup}
                        values={getToggleInsetControls(this.shadowValues.inset, this.changeInset, translate)}
                    />
                </CompositeBlock>
            );
        }

        private renderDirectionController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);
            const units = getDimensionUnits({
                id: DIMENSION_ID.SINGLE_SHADOW_DEGREES_RANGE,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: DEGREES_RANGE,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.directionInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementAngle}
                        value={this.getKnobAngle() + 'deg'}
                        config={{ units }}
                        useDisplaySymbol={true}
                        isSlider={true}
                        isSliderCyclicKnob={true}
                        isInputCyclic={true}
                        onChange={this.changeAngle}
                    />
                </CompositeBlock>
            );
        }

        private renderDistanceController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);
            const units = getDimensionUnits({
                id: DIMENSION_ID.SINGLE_SHADOW_DISTANCE,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_DISTANCE,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.distanceInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementDistance}
                        value={this.shadowValues.distance}
                        config={{ units }}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeDistance}
                    />
                </CompositeBlock>
            );
        }

        private renderBlurController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);
            const units = getDimensionUnits({
                id: DIMENSION_ID.SINGLE_SHADOW_BLUR,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_BLUR,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.blurInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementBlur}
                        value={this.shadowValues.blur}
                        config={{ units }}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeBlur}
                    />
                </CompositeBlock>
            );
        }

        private renderSizeController() {
            const { panelHost } = this.props;
            const translate = getTranslate(panelHost);
            const units = getDimensionUnits({
                id: DIMENSION_ID.SINGLE_SHADOW_SPREAD,
                dimensionUnits: panelHost?.dimensionUnits,
                customUnits: SINGLE_SHADOW_SPREAD,
            });

            return (
                <CompositeBlock
                    className={classes.group}
                    title={translate(StylablePanelTranslationKeys.picker.shadow.sizeInputLabel)}
                >
                    <DimensionInput
                        className={classes.inputElementSpread}
                        value={this.shadowValues.spread !== '' ? this.shadowValues.spread : '0px'}
                        config={{ units }}
                        isSlider={true}
                        keepRange={true}
                        onChange={this.changeSpread}
                    />
                </CompositeBlock>
            );
        }

        private openColorPicker(currentColor: string) {
            const { drivers, siteVarsDriver, panelHost } = this.props;

            if (!panelHost?.onOpenPanel) {
                return;
            }

            const translate = getTranslate(panelHost);

            const colorPickerProps: ColorPickerProps = {
                className: classes.colorPicker,
                title: translate(StylablePanelTranslationKeys.controller.shadows.colorPickerTitle),
                ...getColorPickerAPI(currentColor, (value: string) => this.changeColor(value), drivers),
                siteVarsDriver,
                panelHost,
                // onClose: () => this.setState({colorPickerIndex: -1}),
                // onBlur: () => this.setState({colorPickerIndex: -1}),
                // TODO: Handle hover out with no selection
                onHover: (value: string | null) => this.changeColor(value || currentColor),
            };

            panelHost.onOpenPanel(ColorPicker.panelName, colorPickerProps);
        }

        private callChange() {
            const changeValue = this.getCssStringValue();

            this.value = changeValue;
            this.props.onChange(changeValue);
        }

        private changeColor(value: string) {
            this.shadowValues.color = value;
            this.callChange();
            this.forceUpdate();
        }

        private changeInset = () => {
            this.shadowValues.inset = !this.shadowValues.inset;
            this.callChange();
            this.forceUpdate();
        };

        private changeAngle = (value: string) => {
            const number = parseInt(value, 10);
            if (isNaN(number)) {
                return;
            }

            this.shadowValues.angle = toRadians((number + 90) % 360);
            this.callChange();
        };

        private changeDistance = (value: string) => {
            if (parseNumberUnit(value).value === undefined) {
                return;
            }
            this.shadowValues.distance = value;
            this.callChange();
        };

        private changeBlur = (value: string) => {
            this.shadowValues.blur = value;
            this.callChange();
        };

        private changeSpread = (value: string) => {
            this.shadowValues.spread = value;
            this.callChange();
        };

        private getKnobAngle() {
            return ((toDegrees(this.shadowValues.angle) + 270) % 360).toFixed(0);
        }
    }

    return ShadowInputInner;
}

export const SingleShadowInputInner = ShadowInput(false);
export const TextShadowLayerInputInner = ShadowInput(true);

export const SingleShadowInput = OptimisticWrapper(SingleShadowInputInner, {
    onMouseDown: (context) => {
        context.setActive();
    },
    onMouseUp: (context) => {
        context.setIdle();
    },
    onChange: (context, ...args) => {
        context.onChange(...args);
    },
});

export const TextShadowLayerInput = OptimisticWrapper(TextShadowLayerInputInner, {});
