import React from 'react';
import chroma from 'chroma-js';

import FreeformPicker from './freeform-picker';
import { Tabs, SectionDesc } from '../../tabs/tabs';
import { DimensionInput } from '../dimension-input';
import { HSVColor, colorFromHSVColor, hsvColorFromColor, isWhite } from './color-picker-utils';
import { TranslateDriver } from '../../drivers/translate-driver';
import {
    COLOR_ADDER_RGB,
    COLOR_ADDER_HSB,
    COLOR_ADDER_HSB_HUE,
    DimensionUnits,
    DIMENSION_ID,
} from '@wixc3/stylable-panel-common';

import { style, classes } from './color-adder.st.css';

const DEFAULT_COLOR = 'white';

export interface ColorAdderStrings {
    hexTabLabel: string;
    rgbTabLabel: string;
    hsbTabLabel: string;
    cancelButtonLabel: string;
    addButtonLabel: string;
}

const ColorAdderStringsFallbacks: ColorAdderStrings = {
    hexTabLabel: 'HEX',
    rgbTabLabel: 'RGB',
    hsbTabLabel: 'HSB',
    cancelButtonLabel: 'Cancel',
    addButtonLabel: 'Add',
};

export enum ColorAdderTabs {
    HEX = 0,
    RGB = 1,
    HSB = 2,
}

interface ColorAdderSingleSectionConfig {
    id: string;
    titleKey: keyof ColorAdderStrings;
}

const ColorAdderSectionConfig: Record<ColorAdderTabs, ColorAdderSingleSectionConfig> = {
    [ColorAdderTabs.HEX]: { id: 'hex', titleKey: 'hexTabLabel' },
    [ColorAdderTabs.RGB]: { id: 'rgb', titleKey: 'rgbTabLabel' },
    [ColorAdderTabs.HSB]: { id: 'hsb', titleKey: 'hsbTabLabel' },
};

const getColorAdderSections = (translate: (key: keyof ColorAdderStrings) => string) =>
    Object.keys(ColorAdderSectionConfig).map(
        (_key: string, currTab: ColorAdderTabs) =>
            ({
                id: ColorAdderSectionConfig[currTab].id,
                customButton: () => translate(ColorAdderSectionConfig[currTab].titleKey),
            } as SectionDesc)
    );

export interface ColorAdderProps {
    initialColor?: string;

    onCancel?: () => void;
    onAdd?: (value: string) => void;

    translations?: Partial<ColorAdderStrings>;
    units?: DimensionUnits;

    className?: string;
    style?: React.CSSProperties;
    'data-aid'?: string;
}

export interface ColorAdderState {
    color: HSVColor;
    editingHex: string;
    hexError: boolean;
}

export default class ColorAdder extends React.Component<ColorAdderProps, ColorAdderState> {
    public readonly state: ColorAdderState = {
        color: { hue: 0, saturation: 0, value: 100 },
        editingHex: 'FFFFFF',
        hexError: false,
    };
    private initialColor!: chroma.Color;
    private translateDriver: TranslateDriver<ColorAdderStrings>;

    constructor(props: ColorAdderProps, context: any) {
        super(props, context);
        this.translateDriver = new TranslateDriver<ColorAdderStrings>(ColorAdderStringsFallbacks, props.translations);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillMount() {
        const { initialColor } = this.props;

        this.initialColor = chroma(DEFAULT_COLOR);
        if (initialColor) {
            try {
                this.initialColor = chroma(initialColor);
            } catch {
                //
            }
        }

        this.resetInitialColor();
    }

    public render() {
        const { onCancel, onAdd, className, style: propStyle } = this.props;
        const { color, hexError } = this.state;
        const { translate } = this.translateDriver;

        const newColor = colorFromHSVColor(color);

        return (
            <div className={style(classes.root, className)} style={propStyle} data-aid={this.props['data-aid']}>
                <FreeformPicker className={classes.freeformPicker} color={color} onChange={this.handleFreeformChange} />
                <div className={classes.colorCompare}>
                    <span
                        className={style(
                            classes.colorComparePart,
                            {
                                white: isWhite(this.initialColor),
                            },
                            classes.colorCompareCurrent
                        )}
                        style={{ backgroundColor: this.initialColor.hex('rgb') }}
                        onClick={this.resetInitialColor}
                    />
                    <span
                        className={style(
                            classes.colorComparePart,
                            {
                                white: isWhite(newColor),
                            },
                            classes.colorCompareNew
                        )}
                        style={{ backgroundColor: newColor.hex('rgb') }}
                    />
                </div>
                <Tabs
                    className={classes.tabs}
                    sections={getColorAdderSections(translate)}
                    content={this.renderSelectedTabInputs}
                    preferredTab={ColorAdderSectionConfig[ColorAdderTabs.HEX].id}
                    onSelectTab={(index: number) => this.selectTab(index as ColorAdderTabs)}
                    noScroll
                />
                <div className={classes.footer}>
                    <button className={classes.cancelButton} onClick={onCancel}>
                        {translate('cancelButtonLabel')}
                    </button>
                    <button
                        className={classes.addButton}
                        onClick={() => onAdd && onAdd(this.getAddFormat(colorFromHSVColor(color)))}
                        disabled={hexError}
                        data-aid="st_coloradder_addbutton"
                    >
                        {translate('addButtonLabel')}
                    </button>
                </div>
            </div>
        );
    }

    private resetInitialColor = () => {
        this.setState({
            color: hsvColorFromColor(this.initialColor),
            editingHex: this.getEditingHex(this.initialColor),
        });
    };

    private renderSelectedTabInputs = (id: string) => {
        const { units } = this.props;
        const { color: hsvColor, editingHex, hexError } = this.state;
        const color = colorFromHSVColor(hsvColor);

        switch (id) {
            case ColorAdderSectionConfig[ColorAdderTabs.HEX].id:
                return (
                    <input
                        className={style(classes.textInput, { error: hexError })}
                        type="text"
                        value={editingHex}
                        onChange={(event) => this.setColorHex(event.target.value)}
                        onFocus={(event) => event.target.select()}
                        spellCheck={false}
                        // eslint-disable-next-line react/no-string-refs
                        ref="hexInput"
                        data-aid="st_coloradder_input"
                    />
                );
            case ColorAdderSectionConfig[ColorAdderTabs.RGB].id:
                return ['r', 'g', 'b'].map((channel) => (
                    <DimensionInput
                        key={`rgb_${channel}_input`}
                        className={classes.inputElement}
                        value={color.get(`rgb.${channel}`).toString()}
                        config={{ units: units?.[DIMENSION_ID.COLOR_ADDER_RGB] || COLOR_ADDER_RGB }}
                        keepRange={true}
                        isInputCyclic={true}
                        onChange={(value) => this.setColorRgb(channel, value)}
                        inputDataAid="st_coloradder_input"
                    />
                ));
            case ColorAdderSectionConfig[ColorAdderTabs.HSB].id:
                return Object.keys(hsvColor).map((channel) => (
                    <DimensionInput
                        key={`rgb_${channel}_input`}
                        className={classes.inputElement}
                        value={Math.round(hsvColor[channel as keyof HSVColor]).toString()}
                        config={{
                            units:
                                channel === 'hue'
                                    ? units?.[DIMENSION_ID.COLOR_ADDER_HSB_HUE] || COLOR_ADDER_HSB_HUE
                                    : units?.[DIMENSION_ID.COLOR_ADDER_HSB] || COLOR_ADDER_HSB,
                        }}
                        keepRange={true}
                        isInputCyclic={true}
                        onChange={(value) => this.setColorHsb(channel as keyof HSVColor, value)}
                        inputDataAid="st_coloradder_input"
                    />
                ));
        }

        return;
    };

    private handleFreeformChange = (color: HSVColor) => {
        this.setState({
            color,
            editingHex: this.getEditingHex(colorFromHSVColor(color)),
        });
    };

    private selectTab(tab: ColorAdderTabs) {
        const newState = this.state;

        if (tab === ColorAdderTabs.HEX) {
            newState.editingHex = this.getEditingHex(colorFromHSVColor(this.state.color));
        } else {
            newState.hexError = false;
        }

        this.setState(newState);
    }

    private setColorHex = (value: string) => {
        const newState = this.state;

        if (value.startsWith('#')) {
            newState.editingHex = value.slice(1, 7);
        } else {
            newState.editingHex = value.slice(0, 6);
        }
        try {
            newState.color = hsvColorFromColor(chroma(value));
            newState.hexError = false;
        } catch {
            newState.hexError = true;
        }

        this.setState(newState);
    };

    private setColorRgb(channel: string, value: string) {
        this.setState({
            color: hsvColorFromColor(colorFromHSVColor(this.state.color).set(`rgb.${channel}`, value)),
        });
    }

    private setColorHsb(channel: keyof HSVColor, value: string) {
        const newState = this.state;

        newState.color[channel] = parseInt(value || '0', 10);

        this.setState(newState);
    }

    private getAddFormat(color: chroma.Color) {
        return color.hex('rgb').toUpperCase();
    }

    private getEditingHex(color: chroma.Color) {
        return color.hex('rgb').slice(1).toUpperCase();
    }
}
