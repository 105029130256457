import React from 'react';
import { domRect } from '@wixc3/stylable-panel-common-react';
import { OptimisticWrapper } from './optimistic-wrapper';
import { style, classes } from './slider.st.css';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

export interface SliderProps {
    mode?: string; // todo: remove optional
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    knob?: boolean;
    vertical?: boolean;
    disabled?: boolean;
    color?: string;
    onChange?: (value: number) => void;
    className?: string;
    style?: React.CSSProperties;
}

export interface SliderState {
    railActive: boolean;
}

export enum SliderModes {
    HORIZONTAL = 'horizontal',
    ROUNDED = 'rounded',
    VERTICAL = 'vertical',
}

export class SliderInner extends React.Component<SliderProps, SliderState> {
    public readonly state: SliderState = { railActive: false };

    public render() {
        const { knob, vertical, disabled, color, className, style: propStyle } = this.props;
        const { railActive } = this.state;

        return (
            <span
                className={style(
                    classes.root,
                    {
                        mode: knob ? SliderModes.ROUNDED : vertical ? SliderModes.VERTICAL : SliderModes.HORIZONTAL,
                        vertical: !!vertical,
                        knob: !!knob,
                        active: !!railActive,
                        disabled: !!disabled,
                        gradient: color !== undefined,
                    },
                    className
                )}
                style={{ ...this.getRootStyle(), ...propStyle }}
                onMouseDown={this.handleRailMouseDown}
            >
                {this.renderHiddenInput()}
                <span
                    className={style(classes.rail, { active: !!railActive })}
                    style={color !== undefined ? colorSliderStyle(color) : undefined}
                >
                    <span className={style(classes.axis, { showAxis: !!knob })}></span>
                </span>
                <span className={classes.progressRail} style={this.getProgressRailStyle()} />
                <span
                    className={classes.handle}
                    style={this.getHandleStyle()}
                    onMouseDown={this.handleHandleMouseDown}
                    // eslint-disable-next-line react/no-string-refs
                    ref="handle"
                    data-aid="st_slider_handle"
                >
                    <span className={classes.innerHandle}></span>
                </span>
            </span>
        );
    }

    public componentWillUnmount() {
        this.removeEventListeners();
    }

    private renderHiddenInput() {
        const { value, min: min = DEFAULT_MIN, max: max = DEFAULT_MAX, onChange } = this.props;
        return (
            <input
                className={classes.hiddenInput}
                type="range"
                value={value}
                min={min}
                max={max}
                onChange={(event) => onChange && onChange(parseFloat(event.target.value))}
            />
        );
    }

    private handleRailMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (!this.props.disabled) {
            this.changeValue(event);
            this.addEventListeners();
        }
    };

    private handleHandleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        if (!this.props.disabled) {
            event.stopPropagation();
            this.addEventListeners();
        }
    };

    private changeValue = (event: React.MouseEvent<HTMLSpanElement> | MouseEvent) => {
        const { knob } = this.props;
        if (knob) {
            this.changeFromKnobClick(event);
        } else {
            this.changeFromDirectionalClick(event);
        }
    };

    private changeFromDirectionalClick(event: React.MouseEvent<HTMLSpanElement> | MouseEvent) {
        const { vertical, min: min = DEFAULT_MIN, max: max = DEFAULT_MAX, onChange } = this.props;

        if (!onChange) {
            return;
        }

        const clickCoord = vertical ? event.clientY : event.clientX;

        const rootRect = domRect(this);
        const minCoord = vertical ? rootRect.bottom : rootRect.left;
        const maxCoord = vertical ? rootRect.top : rootRect.right;

        if (vertical) {
            if (clickCoord > minCoord) {
                return onChange(min);
            }
            if (clickCoord < maxCoord) {
                return onChange(max);
            }
        } else {
            if (clickCoord < minCoord) {
                return onChange(min);
            }
            if (clickCoord > maxCoord) {
                return onChange(max);
            }
        }

        const clickDiff = Math.abs(clickCoord - minCoord);
        const rootSpan = Math.abs(max - min);
        const rootSize = vertical ? rootRect.height : rootRect.width;

        onChange(Number(this.snapValue(min + (clickDiff / rootSize) * rootSpan).toFixed(2)));
    }

    private changeFromKnobClick(event: React.MouseEvent<HTMLSpanElement> | MouseEvent) {
        const { min: min = DEFAULT_MIN, max: max = DEFAULT_MAX, onChange } = this.props;

        if (!onChange) {
            return;
        }

        const rootRect = domRect(this);
        const clickXDiff = event.clientX - (rootRect.left + rootRect.width / 2);
        const clickYDiff = rootRect.top + rootRect.height / 2 - event.clientY;

        const diffAngle = 90 - Math.atan(clickYDiff / clickXDiff) * (180 / Math.PI) + (clickXDiff >= 0 ? 0 : 180);
        const rootSpan = Math.abs(max - min);

        const changedVal = Number(this.snapValue(Math.round(min + (diffAngle / 360) * rootSpan)).toFixed(2));
        onChange(changedVal);
    }

    private addEventListeners = () => {
        document.addEventListener('mousemove', this.changeValue);
        document.addEventListener('mouseup', this.removeEventListeners);
        this.setState({ railActive: true });
    };

    private removeEventListeners = () => {
        document.removeEventListener('mousemove', this.changeValue);
        document.removeEventListener('mouseup', this.removeEventListeners);
        this.setState({ railActive: false });
    };

    private snapValue(value: number) {
        const { min: min = DEFAULT_MIN, max: max = DEFAULT_MAX, step } = this.props;

        if (!step) {
            return value;
        }

        let highSnap = min + step;
        while (highSnap < value) {
            highSnap += step;
        }
        if (highSnap < value) {
            highSnap = value;
        }
        const lowSnap = highSnap - step;
        if (highSnap > max) {
            highSnap = max;
        }

        const distanceToHigh = Math.abs(value - highSnap);
        const distanceToLow = Math.abs(value - lowSnap);

        if (distanceToLow <= distanceToHigh) {
            return lowSnap;
        }
        return highSnap;
    }

    private getProgressRailStyle() {
        return { [this.props.vertical ? 'height' : 'width']: this.getProgressLocation() };
    }

    private getHandleStyle() {
        const { vertical, knob } = this.props;
        const location = this.getProgressLocation();
        return knob
            ? {
                  left: '50%',
                  transform: `rotate(-${location})`,
              }
            : {
                  [vertical ? 'bottom' : 'left']: location,
              };
    }

    private getRootStyle() {
        if (!this.props.knob) {
            return {};
        }
        return { transform: `rotate(${this.getProgressLocation()})` };
    }

    private getProgressLocation() {
        const { value: propValue, min: min = DEFAULT_MIN, max: max = DEFAULT_MAX, knob } = this.props;
        const value = propValue !== undefined ? propValue : min;

        let snappedValue;

        if (propValue && propValue > max) {
            snappedValue = max;
        } else {
            snappedValue = this.snapValue(value);
        }

        if (snappedValue <= min || min >= max) {
            return knob ? '0deg' : '0%';
        }
        if (snappedValue >= max) {
            return knob ? '360deg' : '100%';
        }

        const relativeValue = Math.abs(snappedValue - min) / Math.abs(max - min);

        return knob ? relativeValue * 360 + 'deg' : relativeValue * 100 + '%';
    }
}

function colorSliderStyle(color: string) {
    const CHECKERED_GRADIENT =
        'linear-gradient(45deg, #ccc 26%, transparent 26%, transparent 74%, #ccc 74%, #ccc 100%)';
    const CHECKERED_BACKGROUND_SIZE = '8px 8px';

    return {
        background: [
            `linear-gradient(to right, transparent, ${color || 'transparent'})`,
            `${CHECKERED_GRADIENT} -1px 3px / ${CHECKERED_BACKGROUND_SIZE}`,
            `${CHECKERED_GRADIENT} 3px 7px / ${CHECKERED_BACKGROUND_SIZE}`,
            'white',
        ].join(', '),
    };
}

export const Slider = OptimisticWrapper(SliderInner, {});
