import React from 'react';

export interface IconProps {
    className?: string;
    style?: React.CSSProperties;
}

export interface DragHandleProps extends IconProps {
    onMouseDown?: React.MouseEventHandler<SVGElement>;
    'data-key'?: string;
}

export const DragHandle: React.FunctionComponent<DragHandleProps> = ({
    className,
    style,
    onMouseDown,
    'data-key': dataKey,
}) => (
    <svg className={className} style={style} onMouseDown={onMouseDown} data-key={dataKey} version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-12.000000, -13.000000)" fill="currentColor">
                <g transform="translate(12.000000, 13.000000)">
                    <path d="M6,9 L8,9 L8,11 L6,11 L6,9 Z M3,9 L5,9 L5,11 L3,11 L3,9 Z M0,9 L2,9 L2,11 L0,11 L0,9 Z M6,6 L8,6 L8,8 L6,8 L6,6 Z M3,6 L5,6 L5,8 L3,8 L3,6 Z M0,6 L2,6 L2,8 L0,8 L0,6 Z M6,3 L8,3 L8,5 L6,5 L6,3 Z M3,3 L5,3 L5,5 L3,5 L3,3 Z M0,3 L2,3 L2,5 L0,5 L0,3 Z M6,0 L8,0 L8,2 L6,2 L6,0 Z M3,0 L5,0 L5,2 L3,2 L3,0 Z M0,0 L2,0 L2,2 L0,2 L0,0 Z" />
                </g>
            </g>
        </g>
    </svg>
);

export const Add: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="31" height="31" viewBox="0 0 31 31">
        <circle cx="15.5" cy="15.5" r="14.5" />
        <path d="M16 15h4v1h-4v4h-1v-4h-4v-1h4v-4h1z" />
    </svg>
);

export const AddSkin: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="22" height="22" viewBox="0 0 22 22">
        <circle cx="11" cy="11" r="10.5" />
        <path d="M4 0L4 8M0 4L8 4" transform="translate(7 7)" stroke="currentColor" />
    </svg>
);

export const GradientKnob: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18">
        <defs>
            <filter
                id="vpsdutwcua"
                width="155.6%"
                height="155.6%"
                x="-27.8%"
                y="-22.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <path
                id="mshlt9g9fb"
                d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 5C6.79 5 5 6.79 5 9s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use fill="#000" filter="url(#vpsdutwcua)" href="#mshlt9g9fb" />
            <use fill="#FFF" href="#mshlt9g9fb" />
        </g>
    </svg>
);

export const GradientKnobOver: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18" viewBox="3 2 18 18">
        <defs>
            <filter
                id="6gc64tprka"
                width="155.6%"
                height="155.6%"
                x="-27.8%"
                y="-22.2%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <filter id="xgclosqb6c" width="200%" height="200%" x="-50%" y="-40%" filterUnits="objectBoundingBox">
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.16862746 0 0 0 0 0.3372549 0 0 0 0 0.44705883 0 0 0 0.43 0"
                />
            </filter>
            <filter
                id="meqwjeyy7e"
                width="171.4%"
                height="171.4%"
                x="-35.7%"
                y="-28.6%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.168627451 0 0 0 0 0.337254902 0 0 0 0 0.447058824 0 0 0 0.43 0"
                />
            </filter>
            <path
                id="j3t6f6jpjb"
                d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 5C6.79 5 5 6.79 5 9s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
            />
            <path
                id="1hr4ncni8d"
                d="M7 2c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 3c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
            />
            <linearGradient id="qy6q5zerlg" x1="50%" x2="50%" y1="100%" y2="0%">
                <stop offset="0%" stopColor="#00D245" />
                <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
            </linearGradient>
            <circle id="7kjb3kiegf" cx="7" cy="7" r="7" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-232 -189) translate(235 191)">
                        <use fill="#FFF" href="#j3t6f6jpjb" />
                    </g>
                    <g>
                        <g transform="translate(-232 -189) translate(235 191) translate(2 2)">
                            <use fill="#000" filter="url(#xgclosqb6c)" href="#1hr4ncni8d" />
                            <use fill="#FFF" href="#1hr4ncni8d" />
                        </g>
                        <g transform="translate(-232 -189) translate(235 191) translate(2 2)">
                            <use fill="#000" filter="url(#meqwjeyy7e)" href="#7kjb3kiegf" />
                            <circle
                                cx="7"
                                cy="7"
                                r="6"
                                fill="url(#qy6q5zerlg)"
                                fillOpacity="0"
                                stroke="#B1DDF8"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const BackArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="5" height="7">
        <path
            d="M4.264 5.617l-.644.766L.223 3.524 3.678.617l.644.766-2.545 2.141z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const ForwardArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={{ transform: 'rotate(180deg)', ...style }} width="5" height="7">
        <path
            d="M4.264 5.617l-.644.766L.223 3.524 3.678.617l.644.766-2.545 2.141z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const DownArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="135.1 -517.4 1024 1024" width="12" height="12">
        <path
            fill="currentColor"
            d="M137.3-195c0 17.4 7.4 34.7 19.8 47.1l441.4 409.1c27.3 24.8 71.9 24.8 99.2 0l438.9-429c27.3-24.8 27.3-66.9 0-91.7-27.3-24.8-71.9-24.8-99.2 0L648.1 122.3 256.3-242.2c-27.3-24.8-71.9-24.8-99.2 0-12.4 14.9-19.8 29.8-19.8 47.2z"
        />
    </svg>
);

export const SmallDownArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="9px" height="7px" viewBox="0 0 11 7">
        <g>
            <path
                d="M10.073 1.69L9.345.926 5.073 5.4.802.927l-.729.762 5 5.238z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export const MoreStates: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M13.75,9 L17.25,12.5 L13.75,16 L13,15.251 L15.751,12.5 L13,9.751 L13.75,9 Z M9.75,9 L13.25,12.5 L9.75,16 L9,15.251 L11.751,12.5 L9,9.751 L9.75,9 Z"
        />
    </svg>
);

export const MoreActions: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M9,11 L9,13 L7,13 L7,11 L9,11 Z M13,11 L13,13 L11,13 L11,11 L13,11 Z M17,11 L17,13 L15,13 L15,11 L17,11 Z"
        />
    </svg>
);

export const Checkmark: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 41 32">
        <path d="M0.169 17.815c0.169 1.098 0.76 2.111 1.689 2.871l14.269 10.385c1.942 1.435 4.644 1.013 6.079-0.844l18.069-23.303c1.435-1.858 1.098-4.559-0.844-5.995s-4.644-1.098-6.164 0.844l-15.367 19.842-10.723-7.852c-1.942-1.435-4.644-1.013-6.164 0.844-0.76 0.929-1.013 2.111-0.844 3.208z" />
    </svg>
);

export const ArrowTree: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="18" height="18" viewBox="0 0 18 18">
        <polygon fillRule="evenodd" points="9 7 13 11 5 11" transform="rotate(90 9 9)" />
    </svg>
);

export const Close: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="21" height="21" viewBox="0 0 25 25" fill="currentColor">
        <path d="M11.793 12.5L8.146 8.854 7.793 8.5l.707-.707.354.353 3.646 3.647 3.646-3.647.354-.353.707.707-.353.354-3.647 3.646 3.647 3.646.353.354-.707.707-.354-.353-3.646-3.647-3.646 3.647-.354.353-.707-.707.353-.354 3.647-3.646z" />
    </svg>
);

export const Preview: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={style}
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 28 21"
        width="25"
        height="11"
        fill="currentColor"
    >
        <path
            d="M27.888,10.836 C27.716,11.252 23.563,21.011 14.016,21.011 C4.469,21.011 0.316,11.252 0.144,10.836 C0.144,10.836 0.009,10.510 0.009,10.510 C0.009,10.510 0.144,10.184 0.144,10.184 C0.316,9.769 4.469,0.011 14.016,0.011 C23.563,0.011 27.716,9.769 27.888,10.184 C27.888,10.184 28.024,10.510 28.024,10.510 C28.024,10.510 27.888,10.836 27.888,10.836 ZM14.016,1.685 C6.469,1.685 2.588,8.876 1.804,10.509 C2.591,12.142 6.488,19.337 14.016,19.337 C21.563,19.337 25.445,12.146 26.229,10.511 C25.442,8.879 21.544,1.685 14.016,1.685 ZM14.016,15.532 C11.304,15.532 9.097,13.279 9.097,10.510 C9.097,7.741 11.304,5.489 14.016,5.489 C16.729,5.489 18.935,7.741 18.935,10.510 C18.935,13.279 16.729,15.532 14.016,15.532 ZM14.016,7.162 C12.208,7.162 10.737,8.664 10.737,10.510 C10.737,12.357 12.208,13.858 14.016,13.858 C15.825,13.858 17.296,12.357 17.296,10.510 C17.296,8.664 15.825,7.162 14.016,7.162 Z"
            fillRule="evenodd"
        />
    </svg>
);

export const Refresh: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12" height="12" viewBox="0 0 12 12">
        <path
            fill="white"
            fillRule="evenodd"
            d="M11.14 12V9.14S9.29 11.9 5.81 11.9C2.61 11.9.01 9.43.01 6h.86c0 2.57 2.22 5.04 4.94 5.04 1.8 0 3.81-.99 4.69-2.54l-2.79-.04v-.75H12V12h-.86zM6.2.96c-1.8 0-3.82.99-4.69 2.54l2.78.04v.75H0V0h.86v2.86S2.72.1 6.2.1C9.39.1 12 2.57 12 6h-.86C11.14 3.43 8.92.96 6.2.96z"
        />
    </svg>
);

export const SelectionBadge: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="-1 -1 24 24">
        <g fill="none" fillRule="evenodd">
            <g>
                <path
                    fill="#0C9EEB"
                    stroke="#FFF"
                    strokeWidth="2"
                    d="M11 0c6.033 0 10.923 4.89 10.923 10.923S17.033 21.848 11 21.848.077 16.956.077 10.923C.077 4.891 4.967 0 11 0"
                />
                <path
                    fill="#FFF"
                    d="M7.009 11.279a.828.828 0 0 0 .334.551l2.78 2.027a.865.865 0 0 0 1.19-.164l3.513-4.53a.822.822 0 0 0-.164-1.169.865.865 0 0 0-1.196.16l-3 3.87-2.101-1.53a.865.865 0 0 0-1.195.169.819.819 0 0 0-.161.616"
                />
            </g>
        </g>
    </svg>
);

export const ImageSvg: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 72 72" version="1.1">
        <path
            d="M36.1799302,57 C47.678518,57 57,47.5865598 57,36.0461162 C57,27.9422937 52.4005649,20.8889668 45.6818408,17.4223316 C44.2892507,17.1602079 42.8537963,16.9841249 41.3854461,17.0011329 C23.8928393,17.2072301 20.8165808,27.8802645 20.8165808,41.8198397 C20.8165808,53.2542332 15,57 15,57 L36.1799302,57 Z"
            fill="#0099EF"
        ></path>
        <path
            d="M24,20.1181048 C31.8709986,24.8684091 37.3353259,35.3319967 37.3353259,47.4054422 C37.3353259,50.73868 36.9131721,53.0854517 36.1448127,57 L36.3992886,57 C47.7767283,57 57,47.7929825 57,36.5034252 C57,25.2148466 47.7767283,16 36.3992886,16 C31.7437606,16 27.4502197,17.5335243 24,20.1181048 Z"
            fill="#CAEDFF"
        ></path>
        <path
            d="M53,23 C53.027,23.038 53.054,23.076 53.081,23.114 C53.054,23.076 53.027,23.038 53,23"
            fill="#4EB7F6"
        ></path>
        <path
            d="M55,26 C55.016,26.03 55.031,26.062 55.047,26.092 C55.031,26.062 55.016,26.03 55,26"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,31 C57.02,31.088 57.042,31.175 57.061,31.263 C57.042,31.175 57.02,31.088 57,31"
            fill="#4EB7F6"
        ></path>
        <path
            d="M55,27 C55.037,27.08 55.073,27.16 55.109,27.241 C55.073,27.16 55.037,27.08 55,27"
            fill="#4EB7F6"
        ></path>
        <path
            d="M56,30 C56.03,30.101 56.058,30.202 56.086,30.303 C56.058,30.202 56.03,30.101 56,30"
            fill="#4EB7F6"
        ></path>
        <path
            d="M54,25 C54.02,25.032 54.041,25.062 54.06,25.094 C54.041,25.062 54.02,25.031 54,25"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,33 C57.013,33.09 57.022,33.181 57.034,33.272 C57.022,33.181 57.013,33.09 57,33"
            fill="#4EB7F6"
        ></path>
        <path
            d="M51,21 C51.019,21.02 51.039,21.041 51.058,21.061 C51.039,21.041 51.019,21.02 51,21"
            fill="#4EB7F6"
        ></path>
        <path
            d="M57,34 C57.01,34.122 57.02,34.244 57.028,34.367 C57.02,34.244 57.01,34.122 57,34"
            fill="#4EB7F6"
        ></path>
        <path
            d="M36,57 C36.086,57.001 36.172,57 36.258,57 C36.172,57 36.087,57.001 36,57 L36,57 Z"
            fill="#D3EDFF"
        ></path>
        <path
            d="M54,25 C54.238,25.383 54.464,25.774 54.677,26.173 C54.464,25.774 54.238,25.383 54,25"
            fill="#D3EDFF"
        ></path>
        <path
            d="M46,17 C48.092,18.076 49.979,19.495 51.587,21.182 C49.979,19.494 48.093,18.076 46,17"
            fill="#D3EDFF"
        ></path>
        <path
            d="M51,21 C51.623,21.659 52.203,22.358 52.737,23.094 C52.204,22.358 51.623,21.659 51,21"
            fill="#D3EDFF"
        ></path>
        <path
            d="M53,24 C53.243,24.339 53.475,24.685 53.698,25.039 C53.475,24.685 53.242,24.339 53,24"
            fill="#D3EDFF"
        ></path>
        <path
            d="M56,30 C56.097,30.345 56.185,30.693 56.264,31.044 C56.185,30.693 56.097,30.345 56,30"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,34 C57.028,34.446 57.048,34.894 57.048,35.346 C57.048,34.894 57.028,34.446 57,34"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,33 C57.047,33.355 57.086,33.712 57.115,34.072 C57.086,33.712 57.047,33.355 57,33"
            fill="#D3EDFF"
        ></path>
        <path
            d="M55,26 C55.186,26.353 55.361,26.713 55.528,27.078 C55.362,26.713 55.186,26.353 55,26"
            fill="#D3EDFF"
        ></path>
        <path
            d="M55,27 C55.351,27.794 55.653,28.615 55.903,29.457 C55.653,28.615 55.35,27.795 55,27"
            fill="#D3EDFF"
        ></path>
        <path
            d="M57,31 C57.09,31.425 57.167,31.855 57.231,32.289 C57.167,31.855 57.09,31.425 57,31"
            fill="#D3EDFF"
        ></path>
        <path
            d="M36.4943237,55.1854248 C43.1113281,52.5917969 46.927002,46.1784234 47,38 C46.927002,29.2079708 42.4722293,22 34.4200154,22 C32.5024315,22 30.67699,22.4198978 29,23.1619499 C34.0995649,28.7642439 37.3358075,37.0774218 37.3358075,46.3670365 C37.3358075,49.0051116 36.4943237,55.1854248 36.4943237,55.1854248 Z"
            fill="#53CCF9"
        ></path>
        <path
            d="M47,35.5 C47,44.0600733 41.8507904,51 35.4995073,51 C29.1482243,51 24,44.0600733 24,35.5 C24,26.9399267 29.1482243,20 35.4995073,20 C41.8507904,20 47,26.9399267 47,35.5"
            fill="#CAEDFF"
        ></path>
        <path
            d="M53.9674852,25.1551681 C50.3004443,19.0667172 43.668945,15 36.0966655,15 C26.2935361,15 16,22.6926965 16,33.7536012 C21.9129034,33.7536012 32.9370454,33.7161991 39.6185861,33.7536012 C43.8500952,37.6171342 45.5985451,46.2125347 45.5985451,52.4283548 C45.5985451,53.299722 45.5394962,54.1569371 45.4384123,55 C52.2920959,51.5347485 57,44.3818549 57,36.1129644 C57,32.100834 55.8920812,28.3495072 53.9674852,25.1551681"
            fill="#00B7F9"
        ></path>
        <path
            d="M29,38 C29,38.5526992 28.5518423,39 28,39 C27.4473008,39 27,38.5526992 27,38 C27,37.4473008 27.4473008,37 28,37 C28.5518423,37 29,37.4473008 29,38"
            fill="#0099EF"
        ></path>
        <path
            d="M37,38 C37,38.5526992 36.5518423,39 36,39 C35.4473008,39 35,38.5526992 35,38 C35,37.4473008 35.4473008,37 36,37 C36.5518423,37 37,37.4473008 37,38"
            fill="#0099EF"
        ></path>
        <path
            d="M29,44 C29.626,44.8395203 30.1736309,46 32.501,46 C34.8283691,46 35.377,44.8395203 36.003,44"
            fill="#0099EF"
        ></path>
    </svg>
);

export const More: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12px" height="4px" viewBox="0 0 17 5">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M 14.81 4.38C 13.8 4.38 12.97 3.54 12.97 2.5 12.97 1.46 13.8 0.63 14.81 0.63 15.83 0.63 16.66 1.46 16.66 2.5 16.66 3.54 15.83 4.38 14.81 4.38ZM 8.67 4.38C 7.65 4.38 6.83 3.54 6.83 2.5 6.83 1.46 7.65 0.63 8.67 0.63 9.69 0.63 10.51 1.46 10.51 2.5 10.51 3.54 9.69 4.38 8.67 4.38ZM 2.53 4.38C 1.51 4.38 0.69 3.54 0.69 2.5 0.69 1.46 1.51 0.63 2.53 0.63 3.55 0.63 4.37 1.46 4.37 2.5 4.37 3.54 3.55 4.38 2.53 4.38Z"
        />
    </svg>
);

export const Remove: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="13px" height="15px" viewBox="0 0 13 15">
        <g id="Editor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dark-Nov-4-Copy-6" transform="translate(-671.000000, -234.000000)" fill="currentColor">
                <g id="Group-7" transform="translate(408.000000, 144.000000)">
                    <path
                        d="M274,102 C274,103.105 273.105,104 272,104 L267,104 C265.895,104 265,103.105 265,102 L265,93 L267,93 L272,93 L274,93 L274,102 Z M268,91.5 C268,91.229 268.24,91 268.525,91 L270.392,91 C270.693,91 271,91.252 271,91.5 L271,92 L268,92 L268,91.5 Z M272,92 L272,91.5 C272,90.701 271.249,90 270.392,90 L268.525,90 C267.684,90 267,90.673 267,91.5 L267,92 L263,92 L263,93 L264,93 L264,102 C264,103.657 265.343,105 267,105 L272,105 C273.657,105 275,103.657 275,102 L275,93 L276,93 L276,92 L272,92 Z M269,101 L270,101 L270,95 L269,95 L269,101 Z M271,101 L272,101 L272,95 L271,95 L271,101 Z M267,101 L268,101 L268,95 L267,95 L267,101 Z"
                        id="delete"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const DeleteIndicator: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero" transform="translate(-289 -191) translate(289 191) translate(1 1)">
                <circle cx="6" cy="6" r="6.5" fill="#FFF" stroke="#EE5951" />
                <g fill="currentColor" transform="rotate(45 1.61 7.818)">
                    <rect width="1" height="6.857" x="3" rx=".5" />
                    <path
                        d="M3.429 0c.236 0 .428.192.428.429v6c0 .236-.192.428-.428.428-.237 0-.429-.192-.429-.428v-6C3 .192 3.192 0 3.429 0z"
                        transform="rotate(90 3.429 3.429)"
                    />
                </g>
            </g>
            <path d="M0 0H14V14H0z" transform="translate(-289 -191) translate(289 191)" />
        </g>
    </svg>
);

export const FillScale: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-55 -320) translate(55 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="2.92" />
                    <path
                        fill="#D3EDFF"
                        d="M42.894 35l.13.01c.301.043.572.21.746.46L49 43l-13.001-.001L36 43H24l6.74-5.6c.239-.195.548-.282.853-.24.305.041.58.208.757.46l2.472 3.644 7.358-6.014c.202-.166.455-.254.714-.25zM28 29c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const FillScaleSelected: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-55 -510) translate(55 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="2.92" />
                    <path
                        fill="#7FCCF7"
                        d="M42.894 35l.13.01c.301.043.572.21.746.46L49 43H24l6.7-5.63c.239-.195.548-.282.853-.24.305.041.58.208.757.46l2.49 3.66 7.38-6c.235-.194.541-.28.843-.24zM28 29c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const FitScale: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-154 -320) translate(154 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" opacity=".4" rx="3" />
                    <g>
                        <path fill="#4EB7F5" d="M0 0H36V23H0z" transform="translate(18 25)" />
                        <path
                            fill="#D3EDFF"
                            d="M23.873 10.997l.11.01c.259.036.487.183.627.403L29 18H15.27v-.001L8 18l5.65-4.9c.197-.173.46-.25.719-.214.259.036.49.184.631.404l2.064 3.188 6.206-5.258c.167-.148.383-.226.603-.223zM11.5 7c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S10 9.328 10 8.5 10.672 7 11.5 7z"
                            transform="translate(18 25)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const FitScaleSelected: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-154 -510) translate(154 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#7FCCF7" rx="3" />
                    <path fill="#D3EDFF" d="M18 25H54V48H18z" />
                    <path
                        fill="#7FCCF7"
                        d="M41.873 35.997l.11.01c.259.036.487.183.627.403L47 43H26l5.65-4.9c.197-.173.46-.25.719-.214.259.036.49.184.631.404l2.07 3.21 6.2-5.28c.195-.172.456-.25.714-.214zM29.5 32c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const TileScale: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-253 -320) translate(253 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="3" />
                    <path
                        fill="#D3EDFF"
                        d="M9.794 24.997l.094.005c.156.02.297.103.392.228L13 29H0l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.83 3.838-3.02c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 29H16l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.83 3.838-3.02c.1-.077.22-.12.344-.123zM2 22c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L13 18H4.52v-.001L0 18l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.293 1.829L9.45 14.12c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 18h-8.48v-.001L16 18l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.293 1.829 3.837-3.019c.1-.077.22-.12.344-.123zM2 11c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zM9.794 2.997l.094.005c.156.02.297.103.392.228L13 7l-6.781-.001L6.22 7H0l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.829L9.45 3.12c.1-.077.22-.12.344-.123zm16 0l.094.005c.156.02.297.103.392.228L29 7l-6.781-.001.001.001H16l3.48-2.8c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.292 1.829L25.45 3.12c.1-.077.22-.12.344-.123zM2 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                        transform="translate(22 22)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const TileScaleSelected: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-253 -510) translate(253 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="3" />
                    <path
                        fill="#7FCCF7"
                        d="M31.794 46.997l.094.005c.156.02.297.103.392.228L35 51H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 51H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 44c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L35 40H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 40H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 33c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.206-8.003l.094.005c.156.02.297.103.392.228L35 29H22l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zm16 0l.094.005c.156.02.297.103.392.228L51 29H38l3.48-2.82c.124-.102.285-.148.444-.127.16.02.302.107.396.237l1.29 1.83 3.84-3c.124-.096.282-.138.438-.118zM24 22c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm16 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const CustomScale: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-352 -320) translate(352 320)">
                    <rect width="72" height="72" fill="currentColor" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#4EB7F5" rx="3" />
                    <path
                        fill="#D3EDFF"
                        d="M54 33.92V46H26l11.76-10c.408-.36.95-.529 1.491-.464.54.065 1.028.358 1.339.804L44.35 42 54 33.92zM33 25c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                    />
                    <path
                        fill="#4EB7F5"
                        d="M51 19c1.105 0 2 .895 2 2v30c0 1.105-.895 2-2 2H21c-1.105 0-2-.895-2-2V21c0-1.105.895-2 2-2h30m0-1H21c-1.657 0-3 1.343-3 3v30c0 1.657 1.343 3 3 3h30c1.657 0 3-1.343 3-3V21c0-1.657-1.343-3-3-3z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const CustomScaleSelected: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="72" height="72" viewBox="0 0 72 72">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <g transform="translate(-352 -510) translate(352 510)">
                    <rect width="72" height="72" fill="#3899EC" rx="8" />
                    <rect width="36" height="36" x="18" y="18" fill="#D3EDFF" rx="3" />
                    <path
                        fill="#7FCCF7"
                        d="M54 33.92V46H26l11.76-10c.408-.36.95-.529 1.491-.464.54.065 1.028.358 1.339.804L44.35 42 54 33.92zM33 25c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                    />
                    <path
                        fill="#D3EDFF"
                        d="M51 19c1.105 0 2 .895 2 2v30c0 1.105-.895 2-2 2H21c-1.105 0-2-.895-2-2V21c0-1.105.895-2 2-2h30m0-1H21c-1.657 0-3 1.343-3 3v30c0 1.657 1.343 3 3 3h30c1.657 0 3-1.343 3-3V21c0-1.657-1.343-3-3-3z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const Duplicate: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '5px', left: '-4px', marginRight: '4px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <defs>
            <path
                id="path-1"
                d="M6,7 L9.00106183,7 L9.00106183,14.00153 L14,14.00153 L14,17 C14,17.5522847 13.5522847,18 13,18 L6,18 C5.44771525,18 5,17.5522847 5,17 L5,8 C5,7.44771525 5.44771525,7 6,7 Z M8,8 L6,8 L6,17 L13,17 L13,14.996751 L8.99999997,14.997798 C8.4477153,14.9982043 7.99988287,14.5506063 7.9997383,13.9983216 L8,8 Z M9,4 L13,4 L13,8 L17,8 L17,14 C17,14.5522847 16.5522847,15 16,15 L9,15 C8.44771525,15 8,14.5522847 8,14 L8,5 C8,4.44771525 8.44771525,4 9,4 Z M12,5 L9,5 L9,14 L16,14 L16,9 L13,9 C12.4477153,9 12,8.55228475 12,8 L12,5 Z M12,4 L13,4 L17,8 L17,9 L13,9 C12.4477153,9 12,8.55228475 12,8 L12,4 Z M13,5.41421356 L13,8 L15.5857864,8 L13,5.41421356 Z"
            />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <mask id="mask-2" fill="white" />
                <path
                    id="icon"
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M6,7 L9.00106183,7 L9.00106183,14.00153 L14,14.00153 L14,17 C14,17.5522847 13.5522847,18 13,18 L6,18 C5.44771525,18 5,17.5522847 5,17 L5,8 C5,7.44771525 5.44771525,7 6,7 Z M8,8 L6,8 L6,17 L13,17 L13,14.996751 L8.99999997,14.997798 C8.4477153,14.9982043 7.99988287,14.5506063 7.9997383,13.9983216 L8,8 Z M9,4 L13,4 L13,8 L17,8 L17,14 C17,14.5522847 16.5522847,15 16,15 L9,15 C8.44771525,15 8,14.5522847 8,14 L8,5 C8,4.44771525 8.44771525,4 9,4 Z M12,5 L9,5 L9,14 L16,14 L16,9 L13,9 C12.4477153,9 12,8.55228475 12,8 L12,5 Z M12,4 L13,4 L17,8 L17,9 L13,9 C12.4477153,9 12,8.55228475 12,8 L12,4 Z M13,5.41421356 L13,8 L15.5857864,8 L13,5.41421356 Z"
                />
                <g mask="url(#mask-2)" fill="currentColor" fillRule="evenodd">
                    <rect x="0" y="0" width="22" height="22" />
                </g>
            </g>
        </g>
    </svg>
);

export const MoveUp: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '5px', left: '-3px', marginRight: '5px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <defs>
            <path
                id="path-1"
                d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M8,9.5 L11.5,6 L15,9.5 L14.5,10 L12,7.5 L12,13 L11,13 L11,7.5 L8.5,10 L8,9.5 Z"
            />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.000000, 4.000000)">
                <mask id="mask-2" fill="white" />
                <path
                    id="icon"
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M8,9.5 L11.5,6 L15,9.5 L14.5,10 L12,7.5 L12,13 L11,13 L11,7.5 L8.5,10 L8,9.5 Z"
                />
                <g mask="url(#mask-2)" fill="currentColor">
                    <g transform="translate(-5.000000, -5.000000)">
                        <rect x="0" y="0" width="22" height="22" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const MoveDown: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '5px', left: '-3px', marginRight: '5px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <defs>
            <path
                id="path-1"
                d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M15,9.5 L11.5,13 L8,9.5 L8.5,9 L11,11.5 L11,6 L12,6 L12,11.5 L14.5,9 L15,9.5 Z"
            />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(4.000000, 4.000000)">
                <mask id="mask-2" fill="white" />
                <path
                    id="icon"
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M1,1 L4,1 L4,5 L8,5 L8,7 L9,7 L9,4 L5,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 L0,12 C6.76353751e-17,12.5522847 0.44771525,13 1,13 L8,13 L8,12 L1,12 L1,1 Z M5,1.5 L7.5,4 L5,4 L5,1.5 Z M15,9.5 L11.5,13 L8,9.5 L8.5,9 L11,11.5 L11,6 L12,6 L12,11.5 L14.5,9 L15,9.5 Z"
                />
                <g mask="url(#mask-2)" fill="currentColor">
                    <g transform="translate(-5.000000, -5.000000)">
                        <rect x="0" y="0" width="22" height="22" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const ShowLayer: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={{ top: '6px', left: '-3px', marginRight: '5px', ...style }}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="hide_icon" fill="currentColor">
                <path
                    d="M9.5,15 C5.67,15 3.75,11.75 3.14,10.5 C3.75,9.25 5.61,6 9.44,6 C13.27,6 15.19,9.25 15.8,10.5 C15.19,11.75 13.33,15 9.5,15 M16.93,10.46 C16.64,9.75 14.61,5.21 9.77,5.01 C9.66,5.01 9.55,5 9.44,5 C9.33,5 9.22,5.01 9.11,5.01 C4.27,5.21 2.3,9.75 2.01,10.46 C2.01,10.47 2,10.49 2,10.5 C2,10.51 2.01,10.53 2.01,10.54 C2.3,11.25 4.33,15.79 9.17,15.99 C9.28,15.99 9.39,16 9.5,16 C9.61,16 9.72,15.99 9.83,15.99 C14.67,15.79 16.64,11.25 16.93,10.54 C16.93,10.53 16.94,10.51 16.94,10.5 C16.94,10.49 16.93,10.47 16.93,10.46"
                    id="Fill-1"
                />
                <path
                    d="M9.5,12 C8.673,12 8,11.327 8,10.5 C8,9.673 8.673,9 9.5,9 C10.327,9 11,9.673 11,10.5 C11,11.327 10.327,12 9.5,12 M9.5,8 C8.119,8 7,9.119 7,10.5 C7,11.881 8.119,13 9.5,13 C10.881,13 12,11.881 12,10.5 C12,9.119 10.881,8 9.5,8"
                    id="Fill-3"
                />
            </g>
        </g>
    </svg>
);

export interface HideLayerProps extends IconProps {
    onClick?: () => void;
}

export const HideLayer: React.FunctionComponent<HideLayerProps> = ({ className, style, onClick }) => (
    <svg
        className={className}
        style={{ top: '6px', left: '-3px', marginRight: '5px', ...style }}
        onClick={onClick}
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        version="1.1"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="hidden_icon" fill="currentColor">
                <path
                    d="M11.6113,11.8281 C11.8533,11.4421 12.0003,10.9891 12.0003,10.5001 C12.0003,9.1191 10.8813,8.0001 9.5003,8.0001 C9.0103,8.0001 8.5573,8.1461 8.1723,8.3891 L6.5173,6.7341 C7.3293,6.2941 8.2953,6.0001 9.4403,6.0001 C13.2693,6.0001 15.1903,9.2501 15.7993,10.5001 C15.4523,11.2131 14.6953,12.5761 13.3993,13.6161 L11.6113,11.8281 Z M10.3693,11.7181 C10.1233,11.8931 9.8243,12.0001 9.5003,12.0001 C8.6733,12.0001 8.0003,11.3271 8.0003,10.5001 C8.0003,10.1751 8.1063,9.8761 8.2823,9.6311 L10.3693,11.7181 Z M10.8763,11.0941 L8.9063,9.1231 C9.0893,9.0451 9.2893,9.0001 9.5003,9.0001 C10.3273,9.0001 11.0003,9.6731 11.0003,10.5001 C11.0003,10.7111 10.9553,10.9111 10.8763,11.0941 L10.8763,11.0941 Z M9.5003,15.0001 C5.6703,15.0001 3.7503,11.7501 3.1393,10.5001 C3.5123,9.7371 4.3543,8.2301 5.8223,7.1711 L7.5743,8.9231 C7.2203,9.3541 7.0003,9.8981 7.0003,10.5001 C7.0003,11.8811 8.1193,13.0001 9.5003,13.0001 C10.1013,13.0001 10.6453,12.7791 11.0773,12.4251 L12.7343,14.0831 C11.8613,14.6231 10.7963,15.0001 9.5003,15.0001 L9.5003,15.0001 Z M16.9293,10.5401 C16.9293,10.5301 16.9403,10.5101 16.9403,10.5001 C16.9403,10.4901 16.9293,10.4701 16.9293,10.4601 C16.6393,9.7501 14.6103,5.2101 9.7693,5.0101 C9.6603,5.0101 9.5493,5.0001 9.4403,5.0001 C9.3303,5.0001 9.2193,5.0101 9.1103,5.0101 C7.7873,5.0641 6.6863,5.4491 5.7673,5.9851 L3.7833,4.0001 L3.2163,4.5651 L5.0863,6.4341 C3.1183,7.8901 2.2003,9.9951 2.0093,10.4601 C2.0093,10.4701 2.0003,10.4901 2.0003,10.5001 C2.0003,10.5101 2.0093,10.5301 2.0093,10.5401 C2.2993,11.2501 4.3303,15.7901 9.1703,15.9901 C9.2803,15.9901 9.3893,16.0001 9.5003,16.0001 C9.6103,16.0001 9.7193,15.9901 9.8303,15.9901 C11.3023,15.9291 12.5063,15.4641 13.4813,14.8291 L15.2163,16.5651 L15.7833,16.0001 L14.1373,14.3551 C15.9143,12.9151 16.7493,10.9831 16.9293,10.5401 L16.9293,10.5401 Z"
                    id="Fill-1"
                />
            </g>
        </g>
    </svg>
);

export const Solid: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm.333 3.333v13.334h13.334V3.333H3.333z"
            />
            <path fill="#162D3D" d="M0 0h10v10H0z" transform="translate(5 5)" />
        </g>
    </svg>
);

export const Gradient: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <defs>
            <linearGradient id="a" x1="50%" x2="50%" y1="100%" y2="0%">
                <stop offset="0%" stopColor="white" />
                <stop offset="100%" stopColor="#162D3D" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm.333 3.333v13.334h13.334V3.333H3.333z"
            />
            <path fill="url(#a)" d="M0 0h10v10H0z" transform="translate(5 5)" />
        </g>
    </svg>
);

export const Image: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <rect width="20" height="20" fill="#162D3D" rx="3" />
            <path fill="#FFF" d="M5 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM8 17H3l4.5-6 2.318 3.09L13 9l4 8H8z" />
        </g>
    </svg>
);

export const Pattern: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="16" height="16" viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <path
                fill="#162D3D"
                d="M3 0h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 3v14h14V3H3z"
            />
            <path fill="#162D3D" d="M4 4h12v12H4z" />
            <path fill="#FFF" d="M4 9l7 7H8.667L4 11.333zM16 11L9 4h2.333L16 8.667zM5.1 4L16 14.9V16h-1.1L4 5.1V4z" />
        </g>
    </svg>
);

export const Angle: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="22" height="21" viewBox="1 1 22 21">
        <g fill="none" fillRule="evenodd" transform="translate(4 4)">
            <path
                fill="currentColor"
                d="M14 12H0L8.59 0l.83.474-3.13 4.399C8.25 6.255 9.5 8.527 9.5 11H14v1M5.72 5.678L1.94 11H8.5c0-2.146-1.08-4.125-2.78-5.322"
            />
        </g>
    </svg>
);

export const Flip: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="12" height="12" viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path
                        fill="#FFF"
                        d="M2.379 0L3 .653 2.69.98 1.241 2.499 2.689 4.02 3 4.347 2.379 5l-.311-.327L.311 2.827 0 2.5l.31-.327L2.069.327 2.378 0z"
                        transform="translate(-91 -267) translate(91 267) translate(0 7)"
                    />
                    <path
                        stroke="#FFF"
                        strokeLinecap="square"
                        d="M11.45 2.5L1.55 2.5"
                        transform="translate(-91 -267) translate(91 267) translate(0 7)"
                    />
                </g>
                <g>
                    <path
                        stroke="#FFF"
                        strokeLinecap="square"
                        d="M10.45 2.5L0.55 2.5"
                        transform="translate(-91 -267) translate(91 267)"
                    />
                    <path
                        fill="#FFF"
                        d="M9.621 0l.311.327 1.757 1.846L12 2.5l-.31.327-.311.326-1.447 1.52-.31.327L9 4.347l.31-.327L10.758 2.5 9.311.979 9 .654 9.621 0z"
                        transform="translate(-91 -267) translate(91 267)"
                    />
                </g>
            </g>
            <path d="M0 0H12V12H0z" transform="translate(-91 -267) translate(91 267)" />
        </g>
    </svg>
);

export const Focal: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-150 -265) translate(150 265)">
                        <circle cx="7" cy="7" r="6.5" stroke="#FFF" />
                        <circle cx="7" cy="7" r="1" fill="#FFF" />
                    </g>
                    <path d="M0 0H14V14H0z" transform="translate(-150 -265) translate(150 265)" />
                </g>
            </g>
        </g>
    </svg>
);

export const Position: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path
                id="a"
                fill="currentColor"
                d="M16.714 6h-3v.857h3c.237 0 .429.192.429.429v3H18v-3C18 6.576 17.423 6 16.714 6zm.429 10.714a.429.429 0 0 1-.429.429h-3V18h3c.71 0 1.286-.577 1.286-1.286v-3h-.857v3zm-10.286 0v-3H6v3C6 17.424 6.577 18 7.286 18h3v-.857h-3a.429.429 0 0 1-.429-.429zM6 7.286v3h.857v-3c0-.237.192-.429.429-.429h3V6h-3C6.576 6 6 6.577 6 7.286z"
            />
            <path fill="currentColor" d="M11 11h2v2h-2z" />
        </g>
    </svg>
);

export const TopLeftArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="path-1"
            fillRule="evenodd"
            d="M14.969 16v-1h-1v-1h-1v-1h-1v-1h-1v-1.001h-1V14h-1v-4-1h5v1h-3v.938h1v.999h1v1h1v1.001h1v1h1V16h-1z"
        />
    </svg>
);

export const TopRightArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="path-1"
            fillRule="evenodd"
            d="M9.969 16v-1h1v-1h1v-1h1v-1h1v-1.001h1V14h1v-4-1h-1-4v1h3v.938h-1v.999h-1v1h-1v1.001h-1v1h-1V16h1z"
        />
    </svg>
);

export const BottomLeftArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="path-1"
            fillRule="evenodd"
            d="M14.969 9v1h-1v1h-1v1h-1v1h-1v1.001h-1V11h-1v5h5v-1h-3v-.938h1v-.999h1v-1h1v-1.001h1v-1h1V9h-1z"
        />
    </svg>
);

export const BottomRightArrow: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg className={className} style={style} width="24" height="24" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24">
        <path
            id="path-1"
            fillRule="evenodd"
            d="M9.969 9v1h1v1h1v1h1v1h1v1.001h1V11h1v5h-1-4v-1h3v-.938h-1v-.999h-1v-1h-1v-1.001h-1v-1h-1V9h1z"
        />
    </svg>
);

export const Information: React.FunctionComponent<IconProps> = ({ className, style }) => (
    <svg
        className={className}
        style={style}
        preserveAspectRatio="xMidYMid"
        viewBox="1.5 1.5 18 18"
        width="18"
        height="18"
    >
        {/* <circle cx="10.5" cy="10.5" r="8" /> */}
        <path
            fillRule="evenodd"
            fill="currentColor"
            d="M10.5 19.5a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9zm-8-9c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8zm10 5h-4l1-2v-3h-1l1-2h2v5l1 2zm-3-10h2v2h-2v-2z"
        />
    </svg>
);
