import React from 'react';

import { Title, DropDown, Option, Tooltip } from '@wixc3/stylable-panel-components';
import { StylesheetDriver, SiteVarsDriver, StylableSiteVars, FontData } from '@wixc3/stylable-panel-drivers';
import { ColorPicker, NumberInput } from '@wixc3/stylable-panel-controllers';

import { collectStates } from './package-common';

import { style, classes } from './font-package.st.css';

export const DEFAULT_FONT_FAMILY = 'Times New Roman';
export const DEFAULT_FONT_SIZE = 'medium';

export interface FontPackageProps {
    fontDeclarations: FontData;
    sheetDriver: StylesheetDriver;
    siteVarsDriver?: SiteVarsDriver;
    fontFamilies: string[];
    onChangeFamily: (index: number, value: string) => void;
    onChangeSize: (index: number, value: string) => void;
    onChangeColor: (index: number, value: string) => void;
    onGetGoingFamilyChange: (value: string | null) => void;
    onGetGoingFamilySelect: (value: string) => void;
    onGetGoingSizeChange: (value: string) => void;
    onGetGoingColorChange: (value: string | null) => void;
    onGetGoingColorSelect: (value: string) => void;
    className?: string;
}

export interface FontPackageState {
    colorEdited: number;
    getGoingSize: string;
}

export default class FontPackage extends React.Component<FontPackageProps, FontPackageState> {
    public state: FontPackageState = { colorEdited: -1, getGoingSize: DEFAULT_FONT_SIZE };
    private colors: string[] = [];
    private families: string[] = [];
    private selectedFamilies: string[] = [];
    private sizes: string[] = [];
    private collectedStates: Record<string, string> = {};
    private siteVarsDriver!: StylableSiteVars;

    constructor(props: FontPackageProps) {
        super(props);
        const { sheetDriver } = this.props;
        this.siteVarsDriver = new StylableSiteVars(sheetDriver);
        this.setFontsWithProps(this.props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: FontPackageProps) {
        if (this.props.fontDeclarations !== props.fontDeclarations) {
            this.setFontsWithProps(props);
        }
    }

    public render() {
        const { fontDeclarations, className } = this.props;
        const { colorEdited } = this.state;

        const setFamilies = Object.keys(fontDeclarations.fontDeclarations);
        const setSizes = Object.keys(fontDeclarations.sizeDeclarations);
        const setColors = Object.keys(fontDeclarations.colorDeclarations);

        return (
            <div className={style(classes.root, className)}>
                <Title text="All Texts" />
                <div className={classes.fontsWrapper}>
                    {!setFamilies.length && !setSizes.length ? this.renderGetGoingFamilyPicker() : null}
                    {setFamilies ? setFamilies.map(this.renderFontPicker) : null}
                </div>
                <div className={classes.sizesWrapper}>
                    {!setFamilies.length && !setSizes.length ? this.renderGetGoingSizePicker() : null}
                    {setSizes ? setSizes.map(this.renderSizePicker) : null}
                </div>
                <div className={classes.colorBoxesWrapper}>
                    {!setColors.length ? this.renderGetGoingColorBox() : null}
                    {setColors ? setColors.map(this.renderColorBox) : null}
                </div>
                {colorEdited !== -1 ? (
                    <ColorPicker
                        className={classes.colorPicker}
                        siteVarsDriver={this.siteVarsDriver}
                        currentColor={this.colors[colorEdited]}
                        onChange={this.handleColorPickerSelect}
                        onHover={this.handleColorPickerChange}
                        // onClose={() => this.setState({colorEdited: -1})}
                        onBlur={() => this.setState({ colorEdited: -1 })}
                    />
                ) : null}
            </div>
        );
    }

    private setFontsWithProps(props: FontPackageProps) {
        const { fontDeclarations } = props;
        this.colors = Object.keys(fontDeclarations.colorDeclarations);
        this.collectedStates = collectStates(fontDeclarations.colorDeclarations, this.colors);

        this.families = Object.keys(fontDeclarations.fontDeclarations);
        this.selectedFamilies = Object.keys(fontDeclarations.fontDeclarations);
        this.sizes = Object.keys(fontDeclarations.sizeDeclarations);
    }

    private renderFontPicker = (_setFamily: string, index: number) => {
        return (
            <DropDown
                key={`font_family_${index}`}
                className={classes.fontPicker}
                value={this.getFamilyValue(index)}
                options={this.getFontFamilyOptions(this.getSelectedFamilyValue(index))}
                onSelect={this.handleFamilySelect.bind(this, index)}
                onHover={this.handleFamilyChange.bind(this, index)}
                // style={{fontFamily: this.families[index]}}
            />
        );
    };

    private renderSizePicker = (_setSize: string, index: number) => {
        return (
            <NumberInput
                key={`font_size_${index}`}
                className={classes.sizePicker}
                value={this.sizes[index]}
                siteVarsDriver={this.props.siteVarsDriver}
                onChange={this.handleSizeChange.bind(this, index)}
            />
        );
    };

    private renderColorBox = (setColor: string, index: number) => {
        const { colorEdited } = this.state;
        return (
            <Tooltip key={`color_${index}`} text={this.tooltipText(setColor)}>
                <span
                    className={style(classes.colorBox, { selected: index === colorEdited })}
                    style={{ backgroundColor: this.colors[index] }}
                    onClick={() => this.setState({ colorEdited: colorEdited === -1 ? index : -1 })}
                >
                    {' '}
                </span>
            </Tooltip>
        );
    };

    private renderGetGoingFamilyPicker() {
        const { onGetGoingFamilySelect, onGetGoingFamilyChange } = this.props;
        return (
            <Tooltip text="Add First Font Family">
                <DropDown
                    className={classes.getGoingFontPicker}
                    value={DEFAULT_FONT_FAMILY}
                    options={this.getFontFamilyOptions(DEFAULT_FONT_FAMILY)}
                    onSelect={(value: string) => onGetGoingFamilySelect(value)}
                    onHover={(value: string | null) => onGetGoingFamilyChange(value)}
                />
            </Tooltip>
        );
    }

    private renderGetGoingSizePicker() {
        const { siteVarsDriver, onGetGoingSizeChange } = this.props;
        const { getGoingSize } = this.state;
        return (
            <Tooltip text="Add First Font Size">
                <NumberInput
                    className={classes.getGoingSizePicker}
                    value={getGoingSize}
                    siteVarsDriver={siteVarsDriver}
                    onChange={(newGetGoingSize: string) => this.setState({ getGoingSize: newGetGoingSize })}
                    onBlur={() => onGetGoingSizeChange(getGoingSize)}
                />
            </Tooltip>
        );
    }

    private renderGetGoingColorBox() {
        const { colorEdited } = this.state;
        return (
            <Tooltip text="Add First Color">
                <span
                    className={style(classes.colorBox, { selected: colorEdited === 0 })}
                    onClick={() => this.setState({ colorEdited: colorEdited === -1 ? 0 : -1 })}
                >
                    {' '}
                </span>
            </Tooltip>
        );
    }

    private tooltipText(collectKey: string) {
        const value = this.collectedStates[collectKey];
        return value ? `Shows on ${value}` : undefined;
    }

    private getFamilyValue(index: number) {
        return normalizeQuotes(this.families[index].split(',')[0]);
    }

    private getSelectedFamilyValue(index: number) {
        return this.selectedFamilies[index].split(',')[0];
    }

    private getFontFamilyOptions(currValue: string) {
        const { fontFamilies } = this.props;

        currValue = normalizeQuotes(currValue);

        const families = !~fontFamilies.indexOf(currValue) ? [currValue].concat(fontFamilies) : fontFamilies;
        return families.map<Option>((value) => ({
            id: value,
            displayName: value,
            style: { fontFamily: value },
        }));
    }

    private handleFamilySelect = (familyEdited: number, value: string) => {
        this.selectedFamilies[familyEdited] = value;
        this.handleFamilyChange(familyEdited, value);
    };

    private handleFamilyChange = (familyEdited: number, value: string | null) => {
        const { onChangeFamily } = this.props;

        const changeValue = value || this.selectedFamilies[familyEdited];
        onChangeFamily(familyEdited, changeValue);

        this.families[familyEdited] = changeValue;
        this.forceUpdate();
    };

    private handleSizeChange = (sizeEdited: number, value: string) => {
        const { onChangeSize } = this.props;

        onChangeSize(sizeEdited, value);

        this.sizes[sizeEdited] = value;
        this.forceUpdate();
    };

    private handleColorPickerSelect = (value: string) => {
        const { fontDeclarations, onGetGoingColorSelect } = this.props;

        if (Object.keys(fontDeclarations.colorDeclarations).length) {
            this.handleColorPickerChange(value);
        } else {
            onGetGoingColorSelect(value);
        }
    };

    private handleColorPickerChange = (value: string | null) => {
        const { fontDeclarations, sheetDriver, onChangeColor, onGetGoingColorChange } = this.props;

        if (Object.keys(fontDeclarations.colorDeclarations).length) {
            if (!value) {
                return;
            }
            const { colorEdited } = this.state;

            onChangeColor(colorEdited, value);

            this.colors[colorEdited] = sheetDriver.evalDeclarationValue(value);
            this.forceUpdate();
        } else {
            onGetGoingColorChange(value);
        }
    };
}

function normalizeQuotes(value: string) {
    return value.startsWith('"') && value.endsWith('"') ? value.slice(1, value.length - 1) : value;
}
