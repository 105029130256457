import React from 'react';

import { style, classes } from './drawer.st.css';

export interface DrawerProps {
    open?: boolean;
    title?: string;
    drawerKey: string;
    onOpenClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export class Drawer extends React.Component<DrawerProps> {
    public render() {
        const { open, title, drawerKey, onOpenClick, children, className, style: propStyle } = this.props;

        const drawer = (
            <div
                key={drawerKey}
                className={style(classes.root, { open: !!open }, className)}
                onClick={onOpenClick}
                style={propStyle}
            >
                {title}
            </div>
        );

        return open ? [drawer, ...React.Children.toArray(children)] : drawer;
    }
}
