import type * as postcss from 'postcss';

import type { Collection } from '@wixc3/stylable-panel-drivers';

export function collectStates(declarations: Collection, keys: string[]) {
    const collectedStates: Record<string, string> = {};

    keys.forEach((key) => {
        const parentSelector = (declarations[key][0].declaration.parent as postcss.Rule).selector;
        const stateMatch = parentSelector.match(/.*[^:]:([^:()]*)$/);
        if (stateMatch) {
            collectedStates[key] = stateMatch[1];
        }
    });

    return collectedStates;
}
