import React from 'react';

import { DEFAULT_PLANE } from '@wixc3/stylable-panel-common';
import type { CustomInputProps } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './icon-radio-group-input.st.css';

export interface IconRadioGroupIcon {
    default: React.StatelessComponent;
    defaultVertical?: React.StatelessComponent;
    checked?: React.StatelessComponent;
}

export interface IconRadioGroupOption {
    propValue: string;
    icon: IconRadioGroupIcon;
}

export function IconRadioGroupInputFactory(
    options: IconRadioGroupOption[],
    defaultPropValue?: string,
    largeIcons?: boolean
): React.ComponentClass<CustomInputProps> {
    class IconRadioGroupInput extends React.Component<CustomInputProps> {
        public render() {
            const { plane = DEFAULT_PLANE, className } = this.props;

            return (
                <div
                    className={style(
                        classes.root,
                        {
                            large: !!largeIcons,
                            plane,
                        },
                        className
                    )}
                >
                    {this.renderIconRadioGroup()}
                </div>
            );
        }

        private renderIconRadioGroup() {
            const { value = defaultPropValue, onChange } = this.props;

            return options.map(({ propValue, icon }) => {
                const checked = propValue === value;
                const Icon = checked && icon.checked ? icon.checked : icon.default;

                return (
                    <span
                        key={propValue}
                        className={style(classes.icon, { checked })}
                        onClick={() => onChange(propValue)}
                    >
                        <input
                            key={`${propValue}_radio`}
                            className={classes.hiddenInput}
                            type="radio"
                            name={propValue}
                            value={propValue}
                            checked={checked}
                            onChange={() => null}
                        />
                        <Icon />
                    </span>
                );
            });
        }
    }

    return IconRadioGroupInput;
}
