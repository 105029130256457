import type * as postcss from 'postcss';
import type { StylesheetDriver } from './stylable-stylesheet';

// const simpleClassMatch = /^\.\w+/;
export type VariantData = [string, string | null];

// TODO extract to function? (why class?)
export class StylableVariant {
    constructor(private sheet: StylesheetDriver) {}

    // public getVariants():{bySelector:Record<string, VariantData>, list:VariantData[]} {
    //     const list:[string, string|null][] = [];
    //     const bySelector:Record<string, VariantData> = {};

    //     this.sheet.AST.walkRules(rule => {
    //         const {selector, nodes} = rule;
    //         if(nodes && selector.match(simpleClassMatch)){
    //             if(!bySelector[selector]) {
    //                 let variant = false;
    //                 let extendsVal:string|null = null;
    //                 let node:any; // ToDo: find out way postcss types don't match runtime
    //                 for(node of nodes) {
    //                     if(node.type === 'decl') {
    //                         if(node.prop === '-st-variant') {
    //                             variant = true;
    //                         }
    //                         if(node.prop === '-st-extends') {
    //                             extendsVal = node.value;
    //                         }
    //                     }
    //                 }
    //                 if(variant){
    //                     const variantData:VariantData = [selector, extendsVal];
    //                     list.push(variantData);
    //                     bySelector[selector] = variantData;
    //                 }
    //             } else {
    //                 // for now...
    //                 throw new Error('duplicate variant declaration error: ' + rule.selector);
    //             }
    //         }
    //     });

    //     return {bySelector, list};
    // }

    public getVariantStyleRules(selector: string): postcss.Rule[] {
        const result: postcss.Rule[] = [];
        // const {bySelector, list} = this.getVariants();
        // if(bySelector[selector]){
        this.sheet.walkRules((rule) => {
            // if(rule.selector.startsWith(selector)){
            if (rule.selector.match(new RegExp(`^${selector}$|^${selector}[:].*$`))) {
                result.push(rule);
            }
        });
        // }
        return result;
    }
}
