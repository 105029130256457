import type * as postcss from 'postcss';

import { matchSelectorTarget } from '@stylable/core';

import type { ISymbol } from '../stylable-driver';
import type { StylesheetDriver } from '../stylable-stylesheet';
import type { FullDeclarationMap } from '../types';

export type Collection = Record<string, ChangeContext[]>;

export interface ChangeContext {
    declaration: postcss.Declaration;
    change: (value: string) => void;
}

export class CollectionDriver<T> {
    public static changeValue(contexts: ChangeContext[], value: string) {
        contexts.forEach((context) => context.change(value));
    }
    constructor(
        protected getStylesheet: (path: string) => StylesheetDriver | null,
        protected resolveSymbol: (from: string, name: string) => ISymbol | null,
        protected updateFromAST: (modified: postcss.Node) => number
    ) {}

    public collect(sheetPath: string, selector: string): T {
        const data: T = this.initialData();

        const sheet = this.getStylesheet(sheetPath);
        if (!sheet) {
            return data;
        }

        sheet.walkRules((rule) => {
            if (!rule.selector.match(selectorRegExp(selector)) && !matchSelectorTarget(selector, rule.selector)) {
                return;
            }

            rule.walkDecls((decl) => {
                // console.log(`router(${decl.prop})`);
                this.router(decl, sheet, data);
            });
        });

        // console.log(declarations);
        return data;
    }

    protected initialData(): T {
        return {} as T;
    }

    protected router(_decl: postcss.Declaration, _sheet: StylesheetDriver, _data: T | FullDeclarationMap) {
        //
    }
}

function selectorRegExp(selector: string) {
    return new RegExp(`^${selector}$|^${selector}[:].*$`);
}

export function addChangeContext(
    declarations: Collection,
    decl: postcss.Declaration,
    value: string,
    change: (value: string) => void
) {
    if (declarations[value] === undefined) {
        declarations[value] = [];
    }
    declarations[value].push({ declaration: decl, change });
}
