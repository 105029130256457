import React from 'react';

import { Title, DropDown, optionsFromStrings } from '@wixc3/stylable-panel-components';

import type { ControllerComponent, ExtendedControllerProps } from '../types';
import { BasicController } from './basic-controller';

import { style, classes } from './group-controller.st.css';

export interface GroupControllerState {
    editedProp: string;
}

export function GroupControllerFactory(
    title: string,
    inputProps: string[]
): React.ComponentClass<ExtendedControllerProps> {
    class GroupController
        extends React.Component<ExtendedControllerProps, GroupControllerState>
        implements ControllerComponent
    {
        public static INPUT_PROPS: string[] = inputProps;

        public state: GroupControllerState = { editedProp: inputProps[0] };

        public render() {
            const { value, className } = this.props;
            const { editedProp } = this.state;

            return (
                <div className={style(classes.root, className)}>
                    <Title className={classes.title} text={title} />
                    <DropDown
                        className={classes.dropDown}
                        value={editedProp}
                        options={optionsFromStrings(Object.keys(value))}
                        onSelect={(newEditedProp: string) => this.setState({ editedProp: newEditedProp })}
                    />
                    <BasicController
                        className={classes.basicController}
                        value={value[editedProp]}
                        onChange={(newValue: string) => this.handleChange(editedProp, newValue)}
                    />
                </div>
            );
        }

        private handleChange(prop: string, value: string) {
            this.props.onChange && this.props.onChange({ [prop]: value });
        }
    }

    return GroupController;
}
