import React from 'react';

import { DropDown, Option } from '@wixc3/stylable-panel-components';
import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import type { FontTheme } from '../types/font-types';
import type { ExtendedGlobalHost, TranslateFunc } from '../../types';
import { getTranslate } from '../../hosts/translate';

import { style, classes } from './font-theme-selector.st.css';

const MAX_FONT_SIZE = 28;
export const CUSTOM_FONT_THEME_ID = 'font_custom';
const getCustomFontThemeOption = (translate: TranslateFunc) =>
    ({
        id: CUSTOM_FONT_THEME_ID,
        displayName: translate(StylablePanelTranslationKeys.controller.text.fontThemeSelector.customOption),
    } as Option);

export interface FontThemeSelectorProps {
    value: string;
    fontThemes: FontTheme[];
    panelHost?: ExtendedGlobalHost;
    // onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string) => void;
    disabled?: boolean;
    noCloseOnSelect?: boolean;
    className?: string;
}

export class FontThemeSelector extends React.Component<FontThemeSelectorProps> {
    private fontOptions: Option[] = [];

    constructor(props: FontThemeSelectorProps) {
        super(props);

        this.setFontOptions(props);
    }

    public render() {
        const {
            value,
            panelHost,
            // onHover,
            onSelect,
            disabled,
            noCloseOnSelect,
            className,
        } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <DropDown
                className={style(classes.root, className)}
                value={value}
                options={this.fontOptions}
                volatileOptions={[getCustomFontThemeOption(translate)]}
                customOptionRender={this.renderFontThemeOption}
                // onHover={onHover}
                onSelect={onSelect}
                disabled={disabled}
                noCloseOnSelect={noCloseOnSelect}
                data-aid="st_font_theme_selector"
            />
        );
    }

    private renderFontThemeOption = (item: Option, index: number) => {
        const { fontThemes } = this.props;

        if (item.id === CUSTOM_FONT_THEME_ID) {
            return <span>{item.displayName}</span>;
        }

        const { size, style: fontStyle, weight: fontWeight } = fontThemes[index];
        const fontSize = parseInt(size, 10) <= MAX_FONT_SIZE ? size : `${MAX_FONT_SIZE}px`;

        return (
            <div className={classes.fontThemeOption}>
                <div style={{ fontSize, fontStyle, fontWeight }}>{item.displayName}</div>
                <div className={classes.fontThemeOptionSize}>{size}</div>
            </div>
        );
    };

    private setFontOptions(props: FontThemeSelectorProps) {
        const { fontThemes, panelHost } = props;

        const translate = getTranslate(panelHost);

        this.fontOptions = fontThemes.map((fontTheme) => ({
            id: fontTheme.cssClass,
            displayName: translate(fontTheme.displayName),
        }));
    }
}
