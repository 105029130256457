import React from 'react';
import chroma from 'chroma-js';

import {
    BackgroundBox,
    CompositeBlock,
    OptimisticWrapper,
    Title,
    ToggleSwitch,
} from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';
import { GenericDeclarationMap, StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import { ColorPicker, ColorPickerProps } from '../../pickers';
import { controllerNoStateResizing } from '../../utils/controller-data-utils';
import { getTranslate } from '../../hosts/translate';
import { PanelEventList } from '../../hosts/bi';
import {
    controllerToVisualizerChange,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    visualizerOptimisticValueResolver,
} from '../../utils';
import { AngleVisualizer, getDimensionUnits, OpacityVisualizer, SizeVisualizer } from '../../generated-visualizers';
import type { OpenedDeclarationArray } from '../../declaration-types';
import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';

import { classes, style } from './icon-visualizer.st.css';

export const ICON_DISPLAY_HIDDEN = 'none';
export const ICON_DISPLAY_SHOWN = 'initial';

const DEFAULT_ANGLE = '0deg';

export type IconProps = 'display' | 'width' | 'height' | 'fill' | 'transform';

export type IconVisualizerProps = DeclarationVisualizerProps<IconProps>;
type IconDeclarationMap = GenericDeclarationMap<IconProps>;
type IconVisualizerValue = OpenedDeclarationArray<IconProps>;

export class IconVisualizerInner
    extends React.Component<IconVisualizerProps>
    implements VisualizerComponent<IconProps>
{
    public static BLOCK_VARIANT_CONTROLLER = false;
    public static INPUT_PROPS: IconProps[] = ['width', 'height', 'fill'];
    public static OUTSIDE_PROPS: string[] = ['display', 'transform'];

    private angle = DEFAULT_ANGLE;

    private declarationMapValue: IconDeclarationMap;

    constructor(props: IconVisualizerProps) {
        super(props);
        this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: IconVisualizerProps) {
        this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
    }

    public render() {
        const { controllerData, plane = DEFAULT_PLANE, panelHost, className } = this.props;

        const { display } = this.declarationMapValue;

        const translate = getTranslate(panelHost);

        const hideShownToggle = controllerData && !!controllerData.hideShownToggle;
        const elementShown = display !== ICON_DISPLAY_HIDDEN;

        return (
            <div className={style(classes.root, { plane }, className)}>
                <Title className={classes.title} text={translate(StylablePanelTranslationKeys.controller.icon.title)} />
                {!hideShownToggle ? this.renderShownToggle(elementShown) : null}
                {elementShown ? this.renderControllers() : null}
            </div>
        );
    }

    private onChange(toggle: boolean, onChange?: (declarations: IconVisualizerValue) => void) {
        onChange &&
            onChange(
                controllerToVisualizerChange(
                    {
                        display: toggle ? ICON_DISPLAY_HIDDEN : ICON_DISPLAY_SHOWN,
                    } as IconDeclarationMap,
                    this.props
                )
            );
        const { panelHost } = this.props;
        if (panelHost) {
            const { reportBI } = panelHost;
            if (!reportBI) {
                return;
            }
            const { COMPONENT_PART_TOGGLE } = PanelEventList;
            reportBI && reportBI(COMPONENT_PART_TOGGLE, { toggle_name: 'icon', toggle: !toggle });
        }
    }

    private renderShownToggle(elementShown: boolean) {
        const { selectorState, panelHost, onChange } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <CompositeBlock
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.icon.showLabel)}
                singleLine
                divider
            >
                <ToggleSwitch
                    className={classes.shownToggle}
                    value={elementShown}
                    disabled={selectorState !== undefined}
                    onChange={() => this.onChange(elementShown, onChange)}
                />
            </CompositeBlock>
        );
    }

    private renderControllers() {
        const { panelHost, onChange, drivers } = this.props;
        const { width = '0px', height = '0px', fill, transform } = this.declarationMapValue;

        const translate = getTranslate(panelHost);
        const noStateResizing = controllerNoStateResizing(this.props);

        const { dimensionUnits } = panelHost || {};
        const sizeUnits = getDimensionUnits({ id: DIMENSION_ID.SIZE, dimensionUnits });
        const opacityUnits = getDimensionUnits({ id: DIMENSION_ID.OPACITY, dimensionUnits });
        const angleUnits = getDimensionUnits({ id: DIMENSION_ID.ANGLE, dimensionUnits });

        let opacityValue = 100;
        try {
            const color = chroma(fill || '');
            opacityValue = Math.floor(color.alpha() * 100);
        } catch {
            //
        }

        if (transform) {
            this.angle = `${transform.replace(/[^\d]/g, '')}deg`;
        }

        let iconValue = width || height;
        // lazily fix width/height set in percent
        if (iconValue.indexOf('%') > -1) iconValue = iconValue.replace('%', 'px');

        return [
            !noStateResizing ? (
                <CompositeBlock
                    key="size"
                    className={classes.controllerBlock}
                    title={translate(StylablePanelTranslationKeys.controller.icon.sizeLabel)}
                    divider
                >
                    <SizeVisualizer
                        config={{ units: sizeUnits }}
                        drivers={drivers}
                        className={classes.inputElementSize}
                        value={
                            // TODO: Optimization: The value passed here comes directly from the value prop.
                            //  We can find the width or height declaration nodes and pass them directly to the SizeVisualizer
                            createVisualizerValueFromDeclarationMap({
                                size: iconValue,
                            })
                        }
                        onChange={(value: any) => {
                            if (onChange) {
                                const iconSize = createDeclarationMapFromVisualizerValue(value, {
                                    value: [],
                                    drivers,
                                }).size;
                                onChange(
                                    // TODO: Optimization: Have a version of controllerToVisualizerChange that gets the visualizer value directly,
                                    // and then we won't need to call createDeclarationMapFromVisualizerValue and then convert it back.
                                    controllerToVisualizerChange(
                                        {
                                            width: iconSize,
                                            height: iconSize,
                                        } as IconDeclarationMap,
                                        this.props
                                    )
                                );
                            }
                        }}
                    />
                </CompositeBlock>
            ) : null,
            // TODO: Extract to ColorInput component
            <CompositeBlock
                key="fill"
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.icon.colorLabel)}
                divider
            >
                <div className={classes.colorInputWrapper}>
                    <OpacityVisualizer
                        drivers={drivers}
                        className={classes.inputElementOpacity}
                        value={createVisualizerValueFromDeclarationMap({
                            opacity: `${opacityValue}%`,
                        })}
                        config={{ units: opacityUnits }}
                        onChange={(value) => {
                            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (opacity) {
                                this.changeColorAlpha(opacity);
                            }
                        }}
                        opacitySliderColor={fill}
                        isDisabled={!fill}
                    />
                    <BackgroundBox
                        className={classes.colorBox}
                        value={fill}
                        noColor={!fill}
                        showNoColorDiagonal
                        onClick={() => this.openColorPicker()}
                    />
                </div>
            </CompositeBlock>,
            <CompositeBlock
                key="transform"
                className={style(classes.controllerBlock)}
                title={translate(StylablePanelTranslationKeys.controller.icon.angleInputLabel)}
            >
                <span className={classes.inputElementAngleWrapper}>
                    <AngleVisualizer
                        drivers={drivers}
                        className={classes.inputElementAngle}
                        value={createVisualizerValueFromDeclarationMap({
                            angle: this.angle,
                        })}
                        config={{ units: angleUnits }}
                        onChange={(value) => {
                            const { angle } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (angle) {
                                this.changeAngle(angle);
                            }
                        }}
                    />
                </span>
            </CompositeBlock>,
        ];
    }

    private openColorPicker() {
        const { siteVarsDriver, panelHost, onChange: onChangeProp } = this.props;

        const { fill: currentColor } = this.declarationMapValue;

        if (!panelHost?.onOpenPanel) {
            return;
        }

        const translate = getTranslate(panelHost);

        const onChange = (fill: string | null) =>
            onChangeProp &&
            onChangeProp(
                controllerToVisualizerChange(
                    {
                        fill: fill || undefined,
                    } as IconDeclarationMap,
                    this.props
                )
            );

        const colorPickerProps: ColorPickerProps = {
            className: classes.colorPicker,
            title: translate(StylablePanelTranslationKeys.controller.icon.colorPickerTitle),
            siteVarsDriver,
            currentColor,
            panelHost,
            onHover: onChange,
            onChange,
            // onClose: () => this.setState({colorPickerType: ColorPickerTextType.None}),
            // onBlur: () => this.setState({colorPickerType: ColorPickerTextType.None})
        };

        panelHost.onOpenPanel(ColorPicker.panelName, colorPickerProps);
    }

    private changeColorAlpha(value: string) {
        const { onChange } = this.props;
        const { fill } = this.declarationMapValue;

        if (!onChange) {
            return;
        }

        try {
            const color = chroma(fill || '');
            const numberValue = parseFloat(value);

            onChange(
                controllerToVisualizerChange(
                    { fill: color.alpha(numberValue / 100).css() } as IconDeclarationMap,
                    this.props
                )
            );
        } catch {
            //
        }
    }

    private changeAngle(value: string) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        try {
            const newAngle = parseInt(value, 10) % 360;
            this.angle = `${newAngle}deg`;
            onChange(
                controllerToVisualizerChange(
                    { transform: newAngle ? `rotate(${this.angle})` : undefined } as IconDeclarationMap,
                    this.props
                )
            );
        } catch {
            //
        }
    }
}

export const IconVisualizer = OptimisticWrapper<React.ComponentClass<IconVisualizerProps>, IconVisualizerValue>(
    IconVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);
