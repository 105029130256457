import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import keycode from 'keycode';

import {
    ComponentEditSession,
    DeclarationMap,
    BlockVariantSet,
    ArchetypeList,
    stripStates,
    getFinalStateName,
    StylablePanelTranslationKeys,
} from '@wixc3/stylable-panel-drivers';
import {
    ControllerComponentClass,
    VisualizerComponentClass,
    PanelEventList,
    BIParams,
    reportBI,
    getTranslate,
} from '@wixc3/stylable-panel-controllers';
import { MOUSE_QUICK_CHANGE, KEYBOARD_QUICK_CHANGE } from '@wixc3/stylable-panel-common';
import type { StylePanelPlane } from '@wixc3/stylable-panel-common';

import { StylePanel, StylePanelPage, PresetPanel, CustomizationPanel } from '../panels';
import { setupArchetypeList, setupCategoryConfig, unifiedControllersSetup } from '../setup';
import type { CategoryConfig, StylablePanelHost, StylablePanelInternalHost } from '../types';
import { ResetStateOverridesDriver } from '../drivers/reset-state-overrides';
import StateListDialog, { StateListProps } from '../panels/state-list-dialog/state-list-dialog';

import { style, classes } from './stylable/editor-panel.st.css';
import { useForceUpdate } from '../utils/hooks/use-force-update';

// TODO pass these as context rather than props:
const staticArchetypeList = setupArchetypeList();
const staticCategoryConfig = setupCategoryConfig();
const customizationPanelControllers = unifiedControllersSetup();

export interface EditorPanelEvents {
    onPresetSelect?: () => void;
    onElementSelect?: (selector: string) => void;
    onForceState?: (stateOverrideSelector: string | null) => void;
    onStateSelect?: (stateSelector: string) => void;
    onRemoveOverrides?: () => void;
    onPageChange?: (page: StylePanelPage) => void;
}

export interface StylePanelView {
    page?: StylePanelPage;
    section?: string;
    selector?: string;
}

export interface StylePanelExternalProps {
    archetypeList?: ArchetypeList;
    categoryConfig?: CategoryConfig;
    controllers?: Record<string, ControllerComponentClass | VisualizerComponentClass>;
    panelHost?: StylablePanelHost;
    plane?: StylePanelPlane;
    initialView?: StylePanelView;
}

export interface EditorPanelProps extends EditorPanelEvents, StylePanelExternalProps {
    editSession: ComponentEditSession;
    className?: string;
}

export const EditorPanel = ({
    editSession,
    panelHost,
    controllers,
    categoryConfig,
    archetypeList,
    initialView,
    onElementSelect,
    onStateSelect,
    onPresetSelect,
    onForceState,
    onRemoveOverrides,
    onPageChange,
    plane,
    className,
}: EditorPanelProps) => {
    const forceUpdate = useForceUpdate();
    const translate = getTranslate(panelHost);
    const [userBlockVariants, setUserBlockVariants] = useState<Record<string, BlockVariantSet>>({});

    const clearStateStyleOverrides = useCallback(
        (selector: string) => {
            editSession.changeDriver.changeSelectors({
                selector,
                values: undefined,
                revertible: false,
            });
        },
        [editSession.changeDriver]
    );

    const getStates = useCallback(
        (selector: string) => {
            const {
                selectorConfiguration,
                computed: { states },
            } = editSession;

            const statesOrderSet = new Set<string>(selectorConfiguration.getStatesOrder(selector));
            states.forEach((state) => statesOrderSet.add(state));
            return [...statesOrderSet].filter(
                (state) => states.includes(state) && !selectorConfiguration.isStateHidden(selector, state)
            );
        },
        [editSession]
    );

    const openStateOverridesDialog = useCallback(
        (fixedStates: string[]) => {
            const { selector, selectorConfiguration } = editSession;

            const { title, applyButtonLabel } = StylablePanelTranslationKeys.dialog.stateOverrides;

            const statesListDialogProps: StateListProps = {
                title: translate(title),
                applyLabel: translate(applyButtonLabel),
                onSubmit: (stateList: string[]) =>
                    resetStateOverridesDriver.current.onStateListSubmit(selector, stateList),
                panelHost,
                selector,
                usedStates: getStates(selector),
                fixedStates,
                selectorConfiguration,
            };

            panelHost?.onOpenPanel && panelHost.onOpenPanel(StateListDialog.panelName, statesListDialogProps);
        },
        [editSession, getStates, panelHost, translate]
    );

    const createResetStateOverridesDriverInstance = useMemo(
        () => new ResetStateOverridesDriver(editSession, clearStateStyleOverrides, openStateOverridesDialog, translate),
        [clearStateStyleOverrides, editSession, openStateOverridesDialog, translate]
    );

    const resetStateOverridesDriver = useRef<ResetStateOverridesDriver>(createResetStateOverridesDriverInstance);

    useEffect(() => {
        editSession.changeDriver.setPostChangeHook(handleStateOverrides);

        document.addEventListener('mousedown', () => editSession.changeDriver.startBlockingCommits(MOUSE_QUICK_CHANGE));
        document.addEventListener('mouseup', () =>
            editSession.changeDriver.releaseBlockingCommits(true, MOUSE_QUICK_CHANGE)
        );
        document.addEventListener('keydown', (event) => keydown(event, KEYBOARD_QUICK_CHANGE));
        document.addEventListener('keyup', (event) => keyup(event, KEYBOARD_QUICK_CHANGE));

        return () => editSession.changeDriver.setPostChangeHook(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const setInitialView = () => {
            if (!initialView) {
                return;
            }

            const { page, selector } = initialView;

            if (page !== undefined) {
                editSession.page = page;
            }

            if (selector !== undefined) {
                const variantSelector = editSession.selectorConfiguration.selectorToVariant(selector);
                onElementSelect && onElementSelect(stripStates(variantSelector));
                onStateSelect && onStateSelect(variantSelector);
            }
        };

        setInitialView();
    }, [editSession, initialView, onElementSelect, onStateSelect]);

    useEffect(() => {
        resetStateOverridesDriver.current = createResetStateOverridesDriverInstance;
    }, [editSession, createResetStateOverridesDriverInstance]);

    const fixPanelHost = (panelHost?: StylablePanelInternalHost): StylablePanelInternalHost => {
        const { changeDriver } = editSession;

        const internalHost: StylablePanelInternalHost = {
            onRevertQuickChange: changeDriver.onRevertQuickChange,
            blockCommits: changeDriver.startBlockingCommits,
            unblockCommits: changeDriver.releaseBlockingCommits,
        };

        return { ...internalHost, ...(panelHost || {}) };
    };

    const getArchetypeList = () => archetypeList || staticArchetypeList;

    const getCategoryConfig = () => categoryConfig || staticCategoryConfig;

    const getCustomizationPanelControllers = () => controllers || customizationPanelControllers;

    const handleStateOverrides = () => {
        resetStateOverridesDriver.current.handleStateOverrides();
        forceUpdate();
    };

    const internalReportBI = (event: PanelEventList, reportedParams?: BIParams) => {
        const { selector, variant } = editSession;
        const part = editSession.getSelectorWithoutStates().replace(`.${variant}`, 'root');
        const finalState = getFinalStateName(selector);
        const state = finalState ? finalState : 'regular';
        const params = Object.assign({}, reportedParams, { part, state });
        reportBI(event, params, panelHost);
    };

    const onBackButtonClick = () => {
        onPageChange && onPageChange(StylePanelPage.PresetPanel);
    };

    const onDeleteBlockVariant = (category: string, index: number) => {
        const newUserBlockVariants = { ...userBlockVariants };
        if (!newUserBlockVariants[category] || newUserBlockVariants[category].length <= index) {
            // nothing to delete
            return;
        }

        newUserBlockVariants[category].splice(index, 1);
        setUserBlockVariants(newUserBlockVariants);
    };

    const onSaveBlockVariant = (category: string, variant: DeclarationMap) => {
        const newUserBlockVariants = { ...userBlockVariants };
        if (!newUserBlockVariants[category]) {
            newUserBlockVariants[category] = [];
        }
        newUserBlockVariants[category].push(variant);
        setUserBlockVariants(newUserBlockVariants);
    };

    const keydown = (e: KeyboardEvent, id: string) => {
        if (e.keyCode === keycode('up') || e.keyCode === keycode('down')) {
            editSession.changeDriver.startBlockingCommits(id);
        }
    };

    const keyup = (e: KeyboardEvent, id: string) => {
        if (e.keyCode === keycode('up') || e.keyCode === keycode('down')) {
            editSession.changeDriver.releaseBlockingCommits(true, id);
        }
    };

    return (
        <div className={style(classes.root, className)}>
            <header className={classes.header}>
                <nav className={classes.headerLefNav}>
                    <span onClick={onBackButtonClick} className={style(classes.navButton, classes.backButton)} />
                </nav>
                <div className={classes.headerTitle}>{editSession.componentName} Design</div>
                <nav className={classes.headerRightNav}>
                    <span className={style(classes.navButton, classes.helpButton)} />
                    <span className={style(classes.navButton, classes.closeButton)} />
                </nav>
            </header>
            <StylePanel
                className={classes.stylePanel}
                page={editSession.page}
                panelHost={fixPanelHost(panelHost)}
                onPageChange={onPageChange}
                onToggleCssPanel={() => null}
            >
                <PresetPanel
                    className={classes.presetPanel}
                    page={StylePanelPage.PresetPanel}
                    presetDriver={editSession.presetDriver}
                    layouts={editSession.presetSectionLayouts}
                    onSelect={onPresetSelect}
                />
                <CustomizationPanel
                    className={classes.customizationPanel}
                    page={StylePanelPage.CustomizationPanel}
                    selector={editSession.getSelectorWithoutStates()}
                    stateSelector={editSession.selector}
                    elements={editSession.elements}
                    controllerPartTypes={editSession.controllerPartTypes}
                    selectorConfiguration={editSession.selectorConfiguration}
                    states={getStates(editSession.getSelectorWithoutStates())}
                    siteSheetPath={editSession.siteStylesheetPath}
                    sheetPath={editSession.stylesheetPath}
                    aggregationPaths={editSession.aggregationPaths}
                    stylableDriver={editSession.stylableDriver}
                    archetypeList={getArchetypeList()}
                    categoryConfiguration={getCategoryConfig()}
                    controllers={getCustomizationPanelControllers()}
                    panelHost={fixPanelHost(panelHost)}
                    onForceState={onForceState}
                    onElementSelect={onElementSelect}
                    onStateSelect={onStateSelect}
                    onRemoveOverrides={onRemoveOverrides}
                    onSaveBlockVariant={onSaveBlockVariant}
                    onDeleteBlockVariant={onDeleteBlockVariant}
                    changeRuleDeclarations={editSession.changeDriver.changeSelectors}
                    revertRule={editSession.changeDriver.revertRule}
                    userBlockVariants={userBlockVariants}
                    plane={plane}
                    initialView={initialView}
                    reportBI={(event, params) => internalReportBI(event, params)}
                    handleStateOverrides={handleStateOverrides}
                    stateOverridesConfig={resetStateOverridesDriver.current.stateOverridesConfig}
                />
            </StylePanel>
        </div>
    );
};
