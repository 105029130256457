/* eslint-disable react/no-string-refs */
import React from 'react';
import keycode from 'keycode';

import { DownArrow, SmallDownArrow, isClickInElement } from '@wixc3/stylable-panel-common-react';

import { OptionList, Option } from './option-list';

import { style, classes } from './drop-down.st.css';

export interface KeywordVisualizerFactoryProps {
    options: Option[];
    volatileOptions?: Option[];
    customOptionRender?: (item: Option, index: number) => JSX.Element | undefined;
    selectedIcon?: boolean;
    noCloseOnSelect?: boolean;
    noOpenOnSingleOption?: boolean;
    smallArrow?: boolean;
    'data-aid'?: string;
}

export interface KeywordVisualizerComponentProps {
    disabled?: boolean;
}

export interface DropDownProps extends KeywordVisualizerFactoryProps, KeywordVisualizerComponentProps {
    value: string;
    onHover?: (itemId: string | null) => void;
    onSelect?: (itemId: string) => void;
    className?: string;
    style?: React.CSSProperties;
}

export interface DropDownState {
    open: boolean;
}

export class DropDown extends React.Component<DropDownProps, DropDownState> {
    public state: DropDownState = { open: false };

    public optionListOpen() {
        return this.state.open;
    }

    public render() {
        const { open } = this.state;
        const {
            value,
            options,
            customOptionRender,
            selectedIcon,
            onHover,
            disabled,
            volatileOptions,
            noOpenOnSingleOption,
            smallArrow,
            className,
            style: propStyle,
        } = this.props;

        const canOpen = !noOpenOnSingleOption || options.length > 1;

        let valueItem = options.find((item: Option) => value === item.id);
        if (!valueItem && volatileOptions && volatileOptions.length > 0) {
            valueItem = volatileOptions.find((item: Option) => value === item.id);
        }
        const valueDisplayName = valueItem ? valueItem.displayName.trim() : '';

        return (
            <span
                className={style(classes.root, className)}
                style={propStyle}
                tabIndex={0}
                onFocus={this.addFocusEventListeners}
                onBlur={this.cleanupFocusEventListeners}
            >
                <div
                    className={style(classes.dropDown, {
                        open,
                        disabled: !!disabled,
                        canOpen,
                    })}
                    onClick={this.toggleOpen}
                    data-aid={this.props['data-aid'] || 'st_dropdown'}
                >
                    <span
                        className={classes.value}
                        style={
                            valueItem && valueItem.font
                                ? {
                                      fontFamily: valueItem.font,
                                  }
                                : {}
                        }
                    >
                        {valueDisplayName}
                    </span>
                    {canOpen ? (
                        <span className={classes.arrowWrapper}>
                            {smallArrow ? (
                                <SmallDownArrow className={classes.arrow} />
                            ) : (
                                <DownArrow className={classes.arrow} />
                            )}
                        </span>
                    ) : null}
                </div>
                {open ? (
                    <OptionList
                        className={classes.popup}
                        value={value}
                        options={options}
                        customOptionRender={customOptionRender}
                        selectedIcon={selectedIcon}
                        onHover={onHover}
                        onSelect={this.handleSelectItem}
                        onClose={this.closePopup}
                        ref="optionList"
                    />
                ) : null}
            </span>
        );
    }

    public closePopup = () => {
        this.cleanupClickEventListeners();
        this.setState({ open: false });
    };

    public componentWillUnmount() {
        this.cleanupClickEventListeners();
        this.cleanupFocusEventListeners();
    }

    private toggleOpen = (event?: React.MouseEvent<HTMLDivElement>) => {
        const { options, disabled, noOpenOnSingleOption } = this.props;
        const { open } = this.state;

        if (disabled || (noOpenOnSingleOption && options.length <= 1)) {
            return;
        }

        if (event) {
            event.stopPropagation();
        }

        if (!open) {
            this.openPopup();
        } else {
            this.closePopup();
        }
    };

    private openPopup() {
        document.addEventListener('click', this.handleDocumentClick);
        this.setState({ open: true });
    }

    private cleanupClickEventListeners() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    private handleDocumentClick = (event: MouseEvent) => {
        const optionListRef = this.refs.optionList;
        if (optionListRef && !isClickInElement(event, optionListRef)) {
            this.closePopup();
        }
    };

    private handleSelectItem = (id: string) => {
        const { onSelect, noCloseOnSelect } = this.props;
        onSelect && onSelect(id);
        !noCloseOnSelect && this.closePopup();
    };

    private addFocusEventListeners = () => {
        document.addEventListener('keydown', this.handleKeyDown);
    };

    private cleanupFocusEventListeners = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === keycode('enter')) {
            if (!this.refs.optionList || !(this.refs.optionList as OptionList).hasHovered()) {
                this.toggleOpen();
            }
        }
    };
}
