import React from 'react';
import chroma from 'chroma-js';

import {
    BorderColors,
    Borders,
    BordersShallow,
    BorderStyles,
    INITIAL_UNIVERSAL,
    INITIAL_IMAGE_SOURCE,
} from '@wixc3/shorthands-opener';

import { BackgroundBox, CompositeBlock, Title, Tooltip } from '@wixc3/stylable-panel-components';
import {
    DeclarationMap,
    FullDeclarationMap,
    GenericDeclarationMap,
    parseGradient,
    stringifyBorderImage,
    stringifyGradient,
    stringifyTopRightBottomLeft,
    StylablePanelTranslationKeys,
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
} from '@wixc3/stylable-panel-drivers';
import { DownArrow } from '@wixc3/stylable-panel-common-react';
import { areRecordsEqual, DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';

import type { DeclarationVisualizerProps, OpenedDeclarationList, VisualizerComponent } from '../../types';
import {
    BorderType,
    getSideControlConfiguration,
    getSideControls,
    SideControlKey,
} from './border-visualizer-side-controls';
import { controllerNoStateResizing } from '../../utils/controller-data-utils';
import { BorderStylePicker, FillPicker, FillPickerProps, FillPickerTabs } from '../../pickers';
import { getTranslate } from '../../hosts/translate';
import {
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    getOpenedDeclarationList,
    getShorthandChange,
    getShorthandControllerValue,
    createDeclarationVisualizer,
} from '../../utils';
import {
    BorderWidthDoubleVisualizer,
    BorderWidthSolidVisualizer,
    getDimensionUnits,
} from '../../generated-visualizers';

import { classes, style } from './border-visualizer.st.css';

export type BorderEdges = 'top' | 'right' | 'bottom' | 'left';

enum SelectedBorder {
    None = -1,
    Top = 0,
    Right = 1,
    Bottom = 2,
    Left = 3,
    All = 4,
}

const DEFAULT_SELECTED_BORDER = SelectedBorder.Top;
const SIDES: BorderEdges[] = ['top', 'right', 'bottom', 'left'];

function sideToSelectedBorder(side?: BorderEdges): SelectedBorder {
    switch (side) {
        case 'top':
            return SelectedBorder.Top;
        case 'right':
            return SelectedBorder.Right;
        case 'bottom':
            return SelectedBorder.Bottom;
        case 'left':
            return SelectedBorder.Left;
    }
    return SelectedBorder.None;
}

interface TopRightBottomLeft {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

export interface BorderConfig {
    width: string;
    style: string;
    fill: string;
}

interface BorderSide extends BorderConfig {
    side: BorderEdges;
}

interface BorderPreviewPolygon {
    select: SelectedBorder;
    svgPath: string;
    viewBox: string;
}

interface BorderPreviewSVG {
    width: number;
    height: number;
    sides: Record<BorderEdges, BorderPreviewPolygon>;
}

const DEFAULT_WIDTH = '0px';
const DEFAULT_WIDTHS = ['0', '0px', 'medium', INITIAL_UNIVERSAL];
const EMPTY_WIDTHS = ['0', '0px'];
const DEFAULT_STYLE = 'none';
const DEFAULT_COLOR = 'currentcolor';
const DEFAULT_IMAGE_WIDTH = '1';
const DEFAULT_IMAGE_SLICE = '1';

export const BorderInitialConfig: BorderConfig = {
    width: '1px',
    style: 'solid',
    fill: 'value(site_2_3)',
};

export const BorderVisualizerConfig = {
    resetCorners: true,
};

const normalizeEmptyWidth = (width: string) => (!~EMPTY_WIDTHS.indexOf(width) ? width : BorderInitialConfig.width);

export const MINIMUM_BORDER_WIDTH = 0;
export const MINIMUM_DOUBLE_BORDER_WIDTH = 3;

const normalizeDoubleWidth = (width: string, sideStyle: string) => {
    const isDoubleStyle = sideStyle === 'double';
    return !width
        ? `${!isDoubleStyle ? MINIMUM_BORDER_WIDTH : MINIMUM_DOUBLE_BORDER_WIDTH}px`
        : !isDoubleStyle || parseInt(width, 10) >= MINIMUM_DOUBLE_BORDER_WIDTH
        ? width
        : `${MINIMUM_DOUBLE_BORDER_WIDTH}px`;
};

const SOLID_WIDTH_THRESHOLD = 3;
const IMAGE_WIDTH_THRESHOLD = 14;
const LIGHT_THRESHOLD = 25;

const EMPTY_CORNER_RADIUSES = ['0', '0px'];

const viewBoxes = {
    topBottom: '0 0 132 18',
    verticalTopBottom: '0 0 104 18',
    leftRight: '0 0 18 98',
};

const BORDER_PREVIEW_SVG: BorderPreviewSVG = {
    width: 128,
    height: 120,
    sides: {
        top: {
            select: SelectedBorder.Top,
            svgPath:
                'M98.3939322,0.5 C99.796548,0.5 101.066378,1.06852236 101.985554,1.98769865 C102.904731,2.90687495 103.473253,4.17670518 103.473253,5.57932092 C103.473253,6.70698592 103.097995,7.80258849 102.406625,8.69345023 L102.406625,8.69345023 L97.223567,15.3720475 C96.181724,16.7145079 94.5778406,17.5 92.8785358,17.5 L92.8785358,17.5 L11.1214642,17.5 C9.42215944,17.5 7.81827599,16.7145079 6.77643302,15.3720475 L6.77643302,15.3720475 L1.59337546,8.69345023 C0.733432393,7.58537584 0.404035954,6.23364197 0.566643877,4.94394092 C0.729251801,3.65423987 1.38386409,2.42657166 2.49193848,1.5666286 C3.38280022,0.875257711 4.47840279,0.5 5.60606778,0.5 L5.60606778,0.5 Z',
            viewBox: viewBoxes.verticalTopBottom,
        },
        right: {
            select: SelectedBorder.Right,
            svgPath:
                'M17.5,5.27264182 C17.5,4.00728544 16.9975077,2.79371877 16.1029967,1.89874575 C14.2396453,0.0344320871 11.2177758,0.0336519813 9.35346211,1.89700333 L2.11191681,9.13481073 C1.0798525,10.1663423 0.5,11.5657192 0.5,13.0249019 L0.5,84.9750981 C0.5,86.4342808 1.0798525,87.8336577 2.11191681,88.8651893 L9.35346211,96.1029967 C10.2484351,96.9975077 11.4620018,97.5 12.7273582,97.5 C15.3632155,97.5 17.5,95.3632155 17.5,92.7273582 L17.5,5.27264182 Z',
            viewBox: viewBoxes.leftRight,
        },
        bottom: {
            select: SelectedBorder.Bottom,
            svgPath:
                'M92.8517889,0.5 C94.5510937,0.5 96.1549772,1.28549215 97.1968201,2.62795255 L97.1968201,2.62795255 L102.379878,9.30654977 C103.239821,10.4146242 103.569217,11.766358 103.406609,13.0560591 C103.244001,14.3457601 102.589389,15.5734283 101.481315,16.4333714 C100.590453,17.1247423 99.4948504,17.5 98.3671854,17.5 L98.3671854,17.5 L5.57932092,17.5 C4.17670518,17.5 2.90687495,16.9314776 1.98769865,16.0123013 C1.06852236,15.093125 0.5,13.8232948 0.5,12.4206791 C0.5,11.2930141 0.875257711,10.1974115 1.5666286,9.30654977 L1.5666286,9.30654977 L6.74968617,2.62795255 C7.79152913,1.28549215 9.39541258,0.5 11.0947173,0.5 L11.0947173,0.5 Z',
            viewBox: viewBoxes.verticalTopBottom,
        },
        left: {
            select: SelectedBorder.Left,
            svgPath:
                'M0.5,5.27264182 L0.5,92.7273582 C0.5,95.3632155 2.63678453,97.5 5.27264182,97.5 C6.53799819,97.5 7.75156487,96.9975077 8.64653789,96.1029967 L15.8880832,88.8651893 C16.9201475,87.8336577 17.5,86.4342808 17.5,84.9750981 L17.5,13.0249019 C17.5,11.5657192 16.9201475,10.1663423 15.8880832,9.13481073 L8.64653789,1.89700333 C6.78222423,0.0336519813 3.76035468,0.0344320871 1.89700333,1.89874575 C1.00249227,2.79371877 0.5,4.00728544 0.5,5.27264182 Z',
            viewBox: viewBoxes.leftRight,
        },
    },
};

interface BorderVisualizerState {
    borderType: BorderType;
    selected: SelectedBorder;
    editing: SelectedBorder;
    stylePicker: SelectedBorder;
    linked: boolean;
}

type BorderImageProps =
    | 'border-image'
    | 'border-image-source'
    | 'border-image-slice'
    | 'border-image-width'
    | 'border-image-outset'
    | 'border-image-repeat';

type BorderEdgeProps = 'border-top' | 'border-bottom' | 'border-left' | 'border-right';

type BorderRadiusProps =
    | 'border-radius'
    | 'border-top-left-radius'
    | 'border-top-right-radius'
    | 'border-bottom-right-radius'
    | 'border-bottom-left-radius';

export type BorderLonghandProps = Borders | BordersShallow | BorderEdgeProps;
export type BorderProps = 'border' | BorderLonghandProps | BorderImageProps | BorderRadiusProps;
export type BorderShorthandKeys =
    | 'border'
    | BordersShallow
    | 'border-top'
    | 'border-right'
    | 'border-bottom'
    | 'border-left'
    | 'border-image';

export type BorderVisualizerProps = DeclarationVisualizerProps<BorderProps>;

type BorderDeclarationMap = GenericDeclarationMap<BorderProps>;

const BORDER_LONGHAND_PROPS: BorderLonghandProps[] = [
    'border-width',
    'border-style',
    'border-color',
    'border-top',
    'border-top-width',
    'border-top-style',
    'border-top-color',
    'border-right',
    'border-right-width',
    'border-right-style',
    'border-right-color',
    'border-bottom',
    'border-bottom-width',
    'border-bottom-style',
    'border-bottom-color',
    'border-left',
    'border-left-width',
    'border-left-style',
    'border-left-color',
];
const BORDER_PROPS_LIST: BorderProps[] = [
    'border',
    ...BORDER_LONGHAND_PROPS,
    'border-image',
    'border-image-source',
    'border-image-slice',
    'border-image-width',
    'border-image-outset',
    'border-image-repeat',
];

const OUTSIDE_PROPS_LIST: BorderProps[] = [
    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
];

const BORDER_SHORTHAND_KEYS: BorderShorthandKeys[] = [
    'border',
    'border-style',
    'border-width',
    'border-color',
    'border-top',
    'border-right',
    'border-bottom',
    'border-left',
    'border-image',
];

const ALL_PROPS_LIST = BORDER_PROPS_LIST.concat(OUTSIDE_PROPS_LIST);

export const EMPTY_BORDER_LONGHANDS_CHANGE_VALUE = BORDER_LONGHAND_PROPS.reduce(
    (emptyChangeValue, prop) => ({ ...emptyChangeValue, [prop]: '' }),
    {} as Record<BorderLonghandProps, string>
);
export const EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES = SIDES.reduce((changeValue, side) => {
    changeValue[side] = {
        [`border-${side}-width`]: '',
        [`border-${side}-style`]: '',
        [`border-${side}-color`]: '',
    };
    return changeValue;
}, {} as Record<BorderEdges, Record<string, string>>);
export const EMPTY_CORNERS_CHANGE_VALUE = {
    'border-radius': '0px',
};
export const EMPTY_IMAGE_BORDER_CHANGE_VALUE = {
    'border-image': '',
    'border-image-source': '',
};

export class BorderVisualizer
    extends React.Component<BorderVisualizerProps, BorderVisualizerState>
    implements VisualizerComponent<BorderProps>
{
    public static BLOCK_VARIANT_CONTROLLER = false;
    public static INPUT_PROPS: BorderProps[] = BORDER_PROPS_LIST;
    public static OUTSIDE_PROPS: BorderProps[] = OUTSIDE_PROPS_LIST;
    private declarationMapValue: BorderDeclarationMap;
    private openedDeclarationList: OpenedDeclarationList<BorderProps>;
    private fallbackValue: FullDeclarationMap = {};
    private changeFromWithin = false;

    constructor(props: BorderVisualizerProps) {
        super(props);
        this.state = {
            borderType: BorderType.Solid,
            selected: SelectedBorder.None,
            editing: SelectedBorder.None,
            stylePicker: SelectedBorder.None,
            linked: false,
        };

        this.openedDeclarationList = getOpenedDeclarationList<BorderShorthandKeys, BorderProps>(
            BORDER_SHORTHAND_KEYS,
            ALL_PROPS_LIST,
            props
        );
        this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, props);
        this.state = this.setSelected(this.declarationMapValue);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: BorderVisualizerProps) {
        const newOpenedDeclarationList = getOpenedDeclarationList<BorderShorthandKeys, BorderProps>(
            BORDER_SHORTHAND_KEYS,
            ALL_PROPS_LIST,
            props
        );

        const newDeclarationMapValue = getShorthandControllerValue(newOpenedDeclarationList, props);
        if (
            (this.state.editing === SelectedBorder.None &&
                !this.changeFromWithin &&
                !areRecordsEqual(this.declarationMapValue, newDeclarationMapValue)) ||
            this.declarationMapValue['border-image-source'] !== newDeclarationMapValue['border-image-source']
        ) {
            this.setState(this.setSelected(newDeclarationMapValue));
        } else if (!(this.changeFromWithin && areRecordsEqual(this.declarationMapValue, newDeclarationMapValue))) {
            this.changeFromWithin = false;
        }
        this.openedDeclarationList = newOpenedDeclarationList;
        this.declarationMapValue = newDeclarationMapValue;
    }

    public render() {
        const { plane = DEFAULT_PLANE, panelHost, className } = this.props;
        const { borderType } = this.state;
        const translate = getTranslate(panelHost);
        const noStateResizing = controllerNoStateResizing(this.props);

        const solid = borderType === BorderType.Solid || borderType === BorderType.Gradient;

        return (
            <div className={style(classes.root, { plane }, className)} data-box-controller-highlight-parts>
                <Title
                    className={classes.title}
                    text={translate(StylablePanelTranslationKeys.controller.borders.title)}
                />
                <CompositeBlock
                    className={style(classes.controllerBlock)}
                    title={translate(StylablePanelTranslationKeys.controller.borders.sectionLabel)}
                    information={translate(StylablePanelTranslationKeys.controller.borders.sectionTooltip)}
                />
                <div className={classes.boxWrapper}>
                    {solid ? this.renderSolid(noStateResizing) : this.renderImage(noStateResizing)}
                </div>
            </div>
        );
    }

    private setSelected(declarationMapValue: BorderDeclarationMap): BorderVisualizerState {
        let borderType: BorderType = BorderType.Solid;

        const imageSource = declarationMapValue['border-image-source'];
        const hasImageSource =
            imageSource !== undefined &&
            imageSource !== INITIAL_IMAGE_SOURCE &&
            imageSource !== INITIAL_UNIVERSAL &&
            declarationMapValue['border-image-slice'] &&
            SIDES.some(
                (side) =>
                    !!declarationMapValue[`border-${side}-style` as BorderStyles] &&
                    declarationMapValue[`border-${side}-style` as BorderStyles] !== DEFAULT_STYLE
            );
        if (hasImageSource) {
            if (imageSource?.includes('gradient')) {
                borderType = BorderType.Gradient;
            } else {
                borderType = BorderType.Image;
            }
        }

        let selected: SelectedBorder | undefined;
        let linked: boolean | undefined;

        if (!this.changeFromWithin) {
            const sideValuesPerSide = SIDES.map((side) => this.getSideValues(side, declarationMapValue, borderType));
            if (
                sideValuesPerSide.every(
                    (sideValues) =>
                        sideValues.width === sideValuesPerSide[0].width &&
                        sideValues.style === sideValuesPerSide[0].style &&
                        sideValues.fill === sideValuesPerSide[0].fill
                )
            ) {
                selected = SelectedBorder.All;
                linked = true;
            } else {
                const nonEmptyMarker = sideValuesPerSide.map((v) => !~DEFAULT_WIDTHS.indexOf(v.width));
                const hasNonEmptySides = nonEmptyMarker.filter((f) => f).length > 0;
                const hasSingleNonEmpty = nonEmptyMarker.filter((f) => f).length === 1;
                const nonEmptyIndex = nonEmptyMarker.indexOf(true);

                if (hasSingleNonEmpty) {
                    selected = sideToSelectedBorder(sideValuesPerSide[nonEmptyIndex].side);
                } else if (hasNonEmptySides) {
                    selected = SelectedBorder.None;
                    linked = false;
                } else {
                    selected = SelectedBorder.None;
                    linked = true;
                }
            }
        }

        return {
            ...this.state,
            borderType,
            selected: selected ?? this.state.selected,
            linked: linked ?? this.state.linked,
        };
    }

    private renderSolid(noStateResizing = false) {
        const { linked } = this.state;

        const translate = getTranslate(this.props.panelHost);
        return (
            <>
                <div className={classes.topControl}>
                    <div className={style(classes.verticalControlContentWrapper, { top: true })}>
                        {this.renderSideControls('top', SelectedBorder.Top, noStateResizing)}
                    </div>
                </div>

                <div className={classes.borderPreviewContainer}>
                    <div className={classes.leftControl}>
                        <div className={style(classes.controlContainer)}>
                            <div className={style(classes.controlContent)}>
                                {this.renderSideControls('left', SelectedBorder.Left, noStateResizing)}
                            </div>
                        </div>
                    </div>

                    <div className={classes.borderShapePreview}>
                        {this.renderSolidPreview()}
                        <Tooltip
                            text={translate(
                                linked
                                    ? StylablePanelTranslationKeys.controller.borders.unlockTooltip
                                    : StylablePanelTranslationKeys.controller.borders.lockTooltip
                            )}
                            verticalAdjust={-71}
                            horizontalAdjust={79}
                        >
                            <div
                                className={style(classes.linkButton, {
                                    borderType: 'solid',
                                    linked,
                                })}
                                onClick={this.toggleLinked}
                            />
                        </Tooltip>
                    </div>

                    <div className={classes.rightControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent}>
                                {this.renderSideControls('right', SelectedBorder.Right, noStateResizing)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.bottomControl}>
                    <div className={style(classes.verticalControlContentWrapper, { bottom: true })}>
                        {this.renderSideControls('bottom', SelectedBorder.Bottom, noStateResizing)}
                    </div>
                </div>
            </>
        );
    }

    private renderImage(noStateResizing = false) {
        return (
            <>
                <div className={classes.topControl}>
                    {this.renderSideControls('top', SelectedBorder.Top, noStateResizing)}
                </div>
                <div className={classes.borderPreviewContainer}>
                    <div className={classes.leftControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent} />
                        </div>
                    </div>
                    <div className={classes.borderShapePreview}>
                        {this.renderImagePreview()}
                        <div
                            className={style(classes.linkButton, {
                                borderType: 'image',
                                linked: true,
                            })}
                        />
                    </div>
                    <div className={classes.rightControl}>
                        <div className={classes.controlContainer}>
                            <div className={classes.controlContent} />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderBorderView(side: BorderEdges) {
        const { selected: stateSelected, linked } = this.state;

        const borderPreviewSide = BORDER_PREVIEW_SVG.sides[side];

        const selected = linked || stateSelected === borderPreviewSide.select;

        return (
            <span
                key={`selection_${side}`}
                className={style(classes.selection, {
                    selected,
                    light: this.isLightFill(this.getSideValues(side, this.declarationMapValue).fill),
                    side,
                })}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes.svgSelection}
                    viewBox={borderPreviewSide.viewBox}
                >
                    <g className={style(classes.svgSelectionBorder, { selected })}>
                        <path
                            className={classes.svgSelectionBorderPath}
                            d={borderPreviewSide.svgPath}
                            onClick={() => this.selectBorder(borderPreviewSide.select)}
                        />
                    </g>
                </svg>
            </span>
        );
    }

    private renderSolidPreview() {
        const { sides } = BORDER_PREVIEW_SVG;

        return (
            <div className={classes.previewWrapper}>
                <div className={classes.svgContainer}>
                    {(Object.keys(sides) as BorderEdges[]).map((side) => this.renderBorderView(side))}
                </div>
                {(Object.keys(sides) as BorderEdges[]).map((side) => (
                    <div
                        key={`border_side_preview_${side}`}
                        className={style(classes.borderSidePreview, { side })}
                        style={this.sideBorderPreviewStyle(side)}
                        onClick={() => this.selectBorder(sides[side].select)}
                    />
                ))}
            </div>
        );
    }

    private renderImagePreview() {
        const { width, height } = BORDER_PREVIEW_SVG;

        const widths = getSideWidth(this.declarationMapValue, BorderType.Image) as any;
        widths.top = `${Math.min(parseFloat(widths.top), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.right = `${Math.min(parseFloat(widths.right), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.bottom = `${Math.min(parseFloat(widths.bottom), IMAGE_WIDTH_THRESHOLD)}px`;
        widths.left = `${Math.min(parseFloat(widths.left), IMAGE_WIDTH_THRESHOLD)}px`;

        return (
            <div
                className={classes.imageWrapper}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                }}
            >
                <div
                    className={classes.borderImagePreview}
                    style={{
                        borderImage: stringifyBorderImage({
                            'border-image-source': this.declarationMapValue['border-image-source'],
                            'border-image-slice': this.declarationMapValue['border-image-slice'],
                            'border-image-width': stringifyTopRightBottomLeft(widths),
                            'border-image-repeat': this.declarationMapValue['border-image-repeat'],
                        }),
                    }}
                >
                    <div className={classes.imageInner} />
                </div>
            </div>
        );
    }

    private getSideControlRenderMap(
        sideValues: BorderSide,
        side: BorderEdges,
        select: SelectedBorder
    ): Record<SideControlKey, () => JSX.Element> {
        return {
            [SideControlKey.Width]: () => this.renderWidthInput(sideValues.width, side, select),
            [SideControlKey.Fill]: () => this.renderFillInput(sideValues, side),
            [SideControlKey.Style]: () => this.renderStyleInput(sideValues.style, side, select),
        };
    }

    private renderSideControls(side: BorderEdges, select: SelectedBorder, noStateResizing = false) {
        const { borderType, stylePicker } = this.state;

        const selected = this.isBorderSelected(select);
        const nonSolid = borderType !== BorderType.Solid;
        const image = borderType === BorderType.Image || borderType === BorderType.Pattern;

        const sideValues = this.getSideValues(side, this.declarationMapValue);

        const sideControlConfiguration = getSideControlConfiguration({
            selected,
            borderType,
            noStateResizing,
        });
        const sideControlRenderMap = this.getSideControlRenderMap(sideValues, side, select);
        const sideControls = getSideControls(sideControlConfiguration);

        return (
            <span className={style(classes.sideControls, { side, nonSolid, selected: !image && selected, image })}>
                {sideControls.map((sideControl) => sideControlRenderMap[sideControl]())}
                {stylePicker === select ? (
                    <BorderStylePicker
                        className={classes.stylePicker}
                        value={sideValues.style}
                        onChange={(value) => this.changeStyle(side, value)}
                        onClose={() =>
                            this.setState({ editing: SelectedBorder.None, stylePicker: SelectedBorder.None })
                        }
                        onBlur={() => this.setState({ editing: SelectedBorder.None, stylePicker: SelectedBorder.None })}
                    />
                ) : null}
            </span>
        );
    }

    private renderWidthInput(width: string, side: BorderEdges, select: SelectedBorder) {
        const { drivers, panelHost } = this.props;
        const { editing: stateEditing } = this.state;

        const translate = getTranslate(panelHost);

        const editing = stateEditing === select;
        const sideStyle = getSideStyle(side, this.declarationMapValue);

        const isDoubleStyle = sideStyle === 'double';
        const SideVisualizer = isDoubleStyle ? BorderWidthDoubleVisualizer : BorderWidthSolidVisualizer;

        const units = getDimensionUnits({
            id: isDoubleStyle ? DIMENSION_ID.WIDTH_DOUBLE : DIMENSION_ID.WIDTH,
            dimensionUnits: panelHost?.dimensionUnits,
        });

        return (
            <SideVisualizer
                key={`${SideControlKey.Width}_${side}`}
                className={classes.inputElement}
                drivers={drivers}
                value={createVisualizerValueFromDeclarationMap({ width })}
                config={{ units }}
                tooltipContent={
                    !editing ? translate(StylablePanelTranslationKeys.controller.borders.widthTooltip) : undefined
                }
                onChange={(value) => {
                    const { width } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                    if (width) {
                        this.changeWidth(side, width);
                    }
                }}
                onBlur={() => this.setState({ editing: SelectedBorder.None })}
            />
        );
    }

    private renderStyleInput(sideStyle: string, side: BorderEdges, select: SelectedBorder) {
        const { stylePicker } = this.state;

        const translate = getTranslate(this.props.panelHost);

        return (
            <Tooltip
                key={`${SideControlKey.Style}_${side}`}
                text={translate(StylablePanelTranslationKeys.controller.borders.styleTooltip)}
            >
                <span className={classes.miniControllerWrapper}>
                    <span
                        className={classes.styleBox}
                        onClick={() => {
                            this.setState({
                                stylePicker: stylePicker === SelectedBorder.None ? select : SelectedBorder.None,
                            });
                        }}
                    >
                        <span
                            className={classes.styleIndicator}
                            style={{
                                borderTopStyle: (sideStyle !== DEFAULT_STYLE
                                    ? sideStyle
                                    : BorderInitialConfig.style) as any,
                            }}
                        />
                        <DownArrow className={classes.styleArrow} />
                    </span>
                </span>
            </Tooltip>
        );
    }

    private renderFillInput(sideValues: BorderSide, side: BorderEdges) {
        const translate = getTranslate(this.props.panelHost);

        const noColor = sideValues.fill === DEFAULT_COLOR || sideValues.fill === 'transparent';

        return (
            <span key={`${SideControlKey.Fill}_${side}`} className={classes.miniControllerWrapper}>
                <Tooltip
                    text={translate(StylablePanelTranslationKeys.controller.borders.fillTooltip)}
                    verticalAdjust={-9}
                >
                    <BackgroundBox
                        className={classes.colorBox}
                        value={sideValues.fill}
                        noColor={noColor}
                        showNoColorDiagonal
                        onClick={() => this.openFillPicker(sideValues, side)}
                        data-aid="st_bordercontroller_fillbox"
                    />
                </Tooltip>
            </span>
        );
    }

    private toggleLinked = () => {
        const linked = !this.state.linked;
        const selected = this.state.linked ? SelectedBorder.None : SelectedBorder.All;
        this.setState({ linked, selected });
    };

    private openFillPicker(sideValues: BorderSide, side: BorderEdges) {
        const { drivers, siteVarsDriver, panelHost } = this.props;
        const { borderType, linked } = this.state;

        if (!panelHost?.onOpenPanel) {
            return;
        }

        const translate = getTranslate(panelHost);

        this.fallbackValue['border-image'] = stringifyBorderImage(this.declarationMapValue) as string;
        if (!linked) {
            this.fallbackValue[`border-${side}`] = this.declarationMapValue[`border-${side}-color` as BorderColors]
                ? `${sideValues.width} ${sideValues.style} ${
                      this.declarationMapValue[`border-${side}-color` as BorderColors]
                  }`
                : '';
            this.fallbackValue = {
                ...this.fallbackValue,
                ...EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side],
            };
        } else {
            this.fallbackValue.border = this.declarationMapValue[`border-${side}-color` as BorderColors]
                ? `${sideValues.width} ${sideValues.style} ${
                      this.declarationMapValue[`border-${side}-color` as BorderColors]
                  }`
                : '';
            this.fallbackValue = {
                ...this.fallbackValue,
                ...EMPTY_BORDER_LONGHANDS_CHANGE_VALUE,
            };
        }

        const fillPickerProps: FillPickerProps = {
            className: classes.fillPicker,
            title: translate(StylablePanelTranslationKeys.controller.borders.fillPickerTitle),
            siteVarsDriver,
            value: sideValues.fill,
            drivers,
            tab: borderType as any as FillPickerTabs,
            panelHost,
            onChange: (changeValue: string | null, tab: FillPickerTabs) => this.changeFill(tab, side, changeValue),
            border: true,
        };

        panelHost.onOpenPanel(FillPicker.panelName, fillPickerProps);

        this.setState({ stylePicker: SelectedBorder.None, editing: SelectedBorder.All });
    }

    private selectBorder(selected: SelectedBorder) {
        this.setState({ selected });
    }

    private sideBorderPreviewStyle(side: BorderEdges) {
        const { borderType } = this.state;

        const sideValueStr = () => {
            const sideValue = this.getSideValues(side, this.declarationMapValue);
            const width = Math.min(parseFloat(sideValue.width), SOLID_WIDTH_THRESHOLD);
            if (borderType !== BorderType.Solid) {
                return `${width}px solid`;
            }
            return `${width}px ${sideValue.style} ${sideValue.fill}`;
        };

        const result: FullDeclarationMap = {
            [`border${side.charAt(0).toUpperCase()}${side.slice(1)}`]: sideValueStr(),
        };

        if (borderType !== BorderType.Solid) {
            result.borderImage = `${this.declarationMapValue['border-image-source']} 1`;
        }

        return result;
    }

    private isLightFill(color: string) {
        try {
            return chroma.deltaE('white', color) < LIGHT_THRESHOLD;
        } catch {
            //
        }
        return false;
    }

    private isBorderSelected(select: SelectedBorder) {
        const { selected: stateSelected } = this.state;
        return stateSelected === select || (stateSelected === SelectedBorder.All && select === DEFAULT_SELECTED_BORDER);
    }

    private onShorthandChange(value: BorderDeclarationMap) {
        this.props.onChange?.(
            getShorthandChange<BorderShorthandKeys, BorderProps>(
                BORDER_SHORTHAND_KEYS,
                value,
                this.openedDeclarationList,
                this.props
            )
        );
    }

    private changeWidth(side: BorderEdges, value: string) {
        const { borderType, linked } = this.state;

        const { style: sideStyle, fill } = this.getSideValueForChange(side);

        if (borderType === BorderType.Solid) {
            const prop = linked ? 'border' : `border-${side}`;
            this.changeFromWithin = true;
            this.onShorthandChange({
                [prop]: `${normalizeDoubleWidth(value, sideStyle)} ${sideStyle} ${fill}`,
                ...(linked ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]),
            } as BorderDeclarationMap);
        } else {
            const image = borderType === BorderType.Image || borderType === BorderType.Pattern;

            let newWidth = value;
            if (!linked && !image) {
                const widths = getSideWidth(this.declarationMapValue, borderType) as any;
                widths[side] = value;
                newWidth = stringifyTopRightBottomLeft(widths) as string;
            }

            let newSlice = DEFAULT_IMAGE_SLICE;
            if (image) {
                newSlice = this.declarationMapValue['border-image-slice'] || DEFAULT_IMAGE_SLICE;
            }

            this.notifyCornersReset();
            this.changeFromWithin = true;
            this.onShorthandChange({
                'border-image': stringifyBorderImage({
                    'border-image-source': fill,
                    'border-image-width': newWidth,
                    'border-image-slice': newSlice,
                    'border-image-repeat': this.declarationMapValue['border-image-repeat'],
                }),
                ...(BorderVisualizerConfig.resetCorners ? EMPTY_CORNERS_CHANGE_VALUE : {}),
            } as BorderDeclarationMap);
        }
    }

    private changeStyle(side: BorderEdges, value: string | null) {
        const { linked } = this.state;

        const prop = linked ? 'border' : `border-${side}`;
        const { width, fill } = this.getSideValueForChange(side);
        this.changeFromWithin = true;
        const changedWidth = normalizeEmptyWidth(normalizeDoubleWidth(width, value || ''));
        this.onShorthandChange({
            [prop]: `${changedWidth} ${value} ${fill}`,
            ...(linked ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]),
        } as BorderDeclarationMap);
        this.setState({ stylePicker: SelectedBorder.None });
    }

    private changeFill(tab: FillPickerTabs, side: BorderEdges, value: string | null) {
        const { linked } = this.state;

        if (!value) return;

        if (tab === FillPickerTabs.Solid) {
            const prop = linked ? 'border' : `border-${side}`;
            if (value === DEFAULT_COLOR) {
                this.changeFromWithin = true;
                this.onShorthandChange({ [prop]: '' } as BorderDeclarationMap);
            } else if (!!~value.indexOf('gradient') || !!~value.indexOf('url')) {
                // TODO: Probably not needed when switching tabs sends a value change
                this.changeFromWithin = true;
                this.onShorthandChange(this.fallbackValue as BorderDeclarationMap);
            } else {
                const { width, style: sideStyle } = this.getSideValueForChange(side);
                this.changeFromWithin = true;
                const changedWidth = normalizeEmptyWidth(normalizeDoubleWidth(width, sideStyle));
                this.onShorthandChange({
                    [prop]: `${changedWidth} ${sideStyle} ${value}`,
                    ...(linked ? EMPTY_BORDER_LONGHANDS_CHANGE_VALUE : EMPTY_BORDER_EDGE_LONGHANDS_CHANGE_VALUES[side]),
                    ...EMPTY_IMAGE_BORDER_CHANGE_VALUE,
                } as BorderDeclarationMap);
            }
        } else {
            const widths = getSideWidth(this.declarationMapValue, BorderType.Image) as any;
            SIDES.forEach((widthSide) => {
                if (side !== widthSide && !!~DEFAULT_WIDTHS.indexOf(widths[widthSide])) {
                    widths[widthSide] = DEFAULT_WIDTH;
                }
            });
            if (~DEFAULT_WIDTHS.indexOf(widths[side])) {
                widths[side] = BorderInitialConfig.width;
            }
            let sideStyle = getSideStyle(side, this.declarationMapValue);
            if (sideStyle === DEFAULT_STYLE) {
                sideStyle = BorderInitialConfig.style;
            }
            if (linked) {
                widths.top = widths.right = widths.bottom = widths.left = widths[side];
            }
            const newWidth = stringifyTopRightBottomLeft(widths) as string;

            this.notifyCornersReset();
            this.changeFromWithin = true;
            this.onShorthandChange({
                border:
                    `${BorderInitialConfig.width} ${sideStyle}` +
                    (this.declarationMapValue['border-color'] &&
                    this.declarationMapValue['border-color'] !== DEFAULT_COLOR
                        ? ` ${this.declarationMapValue['border-color']}`
                        : ''),
                ...EMPTY_BORDER_LONGHANDS_CHANGE_VALUE,
                'border-width': newWidth,
                'border-image': stringifyBorderImage({
                    'border-image-source': value,
                    'border-image-width': newWidth,
                    'border-image-slice': DEFAULT_IMAGE_SLICE,
                }),
                ...(BorderVisualizerConfig.resetCorners ? EMPTY_CORNERS_CHANGE_VALUE : {}),
            } as BorderDeclarationMap);
        }
    }

    private getSideValueForChange(side: BorderEdges) {
        const { width, style: sideStyle, fill: sideFill } = this.getSideValues(side, this.declarationMapValue);

        if (
            DEFAULT_WIDTHS.includes(width) &&
            (sideStyle === DEFAULT_STYLE || sideStyle === INITIAL_UNIVERSAL) &&
            (sideFill === DEFAULT_COLOR || sideFill === INITIAL_UNIVERSAL)
        ) {
            return BorderInitialConfig;
        }

        const fill = this.getSiteColorWrappedFill(sideFill);

        return { width, style: sideStyle, fill };
    }

    private getSiteColorWrappedFill(fill: string) {
        const { siteVarsDriver } = this.props;
        const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
        const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;
        const { borderType } = this.state;

        loadSiteColors();

        if (borderType === BorderType.Solid) {
            return wrapSiteColor(fill);
        }

        if (borderType === BorderType.Gradient) {
            const gradient = parseGradient(fill);
            if (gradient) {
                for (const colorStop of gradient.colorStops) {
                    colorStop.color = wrapSiteColor(colorStop.color);
                }

                return stringifyGradient(gradient, false);
            }
        }

        return fill;
    }

    private getSideValues(
        side: BorderEdges,
        declarationMapValue: BorderDeclarationMap,
        borderType?: BorderType
    ): BorderSide {
        if (!borderType) {
            borderType = this.state.borderType;
        }

        const borderSideColor =
            borderType === BorderType.Solid
                ? declarationMapValue[`border-${side}-color` as BorderColors]
                : declarationMapValue['border-image-source'];

        let width = getSideWidth(declarationMapValue, borderType)[side];
        if (!width || !!~DEFAULT_WIDTHS.indexOf(width)) {
            width = DEFAULT_WIDTH;
        }
        const sideStyle = getSideStyle(side, declarationMapValue);
        const fill = borderSideColor
            ? borderSideColor !== INITIAL_UNIVERSAL
                ? borderSideColor
                : DEFAULT_COLOR
            : DEFAULT_COLOR;
        return { side, width, style: sideStyle, fill };
    }

    private notifyCornersReset() {
        const { panelHost } = this.props;
        const {
            [`border-top-left-radius`]: topLeft,
            [`border-top-right-radius`]: topRight,
            [`border-bottom-right-radius`]: bottomRight,
            [`border-bottom-left-radius`]: bottomLeft,
        } = this.declarationMapValue;

        if (
            [topLeft, topRight, bottomRight, bottomLeft].every(
                (radiusValue) => !radiusValue || !!~EMPTY_CORNER_RADIUSES.indexOf(radiusValue)
            )
        ) {
            return;
        }

        const translate = getTranslate(panelHost);
        const message = StylablePanelTranslationKeys.notifications.cornerRadiusResetText;

        panelHost?.onEditorNotify &&
            panelHost.onEditorNotify({
                type: 'warning',
                title: translate(message),
                message,
            });
    }
}

export function getSideStyle(side: BorderEdges, value?: DeclarationMap) {
    if (!value) {
        return DEFAULT_STYLE;
    }
    const sideStyle = value[`border-${side}-style`];
    if (!sideStyle || sideStyle === INITIAL_UNIVERSAL) {
        return DEFAULT_STYLE;
    }
    return sideStyle;
}

export function getSideWidth(value: DeclarationMap, borderType: BorderType = BorderType.Gradient): TopRightBottomLeft {
    const imageWidth = value['border-image-width'];
    if (imageWidth && imageWidth !== DEFAULT_IMAGE_WIDTH && borderType !== BorderType.Solid) {
        const widths = imageWidth.split(' ');
        if (widths.length > 0) {
            return {
                top: widths[0],
                right: widths[1] || widths[0],
                bottom: widths[2] || widths[0],
                left: widths[3] || widths[1] || widths[0],
            };
        }
    }

    return {
        top: value['border-top-width'],
        right: value['border-right-width'],
        bottom: value['border-bottom-width'],
        left: value['border-left-width'],
    };
}

export const BorderDeclarationVisualizer = createDeclarationVisualizer<BorderProps>('border', BorderVisualizer);
