import type * as postcss from 'postcss';

import { isInRect } from '@wixc3/stylable-panel-common-react';
import type { StylesheetDriver } from '@wixc3/stylable-panel-drivers';

const DECLARATION_ID_PREFIX = 'declaration_';
const STYLE_RULE_ID_PREFIX = 'style_rule_';

export class DragDeclarationDriver {
    private source: postcss.Declaration | postcss.Rule;

    constructor(
        private sheet: StylesheetDriver,
        private sourceId: number,
        private moveHook?: () => void,
        private endHook?: () => void
    ) {
        this.source = this.sheet.getNodeById(this.sourceId) as postcss.Declaration;

        document.addEventListener('mousemove', this.onDocMouseMove);
        document.addEventListener('mouseup', this.onDocMouseUp);
    }

    private getIdFromPosition(prefix: string, clientX: number, clientY: number) {
        const elements = Array.from(document.querySelectorAll(`div[id^=${prefix}]`));
        for (const element of elements) {
            const rect = element.getBoundingClientRect();
            if (isInRect(clientX, clientY, rect)) {
                return parseInt(element.id.replace(prefix, ''), 10);
            }
        }

        return -1;
    }

    private getYById(prefix: string, id: number) {
        return document.querySelector(`div[id=${prefix}${id}]`)!.getBoundingClientRect().top;
    }

    private getDeclarationId(clientX: number, clientY: number) {
        return this.getIdFromPosition(DECLARATION_ID_PREFIX, clientX, clientY);
    }

    private getStyleRuleId(clientX: number, clientY: number) {
        return this.getIdFromPosition(STYLE_RULE_ID_PREFIX, clientX, clientY);
    }

    private getDeclarationY(id: number) {
        return this.getYById(DECLARATION_ID_PREFIX, id);
    }

    private getRuleY(id: number) {
        return this.getYById(STYLE_RULE_ID_PREFIX, id);
    }

    private onDocMouseMove = (event: MouseEvent) => {
        const sourceRule = this.source.parent as postcss.Rule;

        const targetId = this.getDeclarationId(event.clientX, event.clientY);
        if (targetId === -1) {
            const targetRuleId = this.getStyleRuleId(event.clientX, event.clientY);
            if (targetRuleId === -1) {
                return;
            }
            this.moveToStyleRule(sourceRule, targetRuleId);
            return;
        }
        if (this.sourceId === targetId) {
            return;
        }

        this.moveDeclaration(sourceRule, targetId);
    };

    private onDocMouseUp = () => {
        document.removeEventListener('mousemove', this.onDocMouseMove);
        document.removeEventListener('mouseup', this.onDocMouseUp);

        this.endHook && this.endHook();
    };

    private moveToStyleRule(sourceRule: postcss.Rule, targetRuleId: number) {
        const targetRule = this.sheet.getNodeById(targetRuleId) as postcss.Rule;
        if (!targetRule || sourceRule === targetRule) {
            return;
        }

        sourceRule.removeChild(this.source);
        if (targetRule.nodes.length) {
            const sourceY = this.getDeclarationY(this.sourceId);
            const targetY = this.getRuleY(targetRuleId);
            if (sourceY > targetY) {
                targetRule.append(this.source);
            } else {
                targetRule.prepend(this.source);
            }
        } else {
            targetRule.append(this.source);
        }

        this.sheet.updateFromAST(sourceRule);
        this.sheet.updateFromAST(targetRule);

        this.moveHook && this.moveHook();
    }

    private moveDeclaration(sourceRule: postcss.Rule, targetId: number) {
        const sourceY = this.getDeclarationY(this.sourceId);
        const targetDeclaration = this.sheet.getNodeById(targetId) as postcss.Declaration;
        const targetRule = targetDeclaration.parent as postcss.Rule;
        const targetY = this.getDeclarationY(targetId);

        sourceRule.removeChild(this.source);
        if (sourceY > targetY) {
            targetRule.insertBefore(targetDeclaration, this.source);
        } else {
            targetRule.insertAfter(targetDeclaration, this.source);
        }

        if (sourceRule === targetRule) {
            this.sheet.updateFromAST(targetRule);
        } else {
            this.sheet.updateFromAST(sourceRule);
            this.sheet.updateFromAST(targetRule);
        }

        this.moveHook && this.moveHook();
    }
}
