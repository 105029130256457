import React from 'react';
import { Scrollbars } from '@wixc3/stylable-panel-components';
import { SelectorConfigurationDriver, StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';
import { ExtendedGlobalHost, getTranslate } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './state-list-dialog.st.css';

export interface StateListProps {
    selectorConfiguration: SelectorConfigurationDriver;
    title?: string;
    usedStates?: string[];
    fixedStates?: string[];
    applyLabel?: string;
    selector?: string;
    panelHost?: ExtendedGlobalHost;
    onSubmit?: (value: string[]) => void;
    closeStateListDialog?: () => void;
    className?: string;
}

export interface StateListDialogState {
    selectedStates: string[];
}

export class StateListDialog extends React.Component<StateListProps, StateListDialogState> {
    public static panelName = 'stateListDialog';
    public readonly state: StateListDialogState;

    constructor(props: StateListProps, context: any) {
        super(props, context);
        this.state = {
            selectedStates: [],
        };
    }

    public render() {
        const { fixedStates, usedStates, applyLabel, panelHost } = this.props;

        const { selectedStates } = this.state;

        const translate = getTranslate(panelHost);
        const description = translate(StylablePanelTranslationKeys.dialog.stateOverrides.description);
        const key = 'state_list_dialog_item_';
        const disableApplyButtonState = fixedStates!.length === 0 || !this.state.selectedStates.length;

        const checked = (state: string) => selectedStates.indexOf(state) > -1;
        const disabled = (state: string) => fixedStates!.indexOf(state) > -1;

        return (
            <div className={style(classes.root)}>
                <div className={style(classes.stateListDescriptionText)}>{description}</div>
                <ul className={style(classes.stateListContainer)}>
                    <Scrollbars universal style={{ height: '140px' }}>
                        <span className={style(classes.items)}>
                            {usedStates!.map((state) => (
                                <li
                                    key={`${key}${state}`}
                                    className={style(classes.stateListItem, {
                                        disabled: disabled(state),
                                    })}
                                >
                                    <label
                                        htmlFor={`${key}${state}`}
                                        className={style(classes.stateListItemLabel, {
                                            disabled: disabled(state),
                                        })}
                                    >
                                        <input
                                            id={`${key}${state}`}
                                            type="checkbox"
                                            className={style(classes.stateListItemInput)}
                                            disabled={disabled(state)}
                                            checked={checked(state) || disabled(state)}
                                            onChange={() => false} /* to prevent warning */
                                        />
                                        <span onClick={() => this.onToggle(state)} className={style(classes.toggle)}>
                                            <span
                                                className={style(classes.checkboxSymbol, {
                                                    checked: checked(state),
                                                    disabled: disabled(state),
                                                })}
                                            ></span>
                                            <span
                                                className={style(classes.stateListItemLabelText, {
                                                    disabled: disabled(state),
                                                })}
                                            >
                                                {this.getStateName(state)}
                                            </span>
                                        </span>
                                    </label>
                                </li>
                            ))}
                        </span>
                    </Scrollbars>
                </ul>

                <div className={style(classes.controls)}>
                    <button
                        className={style(classes.stateListDialogApplyButton, {
                            disabled: disableApplyButtonState,
                        })}
                        disabled={disableApplyButtonState}
                        onClick={() => this.onApply()}
                    >
                        <div className={style(classes.submitButton)}>{applyLabel}</div>
                    </button>
                </div>
            </div>
        );
    }

    private getStateName(state: string) {
        const { selector, selectorConfiguration, panelHost } = this.props;
        const translate = getTranslate(panelHost);
        return translate(selectorConfiguration.getStateName(selector!, state));
    }

    private onApply() {
        const { onSubmit, closeStateListDialog } = this.props;
        onSubmit && onSubmit(this.state.selectedStates);
        closeStateListDialog && closeStateListDialog();
    }

    private onToggle(state: string) {
        if (this.props.fixedStates!.indexOf(state) > -1) {
            return;
        }

        const { selectedStates } = this.state;
        let states = selectedStates;

        if (states.indexOf(state) > -1) {
            states = selectedStates.filter((s) => s !== state);
        } else {
            states.push(state);
        }

        this.setState({ selectedStates: states });
    }
}

export default StateListDialog;
