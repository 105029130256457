// Note: this file is generated automatically when running yarn (built by extract-mdn-data):
import { cssShorthandMap } from './css-shorthands';
import type { DeclarationMap } from '../../types';
import { removeDuplicates } from '../general-utils';
import { propExpander } from './shorthand-expanders/shorthand-expand-utils';
import { BrowserComputeCss } from './shorthand-expanders/browser-compute-css';
const valueParser = require('postcss-value-parser');

const isString = (str: any): str is string => typeof str === 'string';

interface ScopedData {
    computed?: string[];
    initial?: string | string[];
}

function getPropertyMetadata(prop: string): ScopedData | undefined {
    const match = Object.entries(cssShorthandMap).find((entry) => {
        return entry[0] === prop;
    });
    return match ? match[1] : undefined;
}

/*
given a shorthand prop name -
return a list of the matching longhand props (taken from mdn/data)
 */
export function getShorthandComputedProps(prop: string): string[] {
    const matchingEntry = getPropertyMetadata(prop);
    if (matchingEntry === undefined) {
        return [];
    }

    if (matchingEntry.computed === undefined || matchingEntry.computed.length === 0) {
        return [prop];
    }
    const levelOneComputed: string[] = matchingEntry.computed || [];
    const deeperProps = levelOneComputed.reduce((acc: string[], longhandProp: string) => {
        acc = acc.concat(getShorthandComputedProps(longhandProp));
        return acc;
    }, []);

    return removeDuplicates(levelOneComputed.concat(deeperProps)); // TODO we don't really need to removeDuplicates if we don't insert them twice to begin with
}

/*
add resolved initial property where 'initial' keyword exists in parsed css
 */
function normalizeInitialsOnParsedCss(prop: string, parsedCss: any) {
    parsedCss.walk((node: any) => {
        if (node.type === 'word') {
            if (node.value === 'initial') {
                node.value = getPropInitialValue(prop);
            }
        }
    });
}

/*
attach initial value to declaration map props if no value specified for longhand prop
 */
function attachInitials(declarationMap: DeclarationMap, computedProps: string[]): DeclarationMap {
    const result = Object.assign({}, declarationMap);
    computedProps.forEach((longhand) => {
        if (result[longhand] === undefined) {
            result[longhand] = getPropInitialValue(longhand);
        }
    });
    return result;
}

/*
resolve computed props from prop with value.
 */
function resolveComputedValues(
    prop: string,
    value: string,
    computedProps: string[] = [],
    includeInitialValues = false
): DeclarationMap {
    // try using propExpander to expand prop if
    const expanded = propExpander(prop, value);
    if (expanded !== undefined) {
        if (includeInitialValues) {
            return attachInitials(expanded, computedProps);
        } else {
            return expanded;
        }
    }

    // if no implementation exists for given prop - expand using browserComputeCss
    const browserComputeCss = new BrowserComputeCss(prop, value);
    return computedProps.reduce((acc: DeclarationMap, longhandProp: string) => {
        const propertyValue = browserComputeCss.getLonghand(longhandProp);
        const parsed = valueParser(propertyValue);
        if (includeInitialValues) {
            normalizeInitialsOnParsedCss(longhandProp, parsed);
        } else if (
            propertyValue === 'initial' ||
            (propertyValue === getPropInitialValue(longhandProp) && !value.includes(propertyValue))
        ) {
            // if prop value is directly 'initial' or originated from 'initial' - don't include it
            return acc;
        }
        acc[longhandProp] = parsed.toString(); // Add longhand prop to accumulated map

        return acc;
    }, {});
}

/*
expands a css shorthand property to its longhand props
returns a declaration map with the matching longhand props extracted from value
 */
export function expandShorthandCssProp(prop: string, value: string, includeInitialValues = false): DeclarationMap {
    const computedProps = getShorthandComputedProps(prop);
    if (computedProps.length === 0) {
        throw new Error(`expandShorthandProps, cannot expand prop '${prop}'`);
    }

    // Maintaining similar behaviour to css-property-parser: throwing error for multiple layer of background
    // We can bring back the support for multiple layers by changing some logic in calcBackgroundLayers
    const ast: any = valueParser(value);
    if (
        prop === 'background' &&
        ast.nodes &&
        ast.nodes.length > 1 &&
        ast.nodes.find((node: any) => node.type === 'div' && node.value === ',')
    ) {
        throw new Error(`expandShorthandProps disabled for background with more than one layer`);
    }

    // if longhand - don't transpose it
    if (computedProps.length === 1 && computedProps[0] === prop) {
        return {
            [prop]: value,
        };
    }

    return resolveComputedValues(prop, value, computedProps, includeInitialValues);
}

/*
returns the initial value for a given prop, as specified in mdn/data
if prop is a shorthand and all matching initial values are the same - their value is returned
 */
export function getPropInitialValue(prop: string): string | undefined {
    let retVal;
    const matchingEntry = getPropertyMetadata(prop);
    if (matchingEntry === undefined || matchingEntry.initial === undefined) {
        return undefined;
    }

    const initial = matchingEntry.initial;
    if (isString(initial)) {
        retVal = initial;
    } else if (Array.isArray(initial)) {
        let sameValue = true;
        const temp = getPropInitialValue(initial[0]);
        initial.forEach((singleInitial: string) => {
            if (getPropInitialValue(singleInitial) !== temp) {
                sameValue = false;
            }
        });
        if (sameValue && isString(temp)) {
            retVal = temp;
        }
    }
    return retVal;
}
