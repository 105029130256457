import * as postcss from 'postcss';

import { Stylable, StylableMeta, StylableTransformer, Diagnostics } from '@stylable/core';

import { StylesheetDriver } from './stylable-stylesheet';
import { createMinimalFS, FileWithVersion, MinimalFSWithWrite } from './memory-minimal-fs';
import type { StylablePanelDriversExperiments, TransformationPlugins } from './types';

export class StylableEnv {
    protected requireModule!: (path: string) => any;
    private version = 0;
    private files: Record<string, FileWithVersion>;
    private fs: MinimalFSWithWrite;
    public stylable: Stylable;

    constructor(files: Record<string, string> = {}) {
        this.files = Object.keys(files).reduce<Record<string, FileWithVersion>>((acc, path) => {
            acc[path] = this.createFile(files[path]);
            return acc;
        }, {});
        const { fs, requireModule } = createMinimalFS({ files: this.files });
        this.fs = fs;
        this.stylable = new Stylable('/', this.fs, requireModule);
    }

    public transform(meta: StylableMeta) {
        this.stylable.transform(meta);
    }

    public getStyleSheetDriver(
        path: string,
        scope?: string,
        transformationPlugins?: TransformationPlugins,
        experiments: StylablePanelDriversExperiments = { newElementree: true }
    ): StylesheetDriver {
        return new StylesheetDriver(
            () => this.getMetaByPath(path),
            (_modified: postcss.Node) => this.updateFS(path, this.getMetaByPath(path).rawAst.toString()),
            () =>
                new StylableTransformer({
                    diagnostics: new Diagnostics(),
                    fileProcessor: this.stylable.fileProcessor,
                    requireModule: this.requireModule,
                }),
            scope,
            transformationPlugins,
            experiments
        );
    }

    public getMetaByPath(path: string) {
        return this.stylable.process(path);
    }

    public updateFS(path: string, source: string): number {
        this.fs.writeFileSync(path, source);
        this.version = this.files[path].v;
        this.stylable.process(path);
        return this.version;
    }

    private createFile(content: string): FileWithVersion {
        return {
            content,
            mtime: new Date(++this.version),
            v: this.version,
        };
    }
}

interface MockStylesheet {
    source?: string;
    astRoot?: postcss.Root;
    onModified?: (node: postcss.Node) => number;
}

export function createMockStylesheet({ source, astRoot, onModified }: MockStylesheet = {}) {
    const meta = new StylableMeta(astRoot || postcss.parse(source || ''), new Diagnostics());
    return new StylesheetDriver(
        () => meta,
        onModified || (() => 0),
        () =>
            new StylableTransformer({
                diagnostics: new Diagnostics(),
                fileProcessor: {
                    add() {
                        throw new Error('not implemented in mock');
                    },
                    cache: {},
                    postProcessors: [],
                    process() {
                        throw new Error('not implemented in mock');
                    },
                    processContent() {
                        throw new Error('not implemented in mock');
                    },
                    resolvePath() {
                        throw new Error('not implemented in mock');
                    },
                },
                requireModule: () => {
                    throw new Error('requireModule not implemented in mock');
                },
            })
    );
}
