import * as postcss from 'postcss';

import { CollectionDriver, Collection, ChangeContext, addChangeContext } from './stylable-collection';
import type { ISymbol } from '../stylable-driver';
import type { StylesheetDriver } from '../stylable-stylesheet';
import { StylableShorthands } from '../stylable-shorthands';

export class GenericCollectionDriver extends CollectionDriver<Collection> {
    constructor(
        getStylesheet: (path: string) => StylesheetDriver | null,
        resolveSymbol: (from: string, name: string) => ISymbol | null,
        updateFromAST: (modified: postcss.Node) => number,
        protected basePropName: string,
        protected propNames?: string[]
    ) {
        super(getStylesheet, resolveSymbol, updateFromAST);
    }

    protected router(decl: postcss.Declaration, sheet: StylesheetDriver, declarations: Collection) {
        const addProp = addChangeContext.bind(this, declarations, decl);
        const shorthands = new StylableShorthands(sheet.evalDeclarationValue.bind(sheet));

        if (this.basePropName === decl.prop || (this.propNames && ~this.propNames.indexOf(decl.prop))) {
            this.handleGenericProp(sheet, decl.value, addProp);
        } else {
            const shorthandProps = shorthands.getShorthandProps(this.basePropName);
            if (~shorthandProps.indexOf(decl.prop)) {
                this.handleShorthandProp(sheet, decl, addProp);
                return;
            }

            if (this.propNames) {
                for (const propName of this.propNames) {
                    const shorthand = shorthands.getShorthandProps(propName);
                    if (~shorthand.indexOf(decl.prop)) {
                        this.handleShorthandProp(sheet, decl, addProp);
                        return;
                    }
                }
            }
        }
    }

    private handleGenericProp(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        function changeDirectProp(this: ChangeContext, value: string) {
            this.declaration.value = value;
            updateFromAST(this.declaration);
        }

        adder(sheet.evalDeclarationValue(declValue), changeDirectProp);
    }

    private handleShorthandProp(
        sheet: StylesheetDriver,
        decl: postcss.Declaration,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);
        const shorthands = new StylableShorthands(sheet.evalDeclarationValue.bind(sheet));
        const basePropName = this.basePropName;

        function changeShorthandProp(this: ChangeContext, value: string) {
            const changeExpanded = shorthands.expandDeclaration(postcss.decl({ prop: basePropName, value }));
            const changeValue = changeExpanded[decl.prop];
            if (changeValue) {
                this.declaration.value = changeValue;
                updateFromAST(this.declaration);
            }
        }

        adder(sheet.evalDeclarationValue(decl.value), changeShorthandProp);
    }
}
