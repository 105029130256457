import React from 'react';

import { ImageSvg } from '@wixc3/stylable-panel-common-react';

import { style as defaultStyle, classes as defaultClasses } from './default-preview.st.css';
import { style as shadowStyle, classes as shadowClasses } from './shadow-preview.st.css';
import { style as spacingStyle, classes as spacingClasses } from './spacing-preview.st.css';
import { style as textStyle, classes as textClasses } from './text-preview.st.css';
import { style as fillStyle, classes as fillClasses } from './fill-preview.st.css';
import { style as borderStyle, classes as borderClasses } from './border-preview.st.css';
import { style as imageStyle, classes as imageClasses } from './image-preview.st.css';

export const DefaultPreview = (style: React.CSSProperties) => (
    <div className={defaultStyle(defaultClasses.root, {})} style={style} data-id="default-preview" />
);

export const ShadowPreview = (style: React.CSSProperties) => (
    <div className={shadowStyle(shadowClasses.root, {})} style={style} />
);

export const SpacingPreview = (style: React.CSSProperties) => (
    <div className={spacingStyle(spacingClasses.root, {})}>
        <div className={spacingClasses.box} style={style}>
            <div className={spacingClasses.innerBox} />
        </div>
    </div>
);

export const TextPreview = (style: React.CSSProperties) => (
    <span className={textStyle(textClasses.root, {})} style={style}>
        Aa
    </span>
);

export const FillPreview = (style: React.CSSProperties) => (
    <div className={fillStyle(fillClasses.root, {})} style={{ ...style, backgroundSize: 'cover' }} />
);

export const BorderPreview = (style: React.CSSProperties) => (
    <div className={borderStyle(borderClasses.root, {})} style={style} />
);

export const ImagePreview = (style: React.CSSProperties) => (
    <ImageSvg className={imageStyle(imageClasses.root, {})} style={{ ...style, height: '100%', width: '100%' }} />
);
