import React from 'react';

import { InformationTooltip } from '../information-tooltip';

import { style, classes } from './composite-block.st.css';

export interface CompositeBlockProps {
    title?: string;
    information?: string;
    singleLine?: boolean;
    iconRow?: boolean;
    divider?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const CompositeBlock: React.FunctionComponent<CompositeBlockProps> = ({
    title,
    information,
    singleLine,
    iconRow,
    divider,
    children,
    className,
    style: propStyle,
}) => (
    <div
        className={style(
            classes.root,
            {
                singleLine: !!singleLine,
                iconRow: !!iconRow,
                divider: !!divider,
            },
            className
        )}
        style={propStyle}
    >
        <span className={classes.title}>
            {title && <label className={style(classes.label)}>{title}</label>}
            {information ? <InformationTooltip className={classes.infoTooltip} text={information} /> : null}
        </span>
        {children}
    </div>
);
