import React from 'react';

import { Close } from '@wixc3/stylable-panel-common-react';
import { Tooltip } from '@wixc3/stylable-panel-components';

import { style, classes } from './background-control.st.css';
import { PanelEventList, BIParams } from '../../hosts/bi';

export interface Action {
    key: string;
    tooltip: string;
    icon: JSX.Element;
    label?: string;
    content?: () => JSX.Element | null;
    run?: () => void;
}

export interface Point {
    x: string;
    y: string;
}

export interface BackgroundControlProps {
    previewStyle?: Record<string, string | undefined>;
    openedAction?: string;
    actions?: Action[];
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    value?: Point;
    onChange?: (point: Point) => void;
    displayModePicker?: (openedAction: string) => JSX.Element | undefined;
    renderActionButtons?: boolean;
    onClickAction: (action: Action) => void;
    customPreviewComponent?: string;
    previewProps?: Record<string, any>;
    className?: string;
    style?: React.CSSProperties;
}

function getMouseRectPercent(rect: ClientRect | DOMRect, clientCoord: number, vertical?: boolean) {
    let newStopLeft = ((clientCoord - rect[vertical ? 'top' : 'left']) / rect[vertical ? 'height' : 'width']) * 100;
    if (newStopLeft < 0) {
        newStopLeft = 0;
    }
    if (newStopLeft > 100) {
        newStopLeft = 100;
    }

    return `${Math.round(newStopLeft)}%`;
}

export interface BackgroundControlState {
    dragging: boolean;
}

export class BackgroundControl extends React.Component<BackgroundControlProps, BackgroundControlState> {
    public static defaultProps: Partial<BackgroundControlProps> = {
        openedAction: '',
        renderActionButtons: true,
    };
    private preview!: HTMLElement | null;
    private control!: HTMLDivElement | null;

    constructor(props: BackgroundControlProps) {
        super(props);

        this.state = {
            dragging: false,
        };
    }

    public render() {
        const {
            previewStyle,
            openedAction,
            customPreviewComponent,
            previewProps,
            children,
            className,
            style: propStyle,
        } = this.props;

        // correct type is keyof React.ReactHTML
        // but it kills typechecking
        const PreviewComp: any = customPreviewComponent || 'div';

        return (
            <div className={style(classes.root, className)} style={propStyle}>
                {this.renderTopControls()}
                <div className={classes.previewContainer}>
                    <PreviewComp
                        className={classes.preview}
                        style={previewStyle}
                        {...previewProps}
                        ref={(c: HTMLDivElement) => (this.preview = c)}
                    />
                    <div className={classes.control} onMouseDown={this.handleMouseDown} ref={(c) => (this.control = c)}>
                        {openedAction ? this.renderActionContent(openedAction) : null}
                        {children}
                    </div>
                </div>
            </div>
        );
    }

    // TODO test
    public componentWillUnmount() {
        document.removeEventListener('mouseup', this.finishPositionDrag);
    }

    public renderActionContent = (openedAction: string) => {
        const { actions } = this.props;
        const action = actions && actions.find((currAction) => currAction.key === openedAction);
        let content;
        if (action?.content) {
            content = action.content();
        } else {
            content = this.renderPositionSelectorContent();
        }

        return (
            <div key="actionContent" className={classes.actionContent}>
                {content}
            </div>
        );
    };

    public handleMouseDown = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        document.addEventListener('mousemove', this.handlePositionDrag);
        document.addEventListener('mouseup', this.finishPositionDrag);

        this.handlePositionDrag(event);
        this.setState({ dragging: true });
    };

    private renderPositionSelectorContent() {
        const { value } = this.props;
        const positionMarkerStyle = value ? { left: value.x, top: value.y } : {};

        return (
            <div className={classes.positionContainer}>
                <div
                    className={style(classes.positionMarker, { dragging: this.state.dragging })}
                    style={positionMarkerStyle}
                />
            </div>
        );
    }

    private finishPositionDrag = () => {
        const { reportBI } = this.props;
        const { MOVE_FOCAL_POINT_CLICK } = PanelEventList;
        reportBI && reportBI(MOVE_FOCAL_POINT_CLICK);

        document.removeEventListener('mousemove', this.handlePositionDrag);
        document.removeEventListener('mouseup', this.finishPositionDrag);
        this.setState({ dragging: false });
    };

    private handlePositionDrag = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        let dragArea;
        if (this.props.customPreviewComponent) {
            dragArea = this.preview;
        } else {
            dragArea = this.control;
        }

        if (!dragArea) {
            return;
        }
        const newX = parseInt(
            getMouseRectPercent((dragArea as HTMLDivElement).getBoundingClientRect(), event.clientX),
            10
        );

        const newY = parseInt(
            getMouseRectPercent((dragArea as HTMLDivElement).getBoundingClientRect(), event.clientY, true),
            10
        );

        this.props.onChange && this.props.onChange({ x: newX + '%', y: newY + '%' });
    };

    private renderActionIcon = (action: Action) => {
        const { openedAction, onClickAction } = this.props;

        return (
            <Tooltip key={action.key} text={!openedAction ? action.tooltip : ''}>
                <div className={classes.actionSelector} onClick={() => onClickAction(action)}>
                    {openedAction ? <Close className={classes.closeIcon} /> : action.icon}
                </div>
            </Tooltip>
        );
    };

    private renderTopControls = () => {
        const { actions, openedAction, displayModePicker, renderActionButtons } = this.props;

        return (
            <div className={classes.topControlsContainer}>
                <div className={style(classes.topControls, { inAction: !displayModePicker || !!openedAction })}>
                    {displayModePicker && displayModePicker(openedAction || '')}
                    <div className={classes.actionsContainer}>
                        {renderActionButtons && actions ? (
                            !openedAction ? (
                                actions.map((action) => this.renderActionIcon(action))
                            ) : (
                                <span className={classes.topControlsOpenedAction}>
                                    {(actions.find((action) => action.key === openedAction) || {}).label}
                                </span>
                            )
                        ) : undefined}
                    </div>
                </div>
            </div>
        );
    };
}
