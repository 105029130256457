import { StylablePanelTranslationKeys, pseudoElementParentSelector } from '@wixc3/stylable-panel-drivers';
import type { ControllerComponentClass, DynamicPartSelectorFilter } from '@wixc3/stylable-panel-controllers';

import {
    LayoutControllerFactory,
    LayoutPropMap,
    LayoutDisplayMap,
    DynamicPartMap,
    DynamicPartDisplayMap,
    LayoutConditionMap,
} from '../panels/customization-panel/controllers/layout/layout-controller';
import {
    FlexDirectionInput,
    JustifyContentInput,
    SingleElementJustifyContentInput,
    AlignItemsInput,
    ChildDisplayDropdown,
    FlexChildSpacing,
    AllElementsDisplayed,
} from '../panels/customization-panel/controllers/layout';

const CONDITION_ALL_DISPLAYED = 'all-displayed';

const FLEX_PROP_MAP: LayoutPropMap = {
    'flex-direction': {
        index: 0,
        Comp: FlexDirectionInput,
        condition: CONDITION_ALL_DISPLAYED,
    },
    'justify-content': {
        index: 1,
        Comp: JustifyContentInput,
        filter: {
            prop: 'flex-direction',
            shouldShow: (value) => !value || !!~value.indexOf('row'),
        },
        condition: CONDITION_ALL_DISPLAYED,
        ReplacementComp: SingleElementJustifyContentInput,
    },
    'align-items': {
        index: 1,
        Comp: AlignItemsInput,
        filter: {
            prop: 'flex-direction',
            shouldShow: (value) => !!value && !!~value.indexOf('column'),
        },
    },
};

const LAYOUT_DISPLAY_MAP: LayoutDisplayMap = {
    flex: FLEX_PROP_MAP,
    'inline-flex': FLEX_PROP_MAP,
};

const DYNAMIC_PART_CHILD_SELECTOR_FILTER: DynamicPartSelectorFilter = (
    partTypeSelector: string,
    inputSelector: string
) => pseudoElementParentSelector(partTypeSelector).startsWith(pseudoElementParentSelector(inputSelector));

const DYNAMIC_PART_MAP: DynamicPartMap = {
    LayoutChildDisplayDropdown: {
        index: 0,
        Comp: ChildDisplayDropdown,
        selectorFilter: DYNAMIC_PART_CHILD_SELECTOR_FILTER,
    },
    LayoutFlexChildSpacing: {
        index: 2,
        Comp: FlexChildSpacing,
        selectorFilter: DYNAMIC_PART_CHILD_SELECTOR_FILTER,
        filter: {
            prop: 'justify-content',
            shouldShow: (value) => value !== 'space-between',
        },
        condition: CONDITION_ALL_DISPLAYED,
    },
};

const DYNAMIC_PART_DISPLAY_MAP: DynamicPartDisplayMap = {
    flex: DYNAMIC_PART_MAP,
    'inline-flex': DYNAMIC_PART_MAP,
};

const LAYOUT_CONDITION_MAP: LayoutConditionMap = {
    [CONDITION_ALL_DISPLAYED]: {
        dynamicPart: 'LayoutChildDisplayDropdown',
        isCondition: AllElementsDisplayed,
    },
};

export default LayoutControllerFactory(
    StylablePanelTranslationKeys.controller.layout.title,
    LAYOUT_DISPLAY_MAP,
    DYNAMIC_PART_DISPLAY_MAP,
    LAYOUT_CONDITION_MAP
) as ControllerComponentClass; // TODO: Fix type
