import type { ComponentsMetadata } from '@stylable/webpack-extensions/dist/component-metadata-builder';

import type { EditorComponentConfig } from '@wixc3/stylable-panel-common';

import type {
    DeclarationMap,
    TransformationPlugins,
    CssModifier,
    StylablePanelDriversExperiments,
    PanelComponentsMetadata,
} from './types';
import { StylableDriver, InlineModules, USAGE_MAPPING_ALLOW_ALL } from './stylable-driver';
import type { StylesheetDriver } from './stylable-stylesheet';
import { ComponentEditSession } from './editor-edit-session';
import { PresetDriver, PresetDriverParams } from './preset-driver';
import { StylableSiteVars } from './stylable-site-vars';
import { StylableChangeSelectors } from './stylable-change-selectors';

interface StylableEditorExtensions {
    inlineModules?: InlineModules;
    transformationPlugins?: TransformationPlugins;
    getMinimalCss?: CssModifier;
}

export interface OpenOptions {
    path: string;
    id: string;
    variant: string;
    presetPaths?: string[];
    inputPaths?: string[];
    scope?: string;
    onQuickChange?: (selector: string, declarationMap: DeclarationMap) => void;
    onRevertQuickChange?: () => void;
}

const createVariantKey = (path: string, variant: string) => `${path}_${variant}`;

export class StylableEditor {
    public stylableDriver!: StylableDriver;
    public stylesheetPath: string;
    public selectionCache: { [key: string]: ComponentEditSession };
    public siteStylesheetPath!: string;
    public getMinimalCss: CssModifier;

    private config!: PanelComponentsMetadata;
    private externalComponentsConfig: Record<string, EditorComponentConfig> = {};
    private variantConfigOverrides: Record<string, Partial<EditorComponentConfig>> = {};
    private selection: ComponentEditSession[];
    private presetPaths: string[] | undefined;

    constructor(
        config: PanelComponentsMetadata,
        siteStylesheetPath: string,
        private experiments?: StylablePanelDriversExperiments
    ) {
        this.stylesheetPath = '';
        this.selection = [];
        this.selectionCache = {};
        this.getMinimalCss = (css: string) => css;
        this.init(config, siteStylesheetPath, experiments);
    }

    public init(
        config: PanelComponentsMetadata,
        siteStylesheetPath: string,
        experiments?: StylablePanelDriversExperiments
    ) {
        this.siteStylesheetPath = siteStylesheetPath;
        this.config = config;
        this.experiments = experiments;
        this.stylableDriver = new StylableDriver(undefined, config.packages, USAGE_MAPPING_ALLOW_ALL, this.experiments);
        this.initDriver();
        return this;
    }

    public initDriver() {
        this.writeFilesWithNamespace(this.config.fs);
        this.stylableDriver.registerBuildHook(() => {
            this.selection.forEach((s) => {
                s.updateComputed();
            });
        });
    }

    public getSelection(index: number): ComponentEditSession | null {
        return this.selection[index] || null;
    }

    public getComponentConfig(id: string): EditorComponentConfig {
        return this.externalComponentsConfig[id] || this.config.components[id] || null;
    }

    public setComponentExternalConfig(id: string, config: EditorComponentConfig) {
        this.externalComponentsConfig[id] = config;
    }

    public setVariantConfigOverrides(path: string, variant: string, config: Partial<EditorComponentConfig>) {
        const variantKey = createVariantKey(path, variant);
        delete this.selectionCache[variantKey];
        this.variantConfigOverrides[variantKey] = config;
    }

    public write(path: string, content: string) {
        this.writeFilesWithNamespace({ [path]: { content, metadata: { namespace: '' } } });
    }

    public writeFilesWithNamespace(files: ComponentsMetadata['fs']) {
        Object.keys(files).forEach((filepath) => {
            this.stylableDriver.writeFile(filepath, files[filepath].content);
            const meta = this.stylableDriver.stylable.process(filepath);
            meta.namespace = files[filepath].metadata.namespace || meta.namespace;
        });
    }

    // TODO: Deprecate
    public open(
        path: string,
        id: string,
        variant: string,
        presetPaths?: string[],
        scope?: string,
        onQuickChange?: (selector: string, declarationMap: DeclarationMap) => void,
        onRevertQuickChange?: () => void,
        onStylesheetCommit?: () => void
    ) {
        this.stylesheetPath = path;
        this.presetPaths = presetPaths;
        this.stylableDriver.setEntries([this.stylesheetPath]);
        this.stylableDriver.setScope(scope);
        this.select({ path, id, variant, onQuickChange, onRevertQuickChange });
        this.getSelection(0)!.changeDriver.setOnStylesheetCommit(onStylesheetCommit);
    }

    public load(options: OpenOptions) {
        const { path, presetPaths, scope } = options;
        this.stylesheetPath = path;
        this.presetPaths = presetPaths;
        this.stylableDriver.setEntries([this.stylesheetPath]);
        this.stylableDriver.setScope(scope);
        this.select(options);
    }

    private select(options: OpenOptions) {
        this.selection.length = 0;
        this.selection.push(this.createSelection(options));
    }

    private createSelection(options: OpenOptions) {
        const { path, id, variant, onQuickChange, onRevertQuickChange, inputPaths } = options;
        const cacheKey = createVariantKey(path, variant);
        if (!this.selectionCache[cacheKey]) {
            this.selectionCache[cacheKey] = new ComponentEditSession(
                this.siteStylesheetPath,
                this.stylesheetPath,
                variant,
                this.getVariantConfigOverrides(path, id, variant),
                this.stylableDriver,
                new StylableChangeSelectors(onQuickChange, onRevertQuickChange),
                this.presetPaths,
                inputPaths
            );
        }
        return this.selectionCache[cacheKey];
    }

    public registerExtensions(extensions: StylableEditorExtensions) {
        const { inlineModules, transformationPlugins, getMinimalCss } = extensions;

        if (inlineModules) {
            this.stylableDriver.registerJsModules(inlineModules); // register mixins and formatters
        }

        if (transformationPlugins) {
            this.stylableDriver.registerTransformationPlugins(transformationPlugins);
        }

        if (getMinimalCss) {
            this.getMinimalCss = getMinimalCss;
        }
    }

    public getStylable() {
        return this.stylableDriver.stylable;
    }

    public getEditableComponents() {
        return Object.keys(this.config.components);
    }

    public createPresetDriver(params: PresetDriverParams) {
        return new PresetDriver(params);
    }

    public createSiteVarsDriver(sheet: StylesheetDriver) {
        return new StylableSiteVars(sheet);
    }

    private getVariantConfigOverrides(path: string, id: string, variant: string) {
        return immutableOverride<EditorComponentConfig>(
            this.getComponentConfig(id),
            this.variantConfigOverrides[createVariantKey(path, variant)] || {}
        );
    }
}

function immutableOverride<T>(objA: T, objB: Partial<T>) {
    const newObj = { ...objA };

    for (const [key, value] of Object.entries(objB)) {
        if (Array.isArray(value)) {
            throw new Error('Array override is not supported');
        }

        if (value && typeof value === 'object') {
            newObj[key as keyof T] = immutableOverride(objA[key as keyof T], value);
        } else {
            newObj[key as keyof T] = value as T[keyof T];
        }
    }

    return newObj;
}
