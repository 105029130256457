const DEFAULT_TRIGGER = /(.*)/;
const DEFAULT_POST_SELECT_WRAPPER = (suggestion: string) => suggestion;

export interface Context {
    suggestions: string[];
    trigger?: RegExp;
    postWrapper?: (suggestion: string) => string;
    title?: string;
}

export class ContextTrigger {
    constructor(private contexts: Context[]) {}

    /* Example: match('value(myVar1') */
    public match(value: string) {
        for (const context of this.contexts) {
            const {
                /* Example: /value\((\S*)(?!.*value\()/ - Last open value() */
                trigger = DEFAULT_TRIGGER,
                /* Example: ['myVar11', 'myVar12', 'myVar21'] - Variable names */
                suggestions,
                /* Example: (suggestion: string) => `value(${suggestion})` - Wrap in value() */
                postWrapper = DEFAULT_POST_SELECT_WRAPPER,
                title,
            } = context;

            /* Example: ['value(myVar1', 'myVar1', index: 0, input: 'value(myVar1'] */
            const contextMatch = value.match(trigger);
            if (!contextMatch) {
                continue;
            }

            /* Example: 'my' */
            const valueRest = contextMatch[1] !== undefined ? contextMatch[1] : value;

            /* Example: ['myVar11', 'myVar12'] */
            const filteredSuggestions = suggestions.filter((suggestion) => !!~suggestion.indexOf(valueRest));

            if (filteredSuggestions.length) {
                return {
                    suggestions: filteredSuggestions,
                    wrapper: (suggestion: string) => value.replace(trigger, postWrapper(suggestion)),
                    title,
                };
            }
        }

        return null;
    }
}
