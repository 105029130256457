import * as postcss from 'postcss';

import { valueMapping, createSubsetAst } from '@stylable/core';

import type { StylesheetDriver } from './stylable-stylesheet';

export const parseMixinValue = (value?: string) => (value ? value.split(',').map((name) => name.trim()) : []);
export const buildMixin = (mixins: string[]) => mixins.join(', ') || undefined;

export function removeMixin(name: string, mixins?: string) {
    if (!mixins) {
        return undefined;
    }

    const mixinList = parseMixinValue(mixins);

    const nameIndex = mixinList.findIndex((mixin) => mixin === name);
    if (nameIndex !== -1) {
        mixinList.splice(nameIndex, 1);
    }

    return buildMixin(mixinList);
}
export function applyMixin(name: string, mixins?: string) {
    if (!mixins) {
        return `${name}`;
    }

    const mixinList = parseMixinValue(removeMixin(name, mixins));
    mixinList.push(name);

    return buildMixin(mixinList);
}

export function getMixinDeclarations(sheet: StylesheetDriver, name: string) {
    const mixinSubset = createSubsetAst(sheet.AST, `.${name}`);
    if (!mixinSubset.nodes || mixinSubset.nodes.length === 0) {
        return [];
    }
    return ((mixinSubset.nodes[0] as postcss.Rule).nodes as postcss.Declaration[]) || []; // TODO: eval all values here or in controller?
}

export class StylableMixin {
    constructor(private sheet: StylesheetDriver) {}

    // TODO: Get from Stylable
    public applyMixin(rule: postcss.Rule, mixinName: string): void {
        const mixinDecl = this.getMixinDeclaration(rule);
        if (!mixinDecl) {
            rule.append(postcss.decl({ prop: valueMapping.mixin, value: mixinName }));
        } else {
            if (!~mixinDecl.value.indexOf(mixinName)) {
                mixinDecl.value = `${mixinDecl.value}, ${mixinName}`;
            }
        }
        mixinDecl && this.writeAST(mixinDecl);
    }

    // TODO: Get from Stylable
    public removeMixin(rule: postcss.Rule, mixinName: string): void {
        const mixinDecl = this.getMixinDeclaration(rule);
        if (!mixinDecl) {
            return;
        }
        mixinDecl.value = mixinDecl.value.replace(new RegExp(`\\s*,*\\s*${mixinName}\\s*`), '').trim();
        if (!mixinDecl.value) {
            rule.removeChild(mixinDecl);
            this.writeAST(rule);
        } else {
            this.writeAST(mixinDecl);
        }
    }

    private writeAST(modified: postcss.Node) {
        this.sheet.updateFromAST(modified);
    }

    private getMixinDeclaration(rule: postcss.Rule): postcss.Declaration | null {
        const declarations: postcss.Declaration[] = [];
        rule.walkDecls((decl: postcss.Declaration) => {
            declarations.push(decl);
        });
        if (!declarations.length) {
            return null;
        }
        declarations.reverse();
        return declarations.find((decl: postcss.Declaration) => decl.prop === valueMapping.mixin) || null;
    }
}
