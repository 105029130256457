import { INPUT_UNITS_MAP, DimensionInputState, InputError, ParsedDimension, DimensionRef, CssUnit } from './types';
import type * as stylablePanelCommon from '@wixc3/stylable-panel-common';
import { exponentValue } from './patterns';
import {
    MAX_FLOAT_VALUE_LENGTH,
    MAX_VALUE,
    DEFAULT_SLIDER_MIN,
    DEFAULT_SLIDER_MAX,
    DEFAULT_STEP,
    UNITLESS_SYMBOL,
    MIN_VALUE,
} from './consts';
import keycode from 'keycode';

export const keyInput = (key: number) => (c: string) => key === keycode(c);

// validation
export const isNumber = (n: number | string | undefined) => n !== undefined && !isNaN(+n);

export const isFloat = (n: number) => n % 1 !== 0;

export const validateNum = (n: number | undefined) => (isNumber(n) ? n : undefined);

export const unitFilter = (s: string) => isNaN(Number(s)) && s !== '.' && s !== '-';

export const valueFilter = (s: string) => !unitFilter(s);

export const sanitizeNegativeFloat = (nodes: string[]): string[] => {
    if (nodes[0] === '-.') nodes[0] = '-0.';
    return nodes;
};

export const stringExtract = (nodes: string[], matcher: (s: string) => boolean) =>
    nodes.filter((s: string) => matcher(s)).join('');

export const validateNodes = (nodes: string[]) => {
    const tokens = nodes.map((i) =>
        isNumber(Number(i)) ? 'digit' : i === '-' ? 'minusSymbol' : i === '.' ? 'floatPoint' : 'non-digit'
    );
    const lastDigit = tokens.lastIndexOf('digit');
    const firstNonDigit = tokens.indexOf('non-digit');
    return !(firstNonDigit > -1 && firstNonDigit < lastDigit);
};

export const breakError =
    (value: number, unit: CssUnit) =>
    (error: InputError): ParsedDimension =>
        Object.assign({}, { value, unit, error });

export const debug = (state: stylablePanelCommon.DimensionProps | DimensionInputState | Record<string, string>) =>
    JSON.stringify(state, null, 2);

// value
export const compileValue = (value: string) =>
    isNaN(Number(value)) || !!`${value}`.match(exponentValue) ? undefined : Number(value);

export const trimFloatValue = (value: number) =>
    Math.min(MAX_VALUE, Math.max(MIN_VALUE, +value.toFixed(MAX_FLOAT_VALUE_LENGTH)));

// units
export const getSymbolRef = (symbol: string): DimensionRef => {
    const refs = Object.entries(INPUT_UNITS_MAP).find((ref) => ref[1] === symbol) || [];

    if (!refs.length) {
        return {
            name: INPUT_UNITS_MAP.unitless as stylablePanelCommon.UnitRef,
            symbol: UNITLESS_SYMBOL,
        };
    }

    return {
        name: refs[0] as stylablePanelCommon.UnitRef,
        symbol: refs[1],
    };
};

export const getRefSymbol = (ref: stylablePanelCommon.UnitRef): CssUnit => INPUT_UNITS_MAP[ref] || UNITLESS_SYMBOL;

// dropdown
export const getDropdownOptions = (units: stylablePanelCommon.UnitRefPartialRecord) =>
    Object.keys(units).map((option) => {
        return {
            id: option,
            displayName: INPUT_UNITS_MAP[option as stylablePanelCommon.UnitRef],
        };
    });

// slider
export const getSliderConfig = ({
    min,
    max,
    step,
    sliderMin,
    sliderMax,
    sliderStep,
}: stylablePanelCommon.DimensionProps): stylablePanelCommon.SliderConfig => {
    return {
        sliderMin: isNumber(sliderMin) ? sliderMin : isNumber(min) ? min : DEFAULT_SLIDER_MIN,
        sliderMax: isNumber(sliderMax) ? sliderMax : isNumber(max) ? max : DEFAULT_SLIDER_MAX,
        sliderStep: isNumber(sliderStep) ? sliderStep : step || DEFAULT_STEP,
    };
};
