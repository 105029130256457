import React from 'react';
import { DownArrow, IconProps } from '@wixc3/stylable-panel-common-react';
import type { TranslateFunc } from '../../types';
import { Tooltip } from '@wixc3/stylable-panel-components';
import { style, classes } from './popup-icon.st.css';

interface PopupIconProps {
    className?: string;
    stateOpenPopup: boolean;
    openPopup: () => void;
    SelectedIcon?: React.ComponentType<IconProps>;
    children?: React.ReactNode;
    tooltipKey: string;
    MainIcon: React.FunctionComponent;
    iconKey: string;
    translate: TranslateFunc;
}

export class PopupIcon extends React.Component<PopupIconProps> {
    public get popupRef() {
        // eslint-disable-next-line react/no-string-refs
        return this.refs.popup as HTMLDivElement;
    }

    public render() {
        const { className, iconKey, stateOpenPopup, openPopup, SelectedIcon, children, translate } = this.props;

        const { tooltipKey, MainIcon } = this.props;
        const Icon = SelectedIcon || MainIcon;

        const icon = (
            <Tooltip key={`popup_${iconKey}`} className={classes.iconTooltip} text={translate(tooltipKey)}>
                <span className={style(classes.popupIcon, { checked: stateOpenPopup })} onClick={openPopup}>
                    <Icon />
                    <DownArrow className={classes.downArrow} />
                </span>
            </Tooltip>
        );

        return (
            <div className={style(classes.root, className)}>
                {stateOpenPopup ? (
                    <div className={classes.popupWrapper}>
                        {icon}
                        <div
                            key="popup"
                            className={classes.popup}
                            // eslint-disable-next-line react/no-string-refs
                            ref="popup"
                        >
                            {children}
                        </div>
                    </div>
                ) : (
                    icon
                )}
            </div>
        );
    }
}
