import React from 'react';
import type * as postcss from 'postcss';

import {
    Collection,
    CollectionDriver,
    ArchetypeList,
    DeclarationMap,
    FullDeclarationMap,
    BlockVariantSet,
    createReactStyleFromCssStyle,
    StylesheetDriver,
    ArchetypeResolver,
    StylableDriver,
    StylableSiteVars,
} from '@wixc3/stylable-panel-drivers';
import { Tabs, SectionDesc, PopupPanel, Tooltip } from '@wixc3/stylable-panel-components';
import SimpleBlockVariantController from '../customization-panel/controllers/block-variants/simple-block-variant-controller';
import type { CategoryConfig } from '../../types';
import type { ControllerComponentClass } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './box-package.st.css';

export interface BoxPackageProps {
    boxDeclarations: Collection;
    categoryConfiguration: CategoryConfig;
    categoryId: string;
    baseProp: string;
    title?: string;
    sheetPath: string;
    stylableDriver: StylableDriver;
    open?: boolean;
    onClickPackageButton?: () => void;
    onClickPopupClose?: () => void;
    archetypeList?: ArchetypeList;
    customTweakComponent?: ControllerComponentClass;
    customBlockVariantPreview?: (variant: Record<string, string>) => JSX.Element;
    onGetGoingChange?: (value: string | null) => void;
    onGetGoingSelect?: (value: string | null) => void;
    className?: string;
}

export interface BoxPackageState {
    open: boolean;
    sections: SectionDesc[];
}

const FAKE_ID = 'Fake_placeholder';

export default class BoxPackage extends React.Component<BoxPackageProps, BoxPackageState> {
    private shouldRestore = false;
    private savedDeclaration: string | undefined;
    private sheetDriver = this.props.stylableDriver.getStylesheet(this.props.sheetPath);
    private siteVarsDriver = this.sheetDriver ? new StylableSiteVars(this.sheetDriver) : undefined;

    public state = {
        open: false,
        sections: this.initSections(),
    };

    public componentDidUpdate(prevProps: BoxPackageProps, _prevState: BoxPackageState) {
        // If collection changed - update state accordingly, disregarding previous changes
        if (JSON.stringify(prevProps.boxDeclarations) !== JSON.stringify(this.props.boxDeclarations)) {
            this.setState({ sections: this.initSections() });
        }
    }

    public render() {
        const { boxDeclarations, title, open, onClickPopupClose, className } = this.props;

        const closeFunction = () => {
            onClickPopupClose && onClickPopupClose();
            this.setState({ open: false });
        };
        return (
            <div className={style(classes.root, className)}>
                <Tooltip
                    text={Object.keys(boxDeclarations).length === 0 ? `Add New ${title}` : `Change Existing ${title}`}
                >
                    <button className={classes.mainButton} onClick={() => this.togglePopup()} />
                </Tooltip>
                <span className={classes.label}>{title}</span>
                {(open || (open === undefined && this.state.open)) && (
                    <PopupPanel title={title} className={classes.popup} onClose={closeFunction}>
                        <Tabs
                            className={classes.tabs}
                            sections={this.state.sections}
                            content={this.contentFunction}
                            initialTab={0}
                        />
                    </PopupPanel>
                )}
            </div>
        );
    }

    private initSections() {
        let setBoxes = Object.keys(this.props.boxDeclarations);
        if (setBoxes.length === 0) {
            setBoxes = [FAKE_ID];
        }

        return setBoxes.map((key) => {
            return {
                id: key,
                customButton: this.customComponentWithStyle(key, this.sheetDriver || undefined),
            };
        });
    }

    private customComponentWithStyle(
        key: string,
        sheetDriver: StylesheetDriver | undefined,
        changeVal?: string
    ): () => JSX.Element {
        const kebabStyle: FullDeclarationMap = {};
        if (this.props.boxDeclarations[key]) {
            kebabStyle[this.props.boxDeclarations[key][0].declaration.prop] =
                changeVal || this.props.boxDeclarations[key][0].declaration.value;
        }

        const divStyle = {
            border: '1px solid black',
            borderRadius: '2px',
            height: '18px',
            ...createReactStyleFromCssStyle(kebabStyle, sheetDriver),
        };
        return () => <div style={divStyle}></div>;
    }

    private togglePopup() {
        this.props.onClickPackageButton && this.props.onClickPackageButton();
        this.setState({ open: !this.state.open });
    }

    private contentFunction = (id: string) => {
        const value: FullDeclarationMap = {
            [this.props.baseProp]:
                this.props.boxDeclarations[id] && this.props.boxDeclarations[id].length > 0
                    ? this.props.boxDeclarations[id][0].declaration.value
                    : '',
        };
        const blockVariantSet: BlockVariantSet = this.getVariantSet(id);
        const CustomTweak: ControllerComponentClass = this.props.customTweakComponent!;

        let changeFunctions;
        if (Object.keys(this.props.boxDeclarations).length === 0) {
            changeFunctions = {
                onChange: (val: DeclarationMap) =>
                    this.props.onGetGoingSelect && this.props.onGetGoingSelect(val[this.props.baseProp]!),
                onHover: (val: DeclarationMap) =>
                    this.props.onGetGoingChange && this.props.onGetGoingChange(val[this.props.baseProp]!),
                onHoverOff: () => this.props.onGetGoingChange && this.props.onGetGoingChange(null),
            };
        } else {
            changeFunctions = {
                onChange: (val: DeclarationMap) => this.onChangeBlockVariant(val, id, false),
                onHover: (val: DeclarationMap) => this.onChangeBlockVariant(val, id, true),
                onHoverOff: () => this.revertIfNeeded(id),
            };
        }
        return (
            <div className={classes.content}>
                <SimpleBlockVariantController
                    className={classes.blockVariant}
                    {...changeFunctions}
                    variantSets={blockVariantSet}
                    sheetDriver={this.sheetDriver || undefined}
                    customComponent={this.props.customBlockVariantPreview}
                />
                {this.props.customTweakComponent ? (
                    <CustomTweak
                        className={classes.customTweakComponent}
                        sheetDriver={this.sheetDriver!}
                        siteVarsDriver={this.siteVarsDriver}
                        value={{
                            [this.props.baseProp]: this.sheetDriver!.evalDeclarationValue(value[this.props.baseProp]),
                        }}
                        onChange={(val) => this.onChangeBlockVariant(val, id, false)}
                    />
                ) : null}
            </div>
        );
    };
    private getVariantSet(groupId: string) {
        let archetypeResolver: ArchetypeResolver;
        if (this.props.archetypeList) {
            archetypeResolver = new ArchetypeResolver(
                this.props.sheetPath,
                this.props.archetypeList,
                this.props.stylableDriver
            );
        }

        const categoryId = this.props.categoryId;
        let aggregateSets: BlockVariantSet = [];
        this.props.boxDeclarations[groupId] &&
            this.props.boxDeclarations[groupId].forEach((val) => {
                if (archetypeResolver) {
                    aggregateSets = aggregateSets.concat(
                        archetypeResolver.getBlockVariantsForElement(
                            (val.declaration.parent as postcss.Rule).selector,
                            categoryId
                        ) || []
                    );
                }
            });

        let retVal =
            this.props.categoryConfiguration[categoryId] && this.props.categoryConfiguration[categoryId].blockVariantSet
                ? this.props.categoryConfiguration[categoryId].blockVariantSet!
                : [];
        if (aggregateSets.length > 0) {
            retVal = aggregateSets.concat(retVal);
        }

        // Remove duplicates:
        retVal = retVal.filter((value: DeclarationMap, index: number, self: BlockVariantSet) => {
            return self.findIndex((val) => JSON.stringify(val) === JSON.stringify(value)) === index;
        });
        return retVal;
    }

    private onChangeBlockVariant(changeVal: DeclarationMap, id: string, revertible: boolean) {
        if (this.props.boxDeclarations[id] !== undefined) {
            if (revertible && !this.shouldRestore) {
                // once turning on shouldRestore - saving previous rule
                this.savedDeclaration = this.props.boxDeclarations[id][0].declaration.value;
                this.shouldRestore = true;
            }
            if (!revertible) {
                // if not revertible - ie onChange - clear restore.
                this.clearRestore();
                this.changePreview(id, changeVal[this.props.baseProp] || '');
            }

            CollectionDriver.changeValue(this.props.boxDeclarations[id], changeVal[this.props.baseProp] || ''); // TODO SEE INTO USING CHANGE FUNCTION FROM TWEAK
        }
    }

    private clearRestore = () => {
        this.shouldRestore = false;
    };

    private revertIfNeeded(id: string) {
        if (this.shouldRestore && this.savedDeclaration) {
            CollectionDriver.changeValue(this.props.boxDeclarations[id], this.savedDeclaration); // TODO SEE INTO USING CHANGE FUNCTION FROM TWEAK
            this.clearRestore();
        }
    }

    private changePreview(id: string, changeValElement: string) {
        const newSections = this.state.sections.map((section) => section);
        newSections.forEach((section) => {
            if (section.id === id) {
                section.customButton = this.customComponentWithStyle(
                    id,
                    this.sheetDriver || undefined,
                    changeValElement
                );
            }
        });
        this.setState({ sections: newSections });
    }
}
