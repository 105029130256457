import React from 'react';

import type { CustomInputProps } from '../../../drivers';
import { MarginInput } from '../margin-input/margin-input';

import { style, classes } from './padding-input.st.css';

export class PaddingInput extends React.Component<CustomInputProps> {
    public render() {
        const { value, siteVarsDriver, onChange, className } = this.props;

        return (
            <MarginInput
                className={style(classes.root, className)}
                value={value}
                siteVarsDriver={siteVarsDriver}
                onChange={onChange}
            />
        );
    }
}
