import * as postcss from 'postcss';

import { CollectionDriver, Collection, ChangeContext, addChangeContext } from './stylable-collection';
import type { StylesheetDriver } from '../stylable-stylesheet';
import { StylableShorthands } from '../stylable-shorthands';
import { preserveAlpha, addColorChangeContext } from './color-collection';

export interface FontData {
    colorDeclarations: Collection;
    fontDeclarations: Collection;
    sizeDeclarations: Collection;
}

// TOOD: Collect fonts from mixins
export class FontCollectionDriver extends CollectionDriver<FontData> {
    protected initialData() {
        return { colorDeclarations: {}, fontDeclarations: {}, sizeDeclarations: {} };
    }

    protected router(decl: postcss.Declaration, sheet: StylesheetDriver, fontData: FontData) {
        const addColor = addColorChangeContext.bind(this, fontData.colorDeclarations, decl);
        const addFontFamily = addChangeContext.bind(this, fontData.fontDeclarations, decl);
        const addFontSize = addChangeContext.bind(this, fontData.sizeDeclarations, decl);

        switch (decl.prop) {
            case 'color':
                this.handleDirectColorProperty(sheet, decl.value, addColor);
                break;
            case 'font':
                this.handleFontShorthandProperty(sheet, decl, addFontFamily, addFontSize);
                break;
            case 'font-family':
                this.handleFontFamilyProperty(sheet, decl.value, addFontFamily);
                break;
            case 'font-size':
                this.handleFontSizeProperty(sheet, decl.value, addFontSize);
                break;
        }
    }

    private handleDirectColorProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        function changeDirectColor(this: ChangeContext, value: string) {
            this.declaration.value = preserveAlpha(sheet, value, this.declaration.value);
            updateFromAST(this.declaration);
        }

        adder(sheet.evalDeclarationValue(declValue), changeDirectColor);
    }

    private handleFontShorthandProperty(
        sheet: StylesheetDriver,
        decl: postcss.Declaration,
        familyAdder: (value: string, change: (value: string) => void) => void,
        sizeAdder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);
        const shorthands = new StylableShorthands(sheet.evalDeclarationValue.bind(sheet));

        const expandedDeclaration = shorthands.shallowExpandDeclaration(decl);

        function familyChange(this: ChangeContext, value: string) {
            const currValue = shorthands.shallowExpandDeclaration(this.declaration)['font-family'];
            this.declaration.value = sheet.evalDeclarationValue(this.declaration.value).replace(currValue, value);
            updateFromAST(this.declaration);
        }

        function sizeChange(this: ChangeContext, value: string) {
            const currValue = shorthands.shallowExpandDeclaration(this.declaration)['font-size'];
            const newValue = sheet.evalDeclarationValue(this.declaration.value).replace(currValue, value);
            const expandedNewSizeValue = shorthands.shallowExpandDeclaration(
                postcss.decl({ prop: this.declaration.prop, value: newValue })
            )['font-size'];
            if (expandedNewSizeValue) {
                this.declaration.value = newValue;
                updateFromAST(this.declaration);
            }
        }

        const familyValue = expandedDeclaration['font-family'].replace(/"/g, '');
        const sizeValue = expandedDeclaration['font-size'];
        familyValue && familyAdder(familyValue, familyChange);
        sizeValue && sizeAdder(sizeValue, sizeChange);
    }

    private handleFontFamilyProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        function changeFontFamily(this: ChangeContext, value: string) {
            this.declaration.value = value;
            updateFromAST(this.declaration);
        }

        adder(sheet.evalDeclarationValue(declValue), changeFontFamily);
    }

    private handleFontSizeProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        function changeFontSize(this: ChangeContext, value: string) {
            this.declaration.value = value;
            updateFromAST(this.declaration);
        }

        adder(sheet.evalDeclarationValue(declValue), changeFontSize);
    }
}
