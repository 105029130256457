// Namespace Resolver
import { processNamespace } from '@stylable/core';

function noCollisionNamespace({ prefix = '', used: usedNamespaces = new Map() } = {}) {
    return (namespace: string, stylesheetPath: string) => {
        const ns = prefix + namespace;
        if (ns.startsWith('steindex')) return processNamespace(ns, stylesheetPath);
        const used = usedNamespaces.get(ns);
        if (used) {
            if (used.stylesheetPath !== stylesheetPath) {
                throw Error(`namespace (${ns} of ${stylesheetPath}) is already in use by ${used.stylesheetPath}`);
            }
        } else {
            usedNamespaces.set(ns, { prefix, namespace, stylesheetPath });
        }
        return ns;
    };
}

export const resolveNamespace = noCollisionNamespace({ prefix: 'ste' });
