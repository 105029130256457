import React from 'react';

import type { CustomInputPreviewProps } from '../drivers';

import { style, classes } from './arrow-preview.st.css';

export default class Preview extends React.Component<CustomInputPreviewProps> {
    public render() {
        return <span className={style(classes.root, this.props.className)} />;
    }
}
