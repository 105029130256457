import React from 'react';
import { Title, Scrollbars } from '@wixc3/stylable-panel-components';
import type { PresetDriver, PresetListType, Preset } from '@wixc3/stylable-panel-drivers';

import type { StylePanelChildProps } from '../style-panel/style-panel';
import { PresetSection } from './preset-section';
import { HOVER_OFF_TIMEOUT_MS } from '../../setup/consts';

import { style, classes } from './preset-panel.st.css';

export interface PresetSectionLayouts {
    static: Record<string, string>;
    theme: Record<string, string>;
    user: Record<string, string>;
}

export interface PresetPanelProps extends StylePanelChildProps {
    presetDriver: PresetDriver;
    layouts?: PresetSectionLayouts;
    onSelect?: () => void;
}

interface PresetPanelState {
    selectedPreset: number;
    selectedList: PresetListType;
}

export class PresetPanel extends React.Component<PresetPanelProps, PresetPanelState> {
    private shouldTriggerLeave = false;

    constructor(props: PresetPanelProps, context: any) {
        super(props, context);
        this.state = {
            selectedPreset: -1,
            selectedList: 'static', // don't care
        };
    }

    public render() {
        const { presetDriver, className } = this.props;

        // Preset data:
        const builtCSS = presetDriver.getBuiltCSS();
        const componentName = presetDriver.componentName;

        return (
            <div className={style(classes.root, className)}>
                <style>{builtCSS}</style>
                <Scrollbars universal style={{ height: 467, width: 419 }}>
                    {this.renderPresets(`My ${componentName}s`, `myPresets`, 'user')}
                    {this.renderPresets(`Theme ${componentName}s`, `themePresets`, 'theme')}
                    {this.renderPresets(`Discover More ${componentName}s`, `staticAssets`, 'static')}
                </Scrollbars>
            </div>
        );
    }

    private renderPresets(title = '', sectionClassName: string, presetList: PresetListType) {
        const { presetDriver, layouts } = this.props;
        const { selectedPreset, selectedList } = this.state;

        const presetIdList = presetDriver.getPresetIdList(presetList);
        if (presetIdList.length === 0) {
            return undefined;
        }

        const sectionStyle = layouts && (layouts as any)[presetList];
        const sectionInlineStyle = sectionStyle ? { style: sectionStyle } : {};
        const inlineStyles =
            presetList === 'static' ? presetDriver.staticPresets.map((p: Preset) => p.style) : undefined;

        const snapshot = presetDriver.firstSnapshot;

        return (
            <div
                className={style(classes.itemsWrapper, classes[sectionClassName], presetDriver.rootClassName)}
                data-aid={`st_preset_panel_${presetList}`}
            >
                <div className={classes.sectionHeader}>
                    <Title className={classes.titleLine} text={title} />
                </div>
                {snapshot ? (
                    <PresetSection
                        className={classes.section}
                        {...sectionInlineStyle}
                        onMouseLeave={this.restore}
                        onMouseLeavePreset={this.leaveTimeout}
                        snapshot={snapshot}
                        selectedPreset={selectedList === presetList ? selectedPreset : undefined}
                        presetIdList={presetIdList}
                        inlineStyles={inlineStyles}
                        onClickPreset={(index) => this.onClickPreset(index, presetList)}
                        onHoverPreset={() => {
                            this.mouseEnterPreset(/*index, presetList*/);
                            this.shouldTriggerLeave = false;
                        }}
                    />
                ) : undefined}
            </div>
        );
    }
    private onClickPreset = (index: number, fromPresetList: PresetListType) => {
        const { presetDriver, onSelect } = this.props;

        presetDriver.applyPresetToWorkingStylesheet(index, fromPresetList);
        presetDriver.clearRestore();

        this.setState({ selectedPreset: index, selectedList: fromPresetList });
        onSelect && onSelect();
    };

    private mouseEnterPreset(/*index: number, fromPresetList: PresetListType*/) {
        // this.props.presetDriver.applyPresetToWorkingStylesheet(index, fromPresetList);
    }

    private restore = () => {
        // this.props.presetDriver.restorePreset();
    };

    private leaveTimeout = () => {
        this.shouldTriggerLeave = true;
        setTimeout(() => {
            if (this.shouldTriggerLeave) {
                this.restore();
                this.shouldTriggerLeave = false;
            }
        }, HOVER_OFF_TIMEOUT_MS);
    };
}
