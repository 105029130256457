import React from 'react';

import { Title } from '@wixc3/stylable-panel-components';

import { style, classes } from './basic-controller.st.css';

export interface BasicControllerProps {
    value?: string;
    title?: string;
    onChange?: (value: string) => void;
    className?: string;
}

export interface BasicControllerState {
    value: string | undefined;
}

export class BasicController extends React.Component<BasicControllerProps, BasicControllerState> {
    public static defaultProps: Partial<BasicControllerProps> = {};
    private input: HTMLInputElement | null = null;
    public state = {
        value: this.props.value || '',
    };

    public componentDidUpdate(prevProps: BasicControllerProps, _prevState: BasicControllerState) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value || '' });
        }
    }

    public render() {
        const { title, className } = this.props;

        return (
            <div className={style(classes.root, className)}>
                {title ? <Title className={classes.title} text={title} /> : null}
                <div className={classes.inputContainer}>
                    <input
                        ref={(input) => (this.input = input)}
                        className={classes.input}
                        value={this.state.value}
                        placeholder="..."
                        onChange={(e) => this.setState({ value: e.currentTarget.value })}
                        onBlur={this.onBlur}
                    />
                </div>
            </div>
        );
    }

    public onBlur = () => {
        this.props.onChange && this.state.value !== undefined && this.props.onChange(this.state.value);
    };

    public setFocus() {
        this.input && this.input.focus();
    }
}
