import React from 'react';

import { domRect } from '@wixc3/stylable-panel-common-react';

import { style, classes } from './preset-section.st.css';

interface Rect {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface MouseDownParams {
    index: number;
    DOMDimensions: Rect;
    e: React.MouseEvent<HTMLElement>;
}

export interface PresetSectionProps {
    selectedPreset?: number;
    presetIdList: string[];
    snapshot: string;
    inlineStyles?: Array<string | undefined>;
    previewButtonProps?: Record<string, any>;
    onMouseLeave?: () => void;
    onClickPreset?: (index: number) => void;
    onHoverPreset?: (index: number) => void;
    onMouseDownPreset?: (params: MouseDownParams) => void;
    onMouseLeavePreset?: () => void;
    className?: string;
    style?: React.CSSProperties;
    ['data-aid']?: string;
}

const matchAttr = (name: string, html: string) => html.match(new RegExp(`^<[^>]*${name}="(.*?)"`));
const replaceAttr = (name: string, origin: string, replacement: string, html: string) =>
    html.replace(`${name}="${origin}"`, `${name}="${replacement}"`);
const addAttr = (name: string, addition: string, html: string) =>
    html.replace(/^<(.*?)(\/?)>/, `<$1 ${name}="${addition}"$2>`);
function addTopLevelAttributeFromHTMLString(name: string, addition: string, sep: string, html: string) {
    const currentClass = matchAttr(name, html); // .match(/^<[^>]*class="(.*?)"/);
    if (currentClass) {
        html = replaceAttr(name, currentClass[1], [currentClass[1], addition].join(sep), html);
    } else {
        html = addAttr(name, addition, html);
    }
    return html;
}

export class PresetSection extends React.Component<PresetSectionProps> {
    private buttons: { [index: number]: HTMLDivElement | null } = {};

    public render() {
        const {
            presetIdList,
            selectedPreset,
            snapshot,
            inlineStyles,
            onMouseLeave,
            onMouseLeavePreset,
            className: propClassName,
            style: propStyle,
        } = this.props;

        const presets = presetIdList.map((className: string, index: number) => {
            // Create html preview from template:
            let html = snapshot;
            html = addTopLevelAttributeFromHTMLString('class', classes.previewElement + ' ' + className, ' ', html);
            // Insert custom styling (for layout-ing)
            if (inlineStyles !== undefined && inlineStyles[index] !== undefined) {
                const inlineStyle = inlineStyles[index];
                html = addTopLevelAttributeFromHTMLString('style', inlineStyle!, ';', html);
            }
            html = addTopLevelAttributeFromHTMLString(
                'data-aid',
                `st_preset_section_preview_content${index}`,
                ' ',
                html
            );
            const isSelectedPreset = selectedPreset === index;

            return (
                <div
                    className={classes.previewElementButton}
                    ref={(c) => (this.buttons[index] = c)}
                    data-aid={`st_preset_section_item${index}`}
                    onClick={() => this.onClickPreset(index)}
                    onMouseEnter={() => this.onHoverPreset(index)}
                    onMouseDown={(e) => this.onMouseDownPreset(index, e)}
                    onMouseLeave={onMouseLeavePreset}
                    key={index}
                    {...this.props.previewButtonProps}
                >
                    <div className={style(classes.previewCloneContainer, { isSelectedPreset })}>
                        {isSelectedPreset && (
                            <div className={classes.selectedBadgeWrapper}>
                                <span className={classes.selectedBadge} />
                            </div>
                        )}
                        <div className={classes.previewContent} dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </div>
            );
        });

        return (
            <div
                data-aid={this.props['data-aid']}
                className={style(classes.root, propClassName)}
                style={propStyle}
                onMouseLeave={onMouseLeave}
            >
                {presets}
            </div>
        );
    }

    private onClickPreset(index: number) {
        this.props.onClickPreset && this.props.onClickPreset(index);
    }

    private onHoverPreset(index: number) {
        this.props.onHoverPreset && this.props.onHoverPreset(index);
    }

    private onMouseDownPreset(index: number, e: React.MouseEvent<HTMLElement>) {
        const domDimension = this.getButtonDimension(index);
        if (this.props.onMouseDownPreset && domDimension) {
            this.props.onMouseDownPreset &&
                this.props.onMouseDownPreset({
                    index,
                    DOMDimensions: domDimension,
                    e,
                });
        }
    }

    private getButtonDimension(index: number): Rect | undefined {
        if (this.buttons[index]) {
            const { left, top, width, height } = domRect(this.buttons[index]!);
            return { left, top, width, height };
        }
        return;
    }
}
