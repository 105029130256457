import React from 'react';
import { Title } from '../title/title';

import { style, classes } from './tab-button.st.css';

export type TabIcon = () => JSX.Element | string;

export interface TabButtonProps {
    id: string;
    selected?: boolean;
    icon?: TabIcon;
    title?: string;
    openIndicator?: TabIcon;
    noIcon?: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export class TabButton extends React.Component<TabButtonProps> {
    public static defaultProps: Partial<TabButtonProps> = {
        selected: false,
    };

    constructor(props: TabButtonProps) {
        super(props);
    }

    public render() {
        const {
            id: tabName,
            selected,
            icon: propIcon,
            title,
            openIndicator: propOpenIndicator,
            noIcon,
            onClick,
            onMouseEnter,
            onMouseLeave,
            className,
            style: propStyle,
        } = this.props;
        const icon = propIcon ? propIcon() : null;
        const openIndicator = propOpenIndicator ? propOpenIndicator() : null;

        return (
            <div
                className={style(
                    classes.root,
                    {
                        selected: !!selected,
                        tabName,
                    },
                    className
                )}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={propStyle}
                data-aid={`st_tabbutton_${tabName}`}
            >
                <div className={classes.tabRow}>
                    {!noIcon ? (
                        <div className={classes.iconContainer}>
                            <div className={classes.icon}>{icon}</div>
                        </div>
                    ) : null}
                    {title ? <Title className={classes.title} text={title} /> : null}
                    {openIndicator}
                </div>
                {/* <div className={classes.divider}/> */}
            </div>
        );
    }
}
