import type { StylableDriver } from './stylable-driver';
import {
    Collection,
    FontData,
    GenericCollectionDriver,
    ColorCollectionDriver,
    FontCollectionDriver,
    ImageCollectionDriver,
} from './collection';

export class StylableTweak {
    constructor(private stylableDriver: StylableDriver) {}

    public collectSelectorColorDeclarations(sheetPath: string, selector: string): Collection {
        return new ColorCollectionDriver(
            this.stylableDriver.getStylesheet.bind(this.stylableDriver),
            this.stylableDriver.resolveSymbol.bind(this.stylableDriver),
            this.stylableDriver.updateFileFromAST.bind(this.stylableDriver, sheetPath)
        ).collect(sheetPath, selector);
    }

    public collectSelectorFontDeclarations(sheetPath: string, selector: string): FontData {
        return new FontCollectionDriver(
            this.stylableDriver.getStylesheet.bind(this.stylableDriver),
            this.stylableDriver.resolveSymbol.bind(this.stylableDriver),
            this.stylableDriver.updateFileFromAST.bind(this.stylableDriver, sheetPath)
        ).collect(sheetPath, selector);
    }

    public collectSelectorImageDeclarations(sheetPath: string, selector: string): Collection {
        return new ImageCollectionDriver(
            this.stylableDriver.getStylesheet.bind(this.stylableDriver),
            this.stylableDriver.resolveSymbol.bind(this.stylableDriver),
            this.stylableDriver.updateFileFromAST.bind(this.stylableDriver, sheetPath)
        ).collect(sheetPath, selector);
    }

    public collectSelectorGenericDeclarations(
        sheetPath: string,
        selector: string,
        basePropName: string,
        propNames?: string[]
    ): Collection {
        return new GenericCollectionDriver(
            this.stylableDriver.getStylesheet.bind(this.stylableDriver),
            this.stylableDriver.resolveSymbol.bind(this.stylableDriver),
            this.stylableDriver.updateFileFromAST.bind(this.stylableDriver, sheetPath),
            basePropName,
            propNames
        ).collect(sheetPath, selector);
    }
}
