import React from 'react';
import type { GenericDeclarationMap } from '@wixc3/stylable-panel-drivers';
import { controllerToVisualizerChange, createDeclarationMapFromVisualizerValue } from '../../utils';
import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';
import { Tooltip } from '@wixc3/stylable-panel-components';
import { getTranslate } from '../../hosts/translate';
import { classes, style } from './icon-toggle-visualizer.st.css';

type IconToggleFactoryProps = {
    tooltipKey: string;
    MainIcon: React.FunctionComponent;
    SecondaryIcon?: React.FunctionComponent;
    checkedPredicate: (value: string | undefined) => boolean;
    checkedValue: string;
    uncheckedValue: string;
};

export interface IconToggleVisualizerProps<MAIN extends string> extends DeclarationVisualizerProps<MAIN> {}

export function IconToggleVisualizerFactory<MAIN extends string>(
    main: MAIN,
    iconToggleProps: IconToggleFactoryProps
): React.ComponentClass<IconToggleVisualizerProps<MAIN>> {
    const { tooltipKey, MainIcon, SecondaryIcon, checkedValue, uncheckedValue, checkedPredicate } = iconToggleProps;

    return class IconToggleVisualizer
        extends React.Component<IconToggleVisualizerProps<MAIN>>
        implements VisualizerComponent<MAIN>
    {
        private declarationMapValue: GenericDeclarationMap<MAIN>;

        constructor(props: IconToggleVisualizerProps<MAIN>) {
            super(props);
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
        }

        public componentDidUpdate() {
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(this.props.value, this.props);
        }

        private onToggleChange = () => {
            if (this.props.onChange) {
                const { [main]: value } = this.declarationMapValue;
                const isChecked = checkedPredicate(value);
                this.props.onChange(
                    controllerToVisualizerChange(
                        {
                            [main]: isChecked ? uncheckedValue : checkedValue,
                        } as GenericDeclarationMap<MAIN>,
                        this.props
                    )
                );
            }
        };

        render() {
            const { panelHost, className } = this.props;
            const { [main]: value } = this.declarationMapValue;
            const isChecked = checkedPredicate(value);
            const translate = getTranslate(panelHost);
            const Icon = isChecked && SecondaryIcon ? SecondaryIcon : MainIcon;

            return (
                <Tooltip className={style(classes.root, className)} text={tooltipKey && translate(tooltipKey)}>
                    <span
                        className={style(classes.icon, {
                            checked: isChecked && !SecondaryIcon,
                        })}
                        onClick={this.onToggleChange}
                        data-id={`icon_${main}`}
                    >
                        <input
                            className={classes.hiddenInput}
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => true}
                        />
                        {Icon && <Icon />}
                    </span>
                </Tooltip>
            );
        }
    };
}
