import type * as postcss from 'postcss';
import chroma from 'chroma-js';
import { stringify } from 'css-box-shadow';
import type { GradientAST } from 'gradient-parser';

import { valueMapping, SBTypesParsers, StylableMeta } from '@stylable/core';

import { CollectionDriver, Collection, ChangeContext } from './stylable-collection';
import type { StylesheetDriver } from '../stylable-stylesheet';
import { StylableShorthands, splitLayers } from '../stylable-shorthands';
import { expandGradient, normalizeGradientColorStop } from '../stylable-gradient';
import {
    colorProperties,
    shorthandsContainingColor,
    nonExpandablesContainingColor,
    shorthandToColorPropMap,
} from '../utils/css-utils/colors';

interface Mixin {
    type: string;
    options: Record<string, string>;
}

function replaceColor(sourceValue: string, currValue: string, newValue: string) {
    const currValueHex = chroma(currValue).hex();
    return sourceValue
        .replace(currValue, newValue)
        .replace(currValueHex, newValue)
        .replace(currValueHex.toUpperCase(), newValue)
        .replace(currValueHex.toLowerCase(), newValue);
}

export class ColorCollectionDriver extends CollectionDriver<Collection> {
    protected router(decl: postcss.Declaration, sheet: StylesheetDriver, declarations: Collection) {
        const addColor = addColorChangeContext.bind(this, declarations, decl);

        if (decl.prop === 'background' || decl.prop === 'background-image') {
            this.handleBackgroundProperty(sheet, decl.value, addColor);
        } else if (decl.prop === 'border-image' || decl.prop === 'border-image-source') {
            this.handleBorderImageProperty(sheet, decl.value, addColor);
        } else if (decl.prop === valueMapping.mixin) {
            this.handleMixinProperty(sheet, decl, addColor);
        } else if (~colorProperties.indexOf(decl.prop)) {
            this.handleDirectColorProperty(sheet, decl.value, addColor);
        } else if (~shorthandsContainingColor.indexOf(decl.prop)) {
            this.handleNonBackgroundShorthandProperty(sheet, decl, addColor);
        } else if (~nonExpandablesContainingColor.indexOf(decl.prop)) {
            this.handleNonExpandableProperty(sheet, decl.value, addColor);
        }
    }

    private handleDirectColorProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        function changeDirectColor(this: ChangeContext, value: string) {
            this.declaration.value = preserveAlpha(sheet, value, this.declaration.value);
            updateFromAST(this.declaration);
        }

        adder(sheet.evalDeclarationValue(declValue), changeDirectColor);
    }

    private handleNonBackgroundShorthandProperty(
        sheet: StylesheetDriver,
        decl: postcss.Declaration,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);
        const shorthands = new StylableShorthands(sheet.evalDeclarationValue.bind(sheet));

        const expandedDeclaration = shorthands.shallowExpandDeclaration(decl);
        shorthandToColorPropMap[decl.prop].forEach((colorProp) => {
            function changeNonBackgroundShorthand(this: ChangeContext, val: string) {
                const currValue = shorthands.shallowExpandDeclaration(this.declaration)[colorProp];
                const newValue = preserveAlpha(sheet, val, currValue);

                this.declaration.value = replaceColor(
                    sheet.evalDeclarationValue(this.declaration.value),
                    currValue,
                    newValue
                );
                updateFromAST(this.declaration);
            }

            const value = expandedDeclaration[colorProp];
            value && adder(value, changeNonBackgroundShorthand);
        });
    }

    private handleBackgroundProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        const backgrounds = splitLayers(sheet.evalDeclarationValue(declValue));
        backgrounds.forEach((layer, index) => {
            const expandedLayer: any = expandGradient(layer, 'background-image', 'background');

            const backgroundImageValue = expandedLayer['background-image'] as GradientAST;
            if (backgroundImageValue && typeof backgroundImageValue !== 'string' && backgroundImageValue.colorStops) {
                backgroundImageValue.colorStops.forEach((colorStop, colorIndex) => {
                    function changeBackgroundGradientColor(this: ChangeContext, value: string) {
                        const currBackgrounds = splitLayers(this.declaration.value);
                        const currEval = sheet.evalDeclarationValue(currBackgrounds[index]);
                        const currLayer = expandGradient(currEval, 'background-image', 'background')[
                            'background-image'
                        ] as GradientAST;
                        const currValue = normalizeGradientColorStop(currLayer.colorStops[colorIndex]);
                        const currValueMatcher = RegExp(
                            currValue.replace('(', '\\(\\s*').replace(')', '\\s*\\)').replace(/\s/g, '\\s*')
                        );
                        currBackgrounds[index] = currEval.replace(
                            currValueMatcher,
                            preserveAlpha(sheet, value, currValue)
                        );
                        this.declaration.value = currBackgrounds.join(', ');
                        updateFromAST(this.declaration);
                    }

                    adder(normalizeGradientColorStop(colorStop), changeBackgroundGradientColor);
                });
            }

            function changeBackgroundColor(this: ChangeContext, value: string) {
                const currBackgrounds = splitLayers(sheet.evalDeclarationValue(this.declaration.value));
                const currValue = expandGradient(currBackgrounds[index], 'background-image', 'background')[
                    'background-color'
                ] as string;
                const newValue = preserveAlpha(sheet, value, currValue);

                currBackgrounds[index] = replaceColor(currBackgrounds[index], currValue, newValue);
                this.declaration.value = currBackgrounds.join(', ');
                updateFromAST(this.declaration);
            }

            const backgroundColorValue = expandedLayer['background-color'] as string;
            backgroundColorValue && adder(backgroundColorValue, changeBackgroundColor);
        });
    }

    private handleBorderImageProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);

        const expandedGradient: any = expandGradient(
            sheet.evalDeclarationValue(declValue),
            'border-image-source',
            'border-image'
        );
        const borderImageSourceValue = expandedGradient['border-image-source'] as GradientAST;
        if (borderImageSourceValue && typeof borderImageSourceValue !== 'string' && borderImageSourceValue.colorStops) {
            borderImageSourceValue.colorStops.forEach((colorStop, colorIndex) => {
                function changeBorderImageSourceGradientColor(this: ChangeContext, value: string) {
                    const currEval = sheet.evalDeclarationValue(this.declaration.value);
                    const currGradient = expandGradient(currEval, 'border-image-source', 'border-image')[
                        'border-image-source'
                    ] as GradientAST;
                    const currValue = normalizeGradientColorStop(currGradient.colorStops[colorIndex]);
                    const currValueMatcher = RegExp(
                        currValue.replace('(', '\\(\\s*').replace(')', '\\s*\\)').replace(/\s/g, '\\s*')
                    );
                    this.declaration.value = currEval.replace(currValueMatcher, preserveAlpha(sheet, value, currValue));
                    updateFromAST(this.declaration);
                }

                adder(normalizeGradientColorStop(colorStop), changeBorderImageSourceGradientColor);
            });
        }
    }

    private handleNonExpandableProperty(
        sheet: StylesheetDriver,
        declValue: string,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const updateFromAST = this.updateFromAST.bind(this);
        const shorthands = new StylableShorthands(sheet.evalDeclarationValue.bind(sheet));

        const shadows = shorthands.expandShadow(declValue);
        shadows.forEach((shadow, index) => {
            function changeNonExpandable(this: ChangeContext, value: string) {
                const currShadows = shorthands.expandShadow(this.declaration.value);
                currShadows[index].color = preserveAlpha(sheet, value, currShadows[index].color);
                this.declaration.value = stringify(currShadows);
                updateFromAST(this.declaration);
            }

            shadow.color && adder(shadow.color, changeNonExpandable);
        });
    }

    private handleMixinProperty(
        sheet: StylesheetDriver,
        decl: postcss.Declaration,
        adder: (value: string, change: (value: string) => void) => void
    ) {
        const meta = sheet.getMeta();
        const updateFromAST = this.updateFromAST.bind(this);
        const parseMixin = this.parseMixin.bind(this, meta);

        const mixins = parseMixin(decl);
        mixins &&
            mixins.forEach((mixin, index) => {
                const resolvedSymbol = this.resolveSymbol(meta.source, mixin.type);
                if (!resolvedSymbol) {
                    return;
                }

                const mixinSheet = this.getStylesheet(resolvedSymbol.from);
                if (!mixinSheet) {
                    return;
                }

                const mixinOverrides = mixinSheet.getCSSMixinOverrides(resolvedSymbol.name);
                const mixinSheetVars = mixinSheet.getVarSymbols();

                const mixinColorOverrides = mixinOverrides.filter(
                    (override) => !!mixinSheetVars[override] && mixinSheetVars[override].valueType === 'COLOR'
                );

                mixinColorOverrides.forEach((varName) => {
                    const color = sheet.evalDeclarationValue(
                        mixinSheet.evalDeclarationValue(`value(${varName})`, mixin.options)
                    );

                    function changeMixin(this: ChangeContext, value: string) {
                        const currMixins = parseMixin(this.declaration);
                        currMixins[index].options[varName] = value;
                        this.declaration.value = stringifyMixins(currMixins);
                        updateFromAST(this.declaration);
                    }

                    adder(color, changeMixin);
                });
            });
    }

    private parseMixin(meta: StylableMeta, decl: postcss.Declaration) {
        return SBTypesParsers[valueMapping.mixin](decl, (type) => {
            const mixinRefSymbol = meta.mappedSymbols[type];
            return mixinRefSymbol && mixinRefSymbol._kind === 'import' && !mixinRefSymbol.import.from.match(/\.css$/)
                ? 'args'
                : 'named';
        }) as Array<{ type: string; options: Record<string, string> }>;
    }
}

export function addColorChangeContext(
    declarations: Collection,
    decl: postcss.Declaration,
    value: string,
    change: (value: string) => void
) {
    try {
        const color = chroma(value).alpha(1).css();

        if (declarations[color] === undefined) {
            declarations[color] = [];
        }
        declarations[color].push({ declaration: decl, change });
    } catch {
        //
    }
}

export function preserveAlpha(sheet: StylesheetDriver, value: string, currValue: string) {
    let newValue = `${value}`;
    let currAlpha = 1;

    try {
        currAlpha = chroma(currValue).alpha();
    } catch {
        //
    }

    if (currAlpha !== 1) {
        const evalNewValue = sheet.evalDeclarationValue(newValue);
        newValue = chroma(evalNewValue).alpha(currAlpha).css();
    }

    return newValue;
}

function stringifyMixins(mixins: Mixin[]) {
    return mixins
        .map(({ type, options }) => {
            const optionsString = Object.keys(options).length
                ? '(' +
                  Object.keys(options)
                      .map((option) => `${option} ${options[option]}`)
                      .join(', ') +
                  ')'
                : '';
            return type + optionsString;
        })
        .join(', ');
}
