import React from 'react';

import { Title, Tooltip } from '@wixc3/stylable-panel-components';
import { StylesheetDriver, StylableSiteVars, Collection } from '@wixc3/stylable-panel-drivers';

import { collectStates } from './package-common';
import { extractURL, ColorPicker, ImagePicker } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './color-package.st.css';

export interface ColorPackageProps {
    colorDeclarations: Collection;
    imageDeclarations: Collection;
    sheetDriver: StylesheetDriver;
    onChangeColor: (index: number, value: string) => void;
    onChangeImage: (index: number, value: string) => void;
    onGetGoingColorChange: (value: string | null) => void;
    onGetGoingColorSelect: (value: string) => void;
    onGetGoingImageChange: (value: string) => void;
    className?: string;
}

export interface ColorPackageState {
    colorEdited: number;
    imageEdited: number;
}

export default class ColorPackage extends React.Component<ColorPackageProps, ColorPackageState> {
    public state: ColorPackageState = { colorEdited: -1, imageEdited: -1 };
    private colors: string[] = [];
    private images: string[] = [];
    private collectedStates: Record<string, string> = {};
    private siteVarsDriver!: StylableSiteVars;

    constructor(props: ColorPackageProps) {
        super(props);
        const { sheetDriver } = this.props;
        this.siteVarsDriver = new StylableSiteVars(sheetDriver);
        this.setColorsWithProps(this.props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: ColorPackageProps) {
        if (
            this.props.colorDeclarations !== props.colorDeclarations ||
            this.props.imageDeclarations !== props.imageDeclarations
        ) {
            this.setColorsWithProps(props);
        }
    }

    public render() {
        const { colorDeclarations, imageDeclarations, className } = this.props;
        const { colorEdited, imageEdited } = this.state;

        const setColors = Object.keys(colorDeclarations);
        const setImages = Object.keys(imageDeclarations);

        return (
            <div className={style(classes.root, className)}>
                <Title text="All Fills" />
                <div className={classes.boxesWrapper}>
                    {!setColors.length && !setImages.length ? this.renderGetGoingBox() : null}
                    {setColors.length ? setColors.map(this.renderColorBox) : null}
                    {setImages.length ? setImages.map(this.renderImageBox) : null}
                </div>
                {colorEdited !== -1 ? (
                    <ColorPicker
                        className={classes.colorPicker}
                        siteVarsDriver={this.siteVarsDriver}
                        currentColor={this.colors[colorEdited]}
                        onChange={this.handleColorPickerSelect}
                        // onHover={this.handleColorPickerChange}
                        // onClose={() => this.setState({colorEdited: -1})}
                        onBlur={() => this.setState({ colorEdited: -1 })}
                    />
                ) : null}
                {imageEdited !== -1 ? (
                    <ImagePicker
                        className={classes.imagePicker}
                        value={this.images[imageEdited]}
                        onChange={this.handleImagePickerChange}
                        // onClose={() => this.setState({imageEdited: -1})}
                    />
                ) : null}
            </div>
        );
    }

    private setColorsWithProps(props: ColorPackageProps) {
        const { colorDeclarations, imageDeclarations } = props;

        this.colors = Object.keys(colorDeclarations);
        this.collectedStates = collectStates(colorDeclarations, this.colors);

        this.images = Object.keys(imageDeclarations);
        this.collectedStates = {
            ...this.collectedStates,
            ...collectStates(imageDeclarations, Object.keys(imageDeclarations)),
        };
    }

    private renderColorBox = (setColor: string, index: number) => {
        const { colorEdited } = this.state;
        return (
            <Tooltip key={`color_${index}`} className={classes.colorTooltip} text={this.tooltipText(setColor)}>
                <span
                    className={style(classes.colorBox, { selected: index === colorEdited })}
                    style={{ backgroundColor: this.colors[index] }}
                    onClick={() => this.setState({ colorEdited: colorEdited === -1 ? index : -1, imageEdited: -1 })}
                    onMouseEnter={() => this.handleColorAccentEnter(index)}
                    onMouseLeave={() => this.handleColorAccentLeave(index)}
                >
                    {' '}
                </span>
            </Tooltip>
        );
    };

    private renderImageBox = (setImage: string, index: number) => {
        const { imageEdited } = this.state;
        return (
            <Tooltip key={`image_${index}`} className={classes.imageTooltip} text={this.tooltipText(setImage)}>
                <span
                    className={style(classes.imageBox, { selected: index === imageEdited })}
                    style={{ background: `center / contain no-repeat ${this.images[index]}` }}
                    onClick={() => this.setState({ imageEdited: imageEdited === -1 ? index : -1, colorEdited: -1 })}
                >
                    {extractURL(true)(this.images[index]) ? ' ' : 'N/A'}
                </span>
            </Tooltip>
        );
    };

    private tooltipText(collectKey: string) {
        const value = this.collectedStates[collectKey];
        return value ? `Shows on ${value}` : undefined;
    }

    private renderGetGoingBox() {
        const { colorEdited, imageEdited } = this.state;
        return [
            <Tooltip key={`color_get_going`} className={classes.getGoingColorTooltip} text="Add First Color">
                <span
                    className={style(classes.getGoingColorBox, { selected: colorEdited === 0 })}
                    onClick={() => this.setState({ colorEdited: colorEdited === -1 ? 0 : -1, imageEdited: -1 })}
                >
                    {' '}
                </span>
            </Tooltip>,
            <Tooltip key={`image`} className={classes.getGoingImageTooltip} text="Add First Image">
                <span
                    className={style(classes.getGoingImageBox, { selected: imageEdited === 0 })}
                    onClick={() => this.setState({ imageEdited: imageEdited === -1 ? 0 : -1, colorEdited: -1 })}
                >
                    {' '}
                </span>
            </Tooltip>,
        ];
    }

    private handleColorPickerSelect = (value: string) => {
        const { colorDeclarations, onGetGoingColorSelect } = this.props;

        if (Object.keys(colorDeclarations).length) {
            this.handleColorPickerChange(value);
        } else {
            onGetGoingColorSelect(value);
        }
    };

    private handleColorPickerChange = (value: string | null) => {
        const { colorDeclarations, sheetDriver, onChangeColor, onGetGoingColorChange } = this.props;

        if (Object.keys(colorDeclarations).length) {
            if (!value) {
                return;
            }
            const { colorEdited } = this.state;

            onChangeColor(colorEdited, value);

            this.colors[colorEdited] = sheetDriver.evalDeclarationValue(value);
            this.forceUpdate();
        } else {
            onGetGoingColorChange(value);
        }
    };

    private handleImagePickerChange = (value: string) => {
        const { imageDeclarations, sheetDriver, onChangeImage, onGetGoingImageChange } = this.props;

        if (Object.keys(imageDeclarations).length) {
            const { imageEdited } = this.state;

            onChangeImage(imageEdited, value);

            this.images[imageEdited] = sheetDriver.evalDeclarationValue(value);
            this.forceUpdate();
        } else {
            onGetGoingImageChange(value);
        }
    };

    private handleColorAccentEnter(_index: number) {
        // this.props.onChangeColor(index, accentuateColor(this.colors[index]));
        // this.colors.forEach((color, colorIndex) => {
        //     if (colorIndex === index) return;
        //     this.props.onChangeColor(colorIndex, fadeColor(color));
        // });
    }

    private handleColorAccentLeave(_index: number) {
        // this.props.onChangeColor(index, this.colors[index]);
        // this.colors.forEach((color, colorIndex) => {
        //     if (colorIndex === index) return;
        //     this.props.onChangeColor(colorIndex, this.colors[index]);
        // });
    }
}

// function accentuateColor(value: string) {
//     const FACTOR = 0.2;
//     const color = Color(value);
//     return (color.isDark() ? color.lighten(FACTOR) : color.darken(FACTOR)).hex('rgb').toString();
// }

// function fadeColor(value: string) {
//     const FACTOR = 0.9;
//     const color = Color(value);
//     // return color.fade(FACTOR).toString();
//     return color.lighten(FACTOR).toString();
// }
