import React from 'react';

import { Information } from '@wixc3/stylable-panel-common-react';

import { Tooltip } from './tooltip';

import { style, classes } from './information-tooltip.st.css';

export interface InformationTooltipProps {
    text?: string;
    verticalAdjust?: number;
    horizontalAdjust?: number;
    className?: string;
    style?: React.CSSProperties;
}

export class InformationTooltip extends React.Component<InformationTooltipProps> {
    public render() {
        const { text, verticalAdjust, horizontalAdjust, className, style: propStyle } = this.props;

        return (
            <Tooltip
                className={style(classes.root, className)}
                style={propStyle}
                text={text}
                verticalAdjust={-3 + (verticalAdjust || 0)}
                horizontalAdjust={horizontalAdjust}
            >
                <Information />
            </Tooltip>
        );
    }
}
