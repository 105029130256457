import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { TooltipAttachTo } from './tooltip';
import { classes, style } from './tooltip.st.css';

interface TooltipProps {
    getTooltipPlacement: Function;
    onMouseEnter: MouseEventHandler<HTMLSpanElement>;
    disabled: boolean;
    attachTo: TooltipAttachTo;
}

const TooltipInner: FC<TooltipProps> = ({ onMouseEnter, getTooltipPlacement, children, disabled, attachTo }) => {
    const tooltipRef = useRef<HTMLSpanElement>(null);
    const [finalAttachTo, setFinalAttachTo] = useState<TooltipAttachTo | null>(null);
    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipWidth = tooltipRef.current.getBoundingClientRect().width;
            const tooltipOffsetLeft = tooltipRef.current.offsetLeft;
            if (tooltipOffsetLeft + tooltipWidth + 1 >= window.innerWidth) {
                setFinalAttachTo(TooltipAttachTo.Left);
            } else {
                setFinalAttachTo(attachTo);
            }
        }
    }, [tooltipRef, attachTo]);
    return (
        <span
            className={style(classes.tooltip, { tooltipPosition: finalAttachTo || attachTo })}
            onMouseEnter={onMouseEnter}
            style={{
                ...getTooltipPlacement(finalAttachTo),
                visibility: tooltipRef.current ? 'visible' : 'hidden',
            }}
            ref={tooltipRef}
        >
            <span className={style(classes.tooltipText, { disabled })}>{children}</span>
        </span>
    );
};

export default TooltipInner;
