import React from 'react';

import { RadioButton, RadioButtonProps } from './radio-button';
import { style, classes } from './radio-group.st.css';

export interface RadioGroupProps {
    values: RadioButtonProps[];
    className?: string;
}

export class RadioGroup extends React.Component<RadioGroupProps> {
    public render() {
        const { values, className } = this.props;
        return (
            <ul className={style(classes.root, className)}>
                {values.map((button: RadioButtonProps, index: number) => (
                    <li
                        key={`radio_button_${index}_${button.id}`}
                        className={style(classes.button, {
                            isFirst: index === 0,
                            isLast: index === values.length - 1,
                            isSelected: button.checked,
                        })}
                    >
                        <RadioButton
                            className={classes.radioButton}
                            {...button}
                            onChange={!button.checked ? button.onChange : () => false}
                        />
                    </li>
                ))}
            </ul>
        );
    }
}
