import { Scrollbars } from '@wixc3/stylable-panel-components';
import { getNodeId, SiteVarsDriver, StylableVariant } from '@wixc3/stylable-panel-drivers';
import * as postcss from 'postcss';
import React, { createRef, FC, RefObject, useContext, useEffect, useRef } from 'react';
import { DragDeclarationDriver } from '../../drivers/drag-declaration';
import { CSSPanelContext } from '../../ui/css-panel';
import { useForceUpdate } from '../../utils/hooks/use-force-update';
import StyleRuleEditor, { StyleRuleRef } from './style-rule-editor';
import { classes, style } from './variant-editor.st.css';

export interface VariantEditorProps {
    siteVarsDriver?: SiteVarsDriver;
    name: string;
    className?: string;
}

const VariantEditor: FC<VariantEditorProps> = ({ siteVarsDriver, name: variant, className }) => {
    const forceUpdate = useForceUpdate();

    const cssPanelContext = useContext(CSSPanelContext);
    const variantDriverRef = useRef<StylableVariant | null>(null);
    const styleRulesRefs = useRef<{ [name: string]: RefObject<StyleRuleRef> }>({});

    const filterStRules = (name: string) =>
        name === '-st-mixin' || (name !== '-editor-theme' && !name.startsWith('-st-'));

    const getStylesheetAst = () => cssPanelContext?.stylableSheet?.AST;

    const dragDriver = useRef<DragDeclarationDriver | null>(null);
    const focusId = useRef(-1);

    useEffect(() => {
        if (cssPanelContext.stylableSheet) {
            variantDriverRef.current = new StylableVariant(cssPanelContext.stylableSheet);
        }
    }, [cssPanelContext]);

    useEffect(() => {
        if (focusId.current !== -1) {
            if (styleRulesRefs.current[`style_rule_${focusId.current}`]) {
                styleRulesRefs.current[`style_rule_${focusId.current}`].current?.focusSelector();
            } else {
                console.warn('focusing on non-existing rule: ' + focusId.current);
            }
            focusId.current = -1;
        }
    }, [focusId]);

    const rules = variantDriverRef?.current?.getVariantStyleRules(variant) || [];
    let selectorSuggestions = rules.map((rule) => rule.selector);

    if (!~selectorSuggestions.indexOf(variant)) {
        selectorSuggestions = [variant].concat(selectorSuggestions);
    }

    if (!rules || !rules.length) {
        return (
            <div className={style(classes.root, classes.emptyRoot, className)} onClick={() => handleAddRule()}>
                <span className={classes.emptyInstruction}>Click to add a new rule</span>
            </div>
        );
    }

    function handleDragStart(id: number) {
        dragDriver.current = new DragDeclarationDriver(cssPanelContext.stylableSheet!, id, forceUpdate, () =>
            setTimeout(() => (dragDriver.current = null), 0)
        );
    }

    function handleAddRule(addAfterRule?: postcss.Rule) {
        const selector = `${name}`;
        const newRule = postcss.rule({ selector });
        const ast = getStylesheetAst();

        focusId.current = getNodeId(newRule);
        if (addAfterRule) {
            ast?.insertAfter(addAfterRule, newRule);
        } else {
            ast?.prepend(newRule);
        }

        cssPanelContext?.stylableSheet?.updateFromAST(newRule);
        forceUpdate();
    }

    function handleDeleteRule(rule: postcss.Rule) {
        if (!rule.selector) {
            const ast = getStylesheetAst();
            ast?.removeChild(rule);
            cssPanelContext?.stylableSheet?.updateFromAST(ast!);
            forceUpdate();
        }
    }

    return (
        <Scrollbars
            universal
            style={{
                width: 300,
                display: 'inline-block',
            }}
            className={style(classes.root, className)}
        >
            {rules.map((rule) => {
                const id = getNodeId(rule);
                styleRulesRefs.current[`style_rule_${id}`] = createRef();
                return (
                    <StyleRuleEditor
                        key={id}
                        className={classes.styleRuleEditor}
                        data-key={id}
                        siteVarsDriver={siteVarsDriver}
                        rule={rule}
                        filter={filterStRules}
                        selectorSuggestions={selectorSuggestions} // TODO: Populate with intelligence completions
                        selectorPrefix={variant}
                        onDragStart={handleDragStart}
                        onClosingBracesClick={() => handleAddRule(rule)}
                        onBlur={() => handleDeleteRule(rule)}
                        ref={styleRulesRefs.current[`style_rule_${id}`]}
                    />
                );
            })}
        </Scrollbars>
    );
};

export default VariantEditor;
