import React from 'react';
import {
    DropDown,
    KeywordVisualizerComponentProps,
    KeywordVisualizerFactoryProps,
} from '@wixc3/stylable-panel-components';
import type { GenericDeclarationMap } from '@wixc3/stylable-panel-drivers';
import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';
import { createDeclarationMapFromVisualizerValue, createVisualizerValueFromDeclarationMap } from '../../utils';
import { style, classes } from './keyword-visualizer.st.css';

export interface KeywordsVisualizerProps<PROPS extends string>
    extends DeclarationVisualizerProps<PROPS>,
        KeywordVisualizerComponentProps {}

export type KeywordDropdownVisualizerClass<PROPS extends string> = React.ComponentClass<KeywordsVisualizerProps<PROPS>>;

export function KeywordVisualizerFactory<PROPS extends string>(
    main: PROPS,
    keywordDropdownProps: KeywordVisualizerFactoryProps,
    defaultOptionId = ''
): KeywordDropdownVisualizerClass<PROPS> {
    class KeywordVisualizer
        extends React.Component<KeywordsVisualizerProps<PROPS>>
        implements VisualizerComponent<PROPS>
    {
        public static INPUT_PROPS: PROPS[] = [main];
        private declarationMapValue: GenericDeclarationMap<PROPS>;

        constructor(props: DeclarationVisualizerProps<PROPS>) {
            super(props);
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillUpdate(props: DeclarationVisualizerProps<PROPS>) {
            this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
        }

        render() {
            const { className, disabled, onChange } = this.props;
            const hasOption = keywordDropdownProps.options.some(
                (option) => option.id === this.declarationMapValue[main]
            );
            const value =
                this.declarationMapValue[main] && hasOption ? this.declarationMapValue[main] : defaultOptionId;

            return (
                <DropDown
                    {...keywordDropdownProps}
                    className={style(classes.root, className)}
                    value={value as string}
                    // onHover={onHover}
                    onSelect={(value) => {
                        if (onChange) {
                            onChange(createVisualizerValueFromDeclarationMap({ [main]: value }));
                        }
                    }}
                    disabled={disabled}
                />
            );
        }
    }

    return KeywordVisualizer;
}
