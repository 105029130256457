import React, { createRef } from 'react';
import type { GradientAST } from 'gradient-parser';

import {
    Angle,
    Focal,
    Flip,
    Position,
    DeleteIndicator,
    GradientKnob,
    GradientKnobOver,
} from '@wixc3/stylable-panel-common-react';
import {
    SiteVarsDriver,
    StylablePanelTranslationKeys,
    Gradient,
    expandGradient,
    divideColorStops,
    isStripesGradient,
    normalizeGradientOrientation,
    stringifyGradient,
    ColorStop,
    RepeatData,
    FullDeclarationMap,
    DEFAULT_LOAD_SITE_COLORS,
    DEFAULT_WRAP_SITE_COLOR,
    DEFAULT_EVAL_DECLARATION_VALUE,
} from '@wixc3/stylable-panel-drivers';
import { CompositeBlock, DimensionInput, PopupPanel } from '@wixc3/stylable-panel-components';

import type { StylablePanelHost, DeclarationVisualizerDrivers } from '../../types';
import { stringifyBackgroundImage } from '../../utils';
import { ColorPicker, ColorPickerProps, CustomColorPicker, getColorPickerAPI } from '../color-picker/color-picker';
import { MySkinsPicker, CustomSkinsPicker } from '../my-skins-picker/my-skins-picker';
import { Action, BackgroundControl, Point } from '../../inputs/background/background-control';
import {
    DisplayModePicker,
    DisplayModePickerBox,
    DisplayModePickerContent,
} from '../../inputs/background/display-mode-picker/display-mode-picker';

import {
    DEGREES_RANGE,
    DIMENSION_ID,
    GRADIENT_OFFSET,
    GRADIENT_POSITION_SCALE,
    GRADIENT_REPEATS,
    GRADIENT_SIZE,
    GRADIENT_STEPS,
    MOUSE_QUICK_CHANGE,
} from '@wixc3/stylable-panel-common';

import { getTranslate } from '../../hosts/translate';
import {
    getCurrentRadialOrientation,
    getLength,
    getNewStopInsertData,
    getStepsGradient,
    getStripesGradient,
    setRepeatData,
} from './gradient-picker-utils';
import { getDimensionUnits } from '../../generated-visualizers/dimension-visualizers';
import { FillPickerContext } from '../fill-picker/fill-picker-context';

import { style, classes } from './gradient-picker.st.css';

export const GRADIENT_VALUE_TYPE = 'gradient';

const EMPTY_GRADIENT: Gradient = {
    type: 'linear-gradient',
    colorStops: [],
    steps: -1,
};

export const DEFAULT_FIRST_STOP_COLOR = 'black';
export const DEFAULT_SECOND_STOP_COLOR = 'rgba(255, 255, 255, 0)';

export const GRADIENT_PICKER_DIRECTION_ANGLES = {
    'to left top': 300,
    'to right top': 60,
    'to left bottom': 240,
    'to right bottom': 120,
};

const DEFAULT_SCALE = '100%';
const DEFAULT_POSITION = { x: '50%', y: '50%' };

export interface GradientPickerProps {
    id?: string;
    siteVarsDriver?: SiteVarsDriver;
    value?: string;
    drivers?: DeclarationVisualizerDrivers;
    panelHost?: StylablePanelHost;
    onChange?: (value: string, noSaveValue?: boolean) => void;
    customColorPicker?: CustomColorPicker;
    customSkinsPicker?: CustomSkinsPicker;
    colorPickerOverrides?: Partial<ColorPickerProps>;
    snapThreshold?: number;
    startingScale?: string;
    className?: string;
    style?: React.CSSProperties;
    onFallback?: (value: string | undefined) => void;
}

export interface GradientPickerState {
    addStopLeft: string;
    newStopColorPickerLeft: string;
    selectedColorIndex: number;
    selectedColor: string;
    openedAction: string;
    typeSelectorOpen: boolean;
    selectedCustomGradient: number;
}

// TODO: Going from steps gradient to other types should remove the double stops
// TODO: Snap to half-way point when adding stop?
// TODO: Toggle switches for angle snap / radial shape?
export class GradientPicker extends React.Component<GradientPickerProps, GradientPickerState> {
    public readonly state: GradientPickerState = {
        addStopLeft: '',
        newStopColorPickerLeft: '',
        selectedColorIndex: -1,
        selectedColor: '',
        openedAction: '',
        typeSelectorOpen: false,
        selectedCustomGradient: -1,
    };
    private expanded: Record<string, string | GradientAST> = {};
    private gradient: Gradient = { ...EMPTY_GRADIENT };
    private scale = DEFAULT_SCALE;
    private position = DEFAULT_POSITION;
    private dragging = false;
    private draggingIndex = -1;
    private reopenColorPickerIndex = -1;
    private fallbackGradient = '';
    private fallbackSteps = -1;
    private fallbackRepeatData: RepeatData | undefined = undefined;
    private midGradientPass = false;
    private resetRepeatData = false;
    private componentId = this.props.id || 'gradientPicker';

    private stops: { [index: number]: HTMLSpanElement | null } = {};
    private colorPicker = createRef<ColorPicker>();
    private stopsStrip = createRef<HTMLDivElement>();

    constructor(props: GradientPickerProps) {
        super(props);

        const { siteVarsDriver } = this.props;
        const addCategory = siteVarsDriver?.addCategory?.bind(siteVarsDriver) ?? undefined;

        addCategory && addCategory(GRADIENT_VALUE_TYPE, (index = '(\\d+)') => `gradient_${index}`);
        this.parseValueGradient(this.props);
        this.gradient = setRepeatData(this.gradient);
    }

    public componentDidUpdate(props: GradientPickerProps) {
        if (this.props.value !== props.value) {
            const oldType = this.gradient.type;
            if (this.resetRepeatData || oldType !== this.gradient.type) {
                this.gradient = setRepeatData(this.gradient);
            }
            this.resetRepeatData = false;
        }

        if (this.reopenColorPickerIndex !== -1) {
            this.setState({
                newStopColorPickerLeft: '',
                selectedColorIndex: this.reopenColorPickerIndex,
                selectedColor: this.gradient.colorStops[this.reopenColorPickerIndex].color,
            });

            this.reopenColorPickerIndex = -1;
        }

        this.midGradientPass = false;
    }

    public componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleColorStopDrag);
    }

    public render() {
        const {
            drivers,
            siteVarsDriver,
            panelHost,
            customColorPicker,
            customSkinsPicker,
            colorPickerOverrides = {},
            className,
            style: propStyle,
        } = this.props;
        const { selectedColor, openedAction } = this.state;

        const translate = getTranslate(panelHost);

        const gradientPositionScaleUnits = getDimensionUnits({
            id: DIMENSION_ID.GRADIENT_POSITION_SCALE,
            dimensionUnits: panelHost?.dimensionUnits,
            customUnits: GRADIENT_POSITION_SCALE,
        });

        const ColorPickerComp = customColorPicker ?? ColorPicker;

        const gradientPicker = (
            <div className={style(classes.root, className)} style={propStyle} data-aid="st_grandient_picker">
                <BackgroundControl
                    className={style(classes.preview, { minimal: this.colorPickerOpen() })}
                    openedAction={openedAction}
                    actions={this.getActions()}
                    onChange={this.handlePreviewMouseDown}
                    value={this.getRadialCenterPosition()}
                    onClickAction={this.clickAction}
                    displayModePicker={this.renderDisplayModePicker}
                    previewStyle={{ background: stringifyGradient(this.gradient, false) }}
                >
                    <div className={classes.previewContent}>
                        <button
                            className={classes.saveGradientButton}
                            data-aid="st_gradient_picker_save_gradient"
                            onClick={() => this.handleAddCustomGradient(stringifyGradient(this.gradient, false))}
                        >
                            <span className={classes.saveGradientText}>
                                {translate(StylablePanelTranslationKeys.picker.gradient.saveCustomGradient)}
                            </span>
                        </button>
                    </div>
                </BackgroundControl>
                {openedAction === 'position' ? (
                    <CompositeBlock
                        className={classes.scaleBlock}
                        title={translate(StylablePanelTranslationKeys.picker.gradient.scaleLabel)}
                        information={translate(StylablePanelTranslationKeys.picker.gradient.scaleTooltip)}
                        divider
                    >
                        <DimensionInput
                            key="scaleInput"
                            className={classes.scaleDimension}
                            value={this.scale}
                            config={{ units: gradientPositionScaleUnits }}
                            onChange={(value) => this.handleScaleChange(value)}
                            isSlider
                        />
                    </CompositeBlock>
                ) : null}
                <div className={classes.stopsArea} key="stopsArea">
                    {!openedAction ? (
                        this.renderStopsEditor()
                    ) : (
                        <div className={classes.actionButtonsContainer}>
                            <span className={classes.cancelActionButton} onClick={this.cancelChanges}>
                                {translate(StylablePanelTranslationKeys.picker.gradient.actions.cancel)}
                            </span>
                            <button className={classes.saveActionButton} onClick={this.saveChanges}>
                                {translate(StylablePanelTranslationKeys.picker.gradient.actions.save)}
                            </button>
                        </div>
                    )}
                </div>
                {this.colorPickerOpen() ? (
                    <PopupPanel
                        className={classes.colorPickerPopup}
                        title={translate(StylablePanelTranslationKeys.picker.gradient.colorPickerTitle)}
                        onClose={() =>
                            this.setState({
                                newStopColorPickerLeft: '',
                                selectedColorIndex: -1,
                                selectedColor: '',
                            })
                        }
                    >
                        <ColorPickerComp
                            className={classes.colorPicker}
                            {...getColorPickerAPI(selectedColor, this.handleColorPickerChange, drivers)}
                            onHover={this.handleColorPickerChange}
                            customSkinsPicker={customSkinsPicker}
                            minimalMode
                            {...colorPickerOverrides}
                            ref={this.colorPicker}
                        />
                    </PopupPanel>
                ) : null}
                {this.renderAdvancedControl()}
                {!openedAction ? this.renderSkinsPicker() : null}
            </div>
        );

        return (
            <FillPickerContext.Provider value={{ siteVarsDriver, panelHost }}>
                {gradientPicker}
            </FillPickerContext.Provider>
        );
    }

    private getActions = (): Action[] => {
        const { panelHost } = this.props;

        const translate = getTranslate(panelHost);

        const isLinear = this.gradient.type.includes('linear');

        return [
            {
                key: isLinear ? 'rotate' : 'focal',
                label: translate(
                    isLinear
                        ? StylablePanelTranslationKeys.picker.gradient.actions.labels.rotate
                        : StylablePanelTranslationKeys.picker.gradient.actions.labels.focal
                ),
                tooltip: translate(
                    isLinear
                        ? StylablePanelTranslationKeys.picker.gradient.actions.tooltips.rotate
                        : StylablePanelTranslationKeys.picker.gradient.actions.tooltips.focal
                ),
                icon: isLinear ? <Angle /> : <Focal />,
                content: isLinear ? this.renderActionSelectorContent : undefined,
            },
            {
                key: 'flip',
                tooltip: translate(StylablePanelTranslationKeys.picker.gradient.actions.tooltips.flip),
                icon: <Flip />,
                run: this.flipGradientColors,
            },
            {
                key: 'position',
                label: translate(StylablePanelTranslationKeys.picker.gradient.actions.labels.position),
                tooltip: translate(StylablePanelTranslationKeys.picker.gradient.actions.tooltips.position),
                icon: <Position />,
                content: () => this.renderPositionContent(),
            },
        ];
    };

    private saveChanges = () => {
        const { panelHost } = this.props;
        panelHost?.unblockCommits && panelHost.unblockCommits(true, this.componentId);
        this.closeAction();
    };

    private cancelChanges = () => {
        const { panelHost } = this.props;
        this.parseValueGradient({ ...this.props, value: this.fallbackGradient });
        this.sendGradientChange();
        panelHost?.onRevertQuickChange && panelHost.onRevertQuickChange();
        panelHost?.unblockCommits && panelHost.unblockCommits(false, this.componentId);
        this.closeAction();
    };

    private closeAction = () => {
        const { onFallback } = this.props;
        onFallback && onFallback(undefined);
        this.setState({
            openedAction: '',
            newStopColorPickerLeft: '',
            selectedColorIndex: -1,
            selectedColor: '',
        });
    };

    // TODO: Extract to stylable-gradient
    private parseValueGradient(props: GradientPickerProps) {
        const { value, siteVarsDriver } = props;
        const evalDeclarationValue =
            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

        if (!value) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        const evalValue = evalDeclarationValue(value);
        this.expanded = expandGradient(evalValue, 'background-image', 'background');

        if (this.expanded['background-color'] === evalValue) {
            const color = this.expanded['background-color'];
            if (color !== 'currentcolor') {
                this.gradient = {
                    ...EMPTY_GRADIENT,
                    orientation: '90deg',
                    colorStops: [
                        { color, length: '0%' },
                        { color, length: '100%' },
                    ],
                };
            } else {
                this.gradient = { ...EMPTY_GRADIENT };
            }
            return;
        }

        if (this.expanded.background !== undefined || this.expanded['background-image'] === evalValue) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        const gradientAST = this.expanded['background-image'] as GradientAST;
        if (!gradientAST) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        this.gradient.type = gradientAST.type;
        if (this.gradient.type === 'repeating-radial-gradient') {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        this.gradient.colorStops = divideColorStops(gradientAST.colorStops);
        if (this.gradient.colorStops.length < 2) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }
        if (!this.gradient.colorStops.every((stop) => stop.length.endsWith('%')) && !isStripesGradient(this.gradient)) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        this.gradient.orientation = normalizeGradientOrientation(this.gradient.type, gradientAST.orientation);
        if (~this.gradient.orientation.indexOf('px')) {
            this.gradient = { ...EMPTY_GRADIENT };
            return;
        }

        const backgroundSize = this.expanded['background-size'] as string;
        this.scale = backgroundSize && backgroundSize !== 'auto auto' ? backgroundSize.split(' ')[0] : DEFAULT_SCALE;

        if (this.expanded['background-position']) {
            const backgroundPosition = (this.expanded['background-position'] as string).split(' ');
            this.position = { x: backgroundPosition[0], y: backgroundPosition[1] };
        } else {
            this.position = DEFAULT_POSITION;
        }
    }

    public colorPickerOpen = () => this.state.selectedColorIndex !== -1;

    public colorPickerAdderOpen = () => this.colorPicker.current?.adderOpen();

    public colorPickerPalettePickerOpen = () => this.colorPicker.current?.palettePickerOpen();

    private renderStopsEditor() {
        return (
            <div className={classes.stopsEditorContainer}>
                <div
                    className={classes.stopsEditor}
                    style={{ background: stringifyGradient(this.gradient, true, 'linear-gradient', 'to right') }}
                >
                    <div
                        className={classes.stopsStrip}
                        onMouseEnter={this.handleStopsStripMouseMove}
                        onMouseMove={this.handleStopsStripMouseMove}
                        onMouseLeave={() => this.setState({ addStopLeft: '', newStopColorPickerLeft: '' })}
                        onClick={this.handleStopAdd}
                        ref={this.stopsStrip}
                    >
                        {this.renderColorStops()}
                    </div>
                </div>
            </div>
        );
    }

    private renderColorStops() {
        const { colorStops } = this.gradient;
        const { addStopLeft, selectedColorIndex } = this.state;

        const showStop = (index: number) =>
            index === 0 ||
            index === colorStops.length - 1 ||
            (this.gradient.steps === -1 && !this.gradient.repeatData) ||
            (this.gradient.steps === -1 && this.gradient.repeatData?.repeats !== -1);

        let stops = colorStops.map((stop, index) => {
            const selected = selectedColorIndex === index;
            const removable = colorStops.length > 2;
            const left = getLength(this.gradient, index);

            stop.overOtherStop = this.isStopOverOtherStop(index);

            return showStop(index)
                ? [
                      <span
                          key={`stop_${index}`}
                          className={style(classes.stop, { selected })}
                          style={{ left }}
                          onMouseDown={(event) => this.handleColorStopMouseDown(event, index)}
                          onMouseUp={(event) => this.handleColorStopMouseUp(event, index)}
                          ref={(c) => (this.stops[index] = c)}
                      >
                          {stop.overOtherStop ? (
                              <GradientKnobOver className={style(classes.stopIcon, { overOtherStop: true })} />
                          ) : (
                              <GradientKnob className={classes.stopIcon} />
                          )}
                      </span>,
                      selected && removable ? (
                          <span
                              key={`stop_${index}_delete_indicator`}
                              className={classes.deleteIndicator}
                              onClick={() => this.handleStopDelete(index)}
                              style={{ left }}
                          >
                              <DeleteIndicator />
                          </span>
                      ) : null,
                  ]
                : null;
        });

        if (addStopLeft) {
            stops = stops.concat([
                <span key="add_stop" className={style(classes.stop, { adding: true })} style={{ left: addStopLeft }}>
                    +
                </span>,
            ]);
        }

        return stops;
    }

    private getDisplayModePickerTitle = () => {
        const { panelHost } = this.props;
        const translate = getTranslate(panelHost);
        let displayModePickerTitle = 'Type'; // TODO: Default translated string

        if (this.gradient.type === 'linear-gradient') {
            displayModePickerTitle = translate(StylablePanelTranslationKeys.picker.gradient.typeSelector.linearLabel);
        } else if (this.gradient.type === 'radial-gradient') {
            displayModePickerTitle = translate(StylablePanelTranslationKeys.picker.gradient.typeSelector.radialLabel);
        }
        return displayModePickerTitle;
    };

    private getDisplayModePickerContent = (): DisplayModePickerContent => {
        const { panelHost } = this.props;
        const translate = getTranslate(panelHost);

        return {
            title: translate(StylablePanelTranslationKeys.picker.gradient.typeSelector.headerLabel),
            content: [
                {
                    id: 'linear-gradient',
                    label: translate(StylablePanelTranslationKeys.picker.gradient.typeSelector.linearLabel),
                    selected: this.gradient.steps === -1 && this.gradient.type === 'linear-gradient',
                    backgroundCSS: stringifyGradient(this.gradient, true, 'linear-gradient', 'to right'),
                },
                {
                    id: 'radial-gradient',
                    label: translate(StylablePanelTranslationKeys.picker.gradient.typeSelector.radialLabel),
                    selected: this.gradient.type === 'radial-gradient',
                    backgroundCSS: stringifyGradient(this.gradient, true, 'radial-gradient', 'circle at 50% 50%'),
                },
            ],
        };
    };

    private handleDisplayModePickerBoxClick = ({ selected, backgroundCSS }: DisplayModePickerBox) => {
        this.scale = DEFAULT_SCALE;
        this.position = DEFAULT_POSITION;

        if (!selected) {
            this.parseValueGradient({ ...this.props, value: backgroundCSS });
            this.sendGradientChange(backgroundCSS, false, true);
        }
        this.setState({ newStopColorPickerLeft: '' });
    };

    private toggleDisplayModePicker = (displayModePickerOpen: boolean) => {
        this.setState({ newStopColorPickerLeft: '', selectedColorIndex: -1 });
        return this.gradient.colorStops.length === 0 ? false : !displayModePickerOpen;
    };

    private renderDisplayModePicker = () => (
        <DisplayModePicker
            className={classes.displayModePicker}
            isActionOpened={!!this.state.openedAction}
            displayModePickerTitle={this.getDisplayModePickerTitle()}
            displayModePickerContent={this.getDisplayModePickerContent()}
            handleDisplayModePickerBoxClick={this.handleDisplayModePickerBoxClick}
            toggleDisplayModePicker={this.toggleDisplayModePicker}
            onDisplayModePickerPopupContentMenu={() => this.setState({ newStopColorPickerLeft: '' })}
        />
    );

    private renderAdvancedControl() {
        const { dimensionUnits } = this.props.panelHost || {};
        if (this.gradient.type.startsWith('repeating') && this.gradient.repeatData) {
            let stripes = false;
            let offsetValue = `${this.gradient.repeatData.offset}`;
            let offsetChange = this.handleOffsetChange;
            let sizeValue = `${this.gradient.repeatData.size}`;
            let sizeChange = this.handleSizeChange;

            if (this.gradient.repeatData.repeats === -1) {
                stripes = true;
                offsetValue = `${this.gradient.repeatData.offset}px`;
                offsetChange = this.handleStripesOffsetChange;
                sizeValue = `${this.gradient.repeatData.size}px`;
                sizeChange = this.handleStripesSizeChange;
            }

            const gradientRepeatsUnits = getDimensionUnits({
                id: DIMENSION_ID.GRADIENT_REPEATS,
                dimensionUnits,
                customUnits: GRADIENT_REPEATS,
            });
            const gradientOffsetUnits = getDimensionUnits({
                id: DIMENSION_ID.GRADIENT_OFFSET,
                dimensionUnits,
                customUnits: GRADIENT_OFFSET,
            });
            const gradientSizeUnits = getDimensionUnits({
                id: DIMENSION_ID.GRADIENT_SIZE,
                dimensionUnits,
                customUnits: GRADIENT_SIZE,
            });

            // TODO: Translation keys
            return (
                <div className={classes.advancedControl}>
                    {this.gradient.repeatData.repeats !== -1 ? (
                        <>
                            <div>Repeats</div>
                            <DimensionInput
                                className={classes.repeatsInput}
                                value={`${this.gradient.repeatData.repeats}`}
                                config={{ units: gradientRepeatsUnits }}
                                trimUnit={true}
                                isSlider={true}
                                onChange={this.handleRepeatsChange}
                            />
                        </>
                    ) : null}
                    <div>Offset</div>
                    <DimensionInput
                        className={style(classes.offsetInput, { stripes })}
                        value={`${offsetValue}`}
                        config={{ units: gradientOffsetUnits }}
                        trimUnit={true}
                        isSlider={true}
                        onChange={offsetChange}
                    />
                    <div>Size</div>
                    <DimensionInput
                        className={style(classes.sizeInput, { stripes })}
                        value={`${sizeValue}`}
                        config={{ units: gradientSizeUnits }}
                        trimUnit={true}
                        isSlider={true}
                        onChange={sizeChange}
                    />
                </div>
            );
        } else if (this.gradient.steps !== -1) {
            const gradientStepsUnits = getDimensionUnits({
                id: DIMENSION_ID.GRADIENT_STEPS,
                dimensionUnits,
                customUnits: GRADIENT_STEPS,
            });

            return (
                <div className={classes.advancedControl}>
                    <div>Steps</div>
                    <DimensionInput
                        className={classes.stepsInput}
                        value={`${this.gradient.steps}`}
                        config={{ units: gradientStepsUnits }}
                        trimUnit={true}
                        isSlider={true}
                        onChange={this.handleStepsChange}
                    />
                </div>
            );
        }

        return <div className={classes.advancedControl} />;
    }

    private renderSkinsPicker = () => {
        const { siteVarsDriver, panelHost, customSkinsPicker: CustomSkinsPickerComp } = this.props;
        const { selectedCustomGradient } = this.state;

        const translate = getTranslate(panelHost);
        const currGradient = stringifyGradient(this.gradient, false);

        const skinsPickerOnItemClick = (index: number, value: string) => {
            this.fallbackGradient = value;
            this.fallbackSteps = -1;
            this.fallbackRepeatData = this.gradient.repeatData
                ? {
                      lengths: [...this.gradient.repeatData.lengths],
                      offset: this.gradient.repeatData.offset,
                      repeats: this.gradient.repeatData.repeats,
                      size: this.gradient.repeatData.size,
                  }
                : undefined;
            this.sendGradientChange(value);
            this.parseValueGradient({ ...this.props, value });
            this.setState({ newStopColorPickerLeft: '', selectedCustomGradient: index });
        };
        const skinsPickerOnItemEnter = (index: number, value: string) => {
            if (selectedCustomGradient === index) {
                return;
            }
            if (!this.midGradientPass) {
                this.fallbackGradient = currGradient;
                this.fallbackSteps = this.gradient.steps;
                this.fallbackRepeatData = this.gradient.repeatData
                    ? {
                          lengths: [...this.gradient.repeatData.lengths],
                          offset: this.gradient.repeatData.offset,
                          repeats: this.gradient.repeatData.repeats,
                          size: this.gradient.repeatData.size,
                      }
                    : undefined;
            }
            this.resetRepeatData = true;
            this.gradient.steps = -1;
            this.sendGradientChange(value, true);
            this.parseValueGradient({ ...this.props, value });
        };
        const skinsPickerOnItemLeave = () => {
            this.midGradientPass = true;
            this.gradient.steps = this.fallbackSteps;
            this.gradient.repeatData = this.fallbackRepeatData
                ? {
                      lengths: [...this.fallbackRepeatData.lengths],
                      offset: this.fallbackRepeatData.offset,
                      repeats: this.fallbackRepeatData.repeats,
                      size: this.fallbackRepeatData.size,
                  }
                : undefined;
            this.sendGradientChange(this.fallbackGradient, true);
            this.parseValueGradient({ ...this.props, value: this.fallbackGradient });
        };

        const handleRemoveCustomGradient = (index: number) => {
            const { siteVarsDriver, panelHost } = this.props;

            const removed = siteVarsDriver?.removeUserValue(GRADIENT_VALUE_TYPE, index) ?? false;
            panelHost?.onUserColorRemove && panelHost.onUserColorRemove(index, GRADIENT_VALUE_TYPE);

            if (removed) {
                const { selectedCustomGradient } = this.state;
                let selected = -1;
                if (selectedCustomGradient !== index) {
                    selected = selectedCustomGradient - (selectedCustomGradient > index ? 1 : 0);
                }
                this.setState({ selectedCustomGradient: selected });
            }
        };

        if (CustomSkinsPickerComp) {
            return (
                <CustomSkinsPickerComp
                    value={currGradient}
                    valueType={GRADIENT_VALUE_TYPE}
                    onItemClick={skinsPickerOnItemClick}
                    onItemEnter={skinsPickerOnItemEnter}
                    onItemLeave={skinsPickerOnItemLeave}
                    onItemRemove={handleRemoveCustomGradient}
                />
            );
        }

        return (
            <MySkinsPicker
                className={classes.myGradients}
                title={translate(StylablePanelTranslationKeys.picker.gradient.myGradientsTitle)}
                information={translate(StylablePanelTranslationKeys.picker.gradient.myGradientsInformationTooltip)}
                addTooltipLabel={translate(StylablePanelTranslationKeys.picker.gradient.addGradientTooltip)}
                values={siteVarsDriver?.getCategoryValues(GRADIENT_VALUE_TYPE).map(({ value }) => value)}
                radioGroupName="custom_gradient"
                isChecked={(index) => index === selectedCustomGradient}
                onItemClick={skinsPickerOnItemClick}
                onItemEnter={skinsPickerOnItemEnter}
                onItemLeave={skinsPickerOnItemLeave}
                onItemRemove={handleRemoveCustomGradient}
            />
        );
    };

    private renderActionSelectorContent = () => {
        let content = null;
        switch (this.gradient.type) {
            case 'linear-gradient':
            case 'repeating-linear-gradient':
                content = this.renderAngleSelectorContent();
                break;
        }
        return content;
    };

    private renderAngleSelectorContent() {
        const { dimensionUnits } = this.props.panelHost || {};
        const currentOrientation = this.gradient.orientation;
        let sliderValue = 0;

        if (currentOrientation) {
            sliderValue = (GRADIENT_PICKER_DIRECTION_ANGLES as any)[currentOrientation];
            if (sliderValue === undefined) {
                sliderValue = parseFloat(currentOrientation);
            }
        }

        const units = getDimensionUnits({
            id: DIMENSION_ID.GRADIENT_DEGREES_RANGE,
            dimensionUnits,
            customUnits: DEGREES_RANGE,
        });

        return (
            <DimensionInput
                className={classes.angleSlider}
                isSlider={true}
                isSliderCyclicKnob={true}
                isInputCyclic={true}
                config={{ units }}
                useDisplaySymbol={true}
                value={sliderValue + 'deg'}
                onChange={this.handleOrientationChange}
            />
        );
    }

    private handleScaleChange = (value: string) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        this.parseValueGradient(this.props);
        this.scale = value;
        this.sendGradientChange();
    };

    // we need a clipping mask to create a frame in which the inside is transparent
    // and can be changed by the scale size and coordinates
    private getClippingMask(x: number, y: number, size: number): string {
        const polygonPoints = [
            '0% 0%',
            '0% 100%',
            `${x}% 100%`,
            `${x}% ${y}%`,
            `${size + x}% ${y}%`,
            `${size + x}% ${size + y}%`,
            `0% ${size + y}%`,
            '0% 100%',
            '100% 100%',
            '100% 0%',
        ];
        return `polygon(${polygonPoints.join(', ')})`;
    }

    private renderPositionContent() {
        const scale = this.parseLengthToNumber(this.scale) / 100;
        const size = 100 / scale;
        const x = (this.parseLengthToNumber(this.position.x) * (scale - 1)) / scale;
        const y = (this.parseLengthToNumber(this.position.y) * (scale - 1)) / scale;
        const clipPath = this.getClippingMask(x, y, size);

        return (
            <div className={classes.scalePositionContainer}>
                <div className={classes.scalePositionFrame} style={{ clipPath }} />
                <div
                    className={classes.scalePosition}
                    style={{ left: `${x}%`, top: `${y}%`, width: `${size}%`, height: `${size}%` }}
                />
            </div>
        );
    }

    private getRadialCenterPosition = (): { x: string; y: string } | undefined => {
        const orientation = getCurrentRadialOrientation(this.gradient);
        if (!orientation || !orientation.at.value.y) {
            return;
        }

        const left = `${orientation.at.value.x.value}${orientation.at.value.x.type}`;
        const top = `${orientation.at.value.y.value}${orientation.at.value.y.type}`;

        return { x: left, y: top };
    };

    private parseLengthToNumber = (length: string): number =>
        parseFloat(parseFloat(length.replace('%', '')).toFixed(3));

    private flipGradientColors = () => {
        const { panelHost } = this.props;
        panelHost?.unblockCommits && panelHost.unblockCommits(true, this.componentId);

        this.gradient.colorStops.reverse().map((colorStop) => {
            colorStop.length = `${100 - this.parseLengthToNumber(colorStop.length)}%`;
        });

        this.sendGradientChange();
    };

    private clickAction = (action: Action) => {
        if (action?.run) {
            action.run();
        } else {
            const { key } = action;
            const { panelHost, startingScale, onFallback } = this.props;
            panelHost?.blockCommits && panelHost.blockCommits(this.componentId);

            const position = `${this.position.x} ${this.position.y}`;
            const scale = `${this.scale} ${this.scale}`;

            this.fallbackGradient = `${position}/${scale} ${stringifyGradient(this.gradient)}`;
            onFallback && onFallback(this.fallbackGradient);

            this.setState({
                openedAction: key,
                typeSelectorOpen: false,
                newStopColorPickerLeft: '',
                selectedColorIndex: -1,
            });

            const backgroundSize = this.expanded['background-size'];
            if (key === 'position' && (!backgroundSize || backgroundSize === `${DEFAULT_SCALE} ${DEFAULT_SCALE}`)) {
                this.scale = startingScale || DEFAULT_SCALE;
            }
        }
    };

    private handleStopsStripMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (
            this.dragging ||
            this.draggingIndex !== -1 ||
            !this.stopsStrip.current ||
            this.gradient.steps !== -1 ||
            this.gradient.repeatData?.repeats === -1
        ) {
            return;
        }

        const numStops = this.gradient.colorStops.length;
        for (let i = 0; i < numStops; i++) {
            const stopRect = this.getStopRect(i);
            if (
                stopRect &&
                event.clientX > stopRect.left &&
                event.clientX < stopRect.right + (i > 0 && i < numStops - 1 ? 8 : 0)
            ) {
                this.setState({ addStopLeft: '', newStopColorPickerLeft: '' });
                return;
            }
        }

        const addStopLeft = this.getMouseRectPercent(this.stopsStrip.current.getBoundingClientRect(), event.clientX);
        this.setState({ addStopLeft, newStopColorPickerLeft: '' });
    };

    private handleColorStopMouseDown(event: React.MouseEvent<HTMLSpanElement>, index: number) {
        if (event.button === 2) {
            return;
        }

        this.draggingIndex = index;

        const isZeroSteps = this.gradient.steps === 2;
        const isRepeatingEdge =
            this.gradient.type.startsWith('repeating') &&
            (index === 0 || index === this.gradient.colorStops.length - 1);

        if (!isZeroSteps && !isRepeatingEdge) {
            document.addEventListener('mousemove', this.handleColorStopDrag);
        }
        document.addEventListener('mouseup', this.stopColorStopDrag);
    }

    private areStopsInRange = (firstStop: ColorStop, secondStop: ColorStop, range = 0): boolean => {
        if (!(firstStop && secondStop)) {
            return false;
        }

        const firstStopLength = this.parseLengthToNumber(firstStop.length);
        const secondStopLength = this.parseLengthToNumber(secondStop.length);
        return Math.abs(firstStopLength - secondStopLength) <= range;
    };

    private isStopOverOtherStop = (index: number) => {
        const { colorStops } = this.gradient;
        const currentStop = colorStops[index];
        if (
            this.areStopsInRange(currentStop, colorStops[index - 1]) ||
            this.areStopsInRange(currentStop, colorStops[index + 1])
        ) {
            return true;
        }
        return false;
    };

    private handleSelectedAfterSort = (oldIndex: number, newIndex: number) => {
        if (oldIndex === newIndex) {
            return;
        }
        const { selectedColorIndex } = this.state;
        if (selectedColorIndex === oldIndex) {
            this.setState({ selectedColorIndex: newIndex });
        }
        if (selectedColorIndex === newIndex) {
            this.setState({ selectedColorIndex: oldIndex });
        }
    };

    private getStopsDistance = (firstStop: ColorStop, secondStop: ColorStop): number =>
        Math.abs(this.parseLengthToNumber(firstStop.length) - this.parseLengthToNumber(secondStop.length));

    private getProximalStopIndex = (stopIndex: number) => {
        const { colorStops } = this.gradient;
        let proximalIndex = stopIndex === 0 ? 1 : 0;
        colorStops.forEach((currStop, index) => {
            const proximalDistance = this.getStopsDistance(colorStops[proximalIndex], colorStops[stopIndex]);
            const currDistance = this.getStopsDistance(currStop, colorStops[stopIndex]);
            if (index !== stopIndex && currDistance < proximalDistance) {
                proximalIndex = index;
            }
        });
        return proximalIndex;
    };

    private sortGradients = () => {
        const colorStop = this.gradient.colorStops[this.draggingIndex];
        this.gradient.colorStops.splice(this.draggingIndex, 1);
        const { index } = getNewStopInsertData(this.gradient, colorStop.length);
        this.gradient.colorStops.splice(index, 0, colorStop);
        this.handleSelectedAfterSort(this.draggingIndex, index);
        this.draggingIndex = index;
    };

    private handleColorStopDrag = (event: MouseEvent) => {
        const { onChange, snapThreshold } = this.props;

        if (!onChange || this.draggingIndex === -1 || !this.stopsStrip.current) {
            return;
        }

        this.dragging = true;

        let newLength = this.getMouseRectPercent(this.stopsStrip.current.getBoundingClientRect(), event.clientX);
        if (this.gradient.repeatData && this.gradient.type.startsWith('repeating')) {
            this.gradient.repeatData.lengths[this.draggingIndex] = `${newLength}`;
            const start = parseFloat(parseFloat(this.gradient.colorStops[0].length).toFixed(3));
            const end = parseFloat(
                parseFloat(this.gradient.colorStops[this.gradient.colorStops.length - 1].length).toFixed(3)
            );
            const multiplier = 100 / (end - start);
            newLength = `${parseFloat(newLength) / multiplier + this.gradient.repeatData.offset}%`;
        }
        const { colorStops } = this.gradient;
        const currentStop = colorStops[this.draggingIndex];
        currentStop.length = newLength;

        if (this.isStopOverOtherStop(this.draggingIndex)) {
            currentStop.overOtherStop = true;
        } else {
            currentStop.overOtherStop = false;
            this.sortGradients();
        }

        const proximalStopIndex = this.getProximalStopIndex(this.draggingIndex);
        if (this.areStopsInRange(colorStops[this.draggingIndex], colorStops[proximalStopIndex], snapThreshold)) {
            colorStops[this.draggingIndex].length = colorStops[proximalStopIndex].length;
        }

        if (this.gradient.steps < 3) {
            this.sendGradientChange();
        } else {
            this.sendGradientChange(
                stringifyGradient(getStepsGradient(this.props.siteVarsDriver, this.gradient), false)
            );
        }
    };

    private stopColorStopDrag = () => {
        this.sendGradientChange();
        this.dragging = false;
        this.draggingIndex = -1;

        document.removeEventListener('mousemove', this.handleColorStopDrag);
        document.removeEventListener('mouseup', this.stopColorStopDrag);
    };

    private handleColorStopMouseUp(event: React.MouseEvent<HTMLSpanElement>, index: number) {
        if (event.button === 2) {
            return;
        }

        const { selectedColorIndex } = this.state;

        if (!this.dragging) {
            if (selectedColorIndex !== -1 && selectedColorIndex !== index) {
                this.reopenColorPickerIndex = index;
            }

            this.setState({
                newStopColorPickerLeft: '',
                selectedColorIndex: selectedColorIndex === -1 ? index : -1,
                selectedColor: selectedColorIndex === -1 ? this.gradient.colorStops[index].color : '',
                openedAction: '',
            });
        }
    }

    private handleStopAdd = (event: React.MouseEvent<HTMLDivElement>) => {
        const { onChange } = this.props;
        const { addStopLeft } = this.state;

        if (!onChange || !addStopLeft) {
            return;
        }

        let selectedColorIndex = 0;
        let selectedColor = DEFAULT_FIRST_STOP_COLOR;
        if (this.gradient.colorStops.length === 0) {
            this.gradient.colorStops.push({ color: DEFAULT_FIRST_STOP_COLOR, length: '0%' });
            this.gradient.colorStops.push({ color: DEFAULT_SECOND_STOP_COLOR, length: '100%' });
        } else {
            const { index, colorStop } = getNewStopInsertData(this.gradient, addStopLeft);
            this.gradient.colorStops.splice(index, 0, colorStop);
            selectedColorIndex = index;
            selectedColor = colorStop.color;
        }

        if (this.state.selectedColorIndex !== -1) {
            this.reopenColorPickerIndex = selectedColorIndex;
            selectedColorIndex = -1;
            selectedColor = '';
        }

        this.sendGradientChange();
        this.setState({
            addStopLeft: '',
            newStopColorPickerLeft: `${event.clientX}px`,
            selectedColorIndex,
            selectedColor,
            openedAction: '',
        });
    };

    private handleOrientationChange = (value: string) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        this.gradient.orientation = value;

        this.sendGradientChange();
        this.setState({ typeSelectorOpen: false, newStopColorPickerLeft: '' });
    };

    private handleColorPickerChange = (value: string | null) => {
        const { onChange, panelHost } = this.props;
        const { selectedColorIndex, selectedColor } = this.state;

        if (!onChange) {
            return;
        }

        this.gradient.colorStops[selectedColorIndex].color = value || selectedColor;

        panelHost?.unblockCommits && panelHost.unblockCommits(true, MOUSE_QUICK_CHANGE);
        if (this.gradient.steps === -1) {
            if (!this.gradient.repeatData || this.gradient.repeatData.repeats !== -1) {
                this.sendGradientChange();
            } else {
                this.sendGradientChange(stringifyGradient(getStripesGradient(this.gradient), false));
            }
        } else {
            this.sendGradientChange(
                stringifyGradient(getStepsGradient(this.props.siteVarsDriver, this.gradient), false)
            );
        }
        panelHost?.blockCommits && panelHost.blockCommits(MOUSE_QUICK_CHANGE);
    };

    private handleStopDelete(index: number) {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (this.gradient.type.startsWith('repeating') && this.gradient.repeatData) {
            this.gradient.repeatData.lengths.splice(index, 1);
        }
        this.gradient.colorStops.splice(index, 1);

        this.sendGradientChange();
        this.setState({ newStopColorPickerLeft: '', selectedColorIndex: -1, selectedColor: '' });
    }

    private handlePreviewMouseDown = (p: Point) => {
        if (!this.state.openedAction) {
            return;
        }

        this.handlePositionStopDrag(p.x, p.y);
    };

    // TODO: Test snap behavior
    private handlePositionStopDrag = (x: string, y: string) => {
        const { openedAction } = this.state;
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (openedAction === 'focal') {
            const orientation = getCurrentRadialOrientation(this.gradient);
            if (!orientation || !orientation.at.value.y) {
                return;
            }

            this.gradient.orientation = `${orientation.value} at ${x} ${y}`;
        }
        if (openedAction === 'position') {
            this.position = { x, y };
        }

        this.sendGradientChange();
    };

    private handleRepeatsChange = (value: string) => {
        if (!this.gradient.type.startsWith('repeating') || !this.gradient.repeatData) {
            return;
        }

        const newRepeats = parseFloat(value);

        this.gradient.repeatData.repeats = newRepeats;
        this.gradient.repeatData.lengths.forEach((length, index) => {
            this.gradient.colorStops[index].length = `${
                parseFloat(length) / newRepeats + this.gradient.repeatData!.offset
            }%`;
        });
        if (parseFloat(this.gradient.colorStops[this.gradient.colorStops.length - 1].length) > 100) {
            this.gradient.colorStops[this.gradient.colorStops.length - 1].length = '100%';
        }
        const newStart = parseFloat(parseFloat(this.gradient.colorStops[0].length).toFixed(3));
        const newEnd = parseFloat(
            parseFloat(this.gradient.colorStops[this.gradient.colorStops.length - 1].length).toFixed(3)
        );
        this.gradient.repeatData.size = Math.round(newEnd - newStart);

        this.sendGradientChange();
    };

    private handleOffsetChange = (value: string) => {
        if (!this.gradient.repeatData) {
            return;
        }

        const start = parseFloat(parseFloat(this.gradient.colorStops[0].length).toFixed(3));
        const end = parseFloat(
            parseFloat(this.gradient.colorStops[this.gradient.colorStops.length - 1].length).toFixed(3)
        );
        const maxOffset = 100 - end + start;
        const newOffset = Math.min(parseFloat(value), maxOffset);
        const diff = newOffset - start;

        this.gradient.repeatData.offset = newOffset;
        for (const colorStop of this.gradient.colorStops) {
            const currLength = parseFloat(colorStop.length);
            colorStop.length = `${currLength + diff}%`;
        }

        this.sendGradientChange();
    };

    private handleSizeChange = (value: string) => {
        if (!this.gradient.repeatData) {
            return;
        }

        const start = parseFloat(parseFloat(this.gradient.colorStops[0].length).toFixed(3));
        const maxSize = (100 - start) / (this.gradient.repeatData.repeats - 1);
        const minSize = (100 - start) / this.gradient.repeatData.repeats;
        const newSize = Math.max(Math.min(parseFloat(value), maxSize), minSize);

        this.gradient.repeatData.size = newSize;
        this.gradient.colorStops[this.gradient.colorStops.length - 1].length = `${start + newSize}%`;

        this.sendGradientChange();
    };

    private handleStripesOffsetChange = (value: string) => {
        if (!this.gradient.repeatData) {
            return;
        }

        const newOffset = parseFloat(value);

        this.gradient.repeatData.offset = newOffset;
        this.gradient.colorStops[1].length = value;
        this.gradient.colorStops[2].length = value;
        this.gradient.colorStops[3].length = `${newOffset + this.gradient.repeatData.size}px`;

        this.sendGradientChange();
    };

    private handleStripesSizeChange = (value: string) => {
        if (!this.gradient.repeatData) {
            return;
        }

        const newSize = parseFloat(value);

        this.gradient.repeatData.size = newSize;
        this.gradient.colorStops[3].length = `${this.gradient.repeatData.offset + newSize}px`;

        this.sendGradientChange();
    };

    private handleStepsChange = (value: string) => {
        const steps = parseFloat(value);
        this.gradient.steps = steps;
        this.sendGradientChange(
            stringifyGradient(getStepsGradient(this.props.siteVarsDriver, this.gradient, true), false)
        );
    };

    private handleAddCustomGradient(value: string) {
        const { siteVarsDriver, panelHost } = this.props;

        siteVarsDriver?.addValue(GRADIENT_VALUE_TYPE, value);
        panelHost?.onUserColorAdd?.(value, GRADIENT_VALUE_TYPE);
        this.fallbackRepeatData = this.gradient.repeatData
            ? {
                  lengths: [...this.gradient.repeatData.lengths],
                  offset: this.gradient.repeatData.offset,
                  repeats: this.gradient.repeatData.repeats,
                  size: this.gradient.repeatData.size,
              }
            : undefined;
        this.setState({
            newStopColorPickerLeft: '',
            selectedCustomGradient: siteVarsDriver
                ? siteVarsDriver.getCategoryValues(GRADIENT_VALUE_TYPE).length - 1
                : -1,
        });
    }

    private getMouseRectPercent(rect: ClientRect | DOMRect, clientCoord: number, vertical?: boolean) {
        let newStopLeft = ((clientCoord - rect[vertical ? 'top' : 'left']) / rect[vertical ? 'height' : 'width']) * 100;
        if (newStopLeft < 0) {
            newStopLeft = 0;
        }
        if (newStopLeft > 100) {
            newStopLeft = 100;
        }

        return `${Math.round(newStopLeft)}%`;
    }

    private getStopRect(index: number) {
        return this.stops[index]?.getBoundingClientRect();
    }

    private sendGradientChange(gradientStr?: string, noResetSelection?: boolean, ignoreExistingBackground?: boolean) {
        const { openedAction } = this.state;
        const { siteVarsDriver, onChange } = this.props;
        const loadSiteColors = siteVarsDriver?.loadSiteColors?.bind(siteVarsDriver) ?? DEFAULT_LOAD_SITE_COLORS;
        const wrapSiteColor = siteVarsDriver?.wrapSiteColor?.bind(siteVarsDriver) ?? DEFAULT_WRAP_SITE_COLOR;
        const evalDeclarationValue =
            siteVarsDriver?.evalDeclarationValue?.bind(siteVarsDriver) ?? DEFAULT_EVAL_DECLARATION_VALUE;

        if (!onChange) {
            return;
        }

        loadSiteColors();
        for (const colorStop of this.gradient.colorStops) {
            colorStop.color = wrapSiteColor(colorStop.color);
        }

        const expanded: Record<string, string | GradientAST> = !ignoreExistingBackground ? { ...this.expanded } : {};
        expanded['background-image'] = gradientStr || stringifyGradient(this.gradient, false);

        if (openedAction === 'position') {
            expanded['background-size'] = `${this.scale} ${this.scale}`;
            expanded['background-position'] = `${this.position.x} ${this.position.y}`;
        }

        // TODO: Look into getting stringifyBackgroundImage into background interface type toString()
        onChange(stringifyBackgroundImage(expanded as FullDeclarationMap)!);

        for (const colorStop of this.gradient.colorStops) {
            colorStop.color = evalDeclarationValue(colorStop.color);
        }

        if (!noResetSelection) {
            this.setState({ newStopColorPickerLeft: '', selectedCustomGradient: -1 });
        }
    }
}
