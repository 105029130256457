import { valueMapping, StylableResolver, StylableMeta } from '@stylable/core';
import type { ElementTree } from './types';
import { nativePseudoElementsSet } from './utils/native-pseudo-elements-set';

/**
 * This function create a tree structure of internal parts starting form a variant customization
 * going over the inheritance of each internal part and also checking native pseudo elements usage.
 */
export function createElementsTree(
    meta: StylableMeta,
    simpleSelector: string,
    resolver: StylableResolver
): ElementTree {
    // Assume simple customization selector like .myComponent or Component.
    // Ideally we want to use StylableSymbols here but historically we use a selector
    const elementsTree: ElementTree = { [simpleSelector]: {} };
    const isClass = simpleSelector.startsWith('.');
    // finds the symbol name
    const name = isClass ? simpleSelector.slice(1) : simpleSelector;
    const symb = isClass ? meta.classes[name] : meta.elements[name];
    // when class have an -st-extends or elements is an alias we want to drill into their internal structure
    const symbType = symb ? (isClass ? symb[valueMapping.extends] : symb.alias) : undefined;

    if (symbType) {
        const origin = resolver.resolveSymbolOrigin(symbType, meta);
        if (
            origin &&
            origin._kind === 'css' &&
            origin.symbol._kind === 'class' &&
            origin.symbol.name === origin.meta.root
        ) {
            // go over all internal parts and add them to the tree
            for (const key in origin.meta.classes) {
                if (key === origin.meta.root) {
                    // we will handle root in later stage
                    continue;
                } else {
                    elementsTree[simpleSelector][key] = createElementsTree(origin.meta, `.${key}`, resolver)[`.${key}`];
                }
            }
            // add all root parts to the tree
            const rootExtensions = createElementsTree(origin.meta, `.${origin.meta.root}`, resolver)[
                `.${origin.meta.root}`
            ];
            // root parts are flatten to the root of the tree since they are the same element
            Object.assign(elementsTree[simpleSelector], rootExtensions);
        }
    }

    // find usage of native pseudo element for all tree parts
    walk(
        elementsTree[simpleSelector],
        (_, path) => {
            const selector = path.join('::');
            // create regexp that match usage of pseudo elements at the end of the selector
            const matcher = new RegExp('^' + selector.replace(/\./g, '\\.') + '::(.*)$');

            meta.ast.walkRules((rule) => {
                rule.selectors.forEach((ruleSelector) => {
                    const match = ruleSelector.match(matcher);
                    if (match) {
                        const name = match[1];
                        const setPath = path.concat(name);
                        if (!getProp(elementsTree, setPath) && nativePseudoElementsSet.has(match[1])) {
                            setProp(elementsTree, setPath, {});
                        }
                    }
                });
            });
        },
        [simpleSelector]
    );

    return elementsTree;
}
function walk(tree: ElementTree, visitor: (tree: ElementTree, path: string[]) => void, path: string[]) {
    visitor(tree, path);
    for (const key in tree) {
        walk(tree[key], visitor, path.concat(key));
    }
}
function setProp(object: any, keys: string[], val: any): void {
    if (keys.length > 1) {
        object[keys[0]] = object[keys[0]] || {};
        return setProp(object[keys[0]], keys.slice(1), val);
    }
    object[keys[0]] = val;
}
function getProp(object: any, keys: string[], defaultVal?: any): any {
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return object === undefined ? defaultVal : object;
}
