import React, { useRef, useState, useEffect } from 'react';
import { DropDown } from '../../drop-down';
import { optionsFromStrings } from '../../option-list';
import { style, classes } from './theme-selector.st.css';

export type Themes = Record<string, string[] | { classes: string[]; css: string }>;
export interface ThemeSelectorProps {
    themes: Themes;
    children: React.ReactChild[];
    className?: string;
}

let current = '';

function useTheme<T extends Record<string, string[] | { classes: string[]; css: string }>>(
    themes: T,
    ref: React.MutableRefObject<any>,
    styleRef: React.MutableRefObject<any>
): (theme: keyof T) => void {
    const [theme, setTheme] = useState<keyof T>(Object.keys(themes)[0]);

    useEffect(() => {
        const sref = styleRef;
        const el = ref.current;

        if (!el) throw new Error('missing ref element');

        let classes = themes[theme] as string[] | { classes: string[]; css: string };

        if (!Array.isArray(classes)) {
            if (!sref) {
                throw new Error('when using inline theme styleRef must be provided');
            }
            sref.current.textContent = classes.css;
            classes = classes.classes;
        }

        classes.forEach((className) => el.classList.add(className));

        return () => {
            if (sref && sref.current) {
                sref.current.textContent = '';
            }
            if (Array.isArray(classes)) {
                classes.forEach((className) => el.classList.remove(className));
            }
        };
    }, [themes, theme, ref, styleRef]);

    current = theme as string;

    return setTheme;
}

export const ThemeSelector = ({ themes, children, className }: ThemeSelectorProps) => {
    if (!themes) {
        throw new Error('missing theme definition');
    }

    const ref = useRef<HTMLDivElement>(null);
    const styleRef = useRef<HTMLStyleElement>(null);
    const setTheme = useTheme(themes, ref, styleRef as React.MutableRefObject<any>);

    return (
        <div ref={ref}>
            <DropDown
                className={style(classes.root, className)}
                value={current}
                options={optionsFromStrings(Object.keys(themes))}
                onSelect={(id: string) => setTheme(id)}
            />
            <style ref={styleRef}></style>
            {children}
        </div>
    );
};

export default ThemeSelector;
