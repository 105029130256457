import React from 'react';

import { Tooltip, TooltipAttachTo } from '../tooltip';

import { style, classes } from './label-with-action.st.css';

export interface LabelWithActionProps {
    text?: string;
    actionText?: string;
    actionTooltipText?: string;
    actionTooltipAttachSide?: TooltipAttachTo;
    actionDataAid?: string;
    onActionClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export const LabelWithAction: React.FunctionComponent<LabelWithActionProps> = ({
    text,
    actionText,
    actionTooltipText,
    actionTooltipAttachSide,
    actionDataAid,
    onActionClick,
    className,
    style: propStyle,
}) => (
    <div className={style(classes.root, className)} style={propStyle}>
        <label className={classes.label}>{text}</label>
        {actionText ? (
            <Tooltip text={actionTooltipText} className={classes.actionTooltip} attachTo={actionTooltipAttachSide}>
                <span className={classes.actionButton} onClick={onActionClick} data-aid={actionDataAid}>
                    {actionText}
                </span>
            </Tooltip>
        ) : null}
    </div>
);
