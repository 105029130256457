import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import type { SingleCategoryConfig } from '../../types';

export const layoutBlockVariants: SingleCategoryConfig = {
    titleKey: StylablePanelTranslationKeys.controller.layout.title,
    props: [/flex/, 'justify-content', 'align-items', /padding/],
};

export default layoutBlockVariants;
