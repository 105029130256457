import type { SelectorConfiguration, StateNameOverride, CopyController } from '@wixc3/stylable-panel-common';
import { stripNativePseudoFromSelector } from './utils/native-pseudo-elements-set';

const SELECTOR_STATE_REGEX = /.*[^:]:([^:]*)$/; // TODO: Unify with one from aggregation
const PSEUDO_ELEMENT_OPERATOR = '::';

export function getTargetState(selector: string) {
    const findState = selector.match(/.*[^:]:([^:]+)/);
    if (findState && findState.length > 1) {
        return selector.slice(0, findState[0].length);
    } else {
        return selector;
    }
}

export function getFinalStateName(selector: string) {
    const stateMatch = getTargetState(selector).match(SELECTOR_STATE_REGEX);
    return stateMatch ? stateMatch[1] : '';
}

export function stripStates(selector: string): string {
    const findState = selector.match(/.*[^:](:[^:]+)/);
    if (findState) {
        return selector.replace(findState[1], '');
    } else {
        return selector;
    }
}

export function applyState(sourceSelector: string, state: string, destSelector: string): string {
    return destSelector.startsWith(sourceSelector)
        ? `${sourceSelector}${state !== '' ? ':' + state : ''}${destSelector.replace(sourceSelector, '')}`
        : destSelector;
}

export function pseudoElementParentSelector(selector: string): string {
    if (!~selector.indexOf(PSEUDO_ELEMENT_OPERATOR)) {
        return selector;
    }

    const splitSelector = selector.split(PSEUDO_ELEMENT_OPERATOR);
    splitSelector.splice(-1, 1);
    return splitSelector.join(PSEUDO_ELEMENT_OPERATOR);
}

export function hasState(selector: string) {
    return !!getFinalStateName(selector);
}

export class SelectorConfigurationDriver {
    public variant: string;
    public defaultStates: Record<string, StateNameOverride> = {};
    constructor(
        public selectorConfigurations: Record<string, SelectorConfiguration>,
        variant: string,
        defaultStates?: Record<string, StateNameOverride>
    ) {
        this.variant = variant;
        if (defaultStates) {
            this.defaultStates = defaultStates;
        }
    }

    public get configuredSelectors() {
        return Object.keys(this.selectorConfigurations);
    }

    /*
    returns the target prime selector for any given rawSelector source. (e.g .button::text -> .button)
     */
    public getPrimeElement(rawSelector: string, onlyFold = false): string {
        const selectorConfiguration = this.getSelectorConfiguration(rawSelector);
        if (
            selectorConfiguration !== undefined &&
            selectorConfiguration.primeElementSelector &&
            (!onlyFold || selectorConfiguration.foldInto)
        ) {
            return this.selectorToVariant(selectorConfiguration.primeElementSelector);
        }

        return rawSelector;
    }

    public getSelectorConfiguration(selector: string): SelectorConfiguration | undefined {
        return this.selectorConfigurations[this.selectorFromVariant(selector)];
    }

    public resolveOverride(selector: string): SelectorConfiguration | undefined {
        const selectorConfiguration = this.getSelectorConfiguration(selector);
        if (selectorConfiguration !== undefined) {
            if (selectorConfiguration.primeElementSelector) {
                return this.resolveOverride(selectorConfiguration.primeElementSelector);
            }
            return selectorConfiguration;
        }
        return;
    }

    // public getElementName(selector: string): string {
    //     const override = this.resolveOverride(selector);
    //     return (override && override.nameKey) ? override.nameKey : '';
    // }

    public getElementStateDisplayKey(selector: string): string {
        const override = this.resolveOverride(selector);
        return override && override.stateDisplayKey ? override.stateDisplayKey : '';
    }

    public isStateHidden(selector: string, state: string): boolean {
        const override = this.resolveOverride(selector);
        return (
            (override &&
                override.stateNameOverrides &&
                override.stateNameOverrides[state] &&
                override.stateNameOverrides[state].hidden) ||
            false
        );
    }

    public getStateName(selector: string, state: string): string {
        const override = this.resolveOverride(selector);
        return (
            (override &&
                override.stateNameOverrides &&
                override.stateNameOverrides[state] &&
                override.stateNameOverrides[state].nameKey) ||
            (this.defaultStates[state] && this.defaultStates[state].nameKey) ||
            state
        );
    }

    /*
    adds the given state to the given selector according to the selector's prime element and returns the resolved selector
     */
    public getStateOverrideTransformed(selector: string, state: string): string {
        // native pseudos cannot have states so we go to the previous element
        const stateOverride = stripNativePseudoFromSelector(this.getPrimeElement(selector));
        return applyState(stateOverride, state, selector);
    }

    public getStatesOrder(selector: string): string[] {
        const override = this.resolveOverride(selector);
        return override && override.statesOrder ? override.statesOrder : [];
    }

    public getCopyControllers(selector: string) {
        let foldedCopiedControllers: Record<string, CopyController[]> | undefined = undefined;
        const foldedIntoSelectors = this.getInheritedStateSelectors(selector, true);
        if (foldedIntoSelectors.length > 0) {
            foldedCopiedControllers = {};
            foldedIntoSelectors.forEach((copySelectorVariant) => {
                foldedCopiedControllers![copySelectorVariant] = [{} as CopyController];
            });
        }

        const selectorConfiguration = this.getSelectorConfiguration(selector);
        if (
            !selectorConfiguration ||
            !selectorConfiguration.copyControllers ||
            Object.keys(selectorConfiguration.copyControllers).length === 0
        ) {
            return foldedCopiedControllers;
        }

        return Object.keys(selectorConfiguration.copyControllers).reduce((copyControllers, copySelector) => {
            copyControllers[this.selectorToVariant(copySelector)] =
                selectorConfiguration.copyControllers![copySelector];
            return copyControllers;
        }, foldedCopiedControllers || ({} as Record<string, CopyController[]>));
    }

    public getInheritedStateSelectors(sourceVariantSelector: string, onlyFold = false) {
        const sourceSelector = this.selectorFromVariant(sourceVariantSelector);

        const inheritedStateSelectors: string[] = [];
        this.configuredSelectors.forEach((elementSelector) => {
            if (elementSelector === sourceSelector) {
                return;
            }

            const primeElementSelector = this.getPrimeElement(elementSelector, onlyFold);
            if (primeElementSelector === sourceVariantSelector) {
                inheritedStateSelectors.push(this.selectorToVariant(elementSelector));
            }
        });

        return inheritedStateSelectors;
    }

    public selectorToVariant = (selector: string) => selector.replace('.root', this.variant);
    private selectorFromVariant = (selector: string) => selector.replace(this.variant, '.root');
}
