import type { ComponentConfig, ComponentsMetadata } from '@stylable/webpack-extensions/dist/component-metadata-builder';
import type { DimensionID } from './dimension-input-types';

export interface PresetSectionLayouts {
    static: Record<string, string>;
    theme: Record<string, string>;
    user: Record<string, string>;
}

export enum StylePanelPlane {
    Vertical = 'vertical',
    VerticalCollapse = 'verticalCollapse',
}

export enum StylePanelPage {
    PresetPanel = 0,
    CustomizationPanel,
}

export enum SelectorConfigurationDisplay {
    Visible = 'visible',
    Hidden = 'hidden',
    ReplaceSelfWithOwnChildren = 'replaceSelfWithOwnChildren',
}

export interface SelectorConfiguration {
    hide?: boolean; // If true selector will not be displayed - Deprecated TODO remove
    display?: SelectorConfigurationDisplay; // This replaces the deprecated 'hide' field, and multiple options. default=visible
    nameKey?: string; // Translation key representing Name of element in a readable format
    stateDisplayKey?: string; // Translation key representing state displayer string
    primeElementSelector?: string; // Specify a selector to be chosen instead of the given one - used for state overrides. states are always applied on prime elements
    stateNameOverrides?: Record<string, StateNameOverride>; // Readable names for states used by element
    statesOrder?: string[]; // List of state names in the order we want to display them in the state dropdown
    copyControllers?: Record<string, CopyController[]>; // Specify selectors and the list of controllers to be copied from them
    controllerData?: Record<string, ControllerData>; // Specify any specific controller data for this selector
    foldInto?: boolean;
}

export interface EditorComponentConfig extends ComponentConfig {
    initialElement?: string; // If specified, this states which element should be shown first when opening stylable panel. (e.g ".root::label")
    presetSectionLayouts?: PresetSectionLayouts;
    selectorConfiguration?: Record<string, SelectorConfiguration>;
    themePresets?: Record<string, string>;
}

export interface StateNameOverride {
    nameKey?: string;
    hidden?: boolean;
}

export interface CopyController {
    categoryId?: string;
    controllerKey?: string;
}

export interface ControllerData {
    translationKeys?: Record<string, string>;
    hideShownToggle?: boolean;
    noStateResizing?: string[];
    glyphMode?: boolean;
}

export interface PanelComponentsMetadata extends ComponentsMetadata {
    components: { [path: string]: EditorComponentConfig };
}

export enum UnitRef {
    CUSTOM = 'unitless',
    PIXELS = 'pixels',
    PERCENTS = 'percents',
    ELEMENTS = 'elements',
    ROOT_ELEMENTS = 'root_elements',
    DEGREES = 'degrees',
    SECONDS = 'seconds',
    MILLISECONDS = 'milliseconds',
    VIEW_WIDTH = 'viewport_width',
    VIEW_HEIGHT = 'viewport_height',
}

export interface DimensionProps extends SliderConfig {
    displaySymbol?: string;
    min?: number;
    max?: number;
    step?: number;
    shiftStep?: number;
    noFloat?: boolean;
}

export interface SliderConfig {
    sliderMin?: number;
    sliderMax?: number;
    sliderStep?: number;
}

export type UnitRefPartialRecord = Partial<Record<UnitRef, DimensionProps>>;

export type DimensionUnits = Partial<Record<DimensionID, UnitRefPartialRecord>>;
