import React from 'react';

import { style, classes } from './radio-button.st.css';

export interface RadioButtonProps {
    checked: boolean;
    id: string;
    label: string;
    onChange?: () => void;
    className?: string;
}

export class RadioButton extends React.Component<RadioButtonProps> {
    public render() {
        const { label, checked, className } = this.props;
        return (
            <label className={style(classes.root, className)} onClick={(event) => this.onChange(event)}>
                <span className={style(classes.label)}>{label}</span>
                <input className={style(classes.input)} type="radio" defaultChecked={checked} />
            </label>
        );
    }

    private onChange(event: React.MouseEvent) {
        event.preventDefault();
        const { onChange } = this.props;
        onChange && onChange();
    }
}
