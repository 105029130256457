import React from 'react';

import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import { ExtendedGlobalHost, getTranslate } from '@wixc3/stylable-panel-controllers';

import { style, classes } from './style-panel.st.css';

export enum StylePanelPage {
    PresetPanel = 0,
    CustomizationPanel,
}

export interface StylePanelChildProps {
    page?: StylePanelPage;
    className?: string;
}

export interface StylePanelProps {
    page: StylePanelPage;
    panelHost?: ExtendedGlobalHost;
    onPageChange?: (page: StylePanelPage) => void;
    onToggleCssPanel?: () => void;
    className?: string;
    children?: React.ReactNode;
}

interface StylePanelState {
    cssPanelShown: boolean;
}

export class StylePanel extends React.Component<StylePanelProps, StylePanelState> {
    public state: StylePanelState = { cssPanelShown: false };

    public render() {
        const { page, className } = this.props;

        const classNames = [`presetPanelWrapper`, `customizationPanelWrapper`];

        return (
            <div className={style(classes.root, className)}>
                <div className={style(classes.panelContent, classNames[page])}>{this.getPanelContent()}</div>
                {page === 0 && (
                    <div className={style(classes.panelFooter, { page, cssPanelShown: this.state.cssPanelShown })}>
                        {this.getPanelFooter()}
                    </div>
                )}
            </div>
        );
    }

    private getPanelButton(label: string, pageId: number, action?: string, callback?: any) {
        const onClick = () => (typeof callback === 'function' ? callback() : this.setPage(pageId));
        return (
            <span className={classes.panelButton} onClick={onClick}>
                <span className={style(classes.panelButtonLabel, action)}>{label}</span>
            </span>
        );
    }

    private findPageChild(page: StylePanelPage) {
        return React.Children.toArray(this.props.children).find(
            (child: React.ReactNode) =>
                !!child && React.isValidElement<StylePanelChildProps>(child) && child.props.page === page
        );
    }

    private getPanelContent() {
        switch (this.props.page) {
            case StylePanelPage.PresetPanel:
                return this.findPageChild(StylePanelPage.PresetPanel);
            case StylePanelPage.CustomizationPanel:
                return this.findPageChild(StylePanelPage.CustomizationPanel);
        }
        return false;
    }

    private getPanelFooter() {
        if (this.state.cssPanelShown) {
            return this.getCssShownFooter();
        }
        switch (this.props.page) {
            case StylePanelPage.PresetPanel:
                return this.getPresetFooter();
        }
        return false;
    }

    private getPresetFooter() {
        const translate = getTranslate(this.props.panelHost);
        return (
            <span className={classes.panelControls}>
                {this.getPanelButton(
                    translate(StylablePanelTranslationKeys.customizeDesign),
                    StylePanelPage.CustomizationPanel,
                    'next'
                )}
            </span>
        );
    }

    private getCssShownFooter() {
        return (
            <span className={classes.panelControls}>
                {this.getPanelButton('Advanced', StylePanelPage.CustomizationPanel, 'prev', this.handleToggleCssPanel)}
            </span>
        );
    }

    private setPage(page: StylePanelPage) {
        const { onPageChange } = this.props;

        onPageChange && onPageChange(page);
    }

    private handleToggleCssPanel = () => {
        const { onToggleCssPanel } = this.props;

        onToggleCssPanel && onToggleCssPanel();
        this.setState({ cssPanelShown: !this.state.cssPanelShown });
    };
}
