import React from 'react';

import type { ShorthandsTypeMap } from '@wixc3/shorthands-opener';

import type { DeclarationMap, GenericDeclarationMap } from '@wixc3/stylable-panel-drivers';
import { Title } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE } from '@wixc3/stylable-panel-common';

import { getTranslate } from '../../hosts/translate';

import type { CustomInputProps } from '../../drivers';
import type { PropsToShorthandFunction } from '../../utils/props-to-shorthand';
import type { DeclarationVisualizerProps, OpenedDeclarationList, VisualizerComponent } from '../../types';
import type { OpenedDeclarationArray } from '../../declaration-types';
import {
    controllerToVisualizerChange,
    getOpenedDeclarationList,
    getShorthandChange,
    getShorthandControllerValue,
} from '../../utils';

import { classes, style } from '../../meta/common-controller.st.css';

export function CommonVisualizerFactory<MAIN extends keyof ShorthandsTypeMap, PROPS extends string>(
    baseProp: MAIN,
    inputProps: PROPS[],
    InputComp: React.ComponentClass<CustomInputProps>,
    propsToShorthand: PropsToShorthandFunction,
    titleKey?: string,
    outsideProps?: PROPS[],
    mainController = false
): React.ComponentClass<DeclarationVisualizerProps<PROPS>> {
    const ALL_PROPS_LIST = inputProps.concat(outsideProps ?? []);
    type CommonDeclarationMap = GenericDeclarationMap<PROPS>;

    class CommonVisualizer
        extends React.Component<DeclarationVisualizerProps<PROPS>>
        implements VisualizerComponent<PROPS>
    {
        public static INPUT_PROPS: PROPS[] = inputProps;
        public static OUTSIDE_PROPS: PROPS[] | undefined = outsideProps;
        private declarationMapValue: CommonDeclarationMap;
        private openedDeclarationList: OpenedDeclarationList<PROPS>;

        constructor(props: DeclarationVisualizerProps<PROPS>) {
            super(props);

            this.openedDeclarationList = getOpenedDeclarationList(baseProp, ALL_PROPS_LIST, props);
            this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, props);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillUpdate(newProps: DeclarationVisualizerProps<PROPS>) {
            this.openedDeclarationList = getOpenedDeclarationList(baseProp, ALL_PROPS_LIST, newProps);
            this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, newProps);
        }

        public render() {
            const { siteVarsDriver, plane = DEFAULT_PLANE, panelHost, onChange, className } = this.props;
            const inputValue = propsToShorthand(this.declarationMapValue);

            const translate = getTranslate(panelHost);

            return (
                <div
                    className={style(classes.root, { mainController, plane }, className)}
                    data-common-controller-highlight-parts
                >
                    {titleKey ? <Title className={classes.title} text={translate(titleKey)} /> : null}
                    <InputComp
                        className={classes.input}
                        value={inputValue}
                        outsideValues={this.getOutsideValues()}
                        siteVarsDriver={siteVarsDriver}
                        panelHost={panelHost}
                        plane={plane}
                        onChange={this.handleInputCompChange}
                        onOutsideChange={(value: DeclarationMap) => {
                            if (onChange) {
                                onChange(controllerToVisualizerChange(value as CommonDeclarationMap, this.props));
                            }
                        }}
                    />
                </div>
            );
        }

        private handleInputCompChange = (value: string) => {
            const { onChange } = this.props;
            if (onChange) {
                onChange(
                    getShorthandChange<MAIN, PROPS>(
                        baseProp,
                        this.createCompleteChangeFromPartial({
                            [baseProp]: value,
                        }),
                        this.openedDeclarationList,
                        this.props
                    ) as OpenedDeclarationArray<PROPS>
                );
            }
        };

        private createCompleteChangeFromPartial(value: GenericDeclarationMap): GenericDeclarationMap<MAIN | PROPS> {
            const newChange: GenericDeclarationMap = Object.assign({}, value);

            inputProps.forEach((prop: string) => {
                if (!Object.prototype.hasOwnProperty.call(newChange, prop)) {
                    newChange[prop] = undefined;
                }
            });

            return newChange;
        }

        private getOutsideValues() {
            if (!outsideProps || outsideProps.length === 0) {
                return {};
            }

            return outsideProps.reduce((outsideValues, prop) => {
                outsideValues[prop] = this.declarationMapValue[prop];
                return outsideValues;
            }, {} as DeclarationMap);
        }
    }

    return CommonVisualizer;
}
