import React, { useState } from 'react';

import { DownArrow } from '@wixc3/stylable-panel-common-react';

import { style, classes } from './display-mode-picker.st.css';

export interface DisplayModePickerProps {
    isActionOpened: boolean;
    displayModePickerTitle: string;
    displayModePickerContent: DisplayModePickerContent;
    handleDisplayModePickerBoxClick?: (box: DisplayModePickerBox) => void;
    toggleDisplayModePicker?: (displayModePickerOpen: boolean) => boolean;
    onDisplayModePickerPopupContentMenu?: () => void;
    className?: string;
}

export interface DisplayModePickerState {
    displayModePickerOpen: boolean;
}

export interface DisplayModePickerBox {
    id: string;
    label: string;
    selected: boolean;
    backgroundCSS?: string;
    backgroundIcon?: JSX.Element;
}

export interface DisplayModePickerContent {
    title: string;
    content: DisplayModePickerBox[];
}

export const DisplayModePicker = ({
    className,
    displayModePickerContent,
    displayModePickerTitle,
    handleDisplayModePickerBoxClick,
    isActionOpened,
    onDisplayModePickerPopupContentMenu,
    toggleDisplayModePicker,
}: DisplayModePickerProps) => {
    const [displayModePickerOpen, setDisplayModePickerOpen] = useState(false);

    const renderDisplayModePicker = () => (
        <div
            className={style(classes.displayModePickerInner, { open: displayModePickerOpen })}
            onClick={toggleDisplayModePickerInternal}
        >
            <span className={classes.displayModePickerButtonText}>{displayModePickerTitle}</span>
            <DownArrow className={classes.downArrow} />
            <div>{displayModePickerOpen ? renderDisplayModePickerPopup() : null}</div>
        </div>
    );

    const toggleDisplayModePickerInternal = () => {
        let newDisplayModePickerOpen;
        if (toggleDisplayModePicker) {
            newDisplayModePickerOpen = toggleDisplayModePicker(displayModePickerOpen);
        } else {
            newDisplayModePickerOpen = !displayModePickerOpen;
        }
        setDisplayModePickerOpen(newDisplayModePickerOpen);
    };

    const renderDisplayModePickerBox = (box: DisplayModePickerBox) => {
        const { id, label, selected, backgroundCSS, backgroundIcon } = box;
        return (
            <span key={id} className={classes.boxWrapper}>
                <span
                    className={style(classes.box, { selected })}
                    style={backgroundCSS ? { backgroundImage: backgroundCSS } : undefined}
                    onClick={() => {
                        handleDisplayModePickerBoxClick && handleDisplayModePickerBoxClick(box);
                        setDisplayModePickerOpen(false);
                    }}
                >
                    {backgroundIcon || null}
                </span>
                {label}
            </span>
        );
    };

    const renderDisplayModePickerPopup = () => (
        <div
            className={classes.displayModePickerPopup}
            onContextMenu={(event) => {
                onDisplayModePickerPopupContentMenu && onDisplayModePickerPopupContentMenu();
                setDisplayModePickerOpen(false);
                event.preventDefault();
            }}
        >
            <div className={classes.displayModePickerText}>{displayModePickerContent.title}</div>
            <div className={classes.boxesContainer}>
                {displayModePickerContent.content.map((box) => renderDisplayModePickerBox(box))}
            </div>
            <span className={classes.arrow} />
        </div>
    );

    return !isActionOpened ? <div className={style(classes.root, className)}>{renderDisplayModePicker()}</div> : null;
};
