import type { InternalDimensionProps } from './types';
import { MAX_INPUT_VALUE } from '@wixc3/stylable-panel-common';

export const UNITLESS_SYMBOL = '';
export const UNITLESS_CONFIG: InternalDimensionProps = { symbol: UNITLESS_SYMBOL };
export const DEFAULT_INPUT_VALUE = 0;
export const DEFAULT_STEP = 1;
export const DEFAULT_SHIFT_STEP = 10;
export const DEFAULT_SLIDER_MIN = 0;
export const DEFAULT_SLIDER_MAX = 1;
export const MAX_FLOAT_VALUE_LENGTH = 3;
export const MAX_INPUT_LENGTH = 16;
export const MAX_VALUE = MAX_INPUT_VALUE;
export const MIN_VALUE = -MAX_VALUE;
export const MAX_VALUE_LENGTH = `${MAX_VALUE}`.length;
export const DEFAULT_PLACEHOLDER = '';
export const TOOLTIP_OFFSET = 0;
export const ZERO_STR = '0';
