import type * as postcss from 'postcss';

import {
    Stylable,
    createDefaultResolver,
    CSSResolve,
    ClassSymbol,
    ElementSymbol,
    StylableMeta,
    safeParse,
} from '@stylable/core';

import { applyStylableForceStateSelectors } from '@stylable/webpack-extensions/dist/stylable-forcestates-plugin';

import { PostCSSDriver, UsageMapping, BuildConfig, BuildHook } from './postcss-driver';
import { StylesheetDriver } from './stylable-stylesheet';
import { StylableSiteVars } from './stylable-site-vars';
import { updateNodeRevision } from './mutable-ast';
import type { TransformationPlugins, StylablePanelDriversExperiments, ElementTree } from './types';
import { FilterFunc, StylableAggregation } from './stylable-aggregation';
import { createElementsTree } from './create-elements-tree';
import { nativePseudoElementsSet } from './utils/native-pseudo-elements-set';

export interface ISymbol {
    from: string;
    name: string;
}

interface UpdateSingleState {
    callbacks: Array<() => void>;
    lock: number;
}

export type JsModuleDefinition = ((...args: string[]) => string) | object;
export type InlineModules = Record<string, JsModuleDefinition>;

type UpdateState = Record<string, UpdateSingleState>;

export const ARCHETYPE_PROPNAME = '-archetype';
export const CONTROLLER_PART_TYPE_PROPNAME = '-controller-part-type';
export const USAGE_MAPPING_ALLOW_ALL: UsageMapping = () => true;

const PROJECT_ROOT = '/';

function wrapRequireWithInlineModules(requireModule: (id: string) => any, inlineModules: InlineModules) {
    return (id: string) => {
        const inlineModule: any = inlineModules[id];
        return inlineModule ? inlineModule : requireModule(id);
    };
}

export class StylableDriver extends PostCSSDriver {
    public stylable: Stylable;

    private siteVarsDriver: StylableSiteVars | undefined;
    private updateState: UpdateState = {};
    private batching = false; // if true - updateFromAst is blocked
    private jsModules: InlineModules;
    private scope: string | undefined;
    private transformationPlugins: TransformationPlugins = [];
    private aggregationCache = new Map<string, Map<string, any>>();

    constructor(
        buildHook?: BuildHook,
        alias: Record<string, string> = {},
        private usageMapping: UsageMapping = {}, // TODO: Deprecate
        private experiments: StylablePanelDriversExperiments = { newElementree: true }
    ) {
        super(buildHook);
        this.jsModules = Object.create(null);
        const requireModule = wrapRequireWithInlineModules(this.requireModule, this.jsModules);
        const resolveOptions = {
            unsafeCache: true,
            symlinks: false,
            alias,
        };
        const defaultResolver = createDefaultResolver(this.fs, resolveOptions);
        const resolveModule = (directoryPath: string, request: string) =>
            this.jsModules[request] ? request : defaultResolver(directoryPath, request);

        this.stylable = Stylable.create({
            projectRoot: PROJECT_ROOT,
            fileSystem: this.fs,
            requireModule,
            resolveOptions,
            resolveModule,
            cssParser: safeParse,
        });
    }

    public aggregateSelectorDeclarations(
        sheetPath: string | string[],
        selector: string,
        filter?: FilterFunc,
        useRawDecl?: boolean
    ) {
        if (Array.isArray(sheetPath)) {
            const stylesheetAggregate = sheetPath.reduce((prev, curr, i) => {
                const isShallow = i !== 0;
                return Object.assign(
                    {},
                    prev,
                    this.runAggregateForSheet(curr, selector, filter, useRawDecl, isShallow)
                );
            }, {});
            return stylesheetAggregate;
        } else {
            return this.runAggregateForSheet(sheetPath, selector, filter, useRawDecl, false);
        }
    }

    private runAggregateForSheet(
        sheetPath: string,
        selector: string,
        filter?: FilterFunc,
        useRawDecl?: boolean,
        shallow?: boolean
    ) {
        if (filter === undefined && useRawDecl === undefined && shallow === undefined) {
            let cacheValue = this.aggregationCache.get(sheetPath);
            if (cacheValue?.has(selector)) {
                return cacheValue.get(selector);
            } else {
                if (!cacheValue) {
                    cacheValue = new Map();
                    this.aggregationCache.set(sheetPath, cacheValue);
                }
                const aggregationDriver = new StylableAggregation(this);
                const decls = aggregationDriver.aggregateSelectorDeclarations(sheetPath, selector);
                cacheValue.set(selector, decls);
                return decls;
            }
        } else {
            const aggregationDriver = new StylableAggregation(this);
            return aggregationDriver.aggregateSelectorDeclarations(sheetPath, selector, filter, useRawDecl, shallow);
        }
    }

    public writeFile(path: string, content: string) {
        if (content === this.readFile(path)) {
            return;
        }
        super.writeFile(path, content);
        this.stylable.process(path);
    }

    public buildCSS({ entries, emitBuild = true }: BuildConfig = {}) {
        const entriesArray = entries || this.entries;
        const css = entriesArray
            .map((entry) => {
                const { meta } = this.stylable.transform(this.getStylesheetMeta(entry));
                this.applyEditorTransformations(meta);
                return meta.outputAst!.toString();
            })
            .join('\n');
        if (this.buildHooks.length && emitBuild !== false) {
            this.buildHooks.forEach((hook) => hook(css, entriesArray));
        }
        return css;
    }
    public applyEditorTransformations(meta: StylableMeta) {
        applyStylableForceStateSelectors(meta.outputAst!, USAGE_MAPPING_ALLOW_ALL || this.usageMapping);
        return meta;
    }

    // TODO: protected?
    public getStylesheetMeta(path: string) {
        return this.stylable.process(path);
    }

    public getTransformer() {
        return this.stylable.createTransformer();
    }

    public setScope(scope?: string) {
        this.scope = scope || undefined;
    }
    public getScope() {
        return this.scope;
    }

    public getStylesheet(path: string) {
        const meta = this.getStylesheetMeta(path);
        // TODO: When does this return null?
        return meta
            ? new StylesheetDriver(
                  this.getStylesheetMeta.bind(this, path),
                  this.updateFileFromAST.bind(this, path),
                  this.getTransformer.bind(this),
                  this.getScope(),
                  this.transformationPlugins,
                  this.experiments
              )
            : null;
    }

    public getSiteVarsDriver(path: string) {
        if (!this.siteVarsDriver) {
            const sheet = this.getStylesheet(path);
            if (!sheet) {
                return null;
            }

            this.siteVarsDriver = new StylableSiteVars(sheet);
        }

        return this.siteVarsDriver;
    }

    public getSelectorTypePath(sheetPath: string, selector: string) {
        const resolved = this.getElementResolved(sheetPath, selector);
        if (!resolved) {
            return '';
        }
        if (resolved.length === 1) {
            return resolved[0].meta.source;
        }
        const root = [...resolved].reverse().find((resolve) => resolve.symbol.name === 'root') || null;
        return root ? root.meta.source : '';
    }

    public getPseudoElements(sheetPath: string, selector: string) {
        const resolved = this.getElementResolved(sheetPath, selector);
        if (!resolved) {
            return [];
        }
        const extendedElements = resolved.filter((resolve) => resolve.symbol.name === 'root');

        const res = new Set<string>();
        extendedElements.forEach((extendedElement) => {
            Object.keys(extendedElement.meta.classes)
                .filter((name) => name !== 'root')
                .forEach((name) => {
                    res.add(name);
                });
        });
        return Array.from(res);
    }

    public getPseudoElementsDeep(sheetPath: string, selector: string, inner = false) {
        if (this.experiments?.newElementree) {
            return createElementsTree(this.getStylesheetMeta(sheetPath), selector, this.stylable.resolver);
        }
        const rootPseudoElements = this.getPseudoElements(sheetPath, selector);
        const newSelector = inner && selector.startsWith('.') ? selector.slice(1, selector.length) : selector;

        const path = this.getSelectorTypePath(sheetPath, selector);

        const returnTree: ElementTree = { [newSelector]: {} };
        rootPseudoElements.forEach((element) => {
            returnTree[newSelector] = {
                ...returnTree[newSelector],
                ...this.getPseudoElementsDeep(path, `.${element}`, true),
            };
        });
        return returnTree;
    }

    public getTargetClass(sheetPath: string, className: string) {
        const meta = this.getStylesheetMeta(sheetPath);
        return this.getTransformer().scope(className, meta.namespace);
    }

    /***/

    // private getASTForPath(path: string){ return this.getStylesheetMeta(path).rawAst; }

    public getPreprocessorValue(preprocessorPropName: string, sheetPath: string, selector: string) {
        const sheet = this.getStylesheet(sheetPath);
        if (!sheet) {
            return undefined;
        }

        const foundPreprocessorValueInFirstSheet = findPreprocessorValueInSheet(sheet, selector);
        if (foundPreprocessorValueInFirstSheet) {
            return foundPreprocessorValueInFirstSheet;
        }

        const meta = sheet.getMeta();
        const elements = this.getTransformer().resolveSelectorElements(meta, selector)[0];
        const { resolved, name } = elements[elements.length - 1];
        if (resolved.length === 0 && nativePseudoElementsSet.has(name)) {
            const part = elements[elements.length - 2];
            return part ? findValueInResolved(this, part.resolved, name) : undefined;
        }

        return findValueInResolved(this, resolved);

        function findValueInResolved(
            driver: StylableDriver,
            resolved: CSSResolve<ClassSymbol | ElementSymbol>[],
            nativePseudo?: string
        ) {
            if (resolved.length < 1) {
                return undefined;
            }
            for (const cssResolve of resolved) {
                const allegedSheet = driver.getStylesheet(cssResolve.meta.source);
                const allegedClassName = cssResolve.symbol.name;

                if (allegedSheet) {
                    const foundPreprocessorValue = findPreprocessorValueInSheet(
                        allegedSheet,
                        '.' + allegedClassName + (nativePseudo ? `::${nativePseudo}` : '')
                    );
                    if (foundPreprocessorValue) {
                        return foundPreprocessorValue;
                    }
                }
            }
            return undefined;
        }

        // check if it is the requested preprocessor declaration, and return it if so:
        function findPreprocessorValueInSheet(allegedSheet: StylesheetDriver, allegedClassName: string) {
            const rules = allegedSheet.queryStyleRule(allegedClassName);

            if (rules.length > 0 && rules[0].nodes) {
                const preprocessorDecl = (rules[rules.length - 1].nodes as postcss.Declaration[]) // TODO check with barak
                    .find((decl) => decl.prop === preprocessorPropName) as postcss.Declaration;
                if (preprocessorDecl) {
                    // rule has the requested preprocessor:
                    return preprocessorDecl.value;
                }
            }

            // not the requested preprocessor declaration
            return undefined;
        }
    }

    // TODO concept should be expanded and moved to stylable as -st-archetype
    public getArchetypeName(sheetPath: string, name: string) {
        return this.getPreprocessorValue(ARCHETYPE_PROPNAME, sheetPath, name);
    }

    public getControllerPartType(sheetPath: string, selector: string) {
        return this.getPreprocessorValue(CONTROLLER_PART_TYPE_PROPNAME, sheetPath, selector);
    }

    // TODO: Move to stylable
    // TODO: mixins that resolve to root should return all used variables in stylesheet
    public getCSSMixinOverrides(sheetPath: string, name: string) {
        const resolvedSymbol = this.resolveSymbol(sheetPath, name);
        if (!resolvedSymbol) {
            return [];
        }

        const sheet = this.getStylesheet(resolvedSymbol.from);
        if (!sheet) {
            return [];
        }

        return sheet.getCSSMixinOverrides(resolvedSymbol.name);
    }

    public getExperiments() {
        return this.experiments;
    }

    public resolveSymbol(from: string, name: string): ISymbol | null {
        const sheet = this.getStylesheet(from);
        if (!sheet) {
            return null;
        }

        const symbol = sheet.getSymbol(name);
        if (!symbol) {
            return null;
        }

        if (symbol._kind === 'import') {
            return this.resolveSymbol(symbol.import.from, symbol.name !== 'default' ? symbol.name : 'root');
        } else if (symbol._kind !== 'class') {
            return null;
        }

        return { from, name };
    }

    /**
     * runs a given function and prevents ast transform to occur while running it
     * @param path - path of file being updated
     * @param func - function to be run
     * @param cb - callback to be called by updateFromAST after transform
     */
    public batch(path: string | string[], func: () => void, cb?: () => void) {
        // Start blocking updates:
        this.batching = true;

        func();

        // release blocking updates:
        this.batching = false;
        if (Array.isArray(path)) {
            path.forEach((path) => this.updateFromAST(path, cb));
        } else {
            this.updateFromAST(path, cb);
        }
    }

    public updateFileFromAST(path: string, modified: postcss.Node, cb?: () => void) {
        this.updateFromAST(path, cb);
        return updateNodeRevision(modified);
    }

    public registerJsModule(key: string, mixin: JsModuleDefinition) {
        this.jsModules[key] = mixin;
    }

    public registerJsModules(mixins: InlineModules) {
        Object.keys(mixins).forEach((key) => this.registerJsModule(key, mixins[key]));
    }

    public registerTransformationPlugins(plugins: TransformationPlugins) {
        this.transformationPlugins.push(...plugins);
    }

    public unregisterJsModule(key: string) {
        delete this.jsModules[key];
    }

    public clearFsCache(filterFunc: (key: string) => boolean) {
        const cache = this.stylable.fileProcessor.cache;
        Object.keys(cache)
            .filter(filterFunc)
            .forEach((key) => {
                delete cache[key];
            });
    }

    private updateFromAST(path: string, cb?: () => void) {
        if (this.batching) {
            return;
        }
        if (this.updateState[path] === undefined) {
            this.updateState[path] = { callbacks: [], lock: 0 };
        }
        // Update FS immediately:
        this.updateFsFromAst(path);

        this.aggregationCache.get(path)?.clear();

        const stylesheet = this.getStylesheet(path)!;
        // restore source meta ref (should be same value)
        const meta = stylesheet.getMeta();
        // update CSS and runtime
        this.stylable.transform(meta);

        if (!this.updateState[path].lock) {
            this.updateState[path].lock = setTimeout(() => {
                // TODO: change hard coded /site.st.css
                this.buildCSS({ entries: this.entries });

                this.updateState[path].callbacks.forEach((func) => func());
                this.updateState[path].callbacks = [];
                this.updateState[path].lock = 0;
            }, 10) as any;
        }

        if (cb && !~this.updateState[path].callbacks.indexOf(cb)) {
            this.updateState[path].callbacks.push(cb);
        }
    }

    private updateFsFromAst(path: string) {
        const stylesheet = this.getStylesheet(path)!;
        const currentSourceAst = stylesheet.AST;
        // update FS
        this.writeFile(path, stylesheet.source);
        const meta = stylesheet.getMeta();
        meta.rawAst = currentSourceAst; // restore mutable-ast metadata
    }

    private getElementResolved(sheetPath: string, selector: string) {
        const meta = this.getStylesheetMeta(sheetPath);
        const transformer = this.getTransformer();
        const elements = transformer.resolveSelectorElements(meta, selector)[0];
        if (selector.split('::').length !== elements.length) {
            return null;
        }
        return elements[elements.length - 1].resolved;
    }
}
