/* eslint-disable react/no-string-refs */
import React from 'react';

import {
    DragHandle,
    Add,
    More,
    Remove,
    Duplicate,
    HideLayer,
    ShowLayer,
    isInRect,
} from '@wixc3/stylable-panel-common-react';
import { StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';
import { Title, OptionList, Option, Tooltip } from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, StylePanelPlane } from '@wixc3/stylable-panel-common';

import type { ExtendedGlobalHost, TranslateFunc } from '../../types';
import { getTranslate } from '../../hosts/translate';
import { style, classes } from './layers-controller.st.css';

export const LAYER_HIDDEN_PROP = 'data-layer-hidden';

const getContextMenuOptions = (_index: number, size: number, hidden: boolean, translate: TranslateFunc) => {
    const alwaysShownOptions: Option[] = [
        {
            id: 'duplicate',
            displayName: translate(StylablePanelTranslationKeys.controller.layers.layerAction.duplicateLabel),
            icon: Duplicate,
        },
        {
            id: 'showHide',
            displayName: hidden
                ? translate(StylablePanelTranslationKeys.controller.layers.layerAction.showLabel)
                : translate(StylablePanelTranslationKeys.controller.layers.layerAction.hideLabel),
            icon: hidden ? ShowLayer : HideLayer,
        },
        // {id: 'moveUp',    displayName: 'Move Up',   icon: MoveUp,   disabled: index === 0},
        // {id: 'moveDown',  displayName: 'Move Down', icon: MoveDown, disabled: index === size - 1},
    ];

    return size > 1
        ? alwaysShownOptions.concat([
              {
                  id: 'remove',
                  displayName: translate(StylablePanelTranslationKeys.controller.layers.layerAction.removeLabel),
                  icon: Remove,
              },
          ])
        : alwaysShownOptions;
};

export interface LayersControllerProps {
    title?: string;
    addLayerLabel: string;
    addOptions?: Option[];
    children?: React.ReactNode;
    plane?: StylePanelPlane;
    panelHost?: ExtendedGlobalHost;
    onAdd?: (option?: string) => void;
    onReplace?: (srcIndex: number, dstIndex: number) => void;
    onDuplicate?: (index: number) => void;
    onRemove?: (index: number) => void;
    onHide?: (hidden: boolean, index: number) => void;
    className?: string;
}

export interface LayersControllerState {
    draggingIndex: number;
    addLayerMenuOpen: boolean;
    contextMenuIndex: number;
}

export class LayersController extends React.Component<LayersControllerProps, LayersControllerState> {
    public readonly state: LayersControllerState = {
        draggingIndex: -1,
        addLayerMenuOpen: false,
        contextMenuIndex: -1,
    };

    public render() {
        const { title, addLayerLabel, children, addOptions, plane = DEFAULT_PLANE, className } = this.props;
        const { addLayerMenuOpen } = this.state;

        const realChildren = React.Children.toArray(children).filter((child) => !!child);

        return (
            <div className={style(classes.root, { plane }, className)}>
                <Title className={classes.title} text={title} />
                <div className={classes.addButtonWrapper}>
                    <div className={classes.addButton} onClick={this.handleAddLayerClick}>
                        {addLayerMenuOpen
                            ? this.renderPopupMenu(addOptions || [], this.handleAddLayerMenuSelection, true)
                            : null}
                        <Add className={classes.addIcon} />
                        {addLayerLabel}
                    </div>
                </div>
                <div className={classes.longDivider} />
                {realChildren.map(this.renderLayer)}
            </div>
        );
    }

    public componentWillUnmount() {
        this.cleanDragEventListeners();
        this.cleanContextMenuEventListeners();
    }

    private renderLayer = (child: React.ReactNode, index: number) => {
        const { children, panelHost, onHide } = this.props;
        const { draggingIndex, contextMenuIndex } = this.state;

        const translate = getTranslate(panelHost);

        const hidden =
            React.isValidElement<{ [LAYER_HIDDEN_PROP]?: boolean }>(child) && !!child.props[LAYER_HIDDEN_PROP];
        const contextMenuOpen = contextMenuIndex === index;
        const childCount = React.Children.toArray(children).filter((c) => !!c).length;

        return [
            <div
                className={style(classes.layer, { dragging: draggingIndex === index })}
                key={`layer_${index}`}
                ref={`layer_${index}`}
            >
                <DragHandle
                    className={style(classes.dragHandle, { disabled: childCount <= 1 })}
                    onMouseDown={() => this.dragStart(index)}
                />
                <span className={classes.layerContent}>{child}</span>
                <Tooltip
                    className={style(classes.hiddenTooltip, { hidden })}
                    text={translate(StylablePanelTranslationKeys.controller.layers.layerAction.showLabel)}
                    verticalAdjust={4}
                    horizontalAdjust={-1.5}
                >
                    <HideLayer
                        className={classes.hiddenIndicator}
                        style={{ top: 0, left: 0, marginRight: 0 }}
                        onClick={() => onHide && onHide(true, index)}
                    />
                </Tooltip>
                <span
                    className={style(classes.contextButton, { open: contextMenuOpen })}
                    onClick={() => this.handleContextMenuOpen(index)}
                    ref={`contextButton_${index}`}
                >
                    {contextMenuOpen
                        ? this.renderPopupMenu(getContextMenuOptions(index, childCount, hidden, translate), (id) =>
                              this.handleContextMenuSelection(id, index, hidden)
                          )
                        : null}
                    <More />
                </span>
            </div>,
            <div key={`divider_${index}`} className={classes.longDivider} />,
        ];
    };

    private renderPopupMenu(options: Option[], onSelect: (id: string) => void, add = false) {
        return (
            <div
                className={style(classes.contextMenu, { add, orientation: this.getContextMenuPosition().orientation })}
                style={!add ? this.getContextMenuStyle() : {}}
                ref="contextMenu"
            >
                <OptionList
                    className={classes.optionList}
                    options={options}
                    onSelect={onSelect}
                    noScroll
                    noKeyboardInteraction
                />
            </div>
        );
    }

    private dragStart(index: number) {
        document.addEventListener('mousemove', this.dragMove);
        document.addEventListener('mouseup', this.dragEnd);
        this.setState({ draggingIndex: index });
    }

    private dragMove = (event: MouseEvent) => {
        const { children, onReplace } = this.props;
        const { draggingIndex } = this.state;

        if (!onReplace) {
            return;
        }

        const realChildren = React.Children.toArray(children).filter((child) => !!child);

        for (let i = 0; i < realChildren.length; i++) {
            if (draggingIndex === i) {
                continue;
            }
            const currRefRect = (this.refs[`layer_${i}`] as HTMLDivElement).getBoundingClientRect();
            if (event.clientY >= currRefRect.top && event.clientY <= currRefRect.bottom) {
                onReplace(draggingIndex, i);
                this.setState({ draggingIndex: i });
                return;
            }
        }
    };

    private dragEnd = () => {
        this.setState({ draggingIndex: -1 });
        this.cleanDragEventListeners();
    };

    private cleanDragEventListeners() {
        document.removeEventListener('mousemove', this.dragMove);
        document.removeEventListener('mouseup', this.dragEnd);
    }

    private handleAddLayerClick = () => {
        const { onAdd } = this.props;

        if (this.state.addLayerMenuOpen || !onAdd) {
            return;
        }

        const { addOptions } = this.props;

        if (!addOptions || addOptions.length === 0) {
            onAdd();
            return;
        }

        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        this.setState({ addLayerMenuOpen: true });
    };

    private handleAddLayerMenuSelection = (id: string) => {
        const { onAdd } = this.props;

        onAdd && onAdd(id);

        this.cleanContextMenuEventListeners();
        this.setState({ addLayerMenuOpen: false });
    };

    private handleContextMenuOpen(contextMenuIndex: number) {
        if (this.state.contextMenuIndex !== -1) {
            return;
        }

        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        this.setState({ contextMenuIndex });
    }

    private handleContextMenuSelection(id: string, index: number, hidden: boolean) {
        switch (id) {
            case 'showHide':
                this.props.onHide && this.props.onHide(!hidden, index);
                break;
            case 'duplicate':
                this.props.onDuplicate && this.props.onDuplicate(index);
                break;
            case 'moveUp':
                this.props.onReplace && this.props.onReplace(index, index - 1);
                break;
            case 'moveDown':
                this.props.onReplace && this.props.onReplace(index, index + 1);
                break;
            case 'remove':
                this.props.onRemove && this.props.onRemove(index);
                break;
        }

        this.cleanContextMenuEventListeners();
        this.setState({ contextMenuIndex: -1 });
    }

    private getContextMenuStyle = () => {
        const { coordinations } = this.getContextMenuPosition();
        return {
            top: `calc(${coordinations.top}px - 11px)`,
            transform: `translateX(${coordinations.left}px)`,
        } as Partial<React.CSSProperties>;
    };

    private getContextMenuPosition = () => {
        const DEFAULT_POSITIONING = 9999;
        const { contextMenuIndex } = this.state;
        const position = {
            orientation: 'right',
            coordinations: { top: DEFAULT_POSITIONING, left: DEFAULT_POSITIONING },
        };

        const contextButtonRect = (
            this.refs[`contextButton_${contextMenuIndex}`] as HTMLSpanElement
        )?.getBoundingClientRect();
        if (!contextButtonRect) {
            console.warn('contextButtonRect is undefined, positioning contextMenu at default');
            return position;
        }

        position.coordinations.top = contextButtonRect.top;
        const margin = 4;
        const contextMenuWidth = 182 + margin;
        const contextButtonWidth = contextButtonRect.width + margin;
        const isOverflowingLeft = contextButtonRect.left + contextButtonWidth + contextMenuWidth > window.innerWidth;

        if (isOverflowingLeft) {
            position.coordinations.left = contextButtonRect.left - contextMenuWidth;
            position.orientation = 'left';
        } else {
            position.coordinations.left = contextButtonRect.left + contextButtonWidth;
            position.orientation = 'right';
        }
        return position;
    };

    private handleDocumentMouseDown = (event: MouseEvent) => {
        const { contextMenuIndex } = this.state;

        const contextButtonRef = this.refs[`contextButton_${contextMenuIndex}`] as HTMLSpanElement;
        const contextMenuRef = this.refs.contextMenu as HTMLDivElement;

        if (
            contextButtonRef &&
            !isInRect(event.clientX, event.clientY, contextButtonRef.getBoundingClientRect()) &&
            contextMenuRef &&
            !isInRect(event.clientX, event.clientY, contextMenuRef.getBoundingClientRect())
        ) {
            this.cleanContextMenuEventListeners();
            this.setState({ contextMenuIndex: -1, addLayerMenuOpen: false });
        }
    };

    private cleanContextMenuEventListeners() {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }
}
