import type { DeclarationMap } from '@wixc3/stylable-panel-drivers';

export type PropsToShorthandFunction = (value: DeclarationMap) => string | undefined;

export const propsToShorthandCorners: PropsToShorthandFunction = (value: DeclarationMap) => {
    const {
        [`border-top-left-radius`]: topLeft,
        [`border-top-right-radius`]: topRight,
        [`border-bottom-right-radius`]: bottomRight,
        [`border-bottom-left-radius`]: bottomLeft,
        [`border-radius`]: borderRadius,
    } = value;

    return borderRadius || `${topLeft || '0px'} ${topRight || '0px'} ${bottomRight || '0px'} ${bottomLeft || '0px'}`;
};
