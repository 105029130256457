import type { DeclarationMap } from '@wixc3/stylable-panel-drivers';

type CssRuleWithTag = CSSStyleRule & { quickChange?: boolean };

// TODO should this be a class? let that sink in
export interface StylesheetHelper {
    styleElement: HTMLStyleElement;
    update: (css: string) => void;
    destroy: () => void;
}
export const stylesheetHelper = (document: HTMLDocument, name?: string): StylesheetHelper => {
    const styleElement = document.createElement('style');
    if (name) {
        styleElement.setAttribute('data-name', name);
    }
    styleElement.setAttribute('id', 'componentStyle');
    document.head.appendChild(styleElement);
    return {
        styleElement,
        update: (css) => (styleElement.textContent = css),
        destroy: () => {
            document.head.removeChild(styleElement);
        },
    };
};

function normalizeCommas(source: string) {
    return source.replace(/\s*,\s*/g, ',');
}

export function getRules(cssStylesheet: CSSStyleSheet) {
    return cssStylesheet.cssRules || cssStylesheet.rules;
}

export const applyQuickChange = (styleElement: HTMLStyleElement, targetSelector: string, values: DeclarationMap) => {
    const cssStylesheet = styleElement.sheet as CSSStyleSheet;
    const normalizedTargetSelector = normalizeCommas(targetSelector);
    const rules = getRules(cssStylesheet);
    let newTargetIndex = rules.length;
    let targetRule: CssRuleWithTag | undefined;

    // Find matching rule:
    Array.from(rules).forEach((ruleset, index) => {
        const rulesetWithTag = ruleset as CssRuleWithTag;

        if (rulesetWithTag.type === rulesetWithTag.MEDIA_RULE) {
            return;
        }

        if (normalizeCommas(rulesetWithTag.selectorText) === normalizedTargetSelector && targetRule === undefined) {
            if (rulesetWithTag.quickChange) {
                // If found rule with quick change tag - use that rule
                targetRule = rulesetWithTag;
            } else {
                newTargetIndex = index + 1;
            }
        }
    });

    // insert invalid selector will break the editing flow.
    // wrap with try catch to allow the edit to be made.
    try {
        // If not found quickChange - create a new quick change rule
        if (targetRule === undefined) {
            // insert new rule after matching one (or at the end if match not found)
            const actualNewIndex = cssStylesheet.insertRule(`${targetSelector} { }`, newTargetIndex);
            targetRule = rules[actualNewIndex] as CSSStyleRule;
            targetRule.quickChange = true;
        }

        // Set changes on rule declarations:
        Object.keys(values).forEach((key: string) => {
            if (values[key] !== undefined && values[key] !== '') {
                targetRule!.style.setProperty(key, values[key]!);
            }
        });
    } catch (e) {
        console.warn(e);
    }
};
