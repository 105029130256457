import React from 'react';

import { Title, Option, DropDown, TabsItemSelection } from '@wixc3/stylable-panel-components';
import {
    SelectorConfigurationDriver,
    getTargetState,
    StylablePanelTranslationKeys,
} from '@wixc3/stylable-panel-drivers';
import { DEFAULT_PLANE, StylePanelPlane } from '@wixc3/stylable-panel-common';

import { ExtendedGlobalHost, PanelEventList, BIParams, getTranslate } from '@wixc3/stylable-panel-controllers';
import type { StateOverridesConfig } from './../../editor-panel/types';
import { Tooltip } from '@wixc3/stylable-panel-components';

import { style, classes } from './part-state-selector.st.css';

export const RESET_STATE_OVERRIDES_ACTION_ID = 'resetStateOverrides';

export interface PartStateSelectorProps {
    selector: string;
    rootSelector: string;
    states?: string[];
    selectorConfiguration: SelectorConfigurationDriver;
    panelHost?: ExtendedGlobalHost;
    plane?: StylePanelPlane;
    hideOnNoStates?: boolean;
    stateOverridesConfig?: StateOverridesConfig;
    handleStateOverrides?: () => void;
    onForceState?: (selector: string | null) => void;
    onSelect?: (selector: string) => void;
    reportBI?: (event: PanelEventList, params?: BIParams) => void;
    className?: string;
}

export default class PartStateSelector extends React.Component<PartStateSelectorProps> {
    public render() {
        const {
            rootSelector,
            states = [],
            selectorConfiguration,
            panelHost,
            plane = DEFAULT_PLANE,
            hideOnNoStates = false,
        } = this.props;

        if (hideOnNoStates && states.length === 0) {
            return null;
        }

        const translate = getTranslate(panelHost);

        // First one (Regular state):
        const options: Option[] = [
            {
                id: rootSelector,
                displayName: translate(StylablePanelTranslationKeys.states.regularLabel),
                // indentation: 1
            },
        ];

        // rest:
        states.forEach((state) => {
            // const highlight = (usedStates && usedStates.indexOf(state) !== -1) ? {indentation: 1} : {};
            const highlight = {};
            const stateName = selectorConfiguration.getStateName(rootSelector, state);
            const displayName = stateName !== state ? translate(stateName) : stateName;
            options.push({
                id: selectorConfiguration.getStateOverrideTransformed(rootSelector, state),
                displayName,
                ...highlight,
            });
        });

        return plane !== StylePanelPlane.Vertical
            ? this.renderDropDownStateSelector(options)
            : this.renderTabsStateSelector(options);
    }

    private renderDropDownStateSelector(options: Option[]) {
        const { selector, rootSelector, selectorConfiguration, panelHost, className } = this.props;

        const translate = getTranslate(panelHost);

        const elementStateDisplayKey =
            selectorConfiguration && selectorConfiguration.getElementStateDisplayKey(rootSelector);

        const { stateOverridesContent, stateOverridesDisabled, disabledTooltipText } = this.getStateOverridesData();

        return (
            <div className={style(classes.root, className)} data-aid="st_part_state_selector">
                {elementStateDisplayKey ? (
                    <Title className={classes.label} text={`${translate(elementStateDisplayKey)}:`} />
                ) : null}
                <div className={classes.dropdownContainer}>
                    <DropDown
                        className={classes.dropdown}
                        value={selector}
                        options={options}
                        onHover={this.handleDropDownHover}
                        onSelect={(state: string) => this.onSelect(state)}
                    />
                    <div className={classes.divider} />
                    <div
                        onClick={() => this.stateOverridesCallback()}
                        className={style(classes.stateOverridesControls, {
                            overridesDisabled: stateOverridesDisabled!,
                        })}
                    >
                        <Tooltip
                            className={style(classes.tooltip)}
                            disabled={disabledTooltipText}
                            text={stateOverridesContent}
                        >
                            <span className={style(classes.stateOverrideMenuLink)}>
                                <span
                                    className={style(classes.stateOverridesTrigger, {
                                        disabled: stateOverridesDisabled!,
                                    })}
                                ></span>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }

    private renderTabsStateSelector(options: Option[]) {
        const { selector, panelHost, plane = DEFAULT_PLANE, className } = this.props;

        const { stateOverridesContent, stateOverridesDisabled } = this.getStateOverridesData();
        const translate = getTranslate(panelHost);

        return (
            <div className={style(classes.root, { plane }, className)} data-aid="st_part_state_selector">
                <TabsItemSelection
                    className={classes.tabs}
                    items={options}
                    selectedItemId={selector}
                    actions={[
                        {
                            id: RESET_STATE_OVERRIDES_ACTION_ID,
                            displayName: stateOverridesContent || '',
                            disabled: stateOverridesDisabled,
                            callback: () => this.stateOverridesCallback(),
                        },
                    ]}
                    onSelectItem={(state: string) => this.onSelect(state)}
                    onHoverItem={this.handleDropDownHover}
                    translations={{
                        contextMenuTooltip: translate(StylablePanelTranslationKeys.states.viewMoreTooltip),
                    }}
                />
            </div>
        );
    }

    private getStateOverridesData() {
        const { stateOverridesConfig } = this.props;

        const { content, disabled } = stateOverridesConfig || { content: 'initial', disabled: true };
        const disabledTooltipText = stateOverridesConfig ? stateOverridesConfig.disabled : false;

        return {
            stateOverridesContent: content,
            stateOverridesDisabled: disabled,
            disabledTooltipText,
        };
    }

    private stateOverridesCallback() {
        const { stateOverridesConfig } = this.props;
        const { callback } = stateOverridesConfig!;
        callback && callback();
    }

    private handleDropDownHover = (selector: string | null) => {
        const { onForceState } = this.props;
        if (!onForceState) {
            return;
        }
        if (selector !== null) {
            onForceState(getTargetState(selector));
        } else {
            onForceState(selector);
        }
    };

    private onSelect(selector: string) {
        const { onSelect, reportBI, handleStateOverrides } = this.props;
        const { STATE_CLICK } = PanelEventList;
        onSelect && onSelect(selector);
        handleStateOverrides && handleStateOverrides();
        reportBI && reportBI(STATE_CLICK);
    }
}
