import React from 'react';
import keycode from 'keycode';

import { ContextTrigger } from '@wixc3/stylable-panel-drivers';
import type { ValueInputProps } from '../../../types';
import type { ValueInputComponent } from '../../../drivers';
import BaseInput from '../../base-input/base-input';
import { OptionList, optionsFromStrings } from '@wixc3/stylable-panel-components';

import { style, classes } from './context-input.st.css';

export interface ContextInputState {
    showSuggestions: boolean;
}

export class ContextInput extends React.Component<ValueInputProps, ContextInputState> implements ValueInputComponent {
    public static type = 'ContextInput';

    public state: ContextInputState = { showSuggestions: false };

    private contextTrigger: ContextTrigger | null = null;
    private baseInput: BaseInput | null = null;
    private optionList: OptionList | null = null;

    constructor(props: ValueInputProps) {
        super(props);
        this.setContextTrigger(this.props);
    }

    public input() {
        return this.baseInput && this.baseInput.input();
    }
    public focus() {
        if (this.baseInput) {
            this.baseInput.focus();
            this.showSuggestions();
        }
    }
    public blur() {
        if (this.baseInput) {
            this.baseInput.blur();
            this.hideSuggestions();
        }
    }
    public select() {
        this.baseInput && this.baseInput.select();
    }
    public suggestionsShown() {
        return !!(this.state.showSuggestions && this.optionList);
    }
    public suggestionsUsed() {
        return !!(this.state.showSuggestions && this.optionList && this.optionList.hasHovered());
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: ValueInputProps) {
        this.setContextTrigger(props);
    }

    public render() {
        const { value, sheetDriver, siteVarsDriver, className } = this.props;

        return (
            <span className={style(classes.root, className)} data-key={this.props['data-key']}>
                <BaseInput
                    className={classes.input}
                    value={value}
                    siteVarsDriver={siteVarsDriver}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    sheetDriver={sheetDriver}
                    ref={(ref) => (this.baseInput = ref)}
                />
                {this.state.showSuggestions && this.renderSuggestions()}
            </span>
        );
    }

    public componentWillUnmount() {
        this.cleanupEventListeners();
    }

    private setContextTrigger({ contexts }: ValueInputProps) {
        this.contextTrigger = contexts && contexts.length ? new ContextTrigger(contexts) : null;
    }

    private handleChange = (value: string) => {
        this.props.onChange && this.props.onChange(value);
        this.showSuggestions();
    };

    private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!this.state.showSuggestions && !event.altKey && event.keyCode === keycode('down')) {
            this.showSuggestions();
        }
        this.props.onKeyDown && this.props.onKeyDown(event);
    };

    private handleFocus = () => {
        const { value, onFocus } = this.props;

        this.select();
        !value && this.showSuggestions();
        onFocus && onFocus();
    };

    private handleBlur = () => {
        this.blur();
        this.props.onBlur && this.props.onBlur();
    };

    // TODO: Test
    private handleMouseDown = (event: MouseEvent) => {
        if (event.currentTarget instanceof Element) {
            const { id } = event.currentTarget;
            if (id.startsWith('option_')) {
                event.preventDefault();
            }
        }
    };

    private renderSuggestions() {
        const { value } = this.props;

        if (value === undefined || !this.contextTrigger) {
            return null;
        }

        const valueMatch = this.contextTrigger.match(value);
        if (valueMatch) {
            const renderReturn = [
                // TODO: Should moving between options in ContextInput apply them?
                <OptionList
                    key="suggestions"
                    className={classes.suggestions}
                    options={optionsFromStrings(valueMatch.suggestions)}
                    onSelect={(val: string) => this.handleSuggestionSelect(valueMatch.wrapper(val))}
                    onHover={(val: string | null) => val && this.handleSuggestionHover(valueMatch.wrapper(val))}
                    onClose={this.hideSuggestions}
                    ref={(ref) => (this.optionList = ref)}
                />,
            ];

            // if (valueMatch.title) {
            //     renderReturn = [(
            //         <div key="contextTitle" className={classes.contextTitle}>
            //             {valueMatch.title}
            //         </div>
            //     )].concat(renderReturn);
            // }

            return renderReturn;
        }

        return null;
    }

    private handleSuggestionSelect = (suggestion: string) => {
        this.props.onChange && this.props.onChange(suggestion);
        this.hideSuggestions();
    };

    private handleSuggestionHover = (_suggestion: string) => {
        //
    };

    private showSuggestions = () => {
        document.addEventListener('mousedown', this.handleMouseDown);
        this.setState({ showSuggestions: true });
    };

    private hideSuggestions = () => {
        this.cleanupEventListeners();
        this.setState({ showSuggestions: false });
    };

    private cleanupEventListeners() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    }
}
