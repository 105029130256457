import React from 'react';

import { NumberUnitInput, UnitEditInfo } from '@wixc3/stylable-panel-components';

import type { CustomInputProps } from '../drivers';

import { style, classes } from './number-unit-factory.st.css';

export function NumberUnitFactory(
    units: Record<string, UnitEditInfo>,
    inlineUnits?: string
): React.ComponentClass<CustomInputProps> {
    class NumberUnitController extends React.Component<CustomInputProps> {
        public render() {
            const { value, className } = this.props;

            return (
                <NumberUnitInput
                    className={style(classes.root, className)}
                    {...this.props}
                    units={units}
                    value={value || ''}
                    inlineUnits={inlineUnits}
                    inlineSlider
                />
            );
        }
    }

    return NumberUnitController;
}
