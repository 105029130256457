import { PresetSectionLayouts, StylePanelPage, EditorComponentConfig } from '@wixc3/stylable-panel-common';

import type { DeclarationMap, ElementTree } from './types';
import type { StylableDriver } from './stylable-driver';
import type { StylableChangeSelectors, SimpleChangeAction, PartialChange } from './stylable-change-selectors';
import { PresetDriver, Preset } from './preset-driver';
import { SelectorConfigurationDriver, stripStates } from './selector-configuration-driver';
import { StylablePanelTranslationKeys } from './stylable-panel-translation-keys';
import { elementTreeSelectors } from './utils/controller-part-utils';

const START_PAGE = StylePanelPage.CustomizationPanel;

export interface Store {
    states: string[];
    usedStates: string[];
}

export class ComponentEditSession {
    public elements: ElementTree;
    public presets: Preset[];
    public selector: string;
    public overrideStateSelector: string;
    public controllerPartTypes: Record<string, string[]>;
    public defaultPreviewProps: Record<string, string>;
    public presetDriver: PresetDriver;
    public componentName: string;
    public presetSectionLayouts: PresetSectionLayouts;
    public selectorConfiguration: SelectorConfigurationDriver;
    public page: StylePanelPage;
    public computed!: Store;

    constructor(
        public siteStylesheetPath: string,
        public stylesheetPath: string,
        public variant: string,
        public componentConfig: EditorComponentConfig,
        public stylableDriver: StylableDriver,
        public changeDriver: StylableChangeSelectors,
        public presetStylesheetPaths?: string[],
        public aggregationPaths?: string[]
    ) {
        this.presets = componentConfig.presets || [];

        this.selector = `.${variant}`;
        this.overrideStateSelector = `.${variant}`;

        this.defaultPreviewProps = componentConfig.previewProps || {};
        this.componentName = this.componentConfig.id;
        this.elements = stylableDriver.getPseudoElementsDeep(this.stylesheetPath, this.selector);

        this.controllerPartTypes = elementTreeSelectors(this.elements).reduce((controllerPartTypes, selector) => {
            const controllerPartType = stylableDriver.getControllerPartType(this.stylesheetPath, selector);
            if (controllerPartType) {
                controllerPartTypes[selector] = controllerPartType
                    .replace(/^"(.+(?="$))"$/, '$1') // remove surrounding quotes
                    .split(',')
                    .map((partType) => partType.trim());
            }
            return controllerPartTypes;
        }, {} as Record<string, string[]>);

        this.presetDriver = new PresetDriver({
            stylableDriver,
            componentName: this.componentName,
            presets: this.presets,
            sourceClassName: this.variant,
            sheetPath: this.stylesheetPath,
            presetSheetPaths: presetStylesheetPaths,
            snapshots: this.componentConfig.snapshots,
        });

        this.selectorConfiguration = new SelectorConfigurationDriver(
            componentConfig.selectorConfiguration || {},
            this.selector,
            {
                hover: { nameKey: StylablePanelTranslationKeys.states.native.hoverLabel },
                focus: { nameKey: StylablePanelTranslationKeys.states.native.focusLabel },
                disabled: { nameKey: StylablePanelTranslationKeys.states.native.disabledLabel },
                active: { nameKey: StylablePanelTranslationKeys.states.native.activeLabel },
            }
        );

        if (this.componentConfig.initialElement) {
            this.selector = this.selectorConfiguration.selectorToVariant(this.componentConfig.initialElement);
        }

        const stylesheet = this.getStylesheet();
        if (stylesheet) {
            stylableDriver.stylable.transform(stylesheet.getMeta());
        }
        this.updateComputed();

        this.presetSectionLayouts = componentConfig.presetSectionLayouts || {
            static: {},
            theme: {},
            user: {},
        };

        this.changeDriver.openSession(this);

        this.page = START_PAGE;
    }

    public getSourceCSS() {
        const sheetDriver = this.getStylesheet();
        return (sheetDriver && sheetDriver.source) || '';
    }

    /**
     *
     * @param selector to change to
     * @return true if state was updated
     */
    public setSelector(selector: string): boolean {
        const oldSelector = this.selector;
        const oldOverrideStateSelector = this.overrideStateSelector;
        this.selector = selector;
        this.updateComputed();
        this.updateStateSelector(oldSelector, oldOverrideStateSelector);
        return !(oldOverrideStateSelector === this.overrideStateSelector);
    }

    public updateComputed() {
        this.updateOverrideStateSelector();
        const sheetDriver = this.getStylesheet();
        if (!sheetDriver) {
            this.computed = { states: [], usedStates: [] };
            return;
        }

        this.computed = {
            states: sheetDriver.getVariantStates(this.overrideStateSelector),
            usedStates: sheetDriver.getVariantUsedStates(this.overrideStateSelector),
        };
    }

    public getSelectorWithoutStates() {
        return stripStates(this.selector);
    }

    public getStylesheet() {
        return this.stylableDriver.getStylesheet(this.stylesheetPath);
    }

    public getSelectorDeclarations(selector: string) {
        return this.stylableDriver.aggregateSelectorDeclarations(
            [this.stylesheetPath, ...(this.aggregationPaths || [])],
            this.selectorConfiguration.selectorToVariant(selector)
        );
    }

    public setSelectorDeclarations(selectorOrActions: string | SimpleChangeAction[], declarations?: DeclarationMap) {
        const simpleChangeActions: SimpleChangeAction[] =
            typeof selectorOrActions === 'string' ? [{ selector: selectorOrActions, declarations }] : selectorOrActions;

        this.changeDriver.changeSelectors(
            simpleChangeActions.map((simpleChangeAction) => ({
                values: simpleChangeAction.declarations,
                selector: this.selectorConfiguration.selectorToVariant(simpleChangeAction.selector),
            }))
        );
    }

    public applyChangesToStylesheets(requestedChange: PartialChange[]) {
        this.changeDriver.applyStylesheetChange(requestedChange);
    }

    private updateOverrideStateSelector() {
        this.overrideStateSelector = this.selectorConfiguration.getPrimeElement(this.getSelectorWithoutStates());
    }

    private updateStateSelector(oldSelector: string, oldOverrideStateSelector: string) {
        if (oldOverrideStateSelector === this.overrideStateSelector) {
            const stateMatch = oldSelector.match(/[^:]:([^:]+)/);
            if (stateMatch && stateMatch[1]) {
                this.selector = this.getSelectorWithoutStates().replace(
                    this.overrideStateSelector,
                    `${this.overrideStateSelector}:${stateMatch[1]}`
                );
                return false;
            }
        }
        return;
    }
}
