/* eslint-disable react/no-string-refs */
import React from 'react';

import { HSVColor, colorFromHSVColor } from './color-picker-utils';

import { style, classes } from './freeform-picker.st.css';

const DEFAULT_BRIGHTNESS_SELECTORS_AMOUNT = 5;

export interface FreeformPickerProps {
    color: HSVColor;
    brightnessSelectors?: number;
    onChange?: (value: HSVColor) => void;
    className?: string;
    style?: React.CSSProperties;
}

export interface FreeformPickerState {
    hueDragging: boolean;
}

export default class FreeformPicker extends React.Component<FreeformPickerProps, FreeformPickerState> {
    public readonly state: FreeformPickerState = { hueDragging: false };

    public render() {
        const { className, style: propStyle } = this.props;

        return (
            <div className={style(classes.root, className)} style={propStyle}>
                <div className={classes.saturationBrightnessWrapper}>
                    {this.renderSaturationPicker()}
                    {this.renderBrightnessPicker()}
                </div>
                {this.renderHuePicker()}
            </div>
        );
    }

    public componentWillUnmount() {
        this.cleanSaturationMouseEventListeners();
        this.cleanHueMouseEventListeners();
    }

    private renderSaturationPicker() {
        const {
            color: { hue, saturation, value },
        } = this.props;

        return (
            <div
                className={classes.saturationPicker}
                style={{ backgroundColor: `hsl(${hue}, 100%, 50%)` }}
                onMouseDown={this.handleSaturationPickerMouseDown}
                ref="saturationPicker"
            >
                <div className={classes.saturationLayer} />
                <div
                    className={style(classes.saturationPointer, { dark: value < 60 })}
                    style={{
                        left: `${saturation}%`,
                        top: `${100 - value}%`,
                    }}
                />
            </div>
        );
    }

    private renderBrightnessPicker() {
        const {
            color: { hue, saturation, value },
            brightnessSelectors = DEFAULT_BRIGHTNESS_SELECTORS_AMOUNT,
            onChange,
        } = this.props;

        return (
            <div className={classes.brightnessPicker}>
                {Array(brightnessSelectors)
                    .fill('')
                    .map((_value, index) => {
                        const percentageSlice = 100 / brightnessSelectors;
                        const newValue = 100 - (index * percentageSlice + percentageSlice / 2);
                        const newColor: HSVColor = { hue, saturation, value: newValue };

                        return (
                            <div
                                key={`brightness_selector_${index}`}
                                className={style(classes.brightnessSelector, { selected: value === newValue })}
                                style={{
                                    height: `${100 / brightnessSelectors}%`,
                                    backgroundColor: colorFromHSVColor(newColor).hex(),
                                }}
                                onClick={() => onChange && onChange(newColor)}
                            />
                        );
                    })}
            </div>
        );
    }

    private renderHuePicker() {
        const {
            color: { hue },
        } = this.props;
        const { hueDragging } = this.state;

        return (
            <div className={classes.huePicker} onMouseDown={this.handleHuePickerMouseDown} ref="huePicker">
                <div
                    className={style(classes.huePointer, { dragging: hueDragging })}
                    style={{ left: `${(hue * 100) / 360}%` }}
                />
            </div>
        );
    }

    private handleSaturationPickerMouseDown = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        this.changeSaturation(event);
        document.addEventListener('mousemove', this.changeSaturation);
        document.addEventListener('mouseup', this.cleanSaturationMouseEventListeners);
    };

    private cleanSaturationMouseEventListeners = () => {
        document.removeEventListener('mousemove', this.changeSaturation);
        document.removeEventListener('mouseup', this.cleanSaturationMouseEventListeners);
    };

    private handleHuePickerMouseDown = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        this.changeHue(event);
        document.addEventListener('mousemove', this.changeHue);
        document.addEventListener('mouseup', this.endHueDrag);
        this.setState({ hueDragging: true });
    };

    private endHueDrag = () => {
        this.cleanHueMouseEventListeners();
        this.setState({ hueDragging: false });
    };

    private cleanHueMouseEventListeners() {
        document.removeEventListener('mousemove', this.changeHue);
        document.removeEventListener('mouseup', this.endHueDrag);
    }

    private changeSaturation = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        const { color, onChange } = this.props;
        event.preventDefault();

        if (!onChange) {
            return;
        }

        const { left, top, width, height } = (this.refs.saturationPicker as HTMLDivElement).getBoundingClientRect();
        onChange({
            hue: color.hue,
            saturation: relativePositionInRect(event.pageX, left, width) * 100,
            value: (1 - relativePositionInRect(event.pageY, top, height)) * 100,
        });
    };

    private changeHue = (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        const { color, onChange } = this.props;
        event.preventDefault();

        if (!onChange) {
            return;
        }

        const { left, width } = (this.refs.huePicker as HTMLDivElement).getBoundingClientRect();
        onChange({
            hue: relativePositionInRect(event.pageX, left, width) * 360,
            saturation: color.saturation,
            value: color.value,
        });
    };
}

function relativePositionInRect(coord: number, rectStart: number, rectSize: number) {
    const constrainedCoord = Math.min(Math.max(coord, rectStart), rectStart + rectSize);
    return (constrainedCoord - rectStart) / rectSize;
}
