import type { ArchetypeList } from '@wixc3/stylable-panel-drivers';

// prop groups
export const backgroundProps = [
    'background',
    'background-color',
    'background-image',
    'background-position',
    'background-position-x',
    'background-position-y',
    'background-size',
    'background-repeat',

    'background-origin',
    'background-attachment',
    'background-blend-mode',
    'background-clip',
    // "isolation",
    // "mask",
    // "mask-type",
    // "mask-clip",
    // "mask-origin",
    // "mix-blend-mode",
];

export const borderProps = [
    'border',
    'border-style',
    'border-bottom-style',
    'border-left-style',
    'border-right-style',
    'border-bottom',
    'border-top-style',
    'border-color',
    'border-bottom-color',
    'border-bottom-width',
    'border-left',
    'border-left-color',
    'border-left-width',
    'border-right',
    'border-right-color',
    'border-right-width',
    'border-spacing',
    'border-top',
    'border-top-color',
    'border-top-width',
    'border-width',
];

export const outlineProps = ['outline', 'outline-style', 'outline-color', 'outline-width', 'outline-offset'];

export const borderImageProps = [
    'border-image',
    'border-image-outset',
    'border-image-repeat',
    'border-image-slice',
    'border-image-source',
    'border-image-width',
];

export const borderWithImageProps = borderProps.concat(borderImageProps);

export const marginProps = ['margin', 'margin-top', 'margin-bottom', 'margin-left', 'margin-right'];

export const paddingProps = ['padding', 'padding-top', 'padding-bottom', 'padding-left', 'padding-right'];

export const layoutDisplayProps = ['flex-direction', 'justify-content', 'align-items'];

export const layoutProps = layoutDisplayProps.concat(paddingProps);

export const spacingProps = marginProps.concat(paddingProps);

export const cornerProps = [
    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
];

export const animationProps = [
    'animation',
    'animation-delay',
    'animation-direction',
    'animation-duration',
    'animation-fill-mode',
    'animation-iteration-count',
    'animation-name',
    'animation-play-state',
    'animation-timing-function',
];

export const transitionProps = [
    'transition',
    'transition-delay',
    'transition-duration',
    'transition-property',
    'transition-timing-function',
];

export const textProps = [
    'font',
    'list-style',
    'caret-color',
    'direction',
    'font-family',
    'font-size',
    'font-style',
    'font-kerning',
    'font-feature-settings',
    'font-language-override',
    'font-size-adjust',
    'font-stretch',
    'font-synthesis',
    'font-variant',
    'font-variant-alternates',
    'font-variant-caps',
    'font-variant-east-asian',
    'font-variant-ligatures',
    'font-variant-numeric',
    'font-variant-position',
    'font-weight',
    'hanging-punctuation',
    'hyphens',
    'letter-spacing',
    'line-height',
    // 'outline',
    'list-style-image',
    'list-style-position',
    'list-style-type',
    // 'outline-color',
    // 'outline-offset',
    // 'outline-style',
    // 'outline-width',
    'quotes',
    'text-align',
    'text-align-last',
    'text-combine-upright',
    'text-decoration-color',
    'text-decoration-line',
    'text-decoration-style',
    'text-emphasis',
    'text-emphasis-color',
    'text-emphasis-position',
    'text-emphasis-style',
    'text-indent',
    'text-justify',
    'text-orientation',
    'text-overflow',
    'text-rendering',
    'text-transform',
    'text-underline-position',
    'text-decoration',
    'text-shadow',
];

const boxProps = [
    backgroundProps,
    'isolation',
    'mask',
    'mask-type',
    'mask-clip',
    'mask-origin',
    'mix-blend-mode',
    'object-fit',
    'object-position',
    'filter',
    'box-shadow',
    'opacity',
    borderProps,
    borderImageProps,
    outlineProps,
    cornerProps,
    // spacingProps,
    animationProps,
    transitionProps,
];

const iconProps = ['width', 'height', 'fill', 'transform'];

const iconPropsBlacklist = [/^fill$/, /^width$/, /^height$/];

const layoutDisplayPropsBlacklist = [/^flex.*/, /^justify-content$/, /^align-items$/];

const paddingPropsBlacklist = [/^padding.*/];

export const setupArchetypeList: () => ArchetypeList = (): ArchetypeList => {
    return {
        default: {
            props: [
                backgroundProps,
                borderProps,
                borderImageProps,
                outlineProps,
                cornerProps,
                layoutProps,
                // spacingProps,
                animationProps,
                transitionProps,
                'box-shadow',
            ],
        },
        line: {
            props: [
                animationProps,
                transitionProps,
                borderProps,
                borderImageProps,
                'filter',
                'opacity',
                'width',
                'height',
                'box-shadow',
            ],
            preferred: 'borders',
        },
        box: {
            props: [...boxProps, layoutProps],
            propBlacklist: [...iconPropsBlacklist],
            preferred: 'fill',
        },
        paddingBox: {
            props: [...boxProps, paddingProps],
            propBlacklist: [...iconPropsBlacklist, ...layoutDisplayPropsBlacklist],
            preferred: 'fill',
        },
        paintBox: {
            props: [...boxProps],
            propBlacklist: [...iconPropsBlacklist, ...layoutDisplayPropsBlacklist, ...paddingPropsBlacklist],
            preferred: 'fill',
        },
        text: {
            props: [
                'color',
                textProps,
                // 'unicode-bidi',
                // 'word-break',
                // 'word-spacing',
                // 'word-wrap',
                // animationProps,
                // 'opacity',
                // transitionProps,
                // borderProps,
                // borderImageProps,
                // outlineProps,
            ],
            propBlacklist: [/^background/],
            preferred: 'text',
            // blockVariantSets: {
            //     borders: [
            //         {},
            //         {'border-style': 'solid', 'border-width': '0 0 2px 0', 'border-image-source': 'linear-gradient(to left, value(site_3_1), value(site_3_3))', 'border-image-slice': '1'},
            //         {'border-style': 'solid', 'border-width': '0 0 2px 0', 'border-image-source': 'linear-gradient(to left, value(site_2_1), value(site_2_2))', 'border-image-slice': '1'},
            //         {'border-style': 'solid', 'border-width': '0 0 2px 0', 'border-image-source': 'linear-gradient(to left, value(site_4_1), value(site_4_3))', 'border-image-slice': '1'},
            //         {'border-style': 'solid', 'border-width': '0 0 7px 0', 'border-image-source': 'linear-gradient(to left, value(site_4_1), value(site_4_3))', 'border-image-slice': '1'},
            //         {'border-style': 'ridge', 'border-width': '0 0 7px 0', 'border-color': 'value(site_5_2)'},
            //         {'border-style': 'groove', 'border-width': '0 0 7px 0', 'border-color': 'value(site_2_2)'},
            //         {'border-style': 'solid', 'border-width': '0 0 1px 0', 'border-color': 'value(site_2_1)'},
            //         {'border-style': 'solid', 'border-width': '0 0 2px 0', 'border-color': 'value(site_3_2)'},
            //         {'border-style': 'double', 'border-width': '0 0 4px 0', 'border-color': 'value(site_5_3)'},
            //         {'border-style': 'solid', 'border-width': '0 0 8px 0', 'border-color': 'value(site_2_2)'},
            //         {'border-style': 'dashed', 'border-width': '0 0 1px 0', 'border-color': 'value(site_2_3)'},
            //         {'border-style': 'dotted', 'border-width': '0 0 2px 0', 'border-color': 'value(site_3_2)'},
            //     ]
            // }
        },
        icon: {
            props: [...iconProps],
            preferred: 'icon',
        },
    };
};
