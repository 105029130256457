import type { ValueInputDriver } from '@wixc3/stylable-panel-controllers';
import type { SiteVarsDriver, StylableDriver, StylesheetDriver } from '@wixc3/stylable-panel-drivers';
import React, { createContext, FC, useEffect } from 'react';
import { VariantEditor } from '../panels/css-panel';
import { useForceUpdate } from '../utils/hooks/use-force-update';
import { classes, style } from './css-panel.st.css';

export interface CSSPanelProps {
    stylableDriver: StylableDriver;
    siteVarsDriver?: SiteVarsDriver;
    valueInputDriver: ValueInputDriver;
    componentName?: string;
    sheetPath: string;
    variant: string;
    className?: string;
}

type CSSPanelContext = Pick<CSSPanelProps, 'stylableDriver' | 'valueInputDriver'> & {
    stylableSheet: StylesheetDriver | null;
};

function getStylableSheet({ sheetPath, stylableDriver }: Pick<CSSPanelProps, 'sheetPath' | 'stylableDriver'>) {
    const stylableSheet = stylableDriver.getStylesheet(sheetPath);
    if (!stylableSheet) {
        throw new Error('Panel must be provided with an existing stylesheet');
    }
    return stylableSheet;
}

export const CSSPanelContext = createContext<Partial<CSSPanelContext>>({});
export const CssPanelContextProvider: FC<CSSPanelContext> = ({
    stylableDriver,
    valueInputDriver,
    stylableSheet,
    children,
}) => (
    <CSSPanelContext.Provider value={{ stylableDriver, valueInputDriver, stylableSheet }}>
        {children}
    </CSSPanelContext.Provider>
);

export const CSSPanel: FC<CSSPanelProps> = ({
    siteVarsDriver,
    componentName,
    variant,
    className,
    stylableDriver,
    valueInputDriver,
    sheetPath,
}) => {
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        stylableDriver.registerBuildHook(forceUpdate);
        // Check if need to unregister in the callback return
    }, [forceUpdate, stylableDriver]);

    const stylableSheet = getStylableSheet({ stylableDriver, sheetPath });

    return (
        <CssPanelContextProvider
            stylableDriver={stylableDriver}
            stylableSheet={stylableSheet}
            valueInputDriver={valueInputDriver}
        >
            <VariantEditor
                key={`variant_editor_${componentName || ''}`}
                className={style(classes.root, className)}
                siteVarsDriver={siteVarsDriver}
                name={variant}
            />
        </CssPanelContextProvider>
    );
};
