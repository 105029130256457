/* eslint-disable react/no-string-refs */
import React from 'react';
import keycode from 'keycode';

import type { SRule, StylableSymbol, ImportSymbol, ClassSymbol } from '@stylable/core';
import type { ValueInputProps } from '../../../types';
import type { ValueInputComponent } from '../../../drivers';

import { ContextInput } from '../context-input/context-input';
import BaseInput from '../../base-input/base-input';

import { style, classes } from './mixin-input.st.css';

export interface MixinParameter {
    name: string;
    value: string;
}

export interface Mixin {
    name: string;
    type: string;
    parameters: MixinParameter[];
    availableOverrides: string[];
}

export interface MixinInputState {
    expandedMixins: boolean[];
    mixins: Mixin[];
}

function getNameRef(mixinIndex: number) {
    return `mixin_${mixinIndex}_name`;
}

function getAddOverrideRef(mixinIndex: number) {
    return `mixin_${mixinIndex}_add`;
}

function getParamNameRef(mixinIndex: number, paramIndex: number) {
    return `mixin_${mixinIndex}_param_${paramIndex}_name`;
}

function getParamValueRef(mixinIndex: number, paramIndex: number) {
    return `mixin_${mixinIndex}_param_${paramIndex}_value`;
}

// TODO: Add, remove and modify mixins usage
export class MixinInput extends React.Component<ValueInputProps, MixinInputState> implements ValueInputComponent {
    public static type = 'MixinInput';

    public state: MixinInputState = {
        mixins: [],
        expandedMixins: [],
    };

    private focusRef = '';

    public focus() {
        const firstMixinNameRef = this.refs[getNameRef(0)] as ContextInput;
        firstMixinNameRef && firstMixinNameRef.focus();
    }
    public select() {
        const firstMixinNameRef = this.refs[getNameRef(0)] as ContextInput;
        firstMixinNameRef && firstMixinNameRef.select();
    }
    public blur() {
        //
    }
    public suggestionsShown() {
        return false;
    }
    public suggestionsUsed() {
        return false;
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillMount() {
        const refedMixins = this.getValueMixins();
        if (refedMixins) {
            const mixins = refedMixins.map((refedMixin) =>
                this.collectMixinData(refedMixin.mixin.type, refedMixin.ref, refedMixin.mixin.options)
            );

            this.setState({ mixins });
        }
    }

    // TODO: Suggestions for mixin names
    public render() {
        const { siteVarsDriver, className } = this.props;
        const { mixins, expandedMixins } = this.state;

        return (
            <div className={style(classes.root, className)}>
                {mixins.length ? (
                    mixins.map((mixin, index) => {
                        const expandable =
                            mixin.name &&
                            mixin.type &&
                            (mixin.type === 'class' ? !!mixin.availableOverrides.length : true);
                        const expanded = expandable && expandedMixins[index];
                        return (
                            <div
                                key={`mixin_${index}`}
                                className={style(classes.mixinLine, { expanded })}
                                onClick={(event: React.MouseEvent<HTMLDivElement>) => this.addMixin(index + 1, event)}
                            >
                                {expandable && (
                                    <button
                                        data-key={index}
                                        className={classes.expandArrow}
                                        onClick={(event: React.MouseEvent<HTMLSpanElement>) =>
                                            this.toggleMixinExpand(index, event)
                                        }
                                    />
                                )}
                                <ContextInput
                                    className={classes.mixinName}
                                    data-key={index}
                                    value={mixin.name}
                                    siteVarsDriver={siteVarsDriver}
                                    onChange={(value: string) => this.handleMixinNameChange(index, value)}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                                        this.handleMixinNameKeyDown(index, event)
                                    }
                                    onBlur={() => this.deleteEmptyMixin(index)}
                                    ref={getNameRef(index)}
                                />
                                <span className={classes.mixinParameters}>{this.printMixin(mixin)}</span>
                                {expanded && this.renderPropertyPanel(index)}
                            </div>
                        );
                    })
                ) : (
                    <button
                        className={classes.emptyPrompt}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.addMixin(0, event)}
                    >
                        Add new mixin
                    </button>
                )}
            </div>
        );
    }

    public componentDidUpdate() {
        if (this.focusRef) {
            (this.refs[this.focusRef] as ContextInput | HTMLButtonElement).focus();
            this.focusRef = '';
        }
    }

    private getValueMixins() {
        const { sheetDriver, value } = this.props;
        if (!sheetDriver) {
            return [];
        }

        const ast = sheetDriver.getMeta().ast;

        let foundRule: SRule | undefined;
        ast.walkDecls('-st-mixin', (decl) => {
            if (foundRule) {
                return;
            }
            if (decl.value === value) {
                foundRule = decl.parent as SRule;
                return;
            }
        });

        if (!foundRule) {
            return [];
        }
        return foundRule.mixins || [];
    }

    private collectMixinData(
        name: string,
        symbol: StylableSymbol | ImportSymbol | ClassSymbol,
        options: Array<{ value: string }> | Record<string, string>
    ) {
        const { stylableDriver, sheetDriver } = this.props;
        if (!stylableDriver || !sheetDriver) {
            return { name, type: '', parameters: [], availableOverrides: [] } as Mixin;
        }

        const type = sheetDriver.resolveSymbolType(symbol);

        let availableOverrides: string[] = [];
        let parameters: MixinParameter[] = [];
        if (type === 'class') {
            parameters = Object.keys(options).map(
                (optionName) =>
                    ({
                        name: optionName,
                        value: (options as any)[optionName],
                    } as MixinParameter)
            );
            availableOverrides = stylableDriver.getCSSMixinOverrides(sheetDriver.getMeta().source, name);
        } else {
            parameters = (options as Array<{ value: string }>).map(
                ({ value }, index) => ({ name: `$${index + 1}`, value } as MixinParameter)
            );
        }

        return { name, type, parameters, availableOverrides } as Mixin;
    }

    private handleMixinNameChange(index: number, name: string) {
        const { sheetDriver } = this.props;
        if (!sheetDriver) {
            return;
        }

        const mixins = this.state.mixins;
        const symbol = sheetDriver.getSymbol(name);
        if (!symbol) {
            mixins[index] = { name, type: '', parameters: [], availableOverrides: [] };
            this.setState({ mixins });
            return;
        }

        mixins[index] = this.collectMixinData(name, symbol, []);
        this.changeMixins(mixins);
    }

    private addMixin(index: number, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) {
        const mixins = this.state.mixins;

        mixins.splice(index, 0, { name: '', type: '', parameters: [], availableOverrides: [] });
        this.focusRef = getNameRef(index);

        event.stopPropagation();
        this.setState({ mixins });
    }

    private deleteEmptyMixin(index: number) {
        const mixins = this.state.mixins;

        if (!mixins[index].name) {
            mixins.splice(index, 1);
            this.changeMixins(mixins);
        }
    }

    private handleMixinNameKeyDown(index: number, event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === keycode('esc')) {
            (this.refs[getNameRef(index)] as ContextInput).blur();
            event.preventDefault();
            return;
        }

        const { mixins, expandedMixins } = this.state;

        if (event.keyCode === keycode('enter')) {
            expandedMixins[index] = true;
            this.focusRef = mixins[index].parameters.length ? getParamNameRef(index, 0) : getAddOverrideRef(index);
            this.setState({ expandedMixins });
            return;
        }

        if (!event.altKey || !(event.keyCode === keycode('up') || event.keyCode === keycode('down'))) {
            return;
        }

        if (
            (event.keyCode === keycode('up') && index === 0) ||
            (event.keyCode === keycode('down') && index === mixins.length - 1)
        ) {
            return;
        }

        event.preventDefault();

        const movedMixin = mixins.splice(index, 1)[0];
        let moveIndex = index + 1;
        if (event.keyCode === keycode('up')) {
            moveIndex = index - 1;
        }

        mixins.splice(moveIndex, 0, movedMixin);
        const originalExpanded = expandedMixins[index];
        expandedMixins[index] = expandedMixins[moveIndex];
        expandedMixins[moveIndex] = originalExpanded;

        this.focusRef = getNameRef(moveIndex);
        this.changeMixins(mixins);
    }

    private renderPropertyPanel(index: number) {
        const mixin = this.state.mixins[index];

        if (!mixin.parameters.length) {
            return (
                <button
                    className={style(classes.emptyPrompt, classes.propertyPanelS)}
                    data-key={index}
                    ref={getAddOverrideRef(index)}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.addParameter(index, 0, event)}
                >
                    Add new override
                </button>
            );
        }

        const suggestions =
            mixin.type === 'class'
                ? mixin.availableOverrides.filter(
                      (override) => !mixin.parameters.find((parameter) => parameter.name === override)
                  )
                : [];

        const { siteVarsDriver } = this.props;

        return (
            <div className={style(classes.propertyPanel, classes.propertyPanelS)} data-key={index}>
                {mixin.parameters.map((parameter, paramIndex) => (
                    <div
                        className={classes.propLine}
                        data-key={paramIndex}
                        key={`mixin_${index}_${paramIndex}`}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            this.addParameter(index, paramIndex + 1, event)
                        }
                    >
                        <ContextInput
                            className={classes.paramName}
                            data-key={paramIndex}
                            value={parameter.name}
                            siteVarsDriver={siteVarsDriver}
                            contexts={[{ suggestions }]}
                            onChange={(name: string) => this.handleParamNameChange(index, paramIndex, name)}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                                this.handleParamNameKeyDown(index, paramIndex, event)
                            }
                            onBlur={() => this.deleteEmptyParam(index, paramIndex)}
                            ref={getParamNameRef(index, paramIndex)}
                        />
                        <span className={classes.specialChar}>: </span>
                        <BaseInput
                            className={classes.paramValue}
                            data-key={paramIndex}
                            value={parameter.value}
                            siteVarsDriver={siteVarsDriver}
                            onChange={(value: string) => this.handleParamValueChange(index, paramIndex, value)}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                                this.handleParamValueKeyDown(index, paramIndex, event)
                            }
                            onBlur={() => this.deleteEmptyParam(index, paramIndex)}
                            ref={getParamValueRef(index, paramIndex)}
                            data-automation-id={`MIXIN_${index}_PARAM_${paramIndex}_VALUE`}
                        />
                    </div>
                ))}
            </div>
        );
    }

    private addParameter(
        mixinIndex: number,
        paramIndex: number,
        event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
    ) {
        if (event) {
            event.stopPropagation();
            const id = (event.target as Element).id;
            if (id && id.startsWith('option_')) {
                return;
            }
        }

        const mixins = this.state.mixins;
        const type = mixins[mixinIndex].type;
        if (type === 'class') {
            const usedOverrides = mixins[mixinIndex].parameters.map((parameter) => parameter.name);
            if (mixins[mixinIndex].availableOverrides.every((override) => !!~usedOverrides.indexOf(override))) {
                return;
            }
        }

        let name = '';
        let focusRef = getParamNameRef(mixinIndex, paramIndex);
        if (type !== 'class') {
            name = `$${paramIndex + 1}`;
            focusRef = getParamValueRef(mixinIndex, paramIndex);
        }

        mixins[mixinIndex].parameters.splice(paramIndex, 0, { name, value: '' });
        this.focusRef = focusRef;
        this.setState({ mixins });
    }

    private handleParamNameChange(mixinIndex: number, paramIndex: number, name: string) {
        if (~name.indexOf(':')) {
            this.focusRef = getParamValueRef(mixinIndex, paramIndex);
        }
        const filteredName = name.replace(/[^a-z0-9-]*/gi, '');
        const mixins = this.state.mixins;

        mixins[mixinIndex].parameters[paramIndex].name = filteredName;
        this.changeMixins(mixins);
    }

    private handleParamValueChange(mixinIndex: number, paramIndex: number, value: string) {
        const filteredValue = value.replace(/[:;,]/g, '');
        const mixins = this.state.mixins;

        mixins[mixinIndex].parameters[paramIndex].value = filteredValue;
        this.changeMixins(mixins);
    }

    private changeMixins(mixins: Mixin[], expandedMixins?: boolean[]) {
        const { onChange } = this.props;

        if (expandedMixins) {
            this.setState({ mixins, expandedMixins });
        } else {
            this.setState({ mixins });
        }
        onChange && onChange(mixins.map((mixin) => mixin.name + this.printMixin(mixin)).join(', '));
    }

    private handleParamNameKeyDown(
        mixinIndex: number,
        paramIndex: number,
        event: React.KeyboardEvent<HTMLInputElement>
    ) {
        const nameRef = this.refs[getParamNameRef(mixinIndex, paramIndex)] as ContextInput;
        const valueRef = this.refs[getParamValueRef(mixinIndex, paramIndex)] as BaseInput;

        switch (event.keyCode) {
            case keycode('enter'):
                !nameRef.suggestionsUsed() && valueRef.focus();
                break;
            case keycode('esc'):
                !nameRef.suggestionsShown() && nameRef.blur();
                break;
        }
    }

    private handleParamValueKeyDown(
        mixinIndex: number,
        paramIndex: number,
        event: React.KeyboardEvent<HTMLInputElement>
    ) {
        const nameRef = this.refs[getParamNameRef(mixinIndex, paramIndex)] as ContextInput;
        const valueRef = this.refs[getParamValueRef(mixinIndex, paramIndex)] as BaseInput;

        switch (event.keyCode) {
            case keycode('enter'):
                if (paramIndex === this.state.mixins[mixinIndex].parameters.length - 1) {
                    this.addParameter(mixinIndex, paramIndex + 1);
                } else {
                    const nextNameRef = this.refs[getParamNameRef(mixinIndex, paramIndex + 1)] as ContextInput;
                    nextNameRef.focus();
                }
                break;
            case keycode('esc'):
                valueRef.blur();
                break;
            case keycode('backspace'): {
                const parameter = this.state.mixins[mixinIndex].parameters[paramIndex];
                if (!parameter.value) {
                    nameRef.focus();
                    event.preventDefault();
                }
                break;
            }
        }
    }

    private deleteEmptyParam(mixinIndex: number, paramIndex: number) {
        const mixins = this.state.mixins;
        const parameter = mixins[mixinIndex].parameters[paramIndex];

        if (!parameter.value && (mixins[mixinIndex].type === 'js' || !parameter.name)) {
            mixins[mixinIndex].parameters.splice(paramIndex, 1);
            this.changeMixins(mixins);
        }
    }

    private toggleMixinExpand(index: number, event: React.MouseEvent<HTMLSpanElement>) {
        const expandedMixins = this.state.expandedMixins;

        expandedMixins[index] = !expandedMixins[index];

        event.stopPropagation();
        this.setState({ expandedMixins });
    }

    private printMixin(mixin: Mixin) {
        // TODO: If JS mixin: allow empty name
        let parameters = '';
        parameters = mixin.parameters
            .filter((parameter) => parameter.name && parameter.value)
            .map((parameter) =>
                mixin.type === 'class' ? `${parameter.name} ${parameter.value}` : `${parameter.value}`
            )
            .join(', ');

        return parameters.length ? `(${parameters})` : '';
    }

    // private renderPreview(mixin: Mixin) {
    //     if (mixin.options.length) {
    //         return <span data-automation-id={`MIXIN_${mixin.name}_PREVIEW`}>JS Mixin: ({mixin.options.join(', ')})</span>;
    //     }
    //     if (this.isClassMixin(mixin.name)) {
    //         return (
    //             <span>
    //                 <span
    //                     className={`preview ${this.getPublicClassname(mixin.name)}`}
    //                     data-automation-id={`MIXIN_${mixin.name}_PREVIEW`}>I'm a Preview</span>
    //                 <div className="mixinDeclarationWrapper">
    //                     {this.getClassDeclarations(mixin.name).map((declaration, index) =>
    //                         <div key={`mixin_${mixin.name}_declaration_${index}`}>
    //                             <span className="declarationKey">{declaration.prop}</span>
    //                             <span className="specialChar">: </span>
    //                             <span className="declarationValue">{declaration.value}</span>
    //                             <span className="specialChar">;</span>
    //                         </div>
    //                     )}
    //                 </div>
    //             </span>
    //         );
    //     }
    //     return <span data-automation-id={`MIXIN_${mixin.name}_PREVIEW`}>JS Mixin</span>;
    // }

    // private getPublicClassname(name: string) {
    //     const {stylableDriver, sheetDriver} = this.props;
    //     if (!stylableDriver || !sheetDriver) return "";
    //     return stylableDriver.stylable.transform(sheetDriver.getMeta()).exports[name];
    // }

    // private getClassDeclarations(selector: string) {
    //     const {sheetDriver} = this.props;
    //     if (!sheetDriver) return [];
    //     const rule = sheetDriver.queryStyleRule(`.${selector}`)[0];
    //     let declarations: postcss.Declaration[] = []
    //     rule.walkDecls((decl: postcss.Declaration) => declarations.push(decl));
    //     return declarations;
    // }
}
