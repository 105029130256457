import React from 'react';
import type { Paddings, Margins } from '@wixc3/shorthands-opener';

import { CompositeBlock, Tooltip, OptimisticWrapper } from '@wixc3/stylable-panel-components';
import { GenericDeclarationMap, StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';
import { areRecordsEqual, DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';

import type {
    OpenedDeclarationList,
    DeclarationIO,
    DeclarationVisualizerProps,
    VisualizerComponent,
} from '../../types';
import type { OpenedDeclarationArray } from '../../declaration-types';
import type { DimensionInputVisualizerClass } from '../dimension-visualizer-factory/dimension-visualizer-factory';
import {
    getDeclarationValue,
    getShorthandControllerValue,
    getShorthandChange,
    getOpenedDeclarationList,
    createDeclarationMapFromVisualizerValue,
    visualizerOptimisticValueResolver,
    getVisualizerWrapper,
} from '../../utils';
import { getTranslate } from '../../hosts/translate';
import {
    PaddingTopInputVisualizer,
    PaddingRightInputVisualizer,
    PaddingBottomInputVisualizer,
    PaddingLeftInputVisualizer,
    MarginTopInputVisualizer,
    MarginRightInputVisualizer,
    MarginBottomInputVisualizer,
    MarginLeftInputVisualizer,
    getDimensionUnits,
} from '../../generated-visualizers/dimension-visualizers';

import { style, classes } from './edge-visualizer.st.css';

export type EdgeMainProp = 'padding' | 'margin';
export type Edges = 'top' | 'right' | 'bottom' | 'left';

export enum SelectedEdge {
    None = -1,
    Top = 0,
    Right = 1,
    Bottom = 2,
    Left = 3,
    All = 4,
}

interface EdgeValues {
    edge: Edges;
    main: string;
}

interface EdgePolygonPreview {
    select: SelectedEdge;
    svgPath: string;
    innerPath?: string;
    viewBox: string;
}

interface EdgeSVGPreview {
    width: number;
    height: number;
    edges: Record<Edges, EdgePolygonPreview>;
}

const EDGES: Edges[] = ['top', 'right', 'bottom', 'left'];

const VIEW_BOXES = {
    topBottom: '0 0 132 18',
    verticalTopBottom: '0 0 104 18',
    leftRight: '0 0 18 98',
};

const ARROW_INNER_PATHS = {
    down: 'M53.5757576,11 L53.5757576,12 L51.2121212,12 L51.2121212,11 L53.5757576,11 Z M55.1515152,9 L55.1515152,10 L49.6363636,10 L49.6363636,9 L55.1515152,9 Z M56.7272727,7 L56.7272727,8 L48.0606061,8 L48.0606061,7 L56.7272727,7 Z',
    left: 'M7.58333333,50.6136364 L6.58333333,50.6136364 L6.58333333,48.3863636 L7.58333333,48.3863636 L7.58333333,50.6136364 Z M9.58333333,52.0984848 L8.58333333,52.0984848 L8.58333333,46.9015152 L9.58333333,46.9015152 L9.58333333,52.0984848 Z M11.5833333,53.5833333 L10.5833333,53.5833333 L10.5833333,45.4166667 L11.5833333,45.4166667 L11.5833333,53.5833333 Z',
    up: 'M51.1515152,8 L51.1515152,7 L53.5151515,7 L53.5151515,8 L51.1515152,8 Z M49.5757576,10 L49.5757576,9 L55.0909091,9 L55.0909091,10 L49.5757576,10 Z M48,12 L48,11 L56.6666667,11 L56.6666667,12 L48,12 Z',
    right: 'M10.5833333,48.3863636 L11.5833333,48.3863636 L11.5833333,50.6136364 L10.5833333,50.6136364 L10.5833333,48.3863636 Z M8.58333333,46.9015152 L9.58333333,46.9015152 L9.58333333,52.0984848 L8.58333333,52.0984848 L8.58333333,46.9015152 Z M6.58333333,45.4166667 L7.58333333,45.4166667 L7.58333333,53.5833333 L6.58333333,53.5833333 L6.58333333,45.4166667 Z',
};

const getVerticalSVGShapePreviewProps = (main: EdgeMainProp): EdgeSVGPreview => ({
    width: 128,
    height: 120,
    edges: {
        top: {
            select: SelectedEdge.Top,
            svgPath:
                'M98.3939322,0.5 C99.796548,0.5 101.066378,1.06852236 101.985554,1.98769865 C102.904731,2.90687495 103.473253,4.17670518 103.473253,5.57932092 C103.473253,6.70698592 103.097995,7.80258849 102.406625,8.69345023 L102.406625,8.69345023 L97.223567,15.3720475 C96.181724,16.7145079 94.5778406,17.5 92.8785358,17.5 L92.8785358,17.5 L11.1214642,17.5 C9.42215944,17.5 7.81827599,16.7145079 6.77643302,15.3720475 L6.77643302,15.3720475 L1.59337546,8.69345023 C0.733432393,7.58537584 0.404035954,6.23364197 0.566643877,4.94394092 C0.729251801,3.65423987 1.38386409,2.42657166 2.49193848,1.5666286 C3.38280022,0.875257711 4.47840279,0.5 5.60606778,0.5 L5.60606778,0.5 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.down : ARROW_INNER_PATHS.up,
            viewBox: VIEW_BOXES.verticalTopBottom,
        },
        right: {
            select: SelectedEdge.Right,
            svgPath:
                'M17.5,5.27264182 C17.5,4.00728544 16.9975077,2.79371877 16.1029967,1.89874575 C14.2396453,0.0344320871 11.2177758,0.0336519813 9.35346211,1.89700333 L2.11191681,9.13481073 C1.0798525,10.1663423 0.5,11.5657192 0.5,13.0249019 L0.5,84.9750981 C0.5,86.4342808 1.0798525,87.8336577 2.11191681,88.8651893 L9.35346211,96.1029967 C10.2484351,96.9975077 11.4620018,97.5 12.7273582,97.5 C15.3632155,97.5 17.5,95.3632155 17.5,92.7273582 L17.5,5.27264182 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.left : ARROW_INNER_PATHS.right,
            viewBox: VIEW_BOXES.leftRight,
        },
        bottom: {
            select: SelectedEdge.Bottom,
            svgPath:
                'M92.8517889,0.5 C94.5510937,0.5 96.1549772,1.28549215 97.1968201,2.62795255 L97.1968201,2.62795255 L102.379878,9.30654977 C103.239821,10.4146242 103.569217,11.766358 103.406609,13.0560591 C103.244001,14.3457601 102.589389,15.5734283 101.481315,16.4333714 C100.590453,17.1247423 99.4948504,17.5 98.3671854,17.5 L98.3671854,17.5 L5.57932092,17.5 C4.17670518,17.5 2.90687495,16.9314776 1.98769865,16.0123013 C1.06852236,15.093125 0.5,13.8232948 0.5,12.4206791 C0.5,11.2930141 0.875257711,10.1974115 1.5666286,9.30654977 L1.5666286,9.30654977 L6.74968617,2.62795255 C7.79152913,1.28549215 9.39541258,0.5 11.0947173,0.5 L11.0947173,0.5 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.up : ARROW_INNER_PATHS.down,
            viewBox: VIEW_BOXES.verticalTopBottom,
        },
        left: {
            select: SelectedEdge.Left,
            svgPath:
                'M0.5,5.27264182 L0.5,92.7273582 C0.5,95.3632155 2.63678453,97.5 5.27264182,97.5 C6.53799819,97.5 7.75156487,96.9975077 8.64653789,96.1029967 L15.8880832,88.8651893 C16.9201475,87.8336577 17.5,86.4342808 17.5,84.9750981 L17.5,13.0249019 C17.5,11.5657192 16.9201475,10.1663423 15.8880832,9.13481073 L8.64653789,1.89700333 C6.78222423,0.0336519813 3.76035468,0.0344320871 1.89700333,1.89874575 C1.00249227,2.79371877 0.5,4.00728544 0.5,5.27264182 Z',
            innerPath: main === 'padding' ? ARROW_INNER_PATHS.right : ARROW_INNER_PATHS.left,
            viewBox: VIEW_BOXES.leftRight,
        },
    },
});

const DEFAULT_VALUE = '0px';
const DEFAULT_VALUES = ['0', '0px'];

function edgeToSelectedBorder(edge?: Edges): SelectedEdge {
    switch (edge) {
        case 'top':
            return SelectedEdge.Top;
        case 'right':
            return SelectedEdge.Right;
        case 'bottom':
            return SelectedEdge.Bottom;
        case 'left':
            return SelectedEdge.Left;
    }
    return SelectedEdge.None;
}

export interface EdgeVisualizerState {
    selected: SelectedEdge;
    editing: SelectedEdge;
    linked: boolean;
}

export type PaddingProps = Paddings | 'padding';
export type MarginProps = Margins | 'margin';
export type EdgeProps = PaddingProps | MarginProps | EdgeMainProp;

export type PaddingVisualizerProps = DeclarationVisualizerProps<PaddingProps>;
export type MarginVisualizerProps = DeclarationVisualizerProps<MarginProps>;
export type EdgeVisualizerProps = DeclarationVisualizerProps<EdgeProps>;

type EdgeDeclarationMap = GenericDeclarationMap<EdgeProps>;
type EdgeVisualizerValue = OpenedDeclarationArray<EdgeProps>;
type EdgeInputVisualizerClass = DimensionInputVisualizerClass<EdgeProps>;

const edgeInputVisualizerMap: Record<EdgeMainProp, Record<Edges, EdgeInputVisualizerClass>> = {
    padding: {
        top: PaddingTopInputVisualizer as EdgeInputVisualizerClass,
        right: PaddingRightInputVisualizer as EdgeInputVisualizerClass,
        bottom: PaddingBottomInputVisualizer as EdgeInputVisualizerClass,
        left: PaddingLeftInputVisualizer as EdgeInputVisualizerClass,
    },
    margin: {
        top: MarginTopInputVisualizer as EdgeInputVisualizerClass,
        right: MarginRightInputVisualizer as EdgeInputVisualizerClass,
        bottom: MarginBottomInputVisualizer as EdgeInputVisualizerClass,
        left: MarginLeftInputVisualizer as EdgeInputVisualizerClass,
    },
};

export function EdgeVisualizerFactory(
    main: EdgeMainProp,
    inputProps: EdgeProps[],
    title: string,
    sectionTooltip: string
): React.ComponentClass<EdgeVisualizerProps> {
    class EdgeVisualizer
        extends React.Component<EdgeVisualizerProps, EdgeVisualizerState>
        implements VisualizerComponent<EdgeProps>
    {
        public static BLOCK_VARIANT_CONTROLLER = false;
        public static INPUT_PROPS: Array<EdgeMainProp | EdgeProps> = [main, ...inputProps];

        private declarationMapValue: EdgeDeclarationMap;
        private openedDeclarationList: OpenedDeclarationList<EdgeProps>;
        private changeFromWithin = false;

        constructor(props: EdgeVisualizerProps) {
            super(props);

            this.state = {
                selected: SelectedEdge.None,
                editing: SelectedEdge.None,
                linked: false,
            };

            this.openedDeclarationList = getOpenedDeclarationList(main, inputProps, props);
            this.declarationMapValue = getShorthandControllerValue(this.openedDeclarationList, props);
            this.state = this.setSelected(this.declarationMapValue);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillUpdate(props: EdgeVisualizerProps) {
            const newOpenedDeclarationList = getOpenedDeclarationList(main, inputProps, props);
            const newDeclarationMapValue = getShorthandControllerValue(newOpenedDeclarationList, props);
            if (this.state.editing === SelectedEdge.None && !this.changeFromWithin) {
                if (!areRecordsEqual(this.declarationMapValue, newDeclarationMapValue)) {
                    this.setState(this.setSelected(newDeclarationMapValue));
                }
            } else {
                this.changeFromWithin = false;
            }
            this.openedDeclarationList = newOpenedDeclarationList;
            this.declarationMapValue = newDeclarationMapValue;
        }

        public render() {
            const { panelHost, plane = DEFAULT_PLANE, className } = this.props;

            const translate = getTranslate(panelHost);
            return (
                <div className={style(classes.root, { plane }, className)} data-box-controller-highlight-parts>
                    <CompositeBlock
                        className={style(classes.controllerBlock)}
                        title={translate(title)}
                        information={translate(sectionTooltip)}
                    />
                    <div className={style(classes.boxWrapper)}>{this.renderBoxPreview()}</div>
                </div>
            );
        }

        private setSelected(declarationMapValue: EdgeDeclarationMap) {
            let selected: SelectedEdge | undefined;
            let linked: boolean | undefined = this.state.linked || false;

            if (!this.changeFromWithin) {
                const edgeValuesPerEdge = EDGES.map((edge) => this.getEdgeValues(edge, declarationMapValue));
                const nonEmptyEdgeValues = edgeValuesPerEdge.filter(
                    (edgeValues) => !~DEFAULT_VALUES.indexOf(edgeValues.main)
                );

                if (nonEmptyEdgeValues.length === 1) {
                    selected = edgeToSelectedBorder(nonEmptyEdgeValues[0].edge);
                } else if (!nonEmptyEdgeValues.length) {
                    selected = SelectedEdge.All;
                    linked = true;
                } else if (
                    nonEmptyEdgeValues.length === 4 &&
                    nonEmptyEdgeValues.map((val) => val.main === nonEmptyEdgeValues[0].main).indexOf(false) === -1
                ) {
                    selected = SelectedEdge.All;
                    linked = true;
                } else if (
                    nonEmptyEdgeValues.length === 4 &&
                    nonEmptyEdgeValues.map((val) => val.main === nonEmptyEdgeValues[0].main).indexOf(false) > -1
                ) {
                    linked = false;
                }
            }

            return {
                selected: selected !== undefined ? selected : this.state.selected,
                editing: this.state.editing,
                linked: linked !== undefined ? linked : this.state.linked,
            };
        }

        private renderBoxPreview() {
            const { linked } = this.state;

            const translate = getTranslate(this.props.panelHost);

            return (
                <>
                    <div className={classes.topControl}>
                        <div className={style(classes.verticalControlContentWrapper, { top: true })}>
                            {this.renderEdgeControls('top', SelectedEdge.Top)}
                        </div>
                    </div>

                    <div className={classes.borderPreviewContainer}>
                        <div className={classes.leftControl}>
                            <div className={classes.controlContainer}>
                                <div className={classes.controlContent}>
                                    {this.renderEdgeControls('left', SelectedEdge.Left)}
                                </div>
                            </div>
                        </div>

                        <div className={classes.borderShapePreview}>
                            {this.renderSolidPreview()}
                            <Tooltip
                                text={translate(
                                    linked
                                        ? StylablePanelTranslationKeys.controller.edge.unlockTooltip
                                        : StylablePanelTranslationKeys.controller.edge.lockTooltip
                                )}
                                verticalAdjust={-71}
                                horizontalAdjust={79}
                            >
                                <div className={style(classes.linkButton, { linked })} onClick={this.toggleLinked} />
                            </Tooltip>
                        </div>

                        <div className={classes.rightControl}>
                            <div className={classes.controlContainer}>
                                <div className={classes.controlContent}>
                                    {this.renderEdgeControls('right', SelectedEdge.Right)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.bottomControl}>
                        <div className={style(classes.verticalControlContentWrapper, { bottom: true })}>
                            {this.renderEdgeControls('bottom', SelectedEdge.Bottom)}
                        </div>
                    </div>
                </>
            );
        }

        private renderBorderView(edge: Edges) {
            const { selected: stateSelected } = this.state;

            const shapePreviewEdge = getVerticalSVGShapePreviewProps(main).edges[edge];

            const selected = stateSelected === SelectedEdge.All || stateSelected === shapePreviewEdge.select;

            return (
                <span key={`selection_${edge}`} className={style(classes.selection, { selected, edge })}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={classes.svgSelection}
                        viewBox={shapePreviewEdge.viewBox}
                    >
                        <g className={style(classes.svgSelectionBorder, { selected })}>
                            <path
                                className={classes.svgSelectionBorderPath}
                                d={shapePreviewEdge.svgPath}
                                onClick={() => this.selectBorder(shapePreviewEdge.select)}
                            />
                            {shapePreviewEdge.innerPath ? (
                                <path
                                    className={classes.svgSelectionBorderInnerPath}
                                    fillRule="nonzero"
                                    d={shapePreviewEdge.innerPath}
                                />
                            ) : null}
                        </g>
                    </svg>
                </span>
            );
        }

        private renderSolidPreview() {
            const { edges } = getVerticalSVGShapePreviewProps(main);

            return (
                <div className={classes.previewWrapper}>
                    <div className={classes.svgContainer}>
                        {(Object.keys(edges) as Edges[]).map((edge) => this.renderBorderView(edge))}
                    </div>
                </div>
            );
        }

        private renderEdgeControls(edge: Edges, select: SelectedEdge) {
            const { selected: stateSelected } = this.state;
            const selected =
                stateSelected === select || (stateSelected === SelectedEdge.All && select === SelectedEdge.Top);
            return (
                <span className={style(classes.edgeControls, { edge, selected })} onClick={() => this.onSelect(select)}>
                    {this.renderEdgeInput(edge, select)}
                </span>
            );
        }

        private renderEdgeInput(edge: Edges, select: SelectedEdge) {
            const { drivers, propertyWrappers, DefaultPropertyWrapper, onDelete } = this.props;
            const { editing: stateEditing } = this.state;

            const EdgeInputVisualizer = edgeInputVisualizerMap[main][edge];

            const edgeProp: EdgeProps = `${main}-${edge}`;
            const EdgeInputWrapper = getVisualizerWrapper(propertyWrappers, edgeProp, DefaultPropertyWrapper);

            const wrapperProps: DeclarationIO<EdgeProps> = {
                value: getDeclarationValue(this.openedDeclarationList, edgeProp, DEFAULT_VALUE),
                onChange: (declarations) => {
                    const changeValue = createDeclarationMapFromVisualizerValue(declarations, { value: [], drivers })[
                        edgeProp
                    ];
                    if (changeValue) {
                        this.changeEdge(edge, changeValue);
                    }
                },
                onDelete,
            };

            const { dimensionUnits } = this.props.panelHost || {};
            const units = getDimensionUnits({
                id: main === 'padding' ? DIMENSION_ID.PADDING : DIMENSION_ID.MARGIN,
                dimensionUnits,
            });

            return (
                <EdgeInputWrapper title={edge} mainProp={edgeProp} claims={new Set([edgeProp])} {...wrapperProps}>
                    <span className={classes.miniControllerWrapper}>
                        <EdgeInputVisualizer
                            className={classes.inputElement}
                            {...wrapperProps}
                            drivers={drivers}
                            config={{ units }}
                            onBlur={() => this.onBlur()}
                            onFocus={() => this.onSelect(select)}
                            forceFocus={stateEditing === select}
                        />
                    </span>
                </EdgeInputWrapper>
            );
        }

        private onSelect(select: SelectedEdge) {
            this.setState({
                editing: select,
                linked: this.state.linked,
                selected: this.state.linked ? SelectedEdge.All : select,
            });
        }

        private onBlur() {
            this.setState({
                editing: SelectedEdge.None,
                selected: this.state.linked ? SelectedEdge.All : SelectedEdge.None,
            });
        }

        private toggleLinked = () => {
            const linked = !this.state.linked;
            const selected = this.state.linked ? SelectedEdge.None : SelectedEdge.All;
            this.setState({ linked, selected, editing: SelectedEdge.None });
        };

        private selectBorder(selected: SelectedEdge) {
            this.setState({
                selected: this.state.linked ? SelectedEdge.All : selected,
                editing: selected,
                linked: this.state.linked,
            });
        }

        private changeEdge(edge: Edges, value: string) {
            const { onChange } = this.props;
            const { linked } = this.state;

            if (!onChange) {
                return;
            }

            const prop = linked ? main : `${main}-${edge}`;

            this.changeFromWithin = true;
            onChange(
                getShorthandChange<EdgeMainProp, EdgeProps>(
                    main,
                    { [prop]: `${value}` } as EdgeDeclarationMap,
                    this.openedDeclarationList,
                    this.props
                )
            );
        }

        private getEdgeValues(
            edge: Edges,
            declarationMapValue: EdgeDeclarationMap = this.declarationMapValue
        ): EdgeValues {
            let value = declarationMapValue[`${main}-${edge}` as EdgeProps];
            if (!value || !!~DEFAULT_VALUES.indexOf(value)) {
                value = DEFAULT_VALUE;
            }

            return { edge, main: value };
        }
    }

    return OptimisticWrapper<React.ComponentClass<EdgeVisualizerProps>, EdgeVisualizerValue>(
        EdgeVisualizer,
        {},
        true,
        visualizerOptimisticValueResolver
    );
}
