import { DragHandle } from '@wixc3/stylable-panel-common-react';
import { ContextInput } from '@wixc3/stylable-panel-controllers';
import { Context, getNodeId, getNodeRevision, SiteVarsDriver } from '@wixc3/stylable-panel-drivers';
import keycode from 'keycode';
import * as postcss from 'postcss';
import React, {
    createRef,
    forwardRef,
    memo,
    RefObject,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { CSSPanelContext } from '../../ui/css-panel';
import DeclarationEditor, { DeclarationEditorRef } from './declaration-editor';
import { classes, style } from './style-rule-editor.st.css';

export interface StyleRuleRef {
    focusSelector: Function;
}
export interface StyleRuleEditorProps {
    siteVarsDriver?: SiteVarsDriver;
    rule?: postcss.Rule; // TODO: Make mandatory + Tests
    filter?: (name: string) => boolean;
    selectorSuggestions?: string[];
    selectorPrefix?: string;
    onDragStart?: (id: number) => void;
    onClosingBracesClick?: () => void;
    onBlur?: () => void;
    className?: string;
    ['data-key']?: number;
}

export interface StyleRuleEditorState {
    expanded: boolean;
}
// TODO: Separate memo to another variable
const StyleRuleEditor = memo(
    forwardRef<StyleRuleRef, StyleRuleEditorProps>(function StyleRuleEditor(
        {
            siteVarsDriver,
            rule,
            selectorSuggestions,
            onClosingBracesClick,
            onBlur,
            className,
            selectorPrefix,
            filter,
            onDragStart,
            ...props
        },
        ref
    ) {
        const cssPanelContext = useContext(CSSPanelContext);
        const [, forceUpdate] = useState(false);
        const [expanded, setExpanded] = useState<boolean>(true);
        // const [focusPropId, setFocusPropId] = useState(-1);
        const focusValueId = useRef(-1);
        const ruleRevision = useRef(-1);
        let selectorInput: ContextInput | null = null;
        const declarationRefs = useRef<{ [name: string]: RefObject<DeclarationEditorRef> }>({});
        const dragStart = (id: number) => onDragStart && onDragStart(id);
        // expose methods to the ref
        useImperativeHandle(ref, () => ({ focusSelector }));

        useEffect(() => {
            setRuleRevision({ rule });
        }, [rule]);

        function setRuleRevision({ rule }: Pick<StyleRuleEditorProps, 'rule'>) {
            if (rule) {
                ruleRevision.current = getNodeRevision(rule);
            }
        }

        function isAcceptableSelector(selector: string) {
            if (selector === '') {
                return true;
            }

            if (selectorPrefix && !selector.startsWith(selectorPrefix)) {
                return false;
            }

            const selectorMatch = selector.match(/\.\D[\w:]*/);
            return !!selectorMatch && selectorMatch[0] === selector;
        }

        function handleDeclarationAdd(addAfterDecl?: postcss.Declaration) {
            if (!rule) {
                return;
            }

            const newDecl = postcss.decl({ prop: '', value: '' });
            // setFocusPropId(getNodeId(newDecl));
            if (addAfterDecl) {
                rule.insertAfter(addAfterDecl, newDecl);
            } else {
                rule.prepend(newDecl);
            }
            cssPanelContext.stylableSheet!.updateFromAST(rule);
            forceUpdate((isUpdated) => !isUpdated);
        }

        function toggleExpanded(event: any) {
            setExpanded(!expanded);
            event.stopPropagation(); // TODO: why?
        }

        function handleSelectorChange(selector: string) {
            if (!rule || !isAcceptableSelector(selector)) {
                return;
            }

            rule.selector = selector;
            cssPanelContext.stylableSheet!.updateFromAST(rule);
            forceUpdate((isUpdated) => !isUpdated);
        }

        function handleSelectorKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
            if (!selectorInput || !rule || !rule.nodes) {
                return;
            }

            switch (event.keyCode) {
                case keycode('esc'):
                    if (!selectorInput.suggestionsShown()) {
                        selectorInput.blur();
                    }
                    break;
                case keycode('enter'): {
                    if (selectorInput.suggestionsUsed()) {
                        return;
                    }

                    if (!rule.nodes.length) {
                        handleDeclarationAdd();
                        return;
                    }

                    const firstDeclaration = getFirstDeclaration();
                    if (firstDeclaration) {
                        const firstDeclarationRef =
                            declarationRefs.current[`declaration_${getNodeId(firstDeclaration)}`];
                        console.log(firstDeclarationRef);
                        firstDeclarationRef && firstDeclarationRef.current?.focusKey();
                    }
                    break;
                }
            }
        }

        function getFirstDeclaration() {
            if (!rule || !rule.nodes) {
                return null;
            }

            if (!filter) {
                return rule.first;
            }

            return rule.nodes.find((decl) => decl.type === 'decl' && filter(decl.prop));
        }

        function openingBraces() {
            const braces = ' { ';
            const collapsedBraces = ' }';

            return (
                <span
                    className={style(classes.openingBraces, { collapsed: !expanded })}
                    onClick={(event: any) => !expanded && toggleExpanded(event)}
                >
                    {braces}
                    {!expanded && <span className={classes.ellipsis}>...</span>}
                    {!expanded && <span>{collapsedBraces}</span>}
                </span>
            );
        }

        function handleDeclarationBlur(decl: postcss.Declaration) {
            if (!rule || decl.prop) {
                return;
            }

            rule.removeChild(decl);
            cssPanelContext.stylableSheet!.updateFromAST(rule);
            forceUpdate((isUpdated) => !isUpdated);
        }

        function handleNextDeclaration(decl: postcss.Declaration) {
            if (!rule) {
                return;
            }
            if (decl === getLastDeclaration()) {
                handleDeclarationAdd(decl);
            } else {
                // focusPropId.current = getNodeId(rule.nodes[rule.index(decl) + 1]);
                forceUpdate((isUpdated) => !isUpdated);
            }
        }

        function getLastDeclaration() {
            if (!rule || !rule.nodes) {
                return null;
            }

            if (!filter) {
                return rule.last;
            }

            for (let i = rule.nodes.length - 1; i >= 0; i--) {
                if (filter((rule.nodes[i] as postcss.Declaration).prop)) {
                    return rule.nodes[i];
                }
            }

            return null;
        }

        function handlePrevDeclaration(decl: postcss.Declaration) {
            if (!rule) {
                return;
            }

            if (decl === getFirstDeclaration()) {
                focusSelector();
            } else {
                focusValueId.current = getNodeId(rule.nodes[rule.index(decl) - 1]);
                forceUpdate((isUpdated) => !isUpdated);
            }
        }

        function handleMoveDeclaration(decl: postcss.Declaration, moveUp: boolean) {
            if (!rule) {
                return;
            }
            if ((moveUp && decl === getFirstDeclaration()) || (!moveUp && decl === getLastDeclaration())) {
                return;
            }
            if (moveUp) {
                const prevChild = rule.nodes[rule.index(decl) - 1];
                rule.removeChild(decl);
                rule.insertBefore(prevChild, decl);
            } else {
                const nextChild = rule.nodes[rule.index(decl) + 1];
                rule.removeChild(decl);
                rule.insertAfter(nextChild, decl);
            }

            cssPanelContext.stylableSheet!.updateFromAST(rule);
            forceUpdate((isUpdated) => !isUpdated);
        }

        function focusSelector() {
            selectorInput && selectorInput.select();
        }

        function renderDeclarations(rule?: postcss.Rule): JSX.Element[] {
            const result: JSX.Element[] = [];

            rule &&
                rule.walkDecls((decl) => {
                    if (filter && !filter(decl.prop)) {
                        return;
                    }

                    const id = getNodeId(decl);

                    declarationRefs.current[`declaration_${id}`] = createRef<DeclarationEditorRef>();
                    result.push(
                        <div
                            id={`declaration_${id}`}
                            key={id}
                            className={style(classes.declarationWrapper, {
                                directive: decl.prop.startsWith('-st-'),
                            })}
                        >
                            <DragHandle
                                className={classes.dragHandle}
                                data-key={id.toString()}
                                onMouseDown={() => dragStart.bind(id)}
                            />
                            <span className={classes.spacing}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <DeclarationEditor
                                className={classes.declaration}
                                data-key={id}
                                siteVarsDriver={siteVarsDriver}
                                declaration={decl}
                                onClick={() => handleDeclarationAdd(decl)}
                                onBlur={() => handleDeclarationBlur(decl)}
                                onNextDeclaration={() => handleNextDeclaration(decl)}
                                onPrevDeclaration={() => handlePrevDeclaration(decl)}
                                onMoveDeclaration={() => handleMoveDeclaration(decl, false)}
                                // onComplexPaste={this.handleComplexPaste.bind(this, index)}
                                ref={declarationRefs.current[`declaration_${id}`]}
                            />
                        </div>
                    );
                });

            return result;
        }

        const id = rule ? getNodeId(rule) : -1;

        // TODO: Filter in VariantEditor
        const selectorContexts: Context[] = selectorSuggestions
            ? [
                  {
                      suggestions: selectorSuggestions.filter((suggestion) => isAcceptableSelector(suggestion)),
                  },
              ]
            : [];

        const closingBraces = '}'; // needed in order not to mess with jsx parsing of this page

        return (
            <div className={style(classes.root, className)} id={`style_rule_${id}`} data-key={props['data-key']}>
                <div className={style(classes.header, { expanded })} onClick={() => expanded && handleDeclarationAdd()}>
                    <button className={classes.expandCollapseButton} onClick={toggleExpanded} tabIndex={-1}>
                        {expanded ? '-' : '+'}
                    </button>
                    <ContextInput
                        className={classes.ruleName}
                        value={rule && rule.selector}
                        contexts={selectorContexts}
                        siteVarsDriver={siteVarsDriver}
                        onChange={handleSelectorChange}
                        onKeyDown={handleSelectorKeyDown}
                        onBlur={onBlur}
                        ref={(ref) => (selectorInput = ref)}
                    />
                    {openingBraces()}
                </div>

                {expanded && (
                    <div className={classes.content}>
                        {renderDeclarations(rule)}
                        <div className={classes.sideLine} />
                    </div>
                )}

                {expanded && (
                    <div className={classes.closingBraces} onClick={onClosingBracesClick}>
                        {closingBraces}
                    </div>
                )}
            </div>
        );
    }),
    (prev, next) => {
        const { rule: oldRule } = prev;
        const { rule: newRule } = next;

        if (!newRule) return true;

        return newRule !== oldRule || getNodeRevision(oldRule) !== getNodeRevision(newRule);
    }
);

export default StyleRuleEditor;
