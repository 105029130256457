export const DIMENSION_ID = {
    // dimension-visualizers
    SIZE: 'size',
    OPACITY: 'opacity',
    ANGLE: 'angle',
    MARGIN: 'margin',
    PADDING: 'padding',
    CORNERS: 'corners',

    FONT_SIZE: 'font-size',
    LETTER_SPACING: 'letter-spacing',
    WIDTH: 'width',
    WIDTH_DOUBLE: 'width-double',

    // color-adder
    COLOR_ADDER_RGB: 'color-adder-rgb',
    COLOR_ADDER_HSB: 'color-adder-hsb',
    COLOR_ADDER_HSB_HUE: 'color-adder-hsb-hue',

    // color-picker
    COLOR_PICKER_PERCENTS_RANGE: 'color-picker-percents-range',

    // gradient-picker
    GRADIENT_REPEATS: 'gradient-repeats',
    GRADIENT_OFFSET: 'gradient-offset',
    GRADIENT_SIZE: 'gradient-size',
    GRADIENT_STEPS: 'gradient-steps',
    GRADIENT_DEGREES_RANGE: 'gradient-degrees-range',
    GRADIENT_POSITION_SCALE: 'gradient-position-scale',

    // image-fill
    IMAGE_PATTERN_SCALE: 'image-pattern-scale',
    IMAGE_SCALE: 'image-scale',

    // single-shadow-input
    SINGLE_SHADOW_DEGREES_RANGE: 'single-shadow-degrees-range',
    SINGLE_SHADOW_DISTANCE: 'single-shadow-distance',
    SINGLE_SHADOW_BLUR: 'single-shadow-blur',
    SINGLE_SHADOW_SPREAD: 'single-shadow-spread',
} as const;

type DimensionKeys = keyof typeof DIMENSION_ID;
export type DimensionID = typeof DIMENSION_ID[DimensionKeys];
