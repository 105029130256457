import { BaseInput, getInputContexts, ValueInputComponent } from '@wixc3/stylable-panel-controllers';
import type { SiteVarsDriver } from '@wixc3/stylable-panel-drivers';
import keycode from 'keycode';
import type * as postcss from 'postcss';
import React, { createRef, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSSPanelContext } from '../../ui/css-panel';
import { classes, style } from './declaration-editor.st.css';

export interface DeclarationEditorRef {
    focusKey: Function;
}
export interface DeclarationEditorProps {
    siteVarsDriver?: SiteVarsDriver;
    declaration: postcss.Declaration;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onBlur?: () => void;
    // onFinish?: () => void;
    // onComplexPaste?: (declarations: postcss.Declaration[]) => void;
    onNextDeclaration?: () => void;
    onPrevDeclaration?: () => void;
    onMoveDeclaration?: (moveUp: boolean) => void;
    className?: string;
    ['data-key']?: number;
}

// const DeclarationEditor = memo(
const DeclarationEditor = forwardRef<DeclarationEditorRef, DeclarationEditorProps>(function DeclarationEditor(
    {
        siteVarsDriver,
        declaration,
        onBlur,
        onClick,
        onNextDeclaration,
        onPrevDeclaration,
        onMoveDeclaration,
        className,
        ...props
    },
    ref
) {
    const cssPanelContext = useContext(CSSPanelContext);
    const [focusValue, setFocusValue] = useState(false);
    const [, forceUpdate] = useState(false);
    const { prop } = declaration;
    const propInput = createRef<BaseInput>();
    const valueInput = useRef<ValueInputComponent | null>(null);
    // expose methods to the ref
    useImperativeHandle(ref, () => ({ focusKey }));

    useEffect(() => {
        if (focusValue) {
            selectValue();
            setFocusValue(false);
        }
    }, [focusValue]);

    function handleClick(event: React.MouseEvent<HTMLDivElement>) {
        const id = (event.target as Element).id;
        if (!(id && id.startsWith('option_')) && onClick) {
            onClick(event);
        }
    }

    function handlePropInputChange(prop = '') {
        setFocusValue(!!~prop.indexOf(':'));
        declaration.prop = prop.replace(/[;:]/g, '');
        cssPanelContext.stylableSheet?.updateFromAST?.(declaration);
        !!~prop.indexOf(';') && onNextDeclaration && onNextDeclaration();
        forceUpdate((isUpdated) => !isUpdated);
    }

    function selectKey() {
        propInput.current && propInput.current.select();
    }

    function selectValue() {
        valueInput.current && valueInput.current.select();
    }

    function focusPropInput(event: React.MouseEvent<HTMLSpanElement>) {
        selectKey();
        event.stopPropagation();
    }

    function focusValueInput(event: React.MouseEvent<HTMLSpanElement>) {
        selectValue();
        event.stopPropagation();
    }

    function handleValueInputChange(value = '') {
        declaration.value = value.replace(/[;:]/g, '');
        cssPanelContext.stylableSheet?.updateFromAST?.(declaration);
        !!~value.indexOf(';') && onNextDeclaration && onNextDeclaration();
        forceUpdate((isUpdated) => !isUpdated);
    }

    function focusKey() {
        propInput.current?.focus();
    }

    function getValueInput() {
        const { prop, value } = declaration;

        // TODO: When no context is provided - fallback to ContextInput
        const InputComp = cssPanelContext.valueInputDriver?.getInput?.(prop);

        // TODO: Test suggestions in DeclarationEditor level
        const contexts = getInputContexts(prop, cssPanelContext.stylableSheet?.vars);

        return InputComp ? (
            <InputComp
                className={style(classes.valueInput, InputComp.type === 'MixinInput' ? classes.mixinInput : undefined)}
                data-input-type={InputComp.type}
                value={value}
                contexts={contexts}
                stylableDriver={cssPanelContext.stylableDriver}
                sheetDriver={cssPanelContext.stylableSheet}
                siteVarsDriver={siteVarsDriver}
                onChange={handleValueInputChange}
                onKeyDown={handleValueInputKeyDown}
                onBlur={onBlur}
                ref={valueInput}
            />
        ) : null;
    }

    function handleValueInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        const { prop, value } = declaration;
        const targetValue = (event.target as HTMLInputElement).value;
        const isPropInput = targetValue === prop;

        if (event.altKey) {
            let moveUp = false;
            switch (event.keyCode) {
                case keycode('up'):
                    moveUp = true;
                    break;
                case keycode('down'):
                    moveUp = false;
                    break;
                default:
                    return;
            }
            event.preventDefault();
            onMoveDeclaration && onMoveDeclaration(moveUp);
            return;
        }

        switch (event.keyCode) {
            case keycode('esc'):
                propInput.current && propInput.current.blur();
                // TODO: Migrate to ref!
                if (valueInput.current && !valueInput.current.suggestionsShown()) {
                    valueInput.current.blur();
                }
                break;
            case keycode('enter'):
                if (isPropInput) {
                    selectValue();
                } else {
                    if (!valueInput?.current?.suggestionsUsed() && onNextDeclaration) {
                        onNextDeclaration();
                    }
                }
                break;
            case keycode('backspace'):
                if (!isPropInput && !value) {
                    event.preventDefault();
                    selectKey();
                }
                if (isPropInput && !prop) {
                    event.preventDefault();
                    onPrevDeclaration && onPrevDeclaration();
                }
                break;
        }
    }

    return (
        <div
            className={style(classes.root, className)}
            data-key={props['data-key']}
            onClick={handleClick}
            // onDoubleClick={this.selectAllText}
            // ref={ref => this.root = ref}
        >
            <BaseInput
                className={classes.keyInput}
                value={prop}
                siteVarsDriver={siteVarsDriver}
                onChange={handlePropInputChange}
                onKeyDown={handleValueInputKeyDown}
                onBlur={onBlur}
                ref={propInput}
            />
            <span className={style(classes.specialChar, classes.declarationColon)} onClick={focusPropInput}>
                :{' '}
            </span>
            {prop && getValueInput()}
            <span
                className={style(classes.specialChar, classes.endChar, classes.declarationSemicolon)}
                onClick={focusValueInput}
            >
                ;
            </span>
        </div>
    );
});

export default DeclarationEditor;
