import type { StylesheetDriver } from '../../stylable-stylesheet';

/*
turn a kebab cased string into a camel cased one
 */
function kebabToCamelCase(s: string): string {
    return s.replace(/(-\w)/g, (m) => m[1].toUpperCase());
}

export function createReactStyleFromCssStyle(
    originalStyle: Record<string, string | undefined>,
    sheetDriver?: StylesheetDriver
) {
    const camelCasedVariantStyle: Record<string, string> = {};
    Object.keys(originalStyle).forEach((value) => {
        if (originalStyle[value] !== undefined) {
            let newVal = originalStyle[value];
            if (sheetDriver) {
                newVal = sheetDriver.evalDeclarationValue(newVal);
            }

            camelCasedVariantStyle[kebabToCamelCase(value)] = newVal!;
        }
    });
    return camelCasedVariantStyle;
}
