import React from 'react';
import chroma from 'chroma-js';
import { parse as parseBoxShadow, stringify as stringifyBoxShadow } from 'css-box-shadow';

import { valueMapping } from '@stylable/core';
import {
    BackgroundBox,
    CompositeBlock,
    Drawer,
    OptimisticWrapper,
    Title,
    ToggleSwitch,
} from '@wixc3/stylable-panel-components';
import { DEFAULT_PLANE, DIMENSION_ID } from '@wixc3/stylable-panel-common';
import {
    applyMixin,
    filterComments,
    GenericDeclarationMap,
    getMixinDeclarations,
    parseMixinValue,
    removeMixin,
    StylablePanelTranslationKeys,
} from '@wixc3/stylable-panel-drivers';

import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';
import type { OpenedDeclarationArray } from '../../declaration-types';
import {
    controllerToVisualizerChange,
    createDeclarationMapFromVisualizerValue,
    createVisualizerValueFromDeclarationMap,
    visualizerOptimisticValueResolver,
} from '../../utils';
import { CUSTOM_FONT_THEME_ID, FontTheme, FontThemeSelector } from '../../components';
import { ColorPicker, ColorPickerProps } from '../../pickers';
import type { ShadowProps } from '../../visualizer-factories';
import {
    FontSizeVisualizer,
    LetterSpacingVisualizer,
    OpacityVisualizer,
    TEXT_DECORATION_NONE,
    TEXT_DECORATION_STRIKETHROUGH,
    TEXT_DECORATION_UNDERLINE,
    TextAlignVisualizer,
    TextShadowVisualizer,
    TextTransformVisualizer,
    BoldVisualizer,
    DirectionVisualizer,
    ItalicVisualizer,
    StrikethroughVisualizer,
    UnderlineVisualizer,
    getDimensionUnits,
} from '../../generated-visualizers';
import { EMPTY_FONT_FAMILY, FontFamilyVisualizer } from '../font-family-visualizer/font-family-visualizer';
import {
    ColorIcon,
    ColorPickerTextType,
    getIconConfiguration,
    getIconRows,
    IconConfigurationMap,
    IconInRowProps,
    IconKey,
    MAX_ROW_ICONS,
} from './text-visualizer-icons';
import { controllerNoStateResizing } from '../../utils/controller-data-utils';
import { PanelEventList } from '../../hosts/bi';
import { getTranslate } from '../../hosts/translate';

import { classes, style } from './text-visualizer.st.css';

export const DISPLAY_HIDDEN = 'none';
export const DISPLAY_SHOWN = 'initial';

const TEXT_TRANSFORM_DEFAULT = 'none';
const TEXT_ALIGN_DEFAULT = 'left';
const TEXT_SHADOW_NONE = 'none';

const ID_FUNCTION = (changeValue: string) => changeValue;

// function getFontWeights(translate: (key: string) => string): Option[] {
//     return [
//         {
//             id: '100',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.thinOptionLabel)
//         },
//         {
//             id: '200',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.extraLightOptionLabel)
//         },
//         {
//             id: '300',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.lightOptionLabel)
//         },
//         {
//             id: '400',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.normalOptionLabel)
//         },
//         {
//             id: '500',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.mediumOptionLabel)
//         },
//         {
//             id: '600',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.semiBoldOptionLabel)
//         },
//         {
//             id: '700',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.boldOptionLabel)
//         },
//         {
//             id: '800',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.extraBoldOptionLabel)
//         },
//         {
//             id: '900',
//             displayName: translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.heavyOptionLabel)
//         }
//     ];
// }

// const EMPTY_FONT_WEIGHT = '400';
// function normalizeFontWeight(weightId: string) {
//     const weight = parseFloat(weightId);
//     if (isNaN(weight)) { return EMPTY_FONT_WEIGHT; }

//     if (weight < 100) { return '100'; }
//     if (weight > 900) { return '900'; }

//     return `${Math.floor(weight / 100) * 100}`;
// }

interface Stroke {
    color?: string;
    value: { 'text-shadow': string };
}

function strokeShadow(color: string) {
    return `1px 0 ${color}, -1px 0 ${color}, 0 1px ${color}, 0 -1px ${color}`;
}

export interface TextVisualizerState {
    fontTheme: string;
    textShadowVisualizerShown: boolean;
}

export type TextProps =
    | 'font'
    | 'font-family'
    | 'font-weight'
    | 'font-size'
    | 'color'
    | 'font-style'
    | 'text-decoration-line'
    | 'text-transform'
    | 'text-align'
    | 'direction'
    | 'letter-spacing'
    | 'line-height'
    | 'text-shadow'
    | 'display'
    | 'background-color'
    | '-st-mixin';

export type TextDeclarationMap = GenericDeclarationMap<TextProps>;

const TEXT_PROPS_LIST: TextProps[] = [
    'font',
    'font-family',
    'font-weight',
    'font-size',
    'color',
    'font-style',
    'text-decoration-line',
    'text-transform',
    'text-align',
    'direction',
    'letter-spacing',
    // 'line-height',
    'text-shadow',
];

const OUTSIDE_PROPS_LIST: TextProps[] = ['display', 'background-color', valueMapping.mixin];

export type TextVisualizerProps = DeclarationVisualizerProps<TextProps> & {
    noShownToggle?: boolean;
    noHighlight?: boolean;
};
type TextVisualizerValue = OpenedDeclarationArray<TextProps>;

export class TextVisualizerInner
    extends React.Component<TextVisualizerProps, TextVisualizerState>
    implements VisualizerComponent<TextProps>
{
    public static BLOCK_VARIANT_CONTROLLER = false;
    public static INPUT_PROPS: TextProps[] = TEXT_PROPS_LIST;
    public static OUTSIDE_PROPS: TextProps[] = OUTSIDE_PROPS_LIST;
    private declarationMapValue: TextDeclarationMap;
    private stroke: Stroke;
    private readonly availableFontThemes: FontTheme[];

    constructor(props: TextVisualizerProps) {
        super(props);
        this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
        this.stroke = this.extractStroke();
        const { panelHost } = props;
        const { value } = this.stroke;

        this.availableFontThemes = panelHost && panelHost.getFontThemes ? panelHost.getFontThemes() : [];

        this.state = {
            fontTheme: this.getStateFontTheme(this.declarationMapValue),
            textShadowVisualizerShown: !!value && value['text-shadow'] !== TEXT_SHADOW_NONE,
        };
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(newProps: TextVisualizerProps) {
        this.declarationMapValue = createDeclarationMapFromVisualizerValue(newProps.value, newProps);
        this.stroke = this.extractStroke();
    }

    public render() {
        const { controllerData, plane = DEFAULT_PLANE, panelHost, noShownToggle, className } = this.props;

        const { display } = this.declarationMapValue;
        const translate = getTranslate(panelHost);

        const hideShownToggle = (controllerData && !!controllerData.hideShownToggle) || !!noShownToggle;
        const glyphMode = !!controllerData && !!controllerData.glyphMode;
        const elementShown = display !== DISPLAY_HIDDEN;
        const titleKey = !glyphMode
            ? StylablePanelTranslationKeys.controller.text.title
            : StylablePanelTranslationKeys.controller.separators.title;

        return (
            <div className={style(classes.root, { plane }, className)}>
                <Title className={classes.title} text={translate(titleKey)} />
                {!hideShownToggle ? this.renderShownToggle(elementShown, glyphMode) : null}
                {elementShown ? (!glyphMode ? this.renderControllers() : this.renderGlyphMode()) : null}
            </div>
        );
    }

    private getStateFontTheme(declarationMapValue: TextDeclarationMap) {
        const availableFontThemeNames = this.availableFontThemes.map((fontTheme) => fontTheme.cssClass);
        const mixinList = parseMixinValue(declarationMapValue[valueMapping.mixin]).reverse();
        const theme = mixinList.find((mixin) => !!~availableFontThemeNames.indexOf(mixin));
        const mixinDeclarations = theme ? this.getMixinDeclarations(theme) : [];

        return theme &&
            mixinDeclarations.length > 0 &&
            mixinDeclarations.every((decl) => declarationMapValue[decl.prop as TextProps] === decl.value)
            ? theme
            : CUSTOM_FONT_THEME_ID;
        // TODO: Translation layer between editor theme name and local theme name/class?
    }

    private onRenderShowElementBlock(toggle: boolean) {
        this.changeFromBlock('display', toggle ? () => DISPLAY_HIDDEN : () => DISPLAY_SHOWN, '');
        const { panelHost } = this.props;
        if (!panelHost) {
            return;
        }
        const { reportBI } = panelHost;
        const { COMPONENT_PART_TOGGLE } = PanelEventList;
        reportBI && reportBI(COMPONENT_PART_TOGGLE, { toggle_name: 'text', toggle: !toggle });
    }

    private renderShownToggle(elementShown: boolean, glyphMode: boolean) {
        const { selectorState, panelHost } = this.props;

        const translate = getTranslate(panelHost);
        const titleKey = !glyphMode
            ? StylablePanelTranslationKeys.controller.text.showLabel
            : StylablePanelTranslationKeys.controller.separators.showLabel;

        return (
            <CompositeBlock className={classes.controllerBlock} title={translate(titleKey)} singleLine divider>
                <ToggleSwitch
                    className={classes.shownToggle}
                    value={elementShown}
                    disabled={selectorState !== undefined}
                    onChange={() => this.onRenderShowElementBlock(elementShown)}
                />
            </CompositeBlock>
        );
    }

    private renderControllers() {
        const { panelHost } = this.props;
        const {
            'font-family': fontFamily = EMPTY_FONT_FAMILY,
            // 'font-weight': fontWeight = EMPTY_FONT_WEIGHT,
            'font-size': fontSize = '',
            'letter-spacing': letterSpacing = '',
            // 'line-height': lineHeight = ''
        } = this.declarationMapValue;
        const translate = getTranslate(panelHost);
        const noStateResizing = controllerNoStateResizing(this.props);

        return [
            !noStateResizing ? this.renderFontThemeBlock(this.state.fontTheme) : null,
            !noStateResizing ? this.renderFontFamilyBlock(fontFamily) : null,
            // this.renderDropDownBlock(
            //     translate(StylablePanelTranslationKeys.controller.text.fontWeightSelector.label),
            //     normalizeFontWeight(fontWeight),
            //     getFontWeights(translate),
            //     'font-weight'
            // ),
            !noStateResizing
                ? this.renderSliderBlock(
                      translate(StylablePanelTranslationKeys.controller.text.fontSizeSelectorLabel),
                      fontSize,
                      'font-size',
                      translate(StylablePanelTranslationKeys.controller.text.fontSizeSelectorInformationTooltip)
                  )
                : null,
            this.renderIconRows(noStateResizing),
            !noStateResizing
                ? this.renderSliderBlock(
                      translate(StylablePanelTranslationKeys.controller.text.letterSpacingSelectorLabel),
                      letterSpacing,
                      'letter-spacing',
                      translate(StylablePanelTranslationKeys.controller.text.letterSpacingSelectorInformationTooltip)
                  )
                : null,
            // this.renderSliderBlock(
            //     'Line Height',
            //     lineHeight,
            //     genericEditInfo,
            //     'line-height',
            // ),
            this.renderTextShadowVisualizer(),
        ];
    }

    private renderFontThemeBlock(value: string) {
        const { panelHost } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <CompositeBlock
                key="font-theme"
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.text.fontThemeSelector.label)}
                information={translate(
                    StylablePanelTranslationKeys.controller.text.fontThemeSelector.informationTooltip
                )}
                divider
            >
                <FontThemeSelector
                    className={classes.dropDown}
                    value={value}
                    fontThemes={this.availableFontThemes}
                    panelHost={panelHost}
                    onSelect={(change: string) => this.onSelectFontTheme(change)}
                />
            </CompositeBlock>
        );
    }

    private renderFontFamilyBlock(value: string) {
        const { drivers, panelHost } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <CompositeBlock
                key="font-family"
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.text.fontFamilySelectorLabel)}
                information={translate(
                    StylablePanelTranslationKeys.controller.text.fontFamilySelectorInformationTooltip
                )}
                divider
            >
                <FontFamilyVisualizer
                    className={classes.dropDown}
                    drivers={drivers}
                    value={createVisualizerValueFromDeclarationMap({
                        'font-family': value,
                    })}
                    panelHost={panelHost}
                    onChange={(change) => {
                        const { 'font-family': fontFamily } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (fontFamily) {
                            this.onSelectFontFamily(fontFamily);
                        }
                    }}
                />
            </CompositeBlock>
        );
    }

    private onSelectFontTheme(newFontTheme: string) {
        const { onChange } = this.props;
        const { fontTheme: oldFontTheme } = this.state;

        if (!onChange) {
            return;
        }

        this.setState({ fontTheme: newFontTheme });

        onChange(
            controllerToVisualizerChange(
                {
                    [valueMapping.mixin]: applyMixin(
                        newFontTheme,
                        removeMixin(oldFontTheme, this.declarationMapValue[valueMapping.mixin])
                    ),
                } as TextDeclarationMap,
                this.props
            )
        );
    }

    private onSelectFontFamily(change: string) {
        this.changeFromBlock(
            'font-family',
            (changeValue) => (changeValue !== EMPTY_FONT_FAMILY ? changeValue : ''),
            change
        );

        const { panelHost } = this.props;
        if (!panelHost) {
            return;
        }
        const { reportBI } = panelHost;
        const { TEXT_DROPDOWN_SELECT } = PanelEventList;
        reportBI && reportBI(TEXT_DROPDOWN_SELECT, { font: change });
    }

    // private renderDropDownBlock(
    //     title: string,
    //     value: string,
    //     options: Option[],
    //     prop: string
    // ) {
    //     return (
    //         <CompositeBlock
    //             key={prop}
    //             className={classes.controllerBlock}
    //             title={title}
    //             divider
    //         >
    //             <DropDown
    //                 className={classes.dropDown}
    //                 value={value}
    //                 options={options}
    //                 onSelect={this.changeFromBlock.bind(this, prop, ID_FUNCTION)}
    //             />
    //         </CompositeBlock>
    //     );
    // }

    private renderSliderBlock(
        title: string,
        value: string,
        prop: 'letter-spacing' | 'font-size',
        informationTooltip?: string
    ) {
        const { drivers, panelHost } = this.props;
        const SliderVisualizer =
            prop === 'letter-spacing' ? LetterSpacingVisualizer : prop === 'font-size' ? FontSizeVisualizer : undefined;

        const units = getDimensionUnits({ id: prop, dimensionUnits: panelHost?.dimensionUnits });

        return (
            SliderVisualizer && (
                <CompositeBlock
                    key={prop}
                    className={classes.controllerBlock}
                    title={title}
                    information={informationTooltip}
                    divider
                >
                    <SliderVisualizer
                        className={style(classes.inputElement, { prop, value, title })}
                        drivers={drivers}
                        value={
                            createVisualizerValueFromDeclarationMap({
                                [prop]: value,
                            }) as OpenedDeclarationArray<'letter-spacing'> & OpenedDeclarationArray<'font-size'>
                        }
                        config={{ units }}
                        onChange={(value) => {
                            const { [prop]: sliderValue } = createDeclarationMapFromVisualizerValue(value, {
                                value: [],
                                drivers,
                            });
                            if (sliderValue) {
                                this.changeFromBlock(prop, ID_FUNCTION, sliderValue);
                            }
                        }}
                    />
                </CompositeBlock>
            )
        );
    }

    private getIconRenderMap(
        iconConfiguration: IconConfigurationMap
    ): Record<IconKey, (iconKey: IconKey) => JSX.Element | JSX.Element[]> {
        const {
            'font-weight': fontWeight,
            'font-style': fontStyle,
            'text-decoration-line': textDecoration,
            color,
            'background-color': backgroundColor,
            'text-transform': transformValue = TEXT_TRANSFORM_DEFAULT,
            'text-align': alignValue = TEXT_ALIGN_DEFAULT,
            direction,
        } = this.declarationMapValue;

        const { drivers, panelHost } = this.props;

        const boundOpenColorPicker = this.openColorPicker.bind(this);
        const translate = getTranslate(panelHost);

        const iconRowProps = (iconKey: IconKey) =>
            ({
                key: `icon_${iconKey}`,
                iconKey,
                iconConfiguration,
                translate,
            } as IconInRowProps);

        return {
            [IconKey.Bold]: (iconKey) => (
                <BoldVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.boldVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'font-weight': fontWeight,
                    })}
                    onChange={(change) => {
                        const { 'font-weight': fontWeight } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (fontWeight) {
                            this.changeFromBlock('font-weight', ID_FUNCTION, fontWeight);
                        }
                    }}
                />
            ),
            [IconKey.Italic]: (iconKey) => (
                <ItalicVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.italicVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'font-style': fontStyle,
                    })}
                    onChange={(change) => {
                        const { 'font-style': fontStyle } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (fontStyle) {
                            this.changeFromBlock('font-style', ID_FUNCTION, fontStyle);
                        }
                    }}
                />
            ),
            [IconKey.Underline]: (iconKey) => (
                <UnderlineVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.underlineVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'text-decoration-line': textDecoration,
                    })}
                    onChange={(change) => {
                        const { 'text-decoration-line': textDecorationNew } = createDeclarationMapFromVisualizerValue(
                            change,
                            { value: [], drivers }
                        );
                        if (textDecorationNew) {
                            this.changeFromBlock(
                                'text-decoration-line',
                                (value) =>
                                    textDecoration?.includes(TEXT_DECORATION_STRIKETHROUGH)
                                        ? value === TEXT_DECORATION_NONE
                                            ? TEXT_DECORATION_STRIKETHROUGH
                                            : `${TEXT_DECORATION_UNDERLINE} ${TEXT_DECORATION_STRIKETHROUGH}`
                                        : value,
                                textDecorationNew
                            );
                        }
                    }}
                />
            ),
            [IconKey.Strikethrough]: (iconKey) => (
                <StrikethroughVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.strikethroughVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'text-decoration-line': textDecoration,
                    })}
                    onChange={(change) => {
                        const { 'text-decoration-line': textDecorationNew } = createDeclarationMapFromVisualizerValue(
                            change,
                            { value: [], drivers }
                        );
                        if (textDecorationNew) {
                            this.changeFromBlock(
                                'text-decoration-line',
                                (value) =>
                                    textDecoration?.includes(TEXT_DECORATION_UNDERLINE)
                                        ? value === TEXT_DECORATION_NONE
                                            ? TEXT_DECORATION_UNDERLINE
                                            : `${TEXT_DECORATION_UNDERLINE} ${TEXT_DECORATION_STRIKETHROUGH}`
                                        : value,
                                textDecorationNew
                            );
                        }
                    }}
                />
            ),
            [IconKey.TextColor]: (iconKey) => (
                <ColorIcon
                    {...iconRowProps(iconKey)}
                    type={ColorPickerTextType.Text}
                    color={color}
                    openColorPicker={boundOpenColorPicker}
                    iconClassName={classes.textColorIcon}
                />
            ),
            [IconKey.BackgroundColor]: (iconKey) => (
                <ColorIcon
                    {...iconRowProps(iconKey)}
                    type={ColorPickerTextType.Background}
                    color={backgroundColor}
                    openColorPicker={boundOpenColorPicker}
                    iconClassName={classes.backgroundColorIcon}
                />
            ),
            [IconKey.StrokeColor]: (iconKey) => (
                <ColorIcon
                    {...iconRowProps(iconKey)}
                    type={ColorPickerTextType.Stroke}
                    color={this.stroke.color}
                    openColorPicker={boundOpenColorPicker}
                    iconClassName={classes.strokeColorIcon}
                />
            ),
            [IconKey.Transform]: (iconKey) => (
                <TextTransformVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.transformVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'text-transform': transformValue,
                    })}
                    onChange={(change) => {
                        const { 'text-transform': textTransform } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (textTransform) {
                            this.changeFromBlock('text-transform', ID_FUNCTION, textTransform);
                        }
                    }}
                />
            ),
            [IconKey.Direction]: (iconKey) => (
                <DirectionVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.directionVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        direction,
                    })}
                    onChange={(change) => {
                        const { direction } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (direction) {
                            this.changeFromBlock('direction', ID_FUNCTION, direction);
                        }
                    }}
                />
            ),
            [IconKey.Alignment]: (iconKey) => (
                <TextAlignVisualizer
                    key={`icon_${iconKey}`}
                    drivers={drivers}
                    panelHost={panelHost}
                    className={classes.alignmentVisualizer}
                    value={createVisualizerValueFromDeclarationMap({
                        'text-align': alignValue,
                    })}
                    onChange={(change) => {
                        const { 'text-align': textAlign } = createDeclarationMapFromVisualizerValue(change, {
                            value: [],
                            drivers,
                        });
                        if (textAlign) {
                            this.changeFromBlock('text-align', ID_FUNCTION, textAlign);
                        }
                    }}
                />
            ),
        };
    }

    private renderIconRows(noStateResizing = false) {
        const { noHighlight } = this.props;
        const iconConfiguration = getIconConfiguration({
            noHighlight,
            noStateResizing,
        });
        const iconRenderMap = this.getIconRenderMap(iconConfiguration);
        const iconRows = getIconRows(iconConfiguration, MAX_ROW_ICONS);

        return iconRows.map((iconRow, index) => (
            <CompositeBlock key={`icon-row-${index}`} className={classes.controllerBlock} iconRow divider>
                {iconRow.map((iconKey) => iconRenderMap[iconKey](iconKey))}
            </CompositeBlock>
        ));
    }

    private renderTextShadowVisualizer() {
        const { drivers, siteVarsDriver, panelHost } = this.props;
        const { textShadowVisualizerShown } = this.state;

        const translate = getTranslate(panelHost);

        return (
            <Drawer
                key="text-shadow-drawer"
                drawerKey="text-shadow-drawer-inner"
                className={classes.textShadowDrawer}
                open={textShadowVisualizerShown}
                title={translate(StylablePanelTranslationKeys.controller.shadows.title)}
                onOpenClick={() =>
                    this.setState({
                        textShadowVisualizerShown: !textShadowVisualizerShown,
                    })
                }
            >
                {siteVarsDriver?.sheet && (
                    <TextShadowVisualizer
                        key="text-shadow"
                        className={classes.textShadowVisualizer}
                        drivers={drivers}
                        siteVarsDriver={siteVarsDriver}
                        value={createVisualizerValueFromDeclarationMap(this.stroke.value)}
                        panelHost={panelHost}
                        onChange={this.changeTextShadow}
                    />
                )}
            </Drawer>
        );
    }

    private renderGlyphMode() {
        const { panelHost, drivers } = this.props;

        const { color } = this.declarationMapValue;
        const translate = getTranslate(panelHost);

        let opacityValue = 100;
        try {
            const parsedColor = chroma(color || '');
            opacityValue = Math.floor(parsedColor.alpha() * 100);
        } catch {
            //
        }

        const units = getDimensionUnits({
            id: DIMENSION_ID.OPACITY,
            dimensionUnits: panelHost?.dimensionUnits,
        });

        // TODO: Extract to ColorInput component
        return (
            <CompositeBlock
                key="color"
                className={classes.controllerBlock}
                title={translate(StylablePanelTranslationKeys.controller.separators.colorLabel)}
                divider
            >
                <div className={classes.colorInputWrapper}>
                    <BackgroundBox
                        className={classes.colorBox}
                        value={color}
                        noColor={!color}
                        showNoColorDiagonal
                        onClick={() => this.openColorPicker(ColorPickerTextType.Text)}
                    />
                    <OpacityVisualizer
                        drivers={drivers}
                        className={classes.inputElementOpacity}
                        value={createVisualizerValueFromDeclarationMap({
                            opacity: `${opacityValue}%`,
                        })}
                        config={{ units }}
                        opacitySliderColor={color}
                        onChange={(value) => {
                            const { opacity } = createDeclarationMapFromVisualizerValue(value, { value: [], drivers });
                            if (opacity) {
                                this.changeColorAlpha(opacity);
                            }
                        }}
                        isDisabled={!color}
                    />
                </div>
            </CompositeBlock>
        );
    }

    private openColorPicker(colorPickerType: ColorPickerTextType) {
        const { siteVarsDriver, panelHost } = this.props;

        if (!panelHost?.onOpenPanel) {
            return;
        }

        const translate = getTranslate(panelHost);

        let title = '';
        let currentColor: string | undefined;
        let onChange: (value: string) => void = () => {
            /**/
        };
        let onReset: (() => void) | undefined;

        switch (colorPickerType) {
            case ColorPickerTextType.Text:
                title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.textTitle);
                currentColor = this.declarationMapValue.color;
                onChange = this.changeFromBlock.bind(this, 'color', ID_FUNCTION);
                break;
            case ColorPickerTextType.Background:
                title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.backgroundTitle);
                currentColor = this.declarationMapValue['background-color'];
                onChange = this.changeFromBlock.bind(this, 'background-color', ID_FUNCTION);
                onReset = () => this.changeFromBlock('background-color', ID_FUNCTION, '');
                break;
            case ColorPickerTextType.Stroke:
                title = translate(StylablePanelTranslationKeys.controller.text.colorPickers.strokeTitle);
                currentColor = this.stroke.color;
                onChange = this.changeFromBlock.bind(this, 'text-shadow', (changeValue: string) => {
                    const strokeOtherLayers = this.stroke.value;

                    if (changeValue) {
                        const strokeShadowLayers = strokeShadow(changeValue);
                        return strokeOtherLayers['text-shadow'] !== TEXT_SHADOW_NONE
                            ? `${strokeShadowLayers}, ${strokeOtherLayers['text-shadow']}`
                            : strokeShadowLayers;
                    }

                    return strokeOtherLayers['text-shadow'];
                });
                onReset = () => this.changeFromBlock('text-shadow', ID_FUNCTION, this.stroke.value['text-shadow']);
                break;
        }

        const colorPickerProps: ColorPickerProps = {
            className: classes.colorPicker,
            title,
            siteVarsDriver,
            currentColor,
            panelHost,
            onHover: onChange as (value: string | null) => void,
            onChange,
            onReset,
            // onClose: () => this.setState({colorPickerType: ColorPickerTextType.None}),
            // onBlur: () => this.setState({colorPickerType: ColorPickerTextType.None})
        };

        panelHost.onOpenPanel(ColorPicker.panelName, colorPickerProps);
    }

    private extractStroke(): Stroke {
        const { 'text-shadow': shadowValue } = this.declarationMapValue;

        if (shadowValue === undefined || shadowValue === TEXT_SHADOW_NONE) {
            return {
                color: undefined,
                value: { 'text-shadow': TEXT_SHADOW_NONE },
            };
        }

        const parsedShadow = this.props.siteVarsDriver?.sheet
            ? parseBoxShadow(this.props.siteVarsDriver.sheet.evalDeclarationValue(filterComments(shadowValue)))
            : [];

        const strokeCandidates: Record<
            string,
            {
                left?: number;
                right?: number;
                top?: number;
                bottom?: number;
            }
        > = {};
        parsedShadow.forEach((shadow, index) => {
            if (shadow.blurRadius !== undefined || shadow.spreadRadius !== undefined || shadow.inset) {
                return;
            }

            try {
                const color = chroma(shadow.color).hex();
                if (!strokeCandidates[color]) {
                    strokeCandidates[color] = {};
                }

                if (shadow.offsetX === -1 && shadow.offsetY === 0) {
                    strokeCandidates[color].left = index;
                } else if (shadow.offsetX === 1 && shadow.offsetY === 0) {
                    strokeCandidates[color].right = index;
                } else if (shadow.offsetX === 0 && shadow.offsetY === -1) {
                    strokeCandidates[color].top = index;
                } else if (shadow.offsetX === 0 && shadow.offsetY === 1) {
                    strokeCandidates[color].bottom = index;
                }
            } catch {
                //
            }
        });

        const strokeColor = Object.keys(strokeCandidates).find(
            (color) =>
                strokeCandidates[color].left !== undefined &&
                strokeCandidates[color].right !== undefined &&
                strokeCandidates[color].top !== undefined &&
                strokeCandidates[color].bottom !== undefined
        );

        if (!strokeColor) {
            return {
                color: undefined,
                value: { 'text-shadow': shadowValue },
            };
        }

        const newShadow = parsedShadow.filter(
            (_shadow, index) =>
                index !== strokeCandidates[strokeColor].left &&
                index !== strokeCandidates[strokeColor].right &&
                index !== strokeCandidates[strokeColor].top &&
                index !== strokeCandidates[strokeColor].bottom
        );

        return {
            color: strokeColor,
            value: { 'text-shadow': stringifyBoxShadow(newShadow) || TEXT_SHADOW_NONE },
        };
    }

    private changeFromBlock = (prop: string, valueFunction: (value: string) => string, value: string) => {
        const { onChange } = this.props;
        const { fontTheme } = this.state;

        if (!onChange) {
            return;
        }

        const changeValue: GenericDeclarationMap = {};

        const hasFontTheme = fontTheme !== CUSTOM_FONT_THEME_ID;
        const mixinDeclarations = hasFontTheme ? this.getMixinDeclarations(fontTheme) : [];
        const shouldBreakTheme = hasFontTheme && !!~mixinDeclarations.map((decl) => decl.prop).indexOf(prop);
        if (shouldBreakTheme) {
            this.setState({ fontTheme: CUSTOM_FONT_THEME_ID });

            changeValue[valueMapping.mixin] = removeMixin(fontTheme, this.declarationMapValue[valueMapping.mixin]);
            mixinDeclarations.forEach((decl) => (changeValue[decl.prop] = decl.value)); // TODO: evalDeclarationValue?
        }

        changeValue[prop] = valueFunction(value);
        onChange(controllerToVisualizerChange(changeValue, this.props));
    };

    private changeTextShadow = (declarations: OpenedDeclarationArray<ShadowProps>) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        let shadowValue = createDeclarationMapFromVisualizerValue(declarations as TextVisualizerValue, this.props)[
            'text-shadow'
        ];

        if (!shadowValue || shadowValue === TEXT_SHADOW_NONE) {
            shadowValue = '';
        }

        const strokeValue = this.stroke.color ? `${strokeShadow(this.stroke.color)}` : '';

        let newShadowValue = `${strokeValue}`;
        if (shadowValue) {
            newShadowValue = newShadowValue ? `${newShadowValue}, ${shadowValue}` : shadowValue;
        }

        onChange(controllerToVisualizerChange({ 'text-shadow': newShadowValue } as TextDeclarationMap, this.props));
    };

    private changeColorAlpha(value: string) {
        const { onChange } = this.props;
        const { color } = this.declarationMapValue;
        if (!onChange) {
            return;
        }

        try {
            const parsedColor = chroma(color || '');
            const numberValue = parseFloat(value);

            onChange(
                controllerToVisualizerChange(
                    { color: parsedColor.alpha(numberValue / 100).css() } as TextDeclarationMap,
                    this.props
                )
            );
        } catch {
            //
        }
    }

    private getMixinDeclarations = (name: string) => {
        const { siteVarsDriver } = this.props;

        const localMixinDeclarations = siteVarsDriver?.sheet ? getMixinDeclarations(siteVarsDriver.sheet, name) : [];
        if (localMixinDeclarations.length > 0) {
            return localMixinDeclarations;
        }

        return siteVarsDriver?.sheet ? getMixinDeclarations(siteVarsDriver?.sheet, name) : [];
    };
}

export const TextVisualizer = OptimisticWrapper<React.ComponentClass<TextVisualizerProps>, TextVisualizerValue>(
    TextVisualizerInner,
    {},
    true,
    visualizerOptimisticValueResolver
);
