import React from 'react';

import { DropDown, Option } from '@wixc3/stylable-panel-components';
import { GenericDeclarationMap, StylablePanelTranslationKeys } from '@wixc3/stylable-panel-drivers';

import type { TranslateFunc, VisualizerComponent } from '../../types';
import { getTranslate } from '../../hosts/translate';

import type { DeclarationVisualizerProps } from '../../types';
import type { SelectableFont, SelectableFonts } from '../../components';
import { style, classes } from './font-family-visualizer.st.css';
import { controllerToVisualizerChange, createDeclarationMapFromVisualizerValue } from '../../utils';

export const EMPTY_FONT_FAMILY = 'Default';

const getEmptyFontFamilyOption = (translate: TranslateFunc) =>
    ({
        id: EMPTY_FONT_FAMILY,
        displayName: translate(StylablePanelTranslationKeys.controller.text.fontFamilySelectorDefaultOption),
    } as Option);

const FONT_FAMILY_OPTION_ID_KEY = 'fontFamily';
const FONT_FAMILY_CSS_VALUE_KEY = 'cssFontFamily';

export interface FontFamilyVisualizerProps extends DeclarationVisualizerProps<FontFamilyProps> {
    disabled?: boolean;
    noCloseOnSelect?: boolean;
}

export type FontFamilyProps = 'font-family';

export type FontFamilyDeclarationMap = GenericDeclarationMap<FontFamilyProps>;

export class FontFamilyVisualizer
    extends React.Component<FontFamilyVisualizerProps>
    implements VisualizerComponent<FontFamilyProps>
{
    private selectableFonts: SelectableFonts[] = [];
    private fontOptions: Option[] = [];

    private declarationMapValue: FontFamilyDeclarationMap;

    constructor(props: FontFamilyVisualizerProps) {
        super(props);

        this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);

        this.setFontOptions(props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: FontFamilyVisualizerProps) {
        this.declarationMapValue = createDeclarationMapFromVisualizerValue(props.value, props);
    }

    public render() {
        const {
            panelHost,
            // onHover,
            disabled,
            noCloseOnSelect,
            className,
        } = this.props;

        const translate = getTranslate(panelHost);

        return (
            <DropDown
                className={style(classes.root, className)}
                value={this.getValue()}
                options={[getEmptyFontFamilyOption(translate)].concat(this.fontOptions)}
                // onHover={onHover}
                onSelect={this.handleSelect}
                disabled={disabled}
                noCloseOnSelect={noCloseOnSelect}
                data-aid="st_font_family_selector"
            />
        );
    }

    private setFontOptions(props: FontFamilyVisualizerProps) {
        const { panelHost } = props;

        this.selectableFonts = [];
        this.fontOptions = [];

        if (!panelHost || !panelHost.getSelectableFonts || !panelHost.getFontsItems) {
            return;
        }

        const translate = getTranslate(panelHost);

        this.selectableFonts = panelHost.getSelectableFonts();
        const fontItems = panelHost.getFontsItems(this.selectableFonts);

        fontItems.forEach((fontGroup) => {
            this.fontOptions = this.fontOptions.concat([
                {
                    id: fontGroup.groupName,
                    displayName: translate(fontGroup.groupName),
                    disabled: true,
                },
            ]);
            fontGroup.items.forEach((fontItem) => {
                this.fontOptions = this.fontOptions.concat([
                    {
                        id: fontItem.value,
                        displayName: fontItem.label,
                        subLabel: fontItem.example ? translate(fontItem.example) : undefined,
                        font: fontItem.cssFontFamily,
                    },
                ]);
            });
        });
    }

    private getValue() {
        const value = this.declarationMapValue?.['font-family'] ?? EMPTY_FONT_FAMILY;

        return value !== EMPTY_FONT_FAMILY
            ? (this.searchSelectableFonts(
                  value,
                  FONT_FAMILY_CSS_VALUE_KEY,
                  FONT_FAMILY_OPTION_ID_KEY,
                  true
              ) as string) || EMPTY_FONT_FAMILY
            : EMPTY_FONT_FAMILY;
    }

    private handleSelect = (itemId: string) => {
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        if (itemId === EMPTY_FONT_FAMILY) {
            onChange(
                controllerToVisualizerChange(
                    {
                        'font-family': itemId,
                    },
                    this.props
                )
            );

            return;
        }

        const selectedItem = this.fontOptions.find((option) => option.id === itemId);
        if (selectedItem) {
            const cssFontFamily = this.searchSelectableFonts(
                selectedItem.id,
                FONT_FAMILY_OPTION_ID_KEY,
                FONT_FAMILY_CSS_VALUE_KEY
            ) as string | undefined;
            if (cssFontFamily) {
                onChange(
                    controllerToVisualizerChange(
                        {
                            'font-family': cssFontFamily,
                        },
                        this.props
                    )
                );
            }
        }
    };

    private searchSelectableFonts = (
        value: string,
        searchKey: keyof SelectableFont,
        valueKey: keyof SelectableFont,
        matchPrefix = false
    ) => {
        for (const currSelectableFont of this.selectableFonts) {
            if (!currSelectableFont.fonts) {
                continue;
            }

            for (const currFont of currSelectableFont.fonts) {
                const searchString = currFont[searchKey] as string;

                if ((matchPrefix && value.startsWith(searchString)) || value === searchString) {
                    return currFont[valueKey];
                }
            }
        }

        return undefined;
    };
}
