import { cssEnumOptions, Context } from '@wixc3/stylable-panel-drivers';

export function getInputContexts(prop: string, vars: string[] = []): Context[] {
    return [
        {
            suggestions: [...vars],
            trigger: /value\((\S*)(?!.*value\()/,
            postWrapper: (suggestion: string) => `value(${suggestion})`,
            title: 'value()',
        },
        { suggestions: cssEnumOptions(prop) },
    ];
}
