import React from 'react';

import { Title } from '@wixc3/stylable-panel-components';
import { Refresh } from '@wixc3/stylable-panel-common-react';
import {
    StylableDriver,
    StylableTweak,
    SiteVarsDriver,
    CollectionDriver,
    Collection,
    FontData,
    ArchetypeList,
} from '@wixc3/stylable-panel-drivers';

import type { StylePanelChildProps } from '../style-panel/style-panel';
import ColorPackage from './color-package';
import FontPackage, { DEFAULT_FONT_SIZE, DEFAULT_FONT_FAMILY } from './font-package';

import BoxPackage, { BoxPackageProps } from './box-package';
import type { CategoryConfig } from '../../types';

import { DefaultPreview } from '../customization-panel/controllers/block-variants/previews/block-variants-previews';
import CornerTweak from './box-tweaks/corner-tweak';

import { style, classes } from './tweak-panel.st.css';

type TweakChange = (index: number, value: string) => void;

export interface TweakPanelProps extends StylePanelChildProps {
    selector: string;
    stylableDriver: StylableDriver;
    sheetPath: string;
    siteVarsDriver?: SiteVarsDriver;
    fontFamilies: string[];
    categoryConfiguration: CategoryConfig;
    archetypeList?: ArchetypeList; // untested passing down of prop. TODO Remove once archetype list is passed as context
}

export interface TweakPanelState {
    activeBoxPackage: string | undefined;
}

export class TweakPanel extends React.Component<TweakPanelProps, TweakPanelState> {
    private colorDeclarations: Collection = {};
    private imageDeclarations: Collection = {};
    private shadowDeclarations: Collection = {};
    private cornerDeclarations: Collection = {};
    private borderDeclarations: Collection = {};
    private fontDeclarations: FontData = { colorDeclarations: {}, fontDeclarations: {}, sizeDeclarations: {} };

    private colorPackageOnChangeColor!: TweakChange;
    private colorPackageOnChangeImage!: TweakChange;
    private fontPackageOnChangeFamily!: TweakChange;
    private fontPackageOnChangeSize!: TweakChange;
    private fontPackageOnChangeColor!: TweakChange;

    constructor(props: TweakPanelProps) {
        super(props);

        this.state = {
            activeBoxPackage: undefined,
        };
        this.extraBoxPackageProps = this.extraBoxPackageProps.bind(this);

        this.collectPackageDeclarations(this.props);
    }

    // eslint-disable-next-line react/no-deprecated
    public componentWillUpdate(props: TweakPanelProps) {
        if (this.props.selector !== props.selector) {
            this.collectPackageDeclarations(props);
        }
    }

    public render() {
        const { stylableDriver, sheetPath, siteVarsDriver, fontFamilies, className } = this.props;
        // TODO: Move to Context
        const sheetDriver = stylableDriver.getStylesheet(sheetPath);

        if (!sheetDriver) {
            return <div className={style(classes.root, className)} />;
        }

        return (
            <div className={style(classes.root, className)}>
                <div className={classes.refreshButton} onClick={this.handleRefresh}>
                    <Refresh />
                </div>
                <ColorPackage
                    className={classes.colorPackage}
                    colorDeclarations={this.colorDeclarations}
                    imageDeclarations={this.imageDeclarations}
                    sheetDriver={sheetDriver}
                    onChangeColor={this.colorPackageOnChangeColor}
                    onChangeImage={this.colorPackageOnChangeImage}
                    onGetGoingColorChange={(value: string | null) => this.onGetGoingChange('color', false, value)}
                    onGetGoingColorSelect={(value: string | null) => this.onGetGoingChange('color', true, value)}
                    onGetGoingImageChange={(value: string) => this.onGetGoingChange('background-image', true, value)}
                />
                <FontPackage
                    className={classes.fontPackage}
                    fontDeclarations={this.fontDeclarations}
                    sheetDriver={sheetDriver}
                    siteVarsDriver={siteVarsDriver}
                    fontFamilies={fontFamilies}
                    onChangeFamily={this.fontPackageOnChangeFamily}
                    onChangeSize={this.fontPackageOnChangeSize}
                    onChangeColor={this.fontPackageOnChangeColor}
                    onGetGoingFamilyChange={(value: string | null) =>
                        this.onGetGoingChange('font', false, value ? `${DEFAULT_FONT_SIZE} ${value}` : null)
                    }
                    onGetGoingFamilySelect={(value: string) =>
                        this.onGetGoingChange('font', true, `${DEFAULT_FONT_SIZE} ${value}`)
                    }
                    onGetGoingSizeChange={(value: string) =>
                        this.onGetGoingChange('font', true, `${value} ${DEFAULT_FONT_FAMILY}`)
                    }
                    onGetGoingColorChange={(value: string | null) => this.onGetGoingChange('color', false, value)}
                    onGetGoingColorSelect={(value: string) => this.onGetGoingChange('color', true, value)}
                />

                <Title text="Box Styles" />
                <div className={classes.boxPackages}>
                    <BoxPackage
                        className={style(classes.borderPackage, classes.package)}
                        boxDeclarations={this.borderDeclarations}
                        title={'Border'}
                        sheetPath={sheetPath}
                        stylableDriver={stylableDriver}
                        {...this.extraBoxPackageProps('borders', 'border')}
                    />
                    <BoxPackage
                        className={style(classes.shadowPackage, classes.package)}
                        boxDeclarations={this.shadowDeclarations}
                        title={'Shadow'}
                        sheetPath={sheetPath}
                        stylableDriver={stylableDriver}
                        {...this.extraBoxPackageProps('shadow', 'box-shadow')}
                    />
                    <BoxPackage
                        className={style(classes.cornerPackage, classes.package)}
                        boxDeclarations={this.cornerDeclarations}
                        title={'Corners'}
                        sheetPath={sheetPath}
                        stylableDriver={stylableDriver}
                        customTweakComponent={CornerTweak}
                        {...this.extraBoxPackageProps('corners', 'border-radius')}
                    />
                </div>
            </div>
        );
    }

    public extraBoxPackageProps(
        id: string,
        baseProp: string
    ): Partial<BoxPackageProps> & { categoryId: string; categoryConfiguration: CategoryConfig; baseProp: string } {
        return {
            categoryId: id,
            open: this.state.activeBoxPackage === id,
            baseProp,
            onClickPackageButton: () => {
                if (id === this.state.activeBoxPackage) {
                    this.clearBoxPopups();
                } else {
                    this.setState({ activeBoxPackage: id });
                }
            },
            onClickPopupClose: () => this.clearBoxPopups(),
            archetypeList: this.props.archetypeList,
            categoryConfiguration: this.props.categoryConfiguration,
            customBlockVariantPreview: DefaultPreview,
            onGetGoingChange: (value) => this.onGetGoingChange(baseProp, false, value),
            onGetGoingSelect: (value) => this.onGetGoingChange(baseProp, true, value),
        };
    }
    private collectPackageDeclarations(props: TweakPanelProps) {
        const { selector, stylableDriver, sheetPath } = props;

        const tweakDriver = new StylableTweak(stylableDriver);

        this.colorDeclarations = tweakDriver.collectSelectorColorDeclarations(sheetPath, selector);
        this.imageDeclarations = tweakDriver.collectSelectorImageDeclarations(sheetPath, selector);
        this.fontDeclarations = tweakDriver.collectSelectorFontDeclarations(sheetPath, selector);
        this.shadowDeclarations = tweakDriver.collectSelectorGenericDeclarations(sheetPath, selector, 'box-shadow');
        this.borderDeclarations = tweakDriver.collectSelectorGenericDeclarations(sheetPath, selector, 'border');
        this.cornerDeclarations = tweakDriver.collectSelectorGenericDeclarations(sheetPath, selector, 'border-radius');

        this.colorPackageOnChangeColor = this.change.bind(this, this.colorDeclarations);
        this.colorPackageOnChangeImage = this.change.bind(this, this.imageDeclarations);

        this.fontPackageOnChangeFamily = this.change.bind(this, this.fontDeclarations.fontDeclarations);
        this.fontPackageOnChangeSize = this.change.bind(this, this.fontDeclarations.sizeDeclarations);
        this.fontPackageOnChangeColor = this.change.bind(this, this.fontDeclarations.colorDeclarations);
    }

    private clearBoxPopups() {
        this.setState({ activeBoxPackage: undefined });
    }

    private change(declarations: Collection, index: number, value: string) {
        CollectionDriver.changeValue(declarations[Object.keys(declarations)[index]], value);
    }

    private handleRefresh = () => {
        this.collectPackageDeclarations(this.props);
        this.forceUpdate();
    };

    private onGetGoingChange(prop: string, refresh: boolean, value: string | null) {
        const { selector, stylableDriver, sheetPath } = this.props;
        const sheetDriver = stylableDriver.getStylesheet(sheetPath);
        if (sheetDriver) {
            sheetDriver.upsertDeclarationToRule(selector, { prop, value: value || undefined });
            refresh && this.handleRefresh();
        }
    }
}
