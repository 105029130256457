import React from 'react';

import {
    DimensionInput,
    DimensionVisualizerFactoryProps,
    DimensionVisualizerComponentProps,
} from '@wixc3/stylable-panel-components';

import type { DeclarationVisualizerProps, VisualizerComponent } from '../../types';
import { getTextFromSinglePropVisualizer, createVisualizerValueFromDeclarationMap } from '../../utils';

import { style, classes } from './dimension-visualizer.st.css';

export interface DimensionVisualizerProps<MAIN extends string>
    extends DeclarationVisualizerProps<MAIN>,
        DimensionVisualizerComponentProps {}

export type DimensionInputVisualizerClass<MAIN extends string> = React.ComponentClass<DimensionVisualizerProps<MAIN>>;

export function DimensionVisualizerFactory<MAIN extends string>(
    main: MAIN,
    dimensionInputProps: DimensionVisualizerFactoryProps
): DimensionInputVisualizerClass<MAIN> {
    class DimensionVisualizer
        extends React.Component<DimensionVisualizerProps<MAIN>>
        implements VisualizerComponent<MAIN>
    {
        public static INPUT_PROPS: MAIN[] = [main];
        private value: string | undefined;

        constructor(props: DeclarationVisualizerProps<MAIN>) {
            super(props);
            this.value = getTextFromSinglePropVisualizer(main, props);
        }

        // eslint-disable-next-line react/no-deprecated
        public componentWillUpdate(props: DeclarationVisualizerProps<MAIN>) {
            this.value = getTextFromSinglePropVisualizer(main, props);
        }

        render() {
            const {
                className,
                onChange,
                onBlur,
                onFocus,
                isDisabled,
                opacitySliderColor,
                forceFocus,
                tooltipContent,
                config,
            } = this.props;

            return (
                <DimensionInput
                    {...dimensionInputProps}
                    className={style(classes.root, className)}
                    value={this.value}
                    config={config}
                    onChange={(value) => {
                        if (onChange) {
                            onChange(createVisualizerValueFromDeclarationMap({ [main]: value }));
                        }
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    isDisabled={isDisabled}
                    opacitySliderColor={opacitySliderColor}
                    forceFocus={forceFocus}
                    tooltipContent={tooltipContent}
                />
            );
        }
    }

    return DimensionVisualizer;
}
